import { InAppTemplate } from "../../../shared/templates/portal";
import ResultCards from "./SettingCards";

const ResultSettings = () => {
 
  return (
    <InAppTemplate
      childComponent={<ResultCards />}
      pageTitle="RESULT SETTINGS"
    />
  );
};

export default ResultSettings;