import React from "react";
import { InAppTemplate } from "../../../shared/templates/portal";
import DocumentsReviewContent from "./DocumentsReviewContent";
import { ToastContainer } from "react-toastify";
const DocumentsReview = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<DocumentsReviewContent />}
        pageTitle="REVIEW DOCUMENTS"
      />
    </>
  );
};

export default DocumentsReview;
