import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { CbsPayment } from "../InvoiceCheckerWrap";
import Skeleton from "react-loading-skeleton";

interface CbsInvoiceTableProps {
  loading: boolean;
  paymentData: CbsPayment;
  isExpanded: any;
  setIsExpanded: any;
}

const CbsInvoiceTable: React.FC<CbsInvoiceTableProps> = ({
  loading,
  paymentData,
  isExpanded,
  setIsExpanded
}) => {

  // Map amountPaid and transactionDate together
  const transactionDetails = paymentData.amountPaid?.map(
    (amount: number, index: number) => ({
      amountPaid: amount,
      transactionDate: paymentData.transactionDate[index],
    })
  );

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Total Amount Paid</th>
          <th>Invoice Number</th>
          <th>Recipient</th>
          <th>Status</th>
          <th>Generated Date</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          [...Array(1)].map((_, index) => (
            <tr key={index}>
              {[...Array(5)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : (
          <>
            <tr>
              <td
                onClick={() => setIsExpanded(!isExpanded)}
                style={{
                  cursor: "pointer",
                  fontSize: "15px",
                  color:
                    paymentData.status === "PAID"
                      ? "#00a759"
                      : paymentData.status === "UNPAID"
                      ? "red"
                      : "",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    marginRight: "8px",
                    transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                >
                  ▶
                </span>
                <b>₦{paymentData.totalAmountPaid}</b>
              </td>

              <td>{paymentData.invoiceNumber}</td>
              <td>{paymentData.recipient}</td>
              <td
                style={{
                  color:
                    paymentData.status === "PAID"
                      ? "#00a759"
                      : paymentData.status === "UNPAID"
                      ? "red"
                      : "",
                  fontWeight: "800",
                  fontSize: "12px",
                }}
              >
                {paymentData.status}
              </td>
              <td>{paymentData.generatedDate}</td>
            </tr>
            {isExpanded && (
              <tr>
                <td colSpan={5}>
                  <Table size="sm" bordered>
                    <thead>
                      <tr>
                        <th>Amount Paid</th>
                        <th>Transaction Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionDetails?.map((item: any, idx: number) => (
                        <tr key={idx}>
                          <td>
                            <b>₦{item.amountPaid}</b>
                          </td>
                          <td>{item.transactionDate}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default CbsInvoiceTable;
