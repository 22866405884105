import { APP_MENU } from "../../_helpers/constants";
import Dashboard from "../../../assets/sidebar-1.png";
import Course from "../../../assets/sidebar-2.png";
import Report from "../../../assets/side-7.png";
import Admission from "../../../assets/sidebar-3.png";
import Students from "../../../assets/sidebar-4.png";
import Settings from "../../../assets/sidebar-5.png";
import Notepad from "../../../assets/Notepad.png";
import CourseSubject from "../../../assets/course-subject.png";
import EdocIcon from "../../../assets/EDocumentation.png";
import HostelIcon from "../../../assets/hostel2.png";
import ResultIcon from "../../../assets/result.png";

export const menuList = [
  {
    routeTo: APP_MENU.DASHBOARD,
    name: "Dashboard",
    icon: Dashboard,
    accessRoles: ["Admin", "Bursary"],
  },
  {
    routeTo: APP_MENU.ADMISSION_MANAGEMENT,
    name: "Admissions",
    icon: Admission,
    accessRoles: ["Admin", "Admission_Manager"],
  },
  {
    routeTo: APP_MENU.COURSES,
    name: "Preset Courses",
    icon: Course,
    accessRoles: ["Admin", "Course_Advisor", "Course_Presetter"],
  },
  {
    routeTo: APP_MENU.STUDENTS,
    name: "Students",
    icon: Students,
    accessRoles: ["Admin", "Student_Manager"],
  },
  {
    routeTo: APP_MENU.POST_UTME,
    name: "Post UTME",
    icon: Notepad,
    accessRoles: ["Admin", "PostUtme_Manager", "Bursary"],
  },
  {
    routeTo: APP_MENU.BURSARY_REPORTS,
    name: "Bursary Reports",
    icon: Report,
    accessRoles: [
      "Admin",
      "Bursary",
      "Nanniss_Manager",
      "Sug_Manager",
      "Invoice_Checker",
    ],
  },
  {
    routeTo: APP_MENU.EO_REPORT,
    name: "EO Reports",
    icon: Report,
    accessRoles: ["Admin", "EO_Report"],
  },
  {
    routeTo: APP_MENU.PORTAL_SETTINGS,
    name: "Portal Settings",
    icon: Settings,
    accessRoles: [
      "Admin",
      "Student_Manager",
      "Admission_Manager",
      "Student_Creator",
      "Mis_Manager",
      "Invoice_Checker",
      "Invoice_Deleter",
    ],
  },
  {
    routeTo: APP_MENU.REPORTS,
    name: "Reports",
    icon: Report,
    accessRoles: ["Admin", "Report_Manager"],
  },
  {
    routeTo: APP_MENU.COURSE_SUBJECT,
    name: "Courses",
    icon: CourseSubject,
    accessRoles: ["Admin", "Course_Manager"],
  },

  {
    routeTo: APP_MENU.E_DOCUMENTATION,
    name: "E-Documentation",
    icon: EdocIcon,
    accessRoles: [
      "Admin",
      "Faculty_Officer",
      "Academic_Officer",
      "Ict_Officer",
      "Bursary",
    ],
  },

  {
    routeTo: APP_MENU.HOSTEL_MANAGEMENT,
    name: "Hostel",
    icon: HostelIcon,
    accessRoles: ["Admin", "Hostel_Manager", "Hostel_Porter"],
  },
  {
    routeTo: APP_MENU.RESULTS_DASHBOARD,
    name: "Results",
    icon: ResultIcon,
    accessRoles:["Admin", "Lecturer" ]
},
{  routeTo:APP_MENU.LOGOUT,
    name: 'Logout',
    accessRoles:[]
},

]