import { useEffect, useState } from "react";
import Navigation from "../../../../shared/components/navigation/Navigation";
import ScoreOptimizerTable from "./components/ScoreOptimzersTable";
import { AddOrUpdateOptimizerModal } from "./components/ScoreOptimizerModal";
import { getExamScoresOptimizer } from "../../../../redux/action/results";
import { IOptimizedScore } from "../../../../utils/types";
import AddButton from "../../../../shared/components/download-components/AddButton";


let initialValues: IOptimizedScore  = {
  examScoreOptimizerUniqueId: "",
  originalScore: undefined as unknown as number,
  optimizedScore: undefined as unknown as number,
  originalGrade: "",
  optimizedGrade:"",
};

const ScoreOptimizer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedScore, setSelectedScore] = useState<IOptimizedScore>(initialValues);
  const [optimizedScores, setOptimizedScores] = useState<IOptimizedScore[]>([]);

  const fetchOptimizedScores = async () => {
    setLoading(true);
    try {
      const response = await getExamScoresOptimizer();
      if (response.status === 200) {
        setLoading(false);
        setOptimizedScores(response.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOptimizedScores();
  }, []);

  return (
    <div className="settings-wrap">
      <div className="back">
        <Navigation to="/app/results/settings" text="Back to Result Settings" />
      </div>

      <AddOrUpdateOptimizerModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedScore={selectedScore}
        setSelectedScore={setSelectedScore}
        initialValues={initialValues}
        fetchOptimizedScores={fetchOptimizedScores}

      />

      <div className="add-grade">
      <AddButton
       onClick={() => {
        setShowModal(true);
      }}
       buttonText= "Add Grade"
       variant= "light"
        />
        {/* <button
          className="add-btn"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add Score
        </button> */}
      </div>
      <ScoreOptimizerTable
        loading={loading}
        setLoading={setLoading}
        setShowModal={setShowModal}
        setSelectedScore={setSelectedScore}
        optimizedScores={optimizedScores}
        fetchOptimizedScores={fetchOptimizedScores}
      />
    </div>
  );
};

export default ScoreOptimizer;
