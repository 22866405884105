import { Table } from "react-bootstrap";
import "./index.scss";
import Skeleton from "react-loading-skeleton";

const ReportTable = ({
  items,
  show,
  loading,
  currentPage,
}: any) => {
  return (
    <div className="table-wrapper ">
      {show && (
        <Table bordered hover>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Full Name</th>
              <th>Matric Number</th>
              <th>Site Name</th>
              <th>Block Name</th>
              <th>Room No</th>
              <th>Level</th>
              <th>Course</th>
              <th>Date Paid</th>
            </tr>
          </thead>
          <tbody>
            {loading
              ? [...Array(1)].map((_, index) => (
                  <tr key={index}>
                    {[...Array(9)].map((_, index) => (
                      <td key={index}>
                        <Skeleton height={20} />
                      </td>
                    ))}
                  </tr>
                ))
              : items.map((item: any, index: any) => {
                  return (
                    <tr key={index}>
                      <td>{(currentPage - 1) * 20 + index + 1}</td>
                      <td>{item.fullName}</td>
                      <td>{item.matricNumber}</td>
                      <td>{item.site}</td>
                      <td>{item.block}</td>
                      <td>{item.room}</td>
                      <td>{item.level}</td>
                      <td>{item.program}</td>
                      <td>{item.paidOn}</td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ReportTable;
