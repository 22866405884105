import { connect } from "react-redux";
import { useEffect } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../../shared/components/alert-msg";
import Logo from "../../../assets/logo2.png";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { adminOnboarding } from "../../../redux/action/onboarding";
import { appConstantsOnboarding } from "../../../redux/action-constants/onboarding";

import "../index.scss";
import { Link } from "react-router-dom";

const adminForgotPwActionRequest = async ({ pageProps, payload }: any) => {
  await pageProps.AdminForgotPwAction(payload);
};

const AdminForgotPw = (pageProps: any) => {
  const screenResolution: any = useWindowSize();
  const reloadVariable = localStorage.getItem("adRld");
  let requestInfo = pageProps?.adminForgotPwRequest;

  let yearTxt = new Date().getFullYear();

  useEffect(() => {
    adminForgotPwActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    useEffect(() => {
      if (!reloadVariable) {
        localStorage.setItem("adRld", JSON.stringify(true));
        window.location.reload();
       }
    }, [reloadVariable]);

  let checkValidationSchema = Yup.object().shape({
    userName: Yup.string().required("Required"),
  });
  let initialValues: any = { userName: "" };
  return (
    <div className="onboarding_wrap reset">
      <div className="login-form-wrapper">
        <div className="onboarding_content splitted">
          {screenResolution?.width >= 767 && (
            <div className="each_side summary">
              <div>
                <div className="logo">
                  <img src={Logo} alt="" />
                </div>
                <div className="school_name">
                  IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                </div>
                <div className="student_type_txt">RESET PASSWORD</div>
              </div>
            </div>
          )}
          {screenResolution?.width <= 766 && (
            <div className="mobile_heading">
              <div className="logo">
                <img src={Logo} alt="" />
              </div>
              <div className="school_name">
                IBRAHIM BADAMASI BABANGIDA UNIVERSITY
              </div>
              {/* <div className="student_type_txt">FULL-TIME  STUDENT <br /> SIGN IN</div> */}
            </div>
          )}
          <div className="each_side form_info">
            <div className="form_heading">FORGOT PASSWORD</div>
            {requestInfo.request_status !==
              appConstantsOnboarding?.FORGOT_PASSWORD_SUCCESS && (
              <Formik
                initialValues={initialValues}
                validationSchema={checkValidationSchema}
                onSubmit={async (values) => {
                  let { userName } = values;
                  let payload: any = `?email=${userName}`;

                  await adminForgotPwActionRequest({ pageProps, payload });
                }}
              >
                {(props: FormikProps<any>) => {
                  const {
                    values,
                    touched,
                    errors,
                    // handleBlur,
                    handleChange,
                    // isSubmitting,
                  } = props;
                  return (
                    <Form>
                      <div className="form-group">
                        <label className="label">Username/Email </label>
                        <div
                          className={
                            errors.userName && touched.userName
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={values.userName}
                            id="userName"
                            name="userName"
                            type="text"
                            onChange={handleChange("userName")}
                            placeholder="Enter Username/Email"
                            className={`input-wrap ${
                              errors.userName && touched.userName
                                ? "is-invalid-input "
                                : !errors.userName && values.userName !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="userName"
                          className="form-input-error"
                          component="div"
                        />
                      </div>

                      {requestInfo.request_status ===
                        appConstantsOnboarding?.FORGOT_PASSWORD_FAILURE && (
                        <AlertMsg
                          type="error"
                          message={requestInfo?.request_data.error}
                        />
                      )}
                      <div className="submit_cta">
                        <button
                          disabled={requestInfo?.is_request_processing}
                          className="btn shaded"
                          type="submit"
                        >
                          {requestInfo?.is_request_processing
                            ? "Please wait..."
                            : "Reset Password"}
                        </button>
                      </div>

                      <div className="forgot_cta">
                        <Link to="/">Sign In</Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
            {requestInfo.request_status ===
              appConstantsOnboarding?.FORGOT_PASSWORD_SUCCESS && (
              <>
                <AlertMsg
                  type="success"
                  message={requestInfo?.request_data?.response}
                />
                <div className="forgot_cta">
                  <Link to="/">Sign In</Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* {screenResolution?.width >= 767 && ( */}
      <div className="login-footer">
        <p>
          {" "}
          <span>&copy; {yearTxt}</span> IBB University. All rights reserved
        </p>
        <p>
          Developed by{" "}
          <Link
            to="https://gigbadi.com.ng/"
            target="_blank"
            className="footer-link"
          >
            Gigbadi
          </Link>
        </p>
      </div>
      {/* )} */}
    </div>
  );
};

const mapDispatchToProps = {
  AdminForgotPwAction: adminOnboarding.AdminForgotPw,
};
const mapStateToProps = (state: any) => ({
  adminForgotPwRequest: state.allOnboardingReducers.adminForgotPw,
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminForgotPw);
