import Navigation from "../../../../shared/components/navigation/Navigation";
import SearchStudentRoom from "./components/SearchStudentRoom";
import "./index.scss";
import {
  availableAccommodation,
  getStudentMatricSearch,
} from "../../../../redux/action/hostels";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import RoomSwap from "./components/RoomSwap";
import { LoadingItem } from "../../../../shared/components/loading";

export interface Room {
  roomName: string;
  roomUniqueId: string;
}

export interface Block {
  blockName: string;
  blockUniqueId: string;
  rooms: Room[];
}

interface SingleSite {
  siteName: string;
  blocks: Block[];
}
const AccommodationSwap = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>(null);

  // State to track selected block and room per site
  const [selectedData, setSelectedData] = useState<{
    [key: string]: {
      blockId: string;
      roomId: string;
      blockName: string;
      roomName: string;
    };
  }>({});
  const [availableHostels, setAvailableHostels] = useState<SingleSite[]>([]);
  const [noRooms, setNoRooms] = useState("");
  const [inputError, setInputError] = useState(false);

  const initialValue = {
    matricNumber: "",
  };

  // Handle Block Selection
  const handleBlockChange = (siteName: string, blockUniqueId: string) => {
    const selectedSite = availableHostels.find(
      (site) => site.siteName === siteName
    );
    const selectedBlock = selectedSite?.blocks.find(
      (block) => block.blockUniqueId === blockUniqueId
    );

    setSelectedData({
      [siteName]: {
        blockId: blockUniqueId,
        roomId: "",
        blockName: selectedBlock?.blockName || "",
        roomName: "",
      }, // Reset room when block changes
    });
    setInputError(false);
  };

  const handleRoomSelect = (siteName: string, roomUniqueId: string) => {
    const selectedSite = availableHostels.find(
      (site) => site.siteName === siteName
    );
    const selectedBlock = selectedSite?.blocks.find(
      (block) => block.blockUniqueId === selectedData[siteName]?.blockId
    );
    const selectedRoom = selectedBlock?.rooms.find(
      (room) => room.roomUniqueId === roomUniqueId
    );
    setSelectedData((prev) => ({
      ...prev,
      [siteName]: {
        ...prev[siteName],
        roomId: roomUniqueId,
        roomName: selectedRoom?.roomName || "",
      },
    }));
    setInputError(false);
  };

  const fetchCurrentRoom = useCallback(async (matricNumber: string) => {
    setLoading(true);
    setShow(false);
    try {
      const response = await getStudentMatricSearch(matricNumber);
      if (response.status === 200) {
        setShow(true);
        setData(response.data);

        if (response.data.roomName) {
          const roomResponse = await availableAccommodation(matricNumber);

          if (roomResponse?.data?.length > 0) {
            setAvailableHostels(roomResponse.data);
          } else if (roomResponse?.response?.status === 404) {
            setNoRooms(roomResponse?.response?.data?.message);
          }
        } else {
          toast.info("No room allocated to student");
        }
      } else if (response?.response?.data?.status === 404) {
        setShow(false);
        toast.error("No record found");
      } else {
        toast.info("No record found");
      }
    } catch (error) {
      toast.error("Failed to fetch records");
    } finally {
      setLoading(false);
    }
  }, []);
  return (
    <div className="room-swap-container">
      <div className="swap-container">
        <div className="back-to-portal">
          <Navigation
            to="/app/hostel/settings"
            text="Back to Accommodation Settings"
          />
        </div>

        {loading && <LoadingItem />}

        <SearchStudentRoom
          fetchCurrentRoom={fetchCurrentRoom}
          initialValue={initialValue}
          loading={loading}
          show={show}
          data={data}
        />

        {availableHostels.length > 0 && <h5>Select a new room to swap</h5>}
        <div className="site-wrapper">
          {availableHostels.length ? (
            availableHostels.map((site: SingleSite) => (
              <RoomSwap
                key={site.siteName}
                site={site}
                setData={setData}
                data={data}
                fetchCurrentRoom={fetchCurrentRoom}
                selectedSite={Object.keys(selectedData)[0]}
                selectedBlock={selectedData[site.siteName]?.blockId || ""}
                selectedBlockName={selectedData[site.siteName]?.blockName || ""}
                selectedRoom={selectedData[site.siteName]?.roomId || ""}
                selectedRoomName={selectedData[site.siteName]?.roomName || ""}
                handleBlockChange={handleBlockChange}
                onRoomSelect={handleRoomSelect}
                inputError={inputError}
              />
            ))
          ) : noRooms?.length ? (
            <h3>{noRooms}</h3>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AccommodationSwap;
