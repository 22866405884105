import { Button } from "react-bootstrap";
import { InAppTemplate } from "../../../../shared/templates/portal";
import InputField from "../../../../shared/components/InputField";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup"
import { DotLoader } from "react-spinners";
import "./index.scss"

export const RegisterStudentCourse = () => {
    const initialValue = { matricNumber: "" };

    let ValidationSchema = Yup.object().shape({
      matricNumber: Yup.string().required("Required"),
      })
  
    return (
        <div className="i">
      <div className="search-student">
        <Formik
          initialValues={initialValue}
          validationSchema={ValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const { matricNumber } = values;
            // await fetchPaymentHistory(matricNumber);
            setSubmitting(false);
          }}
        >
          {(props: FormikProps<any>) => {
            const { values, touched, errors, handleChange, isSubmitting } = props;
            return (
              <Form>
                <div className="search-form">
                  <div className="settings search-input">
                    <InputField
                      field="matricNumber"
                      label="Matric Number"
                      placeholder="Enter matric number"
                      value={values.matricNumber}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </div>
  
                  <div className="modal-footer search-footer">
                    <Button
                      className="submit-btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <DotLoader
                          color="white"
                          size={30}
                          aria-label="Searching..."
                        />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      </div>
    );
  };



const RegisterCourseWrap = () => {
 
  return (
    <InAppTemplate
      childComponent={<RegisterStudentCourse />}
      pageTitle="REGISTER STUDENT COURSES"
    />
  );
};

export default RegisterCourseWrap;