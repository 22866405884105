import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { InAppTemplate } from "../../shared/templates/portal/";
import "./index.scss";
import { BURSARYCARDS } from "../../shared/_helpers/constants";

const ReportsDashboard = () => {
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  const userRoles: string[] = loggedAccountInfo?.profile?.userRoles || [];

  const accessibleCards = BURSARYCARDS.filter((card) =>
    card.allowedRoles?.some((role) => userRoles.includes(role))
  ).sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className="settings_list">
      {accessibleCards.map((card, index) => (
        <div className="each_setting" key={index}>
          <div className="setting_name">{card.title}</div>
          <div className="setting_link">
            <Link className="btn" to={card.routeTo}>
              {card.buttonText}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

const AdminBursaryReportsWrap = () => {
  return (
    <InAppTemplate
      pageTitle={`BURSARY REPORTS`}
      childComponent={<ReportsDashboard  />}
    />
  );
};

const mapDispatchToProps = {};
const mapStateToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminBursaryReportsWrap);
