import { InAppTemplate } from "../../../shared/templates/portal";
import ReportContent from "./ReportContent";

const EDocumentationReports = () => {
  return (
    <InAppTemplate
      childComponent={<ReportContent />}
      pageTitle="E-DOCUMENTATION REPORT"
    />
  );
};

export default EDocumentationReports;
