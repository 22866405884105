import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import InputField from "../../../shared/components/InputField";
import DropDown from "../../../shared/components/dropdown";
import { allLevels } from "../../../shared/_helpers/constants";
import {
  addOrUpdateDocumentType,
  getDocumentTypes,
} from "../../../redux/action/edocumentation";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const AddDocumentModal = ({
  show,
  setShow,
  initialValues,
  selectedDocument,
  setSelectedDocument,
  setDocumentList,
}: any) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  let checkValidationSchema = Yup.object().shape({
    level: Yup.string().required("Level is required"),
    documentType: Yup.string().required("Document type is required"),
    eDocumentationDocsUniqueId: Yup.string().optional(),
  });
  return (
    <div>
      <Modal
        show={show}
        contentClassName="add-document-modal"
        onHide={() => {
          setShow(false);
          setSelectedDocument(initialValues);
        }}
        aria-labelledby="
            contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {selectedDocument?.eDocumentationDocsUniqueId
              ? "Update Document Type"
              : "Add Document Type"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="student-modal-form">
          <Container>
            <Formik
              initialValues={selectedDocument}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                setLoading(true);
                const res = await addOrUpdateDocumentType(values);
                if (res?.status === 200) {
                  toast.success(
                    values?.eDocumentationDocsUniqueId
                      ? "Document Type updated successfully"
                      : "Document Type added successfully"
                  );
                  const res = await dispatch(getDocumentTypes());
                  setDocumentList(res);
                  setShow(false);
                }

                setLoading(false);
              }}
            >
              {(props: FormikProps<any>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                } = props;
                return (
                  <>
                    <Form>
                      <div className="settings">
                        <InputField
                          field="documentType"
                          label="Document Type"
                          value={values.documentType}
                          touched={touched}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>

                      <div
                        className="settings"
                        style={{ marginBottom: "10px" }}
                      >
                        <DropDown
                          label="Level"
                          touched={touched}
                          errors={errors}
                          options={allLevels}
                          field="level"
                          value={{ label: values.level, value: values.level }}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          width={"100%"}
                        />
                      </div>

                      <div className="add-document-modal-footer">
                        <Button className="submit-btn" type="submit">
                          {loading ? (
                            <DotLoader
                              color="white"
                              loading={loading}
                              size={30}
                              aria-label="Submitting"
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddDocumentModal;
