import { Form, Formik, FormikProps } from "formik";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import InputField from "../../../../../shared/components/InputField";
import DropDown from "../../../../../shared/components/dropdown";
import * as Yup from "yup";
import { addOrUpdateExamScoresOptimizer } from "../../../../../redux/action/results";
import {
  IAddOrUpdateOptimizerModal,
  IOptimizedScore,
} from "../../../../../utils/types";
import ModalFooterButtons from "../../../../../shared/components/download-components/ModalFooterButtons";
import { examGrades } from "../../../../../shared/_helpers/constants";

export const AddOrUpdateOptimizerModal: React.FC<
  IAddOrUpdateOptimizerModal
> = ({
  showModal,
  setShowModal,
  selectedScore,
  setSelectedScore,
  initialValues,
  fetchOptimizedScores,
}: any) => {
  const handleSubmit = async (values: IOptimizedScore) => {
    const payLoad = {
      examScoreOptimizerUniqueId: values.examScoreOptimizerUniqueId,
      originalScore: values.originalScore,
      optimizedScore: values.optimizedScore,
      originalGrade: values.originalGrade,
      optimizedGrade: values.optimizedGrade,
    };

    try {
      const response = await addOrUpdateExamScoresOptimizer(payLoad);
      if (response.status === 200) {
        setShowModal(false);
        toast.success(
          selectedScore
            ? "Settings updated successfully"
            : "Settings added successfully"
        );
        await fetchOptimizedScores();
        setShowModal(false);
        setSelectedScore(initialValues);
      } else {
        toast.error("Failed to update settings");
      }
    } catch (error) {
      toast.error("Failed to update settings");
    }
  };

  // Validation schema
  let checkValidationSchema = Yup.object().shape({
    originalScore: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Value cannot be negative")
      .max(100, "Value cannot be more than 100"),
    optimizedScore: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Value cannot be negative")
      .max(100, "Value cannot be more than 100")
      .test(
        "is-greater-than-original",
        "Optimized Score must be greater than Original Score",
        function (optimizedScore) {
          const { originalScore } = this.parent;
          if (optimizedScore === undefined || originalScore === undefined) {
            return true;
          }
          return optimizedScore >= originalScore;
        }
      ),
    originalGrade: Yup.string().required("Required"),
    optimizedGrade: Yup.string().required("Required"),
  });

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setSelectedScore(initialValues);
      }}
      className="records_modal_wrap"
      size="lg"
      contentClassName="records_modal_content"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedScore?.examScoreOptimizerUniqueId
            ? "UPDATE SCORE OPTIMIZATION"
            : "ADD SCORE OPTIMIZATION"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="records-modal-form">
        <Formik
          initialValues={selectedScore}
          validationSchema={checkValidationSchema}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            } = props;

            return (
              <Form>
                <Row>
                  <Col xs={12} md={6}>
                    <DropDown
                      label="Original Grade"
                      name="originalGrade"
                      touched={touched}
                      errors={errors}
                      options={examGrades}
                      field="originalGrade"
                      value={{
                        value: values.originalGrade,
                        label: values.originalGrade,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      classNamePrefix="custom-fields"
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <InputField
                      field="originalScore"
                      label="Original Score"
                      value={values.originalScore}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                    <DropDown
                      label="Optimized Grade"
                      name="optimizedGrade"
                      touched={touched}
                      errors={errors}
                      options={examGrades}
                      field="optimizedGrade"
                      value={{
                        value: values.optimizedGrade,
                        label: values.optimizedGrade,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      classNamePrefix="custom-fields"
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <InputField
                      field="optimizedScore"
                      label="Optimize Score"
                      value={values.optimizedScore}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
                  <ModalFooterButtons
                    onCancel={() => {
                      setShowModal(false);
                      setSelectedScore(null);
                      setSelectedScore(initialValues);
                    }}
                    isSubmitting={isSubmitting}
                    submitButtonText={selectedScore ? "Update" : "Submit"}
                  />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
