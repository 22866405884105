// let BASE_URL = "https://api.ibbuuniversity.com.ng";
// let RESULT_MAIN_BASE_URL = "https://result-api.ibbuuniversity.com.ng";
// let BASE_URL = "https://eduportal-staging-ibbu-api.azurewebsites.net";

// let BASE_URL = window.location.hostname!=="localhost" && window.location.hostname!=="eduportal-staging-ibbu-admin-webapp.azurewebsites.net"? `https://eduportal-prod-ibbu-api.azurewebsites.net`: `https://eduportal-staging-ibbu-api.azurewebsites.net`;
let BASE_URL =
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "staging-admin.ibbuuniversity.com.ng"
    ? `https://api.ibbuuniversity.com.ng`
    : `https://staging-api.ibbuuniversity.com.ng`;

let RESULT_MAIN_BASE_URL =
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "staging-admin.ibbuuniversity.com.ng"
    ? `https://result-api.ibbuuniversity.com.ng`
    : `https://staging-result-api.ibbuuniversity.com.ng`;

let ONBOARDING_BASE_URL = `${BASE_URL}/api/admin/v1/Accounts`;
let ADMIN_BASE = `${BASE_URL}/api/admin/v1`;
let STUDENT_BASE = `${BASE_URL}/api/student/v1`;
let UPLOAD_BASE = `${BASE_URL}/api/upload/v1`;
let UG_PW_RESET_BASE_URL = `${BASE_URL}/api/v1/PasswordReset`;
let LECTURERS_BASE = `${RESULT_MAIN_BASE_URL}/api/admin/v1/LecturersManagement`;
let LECTURERS_SUBJECT_ASSIGNMENT_BASE = `${RESULT_MAIN_BASE_URL}/api/admin/v1/LecturersCourseAssignmentManagement`;
let HODS_BASE = `${RESULT_MAIN_BASE_URL}/api/admin/v1/DepartmentHodManagement`;
let DEANS_BASE = `${RESULT_MAIN_BASE_URL}/api/admin/v1/FacultyDeanManagement`;
let RESULT_UPLOADS_BASE = `${RESULT_MAIN_BASE_URL}/api/upload/v1/ResultPlatformFiles`;
let RESULT_BASE_URL = `${RESULT_MAIN_BASE_URL}/api/admin/v1`;

let apiKey =
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "staging-admin.ibbuuniversity.com.ng"
    ? `b19a6d6e879b4f94b36d7b7d4b137f8d`
    : `b3b4a4ef9c6c4f91b0e2c9b5c6713a95`;

// let apiKey =  "b19a6d6e879b4f94b36d7b7d4b137f8d" // Production
// let apiKey =  "b3b4a4ef9c6c4f91b0e2c9b5c6713a95" //staging

export const routes = {
  apiKey,
  ADMIN_LOGIN: `${ONBOARDING_BASE_URL}/Login`,
  REFRESH_TOKEN: `${ONBOARDING_BASE_URL}/RefreshToken`,
  ADMIN_LOGOUT: `${ONBOARDING_BASE_URL}/Logout`,
  OTP_VERIFY: `${ONBOARDING_BASE_URL}/Authenticate`,

  UNDERGRAD_FORGOT_PW: `${UG_PW_RESET_BASE_URL}/ResetAdminPassword`,
  UNDERGRAD_VERIFY_PW_TOKEN: `${UG_PW_RESET_BASE_URL}/VerifyPasswordReset`,
  UNDERGRAD_RESET_PW: `${UG_PW_RESET_BASE_URL}/ResetPassword`,

  LOAD_DASHBOARD: `${ADMIN_BASE}/DashBoard/GetDashBoardStatistics`,
  DELETE_PRESET_COURSES: `${ADMIN_BASE}/CoursesPresetManagement/DeletePreSetCoursesForStudents`,
  UPDATE_PRESET_COURSES: `${ADMIN_BASE}/CoursesPresetManagement/UpdatePreSetCoursesForStudents`,
  SAVE_PRESET_COURSES: `${ADMIN_BASE}/CoursesPresetManagement/PreSetCoursesForStudents`,
  GET_PRESET_COURSES: `${ADMIN_BASE}/CoursesPresetManagement/GetPreSetCoursesForStudentsEdit`,
  GET_CURRENT_SESSION: `${STUDENT_BASE}/UndergraduateSchoolSession/GetCurrentSchoolSession`,
  GET_CURRENT_POST_UTME_SESSION: `${STUDENT_BASE}/UndergraduateSchoolSession/GetCurrentPostUtmeSession`,
  GET_ALL_SESSIONS: `${STUDENT_BASE}/UndergraduateSchoolSession/GetAllSchoolSessions`,
  SET_CURRENT_SESSION: `${STUDENT_BASE}/UndergraduateSchoolSession/SetCurrentSchoolSession`,
  GET_ALL_COURSE_SUBJECTS: `${STUDENT_BASE}/UndergraduateCourses/GetAllCoursesWithNoPagination`,
  GET_ALL_PROGRAMMES: `${STUDENT_BASE}/UndergraduateProgrammes/GetAllProgrammesWithNoPagination`,
  GET_ALL_DEPARTMENTS: `${STUDENT_BASE}/UndergraduateDepartments/GetAllDepartmentsWithNoPagination`,
  GET_ALL_FACULTY_AND_DEPT: `${STUDENT_BASE}/UndergraduateDepartments/GetAllDepartmentsWithFacultyWithNoPagination`,
  GET_ALL_DEPT: `${STUDENT_BASE}/UndergraduateDepartments/GetAllDepartmentsWithNoPagination`,
  GET_ALL_FACULTIES: `${STUDENT_BASE}/UndergraduateFaculties/GetAllFacultiesWithNoPagination`,
  GET_ALL_STUDENTS: `${ADMIN_BASE}/StudentsManagement/GetAllStudents`,

  // Admin course management
  GET_ALL_COURSE_SUBJECT: `${ADMIN_BASE}/CourseSubjectManagement/GetAllCourseSubjects`,
  ADD_OR_UPDATE_COURSE_SUBJECT: `${ADMIN_BASE}/CourseSubjectManagement/AddOrUpdateCourseSubject`,
  SEARCH_COURSE_SUBJECT: `${ADMIN_BASE}/CourseSubjectManagement/SearchCourseSubject`,
  DELETE_COURSE_SUBJECT: `${ADMIN_BASE}/CourseSubjectManagement/DeleteCourseSubject`,
  DOWNLOAD_ALL_MANAGEMENT_COURSES: `${ADMIN_BASE}/CourseSubjectManagement/DownloadCourseSubject`,

  GET_STUDENT_PAYSTACK_INVOICE: `${ADMIN_BASE}/Settings/GetPayStackInvoice`,
  GET_STUDENT_CBS_INVOICE: `${ADMIN_BASE}/Settings/GetCbsInvoice`,

  DELETE_STUDENT_PAYSTACK_INVOICE: `${ADMIN_BASE}/Settings/DeletePayStackInvoice`,
  UPDATE_STUDENT_PAYSTACK_INVOICE: `${ADMIN_BASE}/Settings/UpdatePayStackInvoice`,
  DELETE_STUDENT_CBS_INVOICE: `${ADMIN_BASE}/Settings/DeleteCbsInvoice`,
  UPDATE_STUDENT_CBS_INVOICE: `${ADMIN_BASE}/Settings/UpdateCbsInvoice`,
  UPDATE_NEW_STUDENT_LEVEL: `${ADMIN_BASE}/Settings/UpdateNewStudentLevel`,

  GET_A_STUDENT: `${ADMIN_BASE}/StudentsManagement/GetStudent`,
  UPDATE_A_STUDENT: `${ADMIN_BASE}/StudentsManagement/UpdateStudent`,

  UPLOAD_STUDENT_PASSPORT: `${BASE_URL}/api/upload/v1/UndergraduateFiles/UploadUndergraduatePassport`,
  BULK_UPLOAD_COURSE_SUBJECT: `${BASE_URL}/api/upload/v1/UndergraduateFiles/AdminUploadUndergraduateCourseSubject`,
  BULK_UPLOAD_POST_UTME_LIST: `${BASE_URL}/api/upload/v1/UndergraduateFiles/AdminUploadUndergraduatePostUtmeList`,

  GET_ALL_ADMITTED_STUDENTS: `${ADMIN_BASE}/AdmissionManagement/AllAdmittedStudents`,
  SEARCH_ADMITTED_STUDENT: `${ADMIN_BASE}/AdmissionManagement/SearchAdmittedStudent`,
  SEARCH_STUDENT: `${ADMIN_BASE}/StudentsManagement/SearchStudent`,
  ADD_ADMITTED_STUDENT: `${ADMIN_BASE}/AdmissionManagement/AddAdmittedStudents`,
  UPDATE_ADMITTED_STUDENT: `${ADMIN_BASE}/AdmissionManagement/UpdateAdmittedStudents`,
  DOWNLOAD_ADMITTED_STUDENT: `${ADMIN_BASE}/AdmissionManagement/DownloadAdmittedStudents`,
  ADMIN_BULK_UPLOAD_ADMISSION_LIST: `${UPLOAD_BASE}/UndergraduateFiles/AdminUploadUndergraduateAdmissionList`,
  GET_ALL_FACULTIES_WITH_DEPARTMENT: `${STUDENT_BASE}/UndergraduateFaculties/GetAllFacultiesWithDepartmentsWithNoPagination`,
  GET_MATRIC_NUMBER_FORMATION: `${STUDENT_BASE}/UndergraduateProgrammes/GetProgrammeMatricNumberFormation`,
  GET_PROGRAMS_PUTME: `${ADMIN_BASE}/PostUtme/GetPrograms`,
  GET_PUTME_STUDENTS_REPORT: `${ADMIN_BASE}/PostUtme/GetPostUtmeStudentsReportPdf`,
  GET_PUTME_STUDENTS_REPORT_EXCEL: `${ADMIN_BASE}/PostUtme/GetPostUtmeStudentsReportExcel`,
  GET_ALL_PUTME_STUDENTS: `${ADMIN_BASE}/PostUtme/GetAllPostUtmeStudent`,
  SEARCH_PUTME_STUDENTS: `${ADMIN_BASE}/PostUtme/SearchPostUtmeStudent`,
  GET_AWARDS_IN_VIEW: `${ADMIN_BASE}/AwardInView/GetAllAwardsInView`,
  ADD_AWARD_IN_VIEW: `${ADMIN_BASE}/AwardInView/AddAwardsInView`,
  DELETE_AWARD_IN_VIEW: `${ADMIN_BASE}/AwardInView/DeleteAwardsInView`,

  GET_ALL_COUNTRIES: `${ADMIN_BASE}/Country/GetAllCountries`,
  ADD_COUNTRY: `${ADMIN_BASE}/Country/AddCountry`,
  DELETE_COUNTRY: `${ADMIN_BASE}/Country/DeleteCountry`,

  GET_QUALIFICATIONS: `${ADMIN_BASE}/HighestQualification/GetAllHighestQualification`,
  ADD_QUALIFICATION: `${ADMIN_BASE}/HighestQualification/AddHighestQualification`,
  DELETE_QUALIFICATION: `${ADMIN_BASE}/HighestQualification/DeleteHighestQualification`,
  SEARCH_QUALIFICATION: `${ADMIN_BASE}/HighestQualification/SearchHighestQualification`,

  GET_SPECIALITIES: `${ADMIN_BASE}/Speciality/GetAllSpecialities`,
  ADD_SPECIALITY: `${ADMIN_BASE}/Speciality/AddSpeciality`,
  DELETE_SPECIALITY: `${ADMIN_BASE}/Speciality/DeleteSpeciality`,
  SEARCH_SPECIALITY: `${ADMIN_BASE}/Speciality/SearchSpeciality`,

  RESET_STUDENT_PASSWORD: `${ADMIN_BASE}/Accounts/ResetUserPassword`,
  RESET_OTHER_USERS_PASSWORD: `${ADMIN_BASE}/Accounts/ResetOtherUsersPassword`,
  RESET_ADMIN_PASSWORD: `${ADMIN_BASE}/Accounts/AdminChangePassword`,

  UPDATE_STUDENT_STATUS: `${ADMIN_BASE}/Settings/UpdateStudentStatus`,
  UPDATE_STUDENT_ORIGIN: `${ADMIN_BASE}/Settings/UpdateStudentStateOfOrigin`,
  UPDATE_STUDENT_COURSE_OF_STUDY: `${ADMIN_BASE}/Settings/ChangeStudentProgram`,
  STUDENT_PROGRAM_CHANGE_HISTORY: `${ADMIN_BASE}/Settings/StudentProgramChangeHistory`,

  ALL_ROLES: `${ADMIN_BASE}/UserManagement/GetUserRoles`,
  ALL_APIS: `${ADMIN_BASE}/UserManagement/GetAllApis`,
  ADD_USER_TO_ROLE: `${ADMIN_BASE}/UserManagement/AddUserToRole`,
  REMOVE_USER_FROM_ROLE: `${ADMIN_BASE}/UserManagement/RemoveUserFromRole`,
  CREATE_A_ROLE: `${ADMIN_BASE}/UserManagement/CreateRole`,
  CREATE_A_USER: `${ADMIN_BASE}/UserManagement/CreateUser`,
  GET_USER_PROFILES: `${ADMIN_BASE}/UserManagement/GetUserProfiles`,
  SEARCH_USER_PROFILES: `${ADMIN_BASE}/UserManagement/SearchUserProfiles`,
  ACTIVATE_OR_DEACTIVATE_USER: `${ADMIN_BASE}/UserManagement/ActivateOrDeactivateUser`,
  GET_A_USER_PROFILE: `${ADMIN_BASE}/UserManagement/GetProfile`,
  GET_STUDENT_PROFILE: `${STUDENT_BASE}/Profile/GetProfile`,
  GET_A_USER: `${ADMIN_BASE}/UserManagement/GetUserProfile`,
  ASSIGN_USER_TO_FACULTY: `${ADMIN_BASE}/UserManagement/MapFacultyOfficerToFaculty`,
  ASSIGN_USER_TO_DEPARTMENT: `${ADMIN_BASE}/UserManagement/MapDepartmentOfficerToDepartment`,

  GET_EDOCUMENTATION_DASHBOARD_DATA: `${ADMIN_BASE}/EDocumentation/DashBoardStatistics`,
  GET_EDOCUMENTATION_REQUESTS: `${ADMIN_BASE}/EDocumentation/AllRequests`,
  EDOCUMENTATION_SEARCH: `${ADMIN_BASE}/EDocumentation/Search`,
  EDOCUMENTATION_SEARCH_BY_PARAMETERS: `${ADMIN_BASE}/EDocumentation/AllRequestsByParameters`,
  EDOCUMENTATION_DOCUMENT_TYPES: `${ADMIN_BASE}/EDocumentation/DistinctDocumentTypes`,
  PENDING_CLEARANCE_REQUESTS: `${ADMIN_BASE}/EDocumentation/AllClearanceRequests`,
  APPROVE_EDOCUMENTATION_DOCUMENT: `${ADMIN_BASE}/EDocumentation/ApproveEDocumentation`,
  REJECT_EDOCUMENTATION_DOCUMENT: `${ADMIN_BASE}/EDocumentation/RejectEDocumentation`,
  SUBMIT_EDOCUMENTATION_REVIEW: `${ADMIN_BASE}/EDocumentation/SubmitEDocumentationReview`,
  ADD_OR_UPDATE_EDOCUMENTATION_DOCUMENT_TYPE: `${ADMIN_BASE}/EDocumentation/AddOrUpdateEDocumentationTypes`,
    ADD_OR_UPDATE_SPECIFIC_DOCUMENT_TYPE_FOR_STUDENT: `${ADMIN_BASE}/EDocumentation/AddOrUpdateSpecificDocumentTypeForStudent`,
    GET_SPECIFIC_DOCUMENT_TYPES_FOR_STUDENTS: `${ADMIN_BASE}/EDocumentation/AllSpecificDocumentTypeForStudents`,
    SEARCH_SPECIFIC_DOCUMENT_TYPES_FOR_STUDENTS: `${ADMIN_BASE}/EDocumentation/SearchSpecificDocumentTypeForStudents`,
    DELETE_SPECIFIC_DOCUMENT_TYPES_FOR_STUDENT: `${ADMIN_BASE}/EDocumentation/DeleteSpecificDocumentTypeForStudent`,
  DELETE_EDOCUMENTATION_DOCUMENT_TYPE: `${ADMIN_BASE}/EDocumentation/DeleteDocumentTypes`,
  SEARCH_STUDENT_DOCUMENTS: `${ADMIN_BASE}/EDocumentation/SearchStudentDocument`,
  SEARCH_CLEARANCE_REQUEST: `${ADMIN_BASE}/EDocumentation/SearchClearanceRequests`,
  GET_EDOCUMENTATION_STATISTICS: `${ADMIN_BASE}/EDocumentation/GetEDocumentationPaymentDashBoardStatistics`,

  ASSIGN_COURSE_ADVISOR_TO_DEPT: `${ADMIN_BASE}/UserManagement/MapCourseAdvisorToDepartment`,

  GET_SCHOOLFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/SchoolFeePayments`,
  SEARCH_SCHOOLFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/SearchSchoolFeePaymentsSearch`,
  DOWNLOAD_SCHOOLFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/SchoolFeePaymentsDownload`,

  GET_ACCEPTANCEFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/AcceptanceFeePayments`,
  SEARCH_ACCEPTANCEFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/AcceptanceFeePaymentsSearch`,
  DOWNLOAD_ACCEPTANCEFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/AcceptanceFeePaymentsDownload`,
  ALL_STUDENT_PAYMENTS_HISTORY: `${ADMIN_BASE}/BursaryReports/AllPaymentByStudent`,
  BURSARY_HOSTEL_ACCOMMODATION_REPORTS: `${ADMIN_BASE}/BursaryReports/HostelAccommodationFeePayments`,
  BURSARY_HOSTEL_REPORT_DOWNLOAD: `${ADMIN_BASE}/BursaryReports/HostelAccommodationFeePaymentsDownload`,
  BURSARY_HOSTEL_REPORT_SEARCH: `${ADMIN_BASE}/BursaryReports/HostelAccommodationFeePaymentsSearch`,

  GET_MATRICULATION_REPORT: `${ADMIN_BASE}/Reports/NewMatriculation`,
  SEARCH_MATRICULATION_REPORT: `${ADMIN_BASE}/Reports/NewMatriculationSearch`,
  DOWNLOAD_MATRICULATION_REPORT: `${ADMIN_BASE}/Reports/NewMatriculationDownload`,

  OLD_MATRICULATION_REPORT: `${ADMIN_BASE}/Reports/Matriculation`,
  SEARCH_OLD_MATRICULATION_REPORT: `${ADMIN_BASE}/Reports/MatriculationSearch`,
  DOWNLOAD_OLD_MATRICULATION_REPORT: `${ADMIN_BASE}/Reports/MatriculationDownload`,
  // DOWNLOAD_MATRICULATION_REPORT:  `${ADMIN_BASE}/BursaryReports/AcceptanceFeePaymentsDownload`,

  GET_NEW_STUDENTS_PAYMENTS_REPORT: `${ADMIN_BASE}/Reports/NewAdmittedStudentsPayment`,
  SEARCH_NEW_STUDENTS_PAYMENTS_REPORT: `${ADMIN_BASE}/Reports/NewAdmittedStudentsPaymentSearch`,
  DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORT: `${ADMIN_BASE}/Reports/NewAdmittedStudentsPaymentDownload`,

  GET_STUDENTS_REGISTRATION_REPORT: `${ADMIN_BASE}/Reports/Registration`,
  SEARCH_STUDENTS_REGISTRATION_REPORT: `${ADMIN_BASE}/Reports/RegistrationSearch`,
  DOWNLOAD_STUDENTS_REGISTRATION_REPORT: `${ADMIN_BASE}/Reports/RegistrationDownload`,

  GET_ADDITIONALFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/AdditionalFeePayments`,
  SEARCH_ADDITIONALFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/AdditionalFeePaymentsSearch`,
  DOWNLOAD_ADDITIONALFEE_PAYMENTS: `${ADMIN_BASE}/BursaryReports/AdditionalFeePaymentsDownload`,
  CREATE_NEW_STUDENT: `${ADMIN_BASE}/Settings/CreateStudent`,
  GENERATE_SCHOOL_FEE_INVOICE: `${ADMIN_BASE}/Settings/CreateSchoolFeesInvoice`,
  GENERATE_OTHER_FEE_INVOICE: `${ADMIN_BASE}/Settings/CreateOtherFeesInvoice`,
  ADD_COMPULSORY_FEE: `${ADMIN_BASE}/Settings/AddCarryOverCompulsoryFeeForStudent`,
  ALL_CARRYOVER_COMPULSORY_FEES: `${ADMIN_BASE}/Settings/CarryOverCompulsoryFeeForStudents`,
  DELETE_CARRYOVER_COMPULSORY_FEE: `${ADMIN_BASE}/Settings/DeleteCarryOverCompulsoryFeeForStudent`,
  SEARCH_CARRYOVER_COMPULSORY_FEE: `${ADMIN_BASE}/Settings/CarryOverCompulsoryFeeForStudentsSearch`,
  QUERY_CBS_INVOICE: `${ADMIN_BASE}/Settings/QueryInvoiceOnCbs`,
  RE_PRINT_ADMISSION_LETTER: `${ADMIN_BASE}/Settings/RePrintAdmissionLetter`,

  SCHOOL_SESSION_HISTORY: `${STUDENT_BASE}/UndergraduateSchoolSession/SchoolSessionHistory`,
  COURSE_REG_SEARCH: `${ADMIN_BASE}/EOReports/CourseRegistrationSearch`,
  COURSE_REG_REPORT: `${ADMIN_BASE}/EOReports/CourseRegistrationReport`,
  COURSE_REG_REPORT_DOWNLOAD: `${ADMIN_BASE}/EOReports/CourseRegistrationReportDownload`,
  COURSE_REG_REPORT_SEARCH: `${ADMIN_BASE}/EOReports/CourseRegistrationReportSearch`,

  GET_NELFUND_REPORT: `${ADMIN_BASE}/Reports/NelFund`,
  GET_NELFUND_REPORT_SEARCH: `${ADMIN_BASE}/Reports/NelFundSearch`,
  GET_NELFUND_REPORT_DOWNLOAD: `${ADMIN_BASE}/Reports/NelFundDownload`,

  PRINT_BIODATA: `${ADMIN_BASE}/StudentsManagement/PrintBioData`,

  GET_EO_REGISTRATION_REPORT: `${ADMIN_BASE}/EOReports/Registration`,
  SEARCH_EO_REGISTRATION_REPORT: `${ADMIN_BASE}/EOReports/RegistrationSearch`,
  DOWNLOAD_EO_REGISTRATION_REPORT: `${ADMIN_BASE}/EOReports/RegistrationDownload`,

  ADD_OR_EDIT_ROOM: `${ADMIN_BASE}/HostelManagement/AddOrUpdateBlock`,
  DELETE_HOSTEL_BLOCK: `${ADMIN_BASE}/HostelManagement/DeleteBlock`,

  HOSTEL_ROOMS: `${ADMIN_BASE}/HostelManagement/HostelRooms`,
  HOSTEL_ROOMS_SEARCH: `${ADMIN_BASE}/HostelManagement/SearchHostelRoomSetting`,
  STUDENTS_IN_ROOM: `${ADMIN_BASE}/HostelManagement/StudentsInHostelRoom`,
  ADD_OR_UPDATE_ROOM: `${ADMIN_BASE}/HostelManagement/AddOrUpdateRoom`,
  HOSTEL_SITE_BLOCKS: `${ADMIN_BASE}/HostelManagement/SiteBlocks`,
  PAGINATED_HOSTEL_SITE_BLOCKS: `${ADMIN_BASE}/HostelManagement/SiteBlocksPaginated`,
  ADD_STUDENT_TO_ROOM: `${ADMIN_BASE}/HostelManagement/AddStudentToHostelRoom`,
  DELETE_STUDENT_FROM_ROOM: `${ADMIN_BASE}/HostelManagement/DeleteStudentFromHostelRoom`,
  GET_ADMIN_AVAILABLE_ROOM: `${ADMIN_BASE}/HostelManagement/AdminAvailableRoom`,

  ADD_OR_UPDATE_COURSE_ADVISOR: `${ADMIN_BASE}/CourseAdvisorsManagement/AddOrUpdateCourseAdvisor`,
  GET_COURSE_ADVISORS: `${ADMIN_BASE}/CourseAdvisorsManagement/GetAllCourseAdvisors`,
  GET_A_COURSE_ADVISOR: `${ADMIN_BASE}/CourseAdvisorsManagement/GetCourseAdvisor`,
  SEARCH_A_COURSE_ADVISOR: `${ADMIN_BASE}/CourseAdvisorsManagement/SearchCourseAdvisor`,
  DELETE_A_COURSE_ADVISOR: `${ADMIN_BASE}/CourseAdvisorsManagement/DeleteCourseAdvisor`,
  UPLOAD_A_COURSE_ADVISOR: `${BASE_URL}/api/upload/v1/UndergraduateFiles/UploadCourseAdvisor`,
  DOWNLOAD_ALL_COURSE_ADVISOR: `${ADMIN_BASE}/CourseAdvisorsManagement/DownloadCourseAdvisor`,

  GET_ALL_LECTURERS: `${LECTURERS_BASE}/GetAllLecturers`,
  GET_A_LECTURER: `${LECTURERS_BASE}/GetALecturer`,
  SEARCH_LECTURERS: `${LECTURERS_BASE}/SearchLecturer`,
  ADD_OR_UPDATE_LECTURER: `${LECTURERS_BASE}/AddOrUpdateLecturer`,
  UPLOAD_LECTURERS: `${RESULT_UPLOADS_BASE}/AdminUploadUndergraduateLecturerList`,
  DOWNLOAD_ALL_LECTURERS: `${LECTURERS_BASE}/DownloadAllLecturers`,
  DELETE_A_LECTURER: `${LECTURERS_BASE}/DeleteLecturer`,
  GET_LECTURER_PROFILE: `${LECTURERS_BASE}/GetLecturerProfile`,

  GET_ALL_LECTURERS_ASSIGNMENTS: `${LECTURERS_SUBJECT_ASSIGNMENT_BASE}/GetAllLecturersCourseAssignments`,
  GET_A_LECTURER_ASSIGNMENT: `${LECTURERS_SUBJECT_ASSIGNMENT_BASE}/GetLecturerAssignedCourses`,
  SEARCH_LECTURERS_ASSIGNMENTS: `${LECTURERS_SUBJECT_ASSIGNMENT_BASE}/SearchLecturersCourseAssignments`,
  GET_ALL_LECTURERS_WITH_NO_PAGINATION: `${LECTURERS_SUBJECT_ASSIGNMENT_BASE}/GetAllLecturersWithNoPagination`,
  DOWNLOAD_ALL_LECTURERS_ASSIGNMENTS: `${LECTURERS_SUBJECT_ASSIGNMENT_BASE}/DownloadAllLecturersCourseAssignments`,
  ADD_UPDATE_A_LECTURER_ASSIGNMENT: `${LECTURERS_SUBJECT_ASSIGNMENT_BASE}/AddOrUpdateLecturerCourseAssignment`,
  UPLOAD_LECTURERS_COURSE_ASSIGNMENT: `${RESULT_UPLOADS_BASE}/UploadUndergraduateLecturerCourseAssignment`,
  DELETE_A_LECTURER_ASSIGNMENT: `${LECTURERS_SUBJECT_ASSIGNMENT_BASE}/DeleteLecturerCourseAssignment`,


  GET_ALL_HODS: `${HODS_BASE}/GetAllDepartmentHod`,
  GET_A_HOD: `${HODS_BASE}/GetHodProfile`,
  SEARCH_HODS: `${HODS_BASE}/SearchHod`,
  DOWNLOAD_ALL_HODS: `${HODS_BASE}/DownloadAllDepartmentHods`,
  ADD_UPDATE_A_HOD: `${HODS_BASE}/AddOrUpdateDepartmentHod`,
  DELETE_A_HOD: `${HODS_BASE}/DeleteDepartmentHod`,


  GET_ALL_DEANS: `${DEANS_BASE}/GetAllFacultyDean`,
  GET_A_DEAN: `${DEANS_BASE}/GetDeanProfile`,
  SEARCH_DEANS: `${DEANS_BASE}/SearchDean`,
  DOWNLOAD_ALL_DEANS: `${DEANS_BASE}/DownloadAllFacultyDeans`,
  ADD_UPDATE_A_DEAN: `${DEANS_BASE}/AddOrUpdateFacultyDean`,
  DELETE_A_DEAN: `${DEANS_BASE}/DeleteFacultyDean`,
  

  UPDATE_PUTME_STUDENT: `${ADMIN_BASE}/PostUtme/UpdatePostUtmeStudent`,
  GET_PUTME_DASHBOARD_STAT: `${ADMIN_BASE}/PostUtme/GetPostUtmeDashBoardStatistics`,
  GET_UTME_OLEVEL_PASSFEED_REPORT: `${ADMIN_BASE}/PostUtme/PostUtmeOLevelPassFeed`,
  GET_DE_ALEVEL_PASSFEED_REPORT: `${ADMIN_BASE}/PostUtme/PostUtmeDeALevelPassFeed`,
  GET_DE_OLEVEL_PASSFEED_REPORT: `${ADMIN_BASE}/PostUtme/PostUtmeDeOLevelPassFeed`,
  GET_UTME_OLEVEL_PASSFEED_SCRATCHCARD_REPORT: `${ADMIN_BASE}/PostUtme/PostUtmeOLevelPassFeedWithScratchCard`,
  GET_DE_OLEVEL_PASSFEED_SCRATCHCARD_REPORT: `${ADMIN_BASE}/PostUtme/PostUtmeDEOLevelPassFeedWithScratchCard`,
  GET_PUTME_ELIGIBILITY_REPORT: `${ADMIN_BASE}/PostUtme/PostUtmeEligibilityReport`,

  ADD_OR_UPDATE_BEDSPACE_PERCENTAGE: `${ADMIN_BASE}/HostelManagement/AddOrUpdateBedSpacePercentageConfiguration`,
  GET_BEDSPACE_PERCENTAGE: `${ADMIN_BASE}/HostelManagement/BedSpacePercentageConfiguration`,
  HOSTEL_ACCOMODATION_DASHBOARD_STATISTICS: `${ADMIN_BASE}/HostelManagement/HostelAccommodationDashBoardStatistics`,
  GET_HOSTEL_MATRIC_SEARCH: `${ADMIN_BASE}/HostelManagement/SearchStudentAccommodation`,
  GET_STUDENT_ACCOMODATION_REPORT: `${ADMIN_BASE}/HostelManagement/AccommodationReport`,
  DOWNLOAD_HOSTEL_ACCOMODATION_REPORT: `${ADMIN_BASE}/HostelManagement/AccommodationReportDownload`,
  GET_STUDENT_IN_ROOM: `${ADMIN_BASE}/HostelManagement/StudentsInRoom`,
  ALL_HOSTEL_ROOMS: `${ADMIN_BASE}/HostelManagement/AllHostelRooms`,
  GET_STUDENT_IN_ROOMS_SEARCH: `${ADMIN_BASE}/HostelManagement/StudentsInRoomSearch`,
  GET_HOSTEL_STATISTICS: `${ADMIN_BASE}/HostelManagement/HostelAccommodationDashBoardPaymentStatistics`,
  GET_EXTEND_REGISTRATION_COURSE_UNITS: `${ADMIN_BASE}/Settings/ExtendRegistrationCourseUnits`,
  SEARCH_EXTEND_REGISTRATION_COURSE_UNITS: `${ADMIN_BASE}/Settings/SearchExtendRegistrationCourseUnit`,
  ADD_OR_UPDATE_EXTEND_COURSE_UNITS: `${ADMIN_BASE}/Settings/AddOrUpdateExtendRegistrationCourseUnit`,
  DELETE_EXTEND_COURSE_UNITS: `${ADMIN_BASE}/Settings/DeleteExtendRegistrationCourseUnit`,
  UPDATE_DEFERRED_STUDENT: `${ADMIN_BASE}/Settings/UpdateStudentDeferStatus`,
  GET_BLACKLISTED_STUDENT: `${ADMIN_BASE}/HostelManagement/Blacklist`,
  ADD_BLACKLISTED_STUDENT: `${ADMIN_BASE}/HostelManagement/AddBlacklist`,
  DELETE_BLACKLISTED_STUDENT: `${ADMIN_BASE}/HostelManagement/DeleteBlacklist`,
  SEARCH_BLACKLISTED_STUDENT: `${ADMIN_BASE}/HostelManagement/SearchBlacklist`,
  GET_HOSTEL_STATUS: `${ADMIN_BASE}/HostelManagement/Status`,
  SET_HOSTEL_STATUS: `${ADMIN_BASE}/HostelManagement/SetStatus`,
  SWAP_ACCOMMODATION_ROOM: `${ADMIN_BASE}/HostelManagement/SwapHostelAccommodation`,
  AVAILABLE_ACCOMMODATION: `${ADMIN_BASE}/HostelAccommodation/AvailableAccommodation`,
  
  
  GET_ALL_DEACTIVATED_STUDENT: `${ADMIN_BASE}/DeactivatedStudents/GetAllDeactivatedStudents`,
  ADD_DEACTIVATED_STUDENT: `${ADMIN_BASE}/DeactivatedStudents/AddDeactivateStudent`,
  DEACTIVATED_STUDENT: `${ADMIN_BASE}/DeactivatedStudents/DeactivateStudent`,
  GET_DEACTIVATED_STUDENT_PROFILE: `${ADMIN_BASE}/DeactivatedStudents/DeactivatedStudentProfile`,
  RE_ACTIVATED_STUDENT: `${ADMIN_BASE}/DeactivatedStudents/ReactivateStudent`,
  SEARCH_DEACTIVATED_STUDENT: `${ADMIN_BASE}/DeactivatedStudents/SearchDeactivatedStudents`,
  DOWNLOAD_ALL_DEACTIVATED_STUDENT: `${ADMIN_BASE}/DeactivatedStudents/DownloadAllDeactivatedStudents`,


};

export const examRoutes = {
  apiKey,
  GET_EXAMS_GRADE_SETTINGS: `${RESULT_BASE_URL}/ExamGradeSettings/GetAllExamGradeSettings`,
  ADD_UPDATE_GRADE_SETTINGS: `${RESULT_BASE_URL}/ExamGradeSettings/AddOrUpdateExamGradeSettings`,
  DELETE_GRADE_SETTINGS: `${RESULT_BASE_URL}/ExamGradeSettings/DeleteExamGradeSettings`,
  GET_A_GRADE_SETTINGS: `${RESULT_BASE_URL}/ExamGradeSettings/GetExamGradeSetting`,

  GET_EXAMS_MAX_SCORE_SETTINGS: `${RESULT_BASE_URL}/ExamMaxScoreSettings/GetAllExamMaxScore`,
  ADD_UPDATE_MAX_SCORE_SETTINGS: `${RESULT_BASE_URL}/ExamMaxScoreSettings/AddOrUpdateExamMaxScore`,
  DELETE_MAX_SCORE_SETTINGS: `${RESULT_BASE_URL}/ExamMaxScoreSettings/DeleteExamMaxScore`,

  GET_EXAMS_SCORE_OPTIMIZER: `${RESULT_BASE_URL}/ExamScoreOptimizer/GetAllExamScoreOptimizer`,
  ADD_UPDATE_SCORE_OPTIMIZER: `${RESULT_BASE_URL}/ExamScoreOptimizer/AddOrUpdateExamScoreOptimizer`,
  DELETE_SCORE_OPTIMIZER: `${RESULT_BASE_URL}/ExamScoreOptimizer/DeleteExamScoreOptimizer`,

  STUDENT_REGISTERED_COURSES: `${RESULT_BASE_URL}/ExamEnrollment/GetStudentsExamRegisteredCourses`,
  ADD_UPDATE_EXAMSCORE: `${RESULT_BASE_URL}/ExamComputationManager/AddOrUpdateExamScore`,
  UPLOAD_EXAM_SCORES: `${RESULT_UPLOADS_BASE}/UploadUndergraduateExamScore`,
};
