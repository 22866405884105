// ModalFooterButtons.tsx
import React from "react";
import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import "./index.scss"

interface ModalFooterButtonsProps {
  onCancel?: () => void;
  onClick?: () => void;
  isSubmitting: boolean;
  submitButtonText?: string;
  cancelButtonText?: string; 
}

const ModalFooterButtons: React.FC<ModalFooterButtonsProps> = ({
  onCancel,
  isSubmitting,
  submitButtonText= "Submit",
  cancelButtonText = "Cancel", 
  onClick
}) => {
  return (
    <div className="modal-footer">
  { onCancel &&  <Button
        className="cancel-btn"
        onClick={onCancel}
        disabled={isSubmitting}
      >
        {cancelButtonText}
      </Button>     }

      <Button
        className="submit-btn"
        type="submit"
        disabled={isSubmitting}
        onClick={onClick}
      >
        {isSubmitting ? (
          <DotLoader color="rgb(14, 47, 119)" loading={isSubmitting} size={30} aria-label="Please wait" />
        ) : (
          submitButtonText
        )}
      </Button>
      </div>

  );
};

export default ModalFooterButtons;