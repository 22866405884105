import { useCallback, useEffect, useState } from "react";
import SearchBox from "../../shared/components/searchbox";
import { ReactComponent as Upload } from "../../assets/upload-icon.svg";
import { CiCirclePlus } from "react-icons/ci";
import "../admission-mgt/index.scss";
import ReactPaginate from "react-paginate";
import fetchAndDownloadExcel from "../../shared/_helpers/downloadexcel";
import { useDispatch, useSelector } from "react-redux";
import CourseTable from "./CourseTable";
import CourseModal from "./CourseModal";
import "./index.scss";
import {
  getAllCourseSubjects,
  searchCourse,
} from "../../redux/action/course-subject";
import CourseBulkUpload from "./CourseBulkUploadModal";
import { courseUploadSampleData } from "../../shared/_helpers/constants";
import DownloadModal from "../../shared/components/download-components/DownloadModal";
import { toast } from "react-toastify";
import { downloadCourses } from "../../redux/action/admission";
import DownloadButton from "../../shared/components/download-components/DownloadButton";
import UploadButton from "../../shared/components/download-components/UploadButton";
import AddButton from "../../shared/components/download-components/AddButton";

const CourseSubject = () => {
  const initialValues = {
    courseUniqueId: "",
    courseName: "",
    courseCode: "",
    courseUnit: "",
    semester: "",
    level: 0,
    departmentUniqueId: "",
    programmeUniqueId: "",
  };
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [courseInfo, setCourseInfo] = useState<any>(initialValues);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);

  const fileType = "EXCEL";
  const dispatch = useDispatch();

  let currentSession: any = localStorage.getItem("adXAuth");
  currentSession = JSON.parse(currentSession);
  const session = currentSession?.currentSession?.session;

  const { items, totalCount } = useSelector(
    (state: any) => state.courseSubjectReducer
  );

  const pageCount = Math.ceil(totalCount / 20);
  const itemsPerPage = 20;
  const lastPageCount = (currentPage - 1) * itemsPerPage + items.length;
  const handleSearch = useCallback(
    (searchParam: any, pageNumber: number) => {
      dispatch(searchCourse(searchParam, pageNumber));
    },
    [dispatch]
  );
  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    setCurrentPage(newOffset);
    if (searchParameter.length) {
      dispatch(searchCourse(searchParameter, newOffset));
    } else {
      dispatch(getAllCourseSubjects(newOffset));
    }
  };

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      setCurrentPage(1);
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      dispatch(getAllCourseSubjects(1));
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameter, searchCourse, dispatch]);

  const handleDownloadCourses = async () => {
    setLoading(true);
    try {
      const response = await downloadCourses();
      if (response?.data) {
        setShowDownloadModal(true);
        setDownloadUrl(response.data);
        setLoading(false);
      } else {
        toast.error("No record found");
      }
    } catch (error) {
      toast.error("Error downloading all course subjects");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admission-management course-subject">
      {downloadUrl && (
        <DownloadModal
          showDownloadModal={showDownloadModal}
          setShowDownloadModal={setShowDownloadModal}
          downloadUrl={downloadUrl}
          fileType={fileType}
          text="All-Courses"
          session={session}
        />
      )}
      <p
        className="admission-upload-template"
        onClick={() => fetchAndDownloadExcel(courseUploadSampleData)}
      >
        NOTE : PLEASE CLICK HERE TO DOWNLOAD SAMPLE EXCEL TEMPLATE FOR COURSE
        BULK UPLOAD. ENSURE YOUR UPLOAD MATCHES THE EXCEL TEMPLATE COLUMNS.
      </p>
      <div className="admission-action-buttons">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />

        <AddButton
          onClick={() => setShow(true)}
          buttonText="Add Single Course"
        />
        <DownloadButton
          onClick={() => handleDownloadCourses()}
          loading={loading}
          buttonText="Download All Courses  "
        />

        <UploadButton
          onClick={() => setShowUpload(true)}
          buttonText="Upload Bulk"
        />

      </div>
      <CourseTable
        courseList={items}
        setCourseInfo={setCourseInfo}
        setShow={setShow}
        setSelectedDepartment={setSelectedDepartment}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <CourseBulkUpload show={showUpload} setShow={setShowUpload} />
      <CourseModal
        show={show}
        setShow={setShow}
        courseInfo={courseInfo}
        setCourseInfo={setCourseInfo}
        initialValues={initialValues}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
      />
      {items?.length ? (
        <div className="result-count">
          <p>
            Showing {lastPageCount} of {totalCount} results
          </p>
        </div>
      ) : null}
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
        forcePage={currentPage - 1}
      />
    </div>
  );
};

export default CourseSubject;
