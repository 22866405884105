import { ApiService } from "../../../services/apiService";
import { toast } from "react-toastify";

import { routes } from "../../../services/paths";
import { Dispatch } from "redux";
import * as types from "../../action-constants/course-subject";
import { handleRequestErrors } from "../../../shared/utils";


export const fetchAllCourseSubjects = (payload: any) => {
    return {
      type: types.GET_ALL_COURSE_SUBJECT,
      payload,
    };
  };

  export const fetchAllDepartments = (payload: any) => {
    return {
      type: types.LOAD_ALL_DEPARTMENTS,
      payload,
    };
  };

  const loadCourseSubject = (payload:any) =>{
    return{
        type: types.GET_ALL_COURSE_SUBJECT,
        payload
    }
  }
  
export  const courseSubjectLoading = (payload:any) =>{
    return{
        type: types.GET_ALL_COURSE_SUBJECT_LOADING,
        payload
    }
  }
 export const addCourseLoading = (payload:any) =>{
    return{
        type: types.ADD_COURSE_LOADING,
        payload
    }
  
  }  


export const loadAllDepartments = () => async (dispatch: Dispatch) => {
    let apiConsume = ApiService.request({
      url: `${routes.GET_ALL_DEPARTMENTS}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then(({ data }: any) => {
        dispatch(fetchAllDepartments(data));
      })
      .catch((err: any) => {
        handleRequestErrors(err)
        return err;
      });
  };

  export const getAllCourseSubjects =
  (pageNumber: number, showLoading: boolean = true) => async (dispatch: Dispatch) => {
    if(showLoading){
        dispatch(courseSubjectLoading(true))
    }
    let apiConsume = ApiService.request({
      url: `${routes.GET_ALL_COURSE_SUBJECT}?pageNumber=${pageNumber}&pageSize=20`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then(({ data }: any) => {
        dispatch(courseSubjectLoading(false))
        const {
          items,
          hasNextPage,
          hasPreviousPage,
          totalPages,
          totalCount,
          currentPage,
        } = data;
        dispatch(
            loadCourseSubject({
            items,
            hasNextPage,
            hasPreviousPage,
            totalPages,
            currentPage,
            totalCount,
          })
        );
      })
      .catch((err: any) => {
        dispatch(courseSubjectLoading(false))
        handleRequestErrors(err)
        return err;
      });
  };

  export const searchCourse =
  (searchQuery: any,pageNumber:number) => async (dispatch: Dispatch) => {
    let apiConsume = ApiService.request({
      url: `${routes.SEARCH_COURSE_SUBJECT}?searchQuery=${searchQuery}&PageNumber=${pageNumber}&PageSize=20`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then(({ data }: any) => {
        const {
          items,
          hasNextPage,
          hasPreviousPage,
          totalPages,
          totalCount,
          currentPage,
        } = data;
        dispatch(
            loadCourseSubject({
            items,
            hasNextPage,
            hasPreviousPage,
            totalPages,
            currentPage,
            totalCount,
          })
        );
      })
      .catch((err: any) => {
        handleRequestErrors(err)
      });
  };

  export const addCourse =
  (
    courseInfo: any,
    callback: (value: boolean) => void,
    resetForm: any,
    initialValues: any,
  ) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch(addCourseLoading(true));
    const currentState = getState();
    await ApiService.request({
      url:`${routes.ADD_OR_UPDATE_COURSE_SUBJECT}`,
      http_method: "POST",
      data: courseInfo,
      headers: { "X-Signature": routes.apiKey },
    })
      .then(async (response: any) => {
        if (response.status === 200) {
          toast.success(
            courseInfo?.studentId
              ? "Course info updated"
              : "Course added successfully"
          );
          dispatch(addCourseLoading(false));
          await resetForm(initialValues);
          callback(false);
          dispatch<any>(
            getAllCourseSubjects(
              currentState?.courseSubjectReducer?.currentPage, false
              
            )
          );
        }
      })
      .catch((err: any) => {
        if(err?.message.includes("Network Error")){
          toast.error('Network error, please check your internet')
        }
        else if(err?.response.status === 400){
          toast.error(err.response.data)
        }
        dispatch(addCourseLoading(false));
        handleRequestErrors(err)
      });
  };


  export const deleteCourseSubject = (uniqueId: string) => async (dispatch: Dispatch,getState: any) => {
    const currentState = getState();
    return ApiService.request({
      url: `${routes.DELETE_COURSE_SUBJECT}?uniqueId=${uniqueId}`,
      http_method: "DELETE",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        dispatch<any>(getAllCourseSubjects(
            currentState?.courseSubjectReducer?.currentPage, false
            
          ));
          return data
      })
      .catch((err: any) => {
        if(err?.message.includes("Network Error")){
            toast.error('Network error, please check your internet')
          }
          else{
            toast.error('An error occured')
          }
        handleRequestErrors(err)
        return err;
      });
  };
  export const bulkUploadCourseList =
  (data: any, callback: (val: boolean) => void) =>
  async (dispatch: Dispatch, getState: any) => {
    const currentState = getState();
    await ApiService.request({
      url: `${routes.BULK_UPLOAD_COURSE_SUBJECT}`,
      http_method: "POST",
      data,
      headers: { "X-Signature": routes.apiKey },
    })
      .then(async (response: any) => {
        if (response.status === 200) {
          toast.success("List uploaded successfully");
          await dispatch<any>(getAllCourseSubjects(
            currentState?.courseSubjectReducer?.currentPage, false
            
          ));
          callback(false);
        }
      })
      .catch((error: any) => {
        if(error?.message.includes("Network Error")){
          toast.error('Network error, please check your internet')
        }
        handleRequestErrors(error)
      });
  };
