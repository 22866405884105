import { InAppTemplate } from "../../../../shared/templates/portal";
import AdditionalDocuments from "./AdditionalDocuments";

const AdditionalEDocDocuments = () => {
  return (
    <InAppTemplate
      childComponent={<AdditionalDocuments />}
      pageTitle="ADDITIONAL DOCUMENT TYPE"
    />
  );
};

export default AdditionalEDocDocuments;
