import { Form, Formik, FormikProps } from "formik";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { DotLoader } from "react-spinners";
import InputField from "../../../../../shared/components/InputField";
import "./index.scss";
import StudentRoomTable from "./StudentRoomTable";

const SearchStudentRoom = ({
  fetchCurrentRoom,
  initialValue,
  loading,
  show,
  data,  
}: any) => {
  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
  });
  return (
    <div className="search-matric-container">
   <div className="report-container">
        <div className="report-page">
          <Formik
            initialValues={initialValue}
            validationSchema={checkValidationSchema}
            onSubmit={async (values) => {
              const { matricNumber } = values;
              fetchCurrentRoom(matricNumber);
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldTouched,
                setFieldValue,
              } = props;
              return (
                <Form>
                  <div className="form-container">
                    <div className="form-input">
                      <InputField
                        field="matricNumber"
                        label="Matric Number"
                        value={values?.matricNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>

                    <div className="report-submit-btn">
                      <Button
                        className="submit-button"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <DotLoader
                            color="white"
                            loading={loading}
                            size={30}
                            aria-label="Searching..."
                          />
                        ) : (
                          "Search"
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      <div className="search-table">
        {data?.matricNumber && (
          <div>
            <StudentRoomTable 
             item={data}
             show={show} 
             />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchStudentRoom;
