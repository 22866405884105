import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

import Select from "react-select";

import Table from "react-bootstrap/Table";
import { InAppTemplate } from "../../shared/templates/portal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Filter from "../../assets/filter.png";
import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import "./index.scss";
import { optionTypes } from "../../types";
import DownloadDropdown from "../../shared/components/download-components/DownloadDropdown";
const adminGetReportRequest = async ({ pageProps, payload, isSearch }: any) => {
  await pageProps.adminGetFeesReportAction(payload, isSearch);
};

const adminDownloadReportRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminDownloadFeesReportAction(payload);
};

const adminLoadSubjectsRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminLoadCourseRegMeta(payload, true);
};

const ReportTable = ({ reportData }: any) => {
  return (
    <Table hover>
      <thead>
        <tr>
          <th>
            <span>Student Name</span>
          </th>
          <th>
            {" "}
            <span>Matric Number</span>
          </th>
          <th>
            {" "}
            <span>State</span>
          </th>
          <th>
            {" "}
            <span>Gender</span>
          </th>
          <th>Course of Study</th>
          <th>Level</th>
          <th>School Fee Paid</th>
          <th>Acceptance Fee Paid</th>
        </tr>
      </thead>
      <tbody>
        {reportData &&
          reportData.map((eachData: any, index: any) => {
            return (
              <tr key={index}>
                <td>{eachData?.fullName} </td>
                <td>{eachData?.jambNumber} </td>
                <td>{eachData?.state} </td>
                <td>{eachData?.gender} </td>
                <td>{eachData?.program} </td>
                <td>{eachData?.level} </td>
                <td>{eachData?.hasPaidSchoolFee ? "Yes" : "No"} </td>
                <td>{eachData?.hasPaidAcceptanceFee ? "Yes" : "No"} </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

const LoadRecordsWrap = ({
  pageProps,
  pageSize,
  pageNumber,
  setPageNumber,
}: any) => {
  let requestData =
    pageProps?.adminFeesReportRequest?.request_data?.response?.data;

  const handlePageClick = (event: any) => {
    setPageNumber(event?.selected + 1);
  };

  return (
    <div className="records_wrap">
      {requestData?.items && (
        <>
          {requestData?.totalCount >= 1 && requestData?.items.length >= 1 && (
            <ReportTable reportData={requestData?.items} />
          )}
          {(requestData?.totalCount === 0 ||
            requestData?.items.length === 0) && (
            <div className="empty_record"> No Records found</div>
          )}
          {requestData?.totalCount >= 1 && requestData?.items.length >= 1 && (
            <div className="records_count">
              Showing{" "}
              {requestData?.totalCount > requestData?.items.length
                ? `${requestData?.items.length} of ${requestData?.totalCount}`
                : `${requestData?.items.length}`}{" "}
              result{requestData?.items.length > 1 ? "s" : ""}{" "}
            </div>
          )}
          {requestData?.totalCount > pageSize &&
            requestData.items.length >= 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                // initialPage={1}
                onPageChange={handlePageClick}
                pageRangeDisplayed={4}
                forcePage={pageNumber - 1}
                // onClick={handleItemsClick}
                className="pagination_items"
                pageClassName="page_num"
                pageLinkClassName="page_link"
                activeClassName="active_page_link"
                previousClassName="previous_page_link"
                nextClassName="next_page_link"
                pageCount={requestData?.totalPages}
                previousLabel="<"
                // @ts-ignore
                renderOnZeroPageCount={null}
              />
            )}
        </>
      )}
    </div>
  );
};

const RecordFiltersWrap = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  userData,
  setSessionFilter,
  sessionFilter,
  isFilterShown,
  setShowFilter,
  setLevelFilter,
  levelFilter,
  searchQuery,
  setSearchQuery,
  setShouldFetch,
  setClearFilter,
  shouldFetch,
  pageProps,
  handleDownload,
  fileType,
  setFileType,
  allPrograms,
}: any) => {
  let downloadReportRequest = pageProps?.adminDownloadFeesReportRequest;

  const [programName, setProgramName] = useState<any>("");

  let programsList = allPrograms?.response?.allProgrammes || [];
  let list = programsList.map((lists: any) => lists.programmeName);
  const sortList = list.sort();

  if (programsList.length >= 1) {
    programsList = sortList.map((eachProgram: any) => {
      return {
        label: eachProgram,
        value: eachProgram,
      };
    });
  }
  const updatedList = [{ label: "ALL COURSES", value: "All" }, ...programsList];

  const saveProgramme = (chosenProgramme: any) => {
    setProgramName(chosenProgramme);
    setShouldFetch(true);
  };

  // const selectProgramme = (chosenProgramme: any) => {
  //   setProgramName(chosenProgramme);
  //   setProgramFilter(chosenProgramme?.value);
  //   // setShouldFetch(true);
  // };

  return (
    <>
      {isFilterShown && (
        <Modal
          className="delete_modal"
          show={isFilterShown}
          onHide={() => {
            setShowFilter(false);
          }}
        >
          <Modal.Body>
            <div className="records_filter_wrap">
              {/* <div className="search_course_of_study">
                <label htmlFor="">Course</label>
                <Select
                  value={programName}
                  onChange={selectProgramme}
                  options={updatedList}
                  placeholder="Select course of study"
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div> */}

              {/* <div className="search_course_of_study">
                <label htmlFor="">Level</label>
                <Select
                  options={[
                    { label: "All Levels", value: "All" },
                    { label: "100", value: "100" },
                    { label: "200", value: "200" },
                  ]}
                  value={levelFilter}
                  onChange={setLevelFilter}
                  placeholder="Select level"
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div> */}
            </div>

            <div className="dates_filters">
              <div className="date_wrap">
                <label htmlFor="">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date);
                  }}
                  maxDate={new Date()}
                  dateFormat="d MMMM, yyyy"
                  // maxDate={new Date()}
                  placeholderText="Select start date"
                />
              </div>
              <div className="date_wrap">
                <label htmlFor="">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date: any) => {
                    setEndDate(date);
                  }}
                  maxDate={new Date()}
                  dateFormat="d MMMM, yyyy"
                  // maxDate={new Date()}
                  placeholderText="Select end date"
                />
              </div>
            </div>
            <div className="records_filter_wrap">
              <div className="search_course_of_study">
                <label htmlFor="">Session</label>
                <Select
                  options={[
                    {
                      label: userData?.currentSession?.session,
                      value: userData?.currentSession?.session,
                    },
                  ]}
                  value={{ label: sessionFilter, value: sessionFilter }}
                  onChange={(option: optionTypes) => {
                    setSessionFilter(option?.value);
                  }}
                  placeholder="Select session"
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div>
            </div>
            <div className="filter_proceed">
              <button
                className="btn"
                onClick={() => {
                  setShouldFetch(true);
                  setShowFilter(false);
                }}
              >
                Fetch Report
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="fetch_ctas">
        <div className="filter_summary">
          {sessionFilter} session <span>New Students Payments Report</span>
          {/* {sessionFilter} session   <span>New Students Payments Report</span>  from <span>{getDateFromISO({ date: startDate.toISOString() })}</span>   to <span>{getDateFromISO({ date: endDate.toISOString() })}</span> */}
        </div>
      </div>
      <div className="report_filters">
        <input
          type="text"
          className="input_wrap search_wrap"
          placeholder="Search"
          value={searchQuery}
          onChange={(e: any) => {
            setSearchQuery(e.target.value);
            setShouldFetch(false);
            setShowFilter(false);
          }}
          onKeyUp={(e: any) => {
            if (searchQuery === "" || e.key === "Enter") {
              setShouldFetch(true);
              setShowFilter(false);
            }
          }}
        />
        {/* {shouldFetch &&
                    <button className="btn filter"
                        onClick={() => {
                            setSessionFilter(userData?.currentSession?.session)
                            setLevelFilter("100")
                            setSearchQuery("")
                            setClearFilter(true)
                        }}
                    >

                        X Clear Filter
                    </button>
                } */}

        <div className="search_course_of_study">
          <Select
            value={programName}
            onChange={saveProgramme}
            options={updatedList}
            placeholder="Select course of study"
            className="select-wrap"
            classNamePrefix="course-select"
          />
        </div>

        <div className="ctas">
          <button
            className="btn filter"
            onClick={() => {
              setShowFilter(true);
            }}
          >
            <img src={Filter} alt="" />
            Filter
          </button>

          <DownloadDropdown
            loading={downloadReportRequest?.is_request_processing}
            fileType={fileType}
            setFileType={setFileType}
            onSubmit={handleDownload}
            disabled={downloadReportRequest?.is_request_processing}
          />
        </div>
      </div>
    </>
  );
};

const AllRecordsWrap = ({
  pageProps,
  userData,
  defaultStartDate,
  defaultEndDate,
}: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState<any>(defaultStartDate);
  const [endDate, setEndDate] = useState<any>(defaultEndDate);
  const [levelFilter, setLevelFilter] = useState<any>("100");
  const [sessionFilter, setSessionFilter] = useState<any>(
    userData?.currentSession?.session || ""
  );
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [fileType, setFileType] = useState<any>("CSV");
  const [isFilterShown, setShowFilter] = useState<boolean>(false);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [isClearFilter, setClearFilter] = useState<boolean>(false);
  const [downloadPayload, setDownloadPayload] = useState<any>("");
  const pageSize = 20;

  let fetchReportRequest = pageProps?.adminFeesReportRequest;
  let downloadReportRequest = pageProps?.adminDownloadFeesReportRequest;
  let requestInfo = pageProps?.adminLoadRegMetaRequest;

  useEffect(() => {
    adminLoadSubjectsRequest({
      pageProps,
    });
    adminGetReportRequest({
      pageProps,
      payload: "CLEAR",
    });

    adminDownloadReportRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // let startMonth =
    //   startDate.getMonth() + 1 < 10
    //     ? `0${startDate.getMonth() + 1}`
    //     : startDate.getMonth() + 1;
    // let startDay =
    //   startDate.getDate() < 10
    //     ? `0${startDate.getDate()}`
    //     : startDate.getDate();

    // let endMonth =
    //   endDate.getMonth() + 1 < 10
    //     ? `0${endDate.getMonth() + 1}`
    //     : endDate.getMonth() + 1;
    // let endDay =
    //   endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();

    // let formattedStartDate = `${startDate.getFullYear()}-${startMonth}-${startDay}`;
    // let formattedEndDate = `${endDate.getFullYear()}-${endMonth}-${endDay}`;
    // let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Session=${sessionFilter}`;
    setDownloadPayload(payload);
    adminGetReportRequest({
      pageProps,
      payload,
      isSearch: searchQuery.length >= 1,
    });

    adminDownloadReportRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // let startMonth =
    //   startDate.getMonth() + 1 < 10
    //     ? `0${startDate.getMonth() + 1}`
    //     : startDate.getMonth() + 1;
    // let startDay =
    //   startDate.getDate() < 10
    //     ? `0${startDate.getDate()}`
    //     : startDate.getDate();

    // let endMonth =
    //   endDate.getMonth() + 1 < 10
    //     ? `0${endDate.getMonth() + 1}`
    //     : endDate.getMonth() + 1;
    // let endDay =
    //   endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();

    // let formattedStartDate = `${startDate.getFullYear()}-${startMonth}-${startDay}`;
    // let formattedEndDate = `${endDate.getFullYear()}-${endMonth}-${endDay}`;
    // let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Session=${sessionFilter}`;
    setDownloadPayload(payload);
    if (searchQuery) {
      payload = `${payload}&SearchQuery=${searchQuery}`;
    }
    adminDownloadReportRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminGetReportRequest({
      pageProps,
      payload,
      isSearch: searchQuery.length >= 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (shouldFetch) {
      // let startMonth =
      //   startDate.getMonth() + 1 < 10
      //     ? `0${startDate.getMonth() + 1}`
      //     : startDate.getMonth() + 1;
      // let startDay =
      //   startDate.getDate() < 10
      //     ? `0${startDate.getDate()}`
      //     : startDate.getDate();

      // let endMonth =
      //   endDate.getMonth() + 1 < 10
      //     ? `0${endDate.getMonth() + 1}`
      //     : endDate.getMonth() + 1;
      // let endDay =
      //   endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();

      // let formattedStartDate = `${startDate.getFullYear()}-${startMonth}-${startDay}`;
      // let formattedEndDate = `${endDate.getFullYear()}-${endMonth}-${endDay}`;
      // let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;
      let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Session=${sessionFilter}`;
      setDownloadPayload(payload);
      if (searchQuery) {
        payload = `${payload}&SearchQuery=${searchQuery}`;
      }
      adminDownloadReportRequest({
        pageProps,
        payload: "CLEAR",
      });
      adminGetReportRequest({
        pageProps,
        payload,
        isSearch: searchQuery.length >= 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useEffect(() => {
    if (isClearFilter) {
      // let startMonth =
      //   defaultStartDate.getMonth() + 1 < 10
      //     ? `0${defaultStartDate.getMonth() + 1}`
      //     : defaultStartDate.getMonth() + 1;
      // let startDay =
      //   defaultStartDate.getDate() < 10
      //     ? `0${defaultStartDate.getDate()}`
      //     : defaultStartDate.getDate();

      // let endMonth =
      //   defaultEndDate.getMonth() + 1 < 10
      //     ? `0${defaultEndDate.getMonth() + 1}`
      //     : defaultEndDate.getMonth() + 1;
      // let endDay =
      //   defaultEndDate.getDate() < 10
      //     ? `0${defaultEndDate.getDate()}`
      //     : defaultEndDate.getDate();

      // let formattedStartDate = `${defaultStartDate.getFullYear()}-${startMonth}-${startDay}`;
      // let formattedEndDate = `${defaultEndDate.getFullYear()}-${endMonth}-${endDay}`;
      // let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;
      let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Session=${sessionFilter}`;
      if (searchQuery) {
        payload = `${payload}&SearchQuery=${searchQuery}`;
      }
      adminGetReportRequest({
        pageProps,
        payload,
        isSearch: searchQuery.length >= 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearFilter]);

  const handleDownload = () => {
    let payload = `${downloadPayload}&FileType=${fileType}`;
    adminDownloadReportRequest({
      pageProps,
      payload,
    });
  };

  useEffect(() => {
    if (fetchReportRequest?.is_request_processing === false) {
      setShouldFetch(false);
    }
  }, [fetchReportRequest?.is_request_processing]);

  return (
    <div className="all_reports_wrap">
      <div className="back_to">
        {" "}
        <Link to="/app/reports"> &lt;&lt;Back to Reports </Link>
      </div>

      {requestInfo?.request_status ===
        appConstantsPortal?.FETCH_COURSE_REG_META_PENDING && <LoadingItem />}

      {requestInfo?.request_status ===
        appConstantsPortal?.FETCH_COURSE_REG_META_FAILURE && (
        <div className="w-50-a">
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        </div>
      )}

      {requestInfo?.request_status ===
        appConstantsPortal?.FETCH_COURSE_REG_META_SUCCESS && (
        <>
          <RecordFiltersWrap
            pageProps={pageProps}
            handleDownload={handleDownload}
            setStartDate={setStartDate}
            startDate={startDate}
            setEndDate={setEndDate}
            endDate={endDate}
            setLevelFilter={setLevelFilter}
            levelFilter={levelFilter}
            setSessionFilter={setSessionFilter}
            sessionFilter={sessionFilter}
            setFileType={setFileType}
            fileType={fileType}
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            userData={userData}
            isFilterShown={isFilterShown}
            setShowFilter={setShowFilter}
            setShouldFetch={setShouldFetch}
            shouldFetch={shouldFetch}
            setClearFilter={setClearFilter}
            allPrograms={requestInfo?.request_data}
          />

          {fetchReportRequest?.request_status ===
            appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING && (
            <LoadingItem />
          )}
          {fetchReportRequest?.request_status ===
            appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE && (
            <AlertMsg
              type="error"
              message={fetchReportRequest?.request_data.error}
            />
          )}
          {downloadReportRequest?.request_status ===
            appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE && (
            <AlertMsg
              type="error"
              message={downloadReportRequest?.request_data.error}
            />
          )}

          {downloadReportRequest?.request_status ===
            appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS && (
            <div className="alert-msg success">
              <div className="alert-txt">
                You can now{" "}
                <a
                  className="dl_link"
                  target="_blank"
                  href={downloadReportRequest?.request_data?.response?.data}
                  download
                  rel="noreferrer"
                >
                  download the report
                </a>{" "}
              </div>
            </div>
          )}
          {fetchReportRequest?.request_status ===
            appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS && (
            <LoadRecordsWrap
              pageProps={pageProps}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </>
      )}
    </div>
  );
};

const AdminNewStudentsPaymentsReportWrap = (pageProps: any) => {
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  let defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 7));
  let defaultEndDate = new Date();

  return (
    <InAppTemplate
      pageTitle="Generate New Students Payments Report"
      childComponent={
        <AllRecordsWrap
          pageProps={pageProps}
          userData={loggedAccountInfo}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
        />
      }
    />
  );
};

const mapDispatchToProps = {
  adminGetFeesReportAction: adminInAppActions.AdminGetNewStudentsPaymentsReport,
  adminDownloadFeesReportAction:
    adminInAppActions.AdminDownloadNewStudentsPaymentsReport,
  adminLoadCourseRegMeta: adminInAppActions.AdminLoadCourseRegMeta,
};
const mapStateToProps = (state: any) => ({
  adminFeesReportRequest:
    state.allUGradPortalReducers.adminGetNewStudentsPaymentsReportReducer,

  adminDownloadFeesReportRequest:
    state.allUGradPortalReducers.adminDownloadNewStudentsPaymentsReducer,
  adminLoadRegMetaRequest: state.allUGradPortalReducers.adminLoadRegMetaReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNewStudentsPaymentsReportWrap);
