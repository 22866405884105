import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import Pencil from "../../../assets/pencil.png";
import { formatDate } from "../../postutme/reports/Reports";

const StudentStatusTable = ({
  items,
  loading,
  setShow,
  setSelectedStudent,
  
}: any) => {

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Matric No.</th>
          <th>Full Name</th>
          <th>Level</th>
          <th>Session</th>
          <th>Deactivated By</th>
          <th>Deactivated Date</th>
          <th>Deactivation Reasons</th>
          <th>Status</th>
          <th style={{ textAlign: "center" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          [...Array(20)].map((_, index) => (
            <tr key={index}>
              {[...Array(10)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) :items?.length ? (
         items.map((item: any, index: number) => {
          const deactivatedDate = formatDate(item?.deactivatedAt)
            return (
              <tr key={item?.studentUniqueId} id={item?.studentUniqueId}>
                <td>{index + 1}</td>
                <td>{item?.matricNumber}</td>
                <td>{item?.fullName}</td>
                <td>{item?.level}</td>
                <td>{item?.session}</td>
                <td>{item?.deactivatedBy}</td>
                <td>{deactivatedDate}</td>
                <td>{item?.reason}</td>
                <td
                  style={{
                    color: item?.status === "DE-ACTIVATED" ? "red" : "green",
                    fontWeight: "800",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {item?.status}
                </td>

                <td onClick={() => {}}>
                  <img
                    src={Pencil}
                    alt="edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShow(true);
                      setSelectedStudent(item)
                    }}
                  />
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={10} style={{ textAlign: "center" }}>
              {" "}
              <h5>No deactivated student available</h5>{" "}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default StudentStatusTable;
