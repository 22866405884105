import { useState } from "react";

import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import Delete from "../../../../assets/delete.png";
import Pencil from "../../../../assets/pencil.png";
import { handleRequestErrors } from "../../../../shared/utils";
import { deleteSpecificDocumentTypeForStudent } from "../../../../redux/action/edocumentation";

const AdditionalDocumentsTable = ({
  items,
  loading,
  fetchStudentsAdditionalDocuments,
  setSelectedDocumentType,
  setShow,
}: any) => {
  const [deleting, setDeleting] = useState(false);
  const [dialogId, setDialogId] = useState<number | null>(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleDelete = async (eDocumentationDocsUniqueId: any) => {
    setDeleting(true);
    await deleteSpecificDocumentTypeForStudent(eDocumentationDocsUniqueId)
      .then((res: any) => {
        if (res.status === 200) {
          toast.success("Document type deleted successfully");
          fetchStudentsAdditionalDocuments(1);
          setShowDialog(!showDialog);
        }
        setDeleting(false);
        setDialogId(null);
      })
      .catch((err: any) => {
        toast.error("An error occured, please try again.");
        handleRequestErrors(err);
      });
  };
  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <p>Are you sure you want to delete this item?</p>
      <div className="action-buttons">
        <Button
          variant="danger"
          onClick={() => handleDelete(dialogId)}
          disabled={loading}
        >
          Delete {deleting ? <Spinner animation="border" size="sm" /> : null}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={deleting}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Matric No.</th>
          <th>Session</th>
          <th>Document Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          [...Array(20)].map((_, index) => (
            <tr key={index}>
              {[...Array(10)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : items?.length ? (
          items?.map((item: any, index: any) => {
            return (
              <tr key={index} id={index}>
                <td>{item?.matricNumber}</td>
                <td>{item?.session}</td>
                <td>{item?.documentName}</td>

                {/* PENCIL */}
                <td onClick={async () => {}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <img
                      src={Pencil}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedDocumentType(item);
                        setShow(true);
                      }}
                    />
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      show={
                        item?.eDocumentationDocsUniqueId === dialogId &&
                        showDialog
                      }
                      overlay={popover}
                      rootClose
                    >
                      <img
                        src={Delete}
                        alt="delete"
                        onClick={async () => {
                          setShowDialog(!showDialog);
                          setDialogId(item?.eDocumentationDocsUniqueId);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "28px",
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            );
          })
        ) : (
          <p className="no-results">No results...</p>
        )}
      </tbody>
    </Table>
  );
};

export default AdditionalDocumentsTable;
