import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { adminInAppActions } from "../../../../redux/action/portal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { appConstantsPortal } from "../../../../redux/action-constants/portal";
import { AlertMsg } from "../../../../shared/components/alert-msg";

const AssignUserToDepartmentSearch = ({ setUserId }: any) => {
  const requestInfo = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetAUserReducer
  );
  const dispatch = useDispatch();
  let initialValues: any = { searchTerm: "" };
  
  let checkValidationSchema = Yup.object().shape({
    searchTerm: Yup.string().required("Required"),
  });

  useEffect(() => {
    dispatch(adminInAppActions.AdminGetAUser("CLEAR"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="assign-user-form-container settings_wrap_content">
      <div className="student_form">
        <Formik
          initialValues={initialValues}
          validationSchema={checkValidationSchema}
          onSubmit={async (values) => {
            let { searchTerm } = values;

            let payload: any = {
              searchTerm,
            };
            adminInAppActions.AdminAssignCourseAdvToDept("CLEAR");
            await dispatch(adminInAppActions.AdminGetAUser(payload, true));
            setUserId(searchTerm);
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              // handleBlur,
              handleChange,
              // isSubmitting,
            } = props;
            return (
              <Form>
                <div className="form-group">
                  <label className="label">Username/Email </label>
                  <div
                    className={
                      errors.searchTerm && touched.searchTerm
                        ? "invalid-input-wrap"
                        : ""
                    }
                  >
                    <Field
                      value={values.searchTerm}
                      id="searchTerm"
                      name="searchTerm"
                      type="text"
                      // disabled={updateStatusRequestInfo?.is_request_processing}
                      onChange={handleChange("searchTerm")}
                      placeholder="Enter username or email"
                      className={`input-wrap ${
                        errors.searchTerm && touched.searchTerm
                          ? "is-invalid-input "
                          : !errors.searchTerm && values.searchTerm !== ""
                          ? "valid-input"
                          : ""
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    name="searchTerm"
                    className="form-input-error"
                    component="div"
                  />
                </div>

                {requestInfo.request_status ===
                  appConstantsPortal?.GET_A_USER_FAILURE && (
                  <AlertMsg
                    type="error"
                    message={requestInfo?.request_data.error}
                  />
                )}
                <div className="submit_cta">
                  <button
                    disabled={requestInfo?.is_request_processing}
                    className="btn shaded"
                    type="submit"
                  >
                    {requestInfo?.is_request_processing
                      ? "Please wait..."
                      : "Search "}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AssignUserToDepartmentSearch;
