import React, { useCallback, useEffect, useState } from "react";
import Navigation from "../../../../shared/components/navigation/Navigation";
import ReactPaginate from "react-paginate";
import fetchAndDownloadExcel from "../../../../shared/_helpers/downloadexcel";
import { lecturersCourseAssignmentSampleData } from "../../../../shared/_helpers/constants";
import { adminInAppActions } from "../../../../redux/action/portal";
import "./index.scss";
import SearchBox from "../../../../shared/components/searchbox";
import {
  NewDeanModal,
  EditDeanModal,
  DeleteDeanModal,
  BulkUploadModal,
} from "./dean-assignment-modal";
import DeanFullList from "./deans-list";
import { toast } from "react-toastify";
import DownloadButton from "../../../../shared/components/download-components/DownloadButton";
import DownloadModal from "../../../../shared/components/download-components/DownloadModal";
import UploadButton from "../../../../shared/components/download-components/UploadButton";
import AddButton from "../../../../shared/components/download-components/AddButton";

const DeansManagement = () => {
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(true);
  //   const [refetch, setRefetch] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const pageCount = Math.ceil(totalCount / 20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dataList, setDataList] = useState<any[]>([]);

  const [allLecturersList, setAllLecturersList] = useState<any[]>([]);
  const [allFacultyList, setAllFacultyList] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string>("");

  const fileType = "EXCEL";

  let currentSession: any = localStorage.getItem("adXAuth");
  currentSession = JSON.parse(currentSession);
  const session = currentSession?.currentSession?.session;

  const fetchRecords = async (page: number) => {
    setLoading(true);
    const getAllRecordsResponse = await adminInAppActions.AdminGetAllDeans(page);
    const getAllLecturers = await adminInAppActions.AdminGetAllLecturersNoPagination();
    const getAllFaculty = await adminInAppActions.AdminAllDeptOrFaculty("faculty");

    if (getAllRecordsResponse?.status === 200 && getAllRecordsResponse?.data?.items) {
  
      setTotalCount(getAllRecordsResponse?.data?.totalCount);
      setDataList(getAllRecordsResponse?.data?.items);
      if (getAllLecturers?.status === 200 && getAllLecturers?.data) {
        setAllLecturersList(getAllLecturers?.data)
        if (getAllFaculty?.status === 200 && getAllFaculty?.data) {
          setAllFacultyList(getAllFaculty?.data)
        } else {
          toast.error(`${getAllFaculty} Refresh page to try again`);
        }
      } else {
        toast.error(`${getAllLecturers} Refresh page to try again`);
      }

    } else {
      toast.error(`${getAllRecordsResponse} Refresh page to try again`);
    }



    setLoading(false);
  };

  const handleSearch = useCallback(
    async (searchParam: any, pageNumber: number) => {
      setLoading(true);
      const getAllRecordsResponse =
        await adminInAppActions.AdminSearchDean(
          searchParam,
          pageNumber
        );
      if (
        getAllRecordsResponse?.status === 200 &&
        getAllRecordsResponse?.data?.items
      ) {
        setDataList(getAllRecordsResponse?.data?.items);
        setTotalCount(getAllRecordsResponse?.data?.totalCount);
      } else {
        toast.error(getAllRecordsResponse);
      }
      setLoading(false);
    },
    []
  );

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    fetchRecords(newOffset);
  };

  useEffect(() => {
    // const handler = setTimeout(() => {
    if (currentPage >= 1) {
      fetchRecords(currentPage);
    }
    // }, 1000);

    // return () => {
    //   clearTimeout(handler);
    // };
  }, [currentPage]);

  useEffect(() => {
    let searchDebounce: any;
    if (searchTerm.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(searchTerm, 1);
      }, 1000);
    } else {
      fetchRecords(1);
    }
    return () => clearTimeout(searchDebounce);
  }, [searchTerm, handleSearch]);


  const downloadLecturersAssignment = async () => {
    setFileLoading(true);
    const downloadResponse =
      await adminInAppActions.AdminDownloadDeans();
    if (downloadResponse?.data) {
      setShowDownloadModal(true);
      setDownloadUrl(downloadResponse.data);
      setFileLoading(false);
    } else {
      toast.error("No record found");
    }
    setFileLoading(false);
  };

  return (
    <div className="main-content-wrap">
      <div className="top-nav">
        <Navigation to="/app/results/settings" text="Back to Result Settings" />
      </div>
      {downloadUrl && (
        <DownloadModal
          showDownloadModal={showDownloadModal}
          setShowDownloadModal={setShowDownloadModal}
          downloadUrl={downloadUrl}
          fileType={fileType}
          text="lecturers-subject-assignment-list"
          session={session}
        />
      )}
      {/* <p
        className="upload-template"
        onClick={() =>
          fetchAndDownloadExcel(
            lecturersCourseAssignmentSampleData,
            "LecturersCourseAssignmentTemplate.xlsx"
          )
        }
      >
        NOTE : PLEASE CLICK HERE TO DOWNLOAD SAMPLE EXCEL TEMPLATE FOR ASSIGNMENT OF COURSE
        LECTURERS FOR BULK UPLOAD. ENSURE YOUR UPLOAD MATCHES THE EXCEL TEMPLATE
        COLUMNS.
      </p> */}
      {showNew && (
        <NewDeanModal
          showNew={showNew}
          setShowNew={setShowNew}
          allLecturersList={allLecturersList}
          allFacultyList={allFacultyList}
          dataList={dataList}
          setCurrentPage={setCurrentPage}
        />
      )}
      {selectedRecord && showEdit && (
        <EditDeanModal
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          allLecturersList={allLecturersList}
          allFacultyList={allFacultyList}
          
          setCurrentPage={setCurrentPage}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}
      {selectedRecord && showDelete && (
        <DeleteDeanModal
          showDelete={showDelete}
          setShowDelete={setShowDelete}
          setCurrentPage={setCurrentPage}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
        />
      )}

      {/* <BulkUploadModal
        show={showUpload}
        setShow={setShowUpload}
        setCurrentPage={setCurrentPage}
      /> */}

      <div className="main-actions">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchTerm}
          searchParameter={searchTerm}
        />

        <div className="ctas">
        <DownloadButton
            onClick={()=> downloadLecturersAssignment()}
            loading={fileLoading}
            buttonText="Download "
            // className="add-dwnld-btn"
          />

          {/* <UploadButton
            onClick={() => {
              // setRoomInfo(initialValues);
              setShowUpload(true);
            }}
            buttonText="Upload Bulk"
            className="add-btn"
          /> */}

          <AddButton
              onClick={() => {
                // setRoomInfo(initialValues);
                setShowNew(true);
              }}
              buttonText="Add New Dean"
              className="add-btn"
              disabled={!allLecturersList || !allFacultyList}
          />
        </div>
      </div>
      <div className="data-list">
        <DeanFullList
          dataList={dataList}
          currentPage={currentPage}
          setSelectedRecord={setSelectedRecord}
          setShowDelete={setShowDelete}
          setShowEdit={setShowEdit}
          loading={loading}
        />
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          className="pagination_items"
          pageClassName="page_num"
          pageLinkClassName="page_link"
          activeClassName="active_page_link"
          previousClassName="previous_page_link"
          nextClassName="next_page_link"
        />
        {dataList?.length ? (
          <div className="result-count">
            <p>
              Showing {dataList?.length} of {totalCount} results
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DeansManagement;
