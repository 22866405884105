import React, { useCallback, useEffect, useState } from "react";
import "./otp-modal.scss";
import { OtpVerificationLogin } from "../../../redux/action/onboarding/OtpAuthentication";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { appConstantsOnboarding } from "../../../redux/action-constants/onboarding";
import { decryptData } from "../../../shared/_helpers/password-encrypt";

const OTPInputPage = () => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const [loading, setLoading] = useState(false);

  const [countdown, setCountdown] = useState<number | null>(null);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  let dispatch = useDispatch();

  const otpState = useSelector(
    (state: any) => state.allOnboardingReducers.adminLogin
  );

  const userInfo = localStorage.getItem("adXAuth");
  const payload = JSON.parse(userInfo || "{}");
  const decryptedPassword = payload.password
    ? decryptData(payload.password)
    : "";
  const { userName, tokenUniqueId } = payload || {};

  const [verificationPayload, setVerificationPayload] = useState({
    userName: userName || "",
    password: decryptedPassword || "",
    otp: "",
    tokenUniqueId: tokenUniqueId || "",
  });

  useEffect(() => {
    setVerificationPayload((prev) => ({
      ...prev,
      otp: code.join(""),
    }));
  }, [code]);

  useEffect(() => {
    const storedTime = localStorage.getItem("savedTime");
    let startTime: Date;

    if (!storedTime) {
      startTime = new Date();
      localStorage.setItem("savedTime", startTime.toISOString());
    } else {
      startTime = new Date(storedTime);
    }

    const now = new Date();
    const elapsedSeconds = Math.floor(
      (now.getTime() - startTime.getTime()) / 1000
    );
    const remainingTime = 240 - elapsedSeconds;

    if (remainingTime <= 0) {
      setIsExpired(true);
      setCountdown(0);
      setVerificationPayload({
        userName: "",
        password: "",
        otp: "",
        tokenUniqueId: "",
      });
    } else {
      setCountdown(remainingTime);
      setIsExpired(false);

      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev !== null && prev > 0) {
            return prev - 1;
          } else {
            clearInterval(interval);
            setIsExpired(true);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: appConstantsOnboarding.OTP_VERIFICATION_FAILURE,
      payload: "",
    });
  }, []);

  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
  };

  const handleChange = useCallback(
    (element: any, index: number) => {
      if (!/^\d?$/.test(element.value)) return;

      setCode((prevCode) => {
        const newCode = [...prevCode];
        newCode[index] = element.value;
        return newCode;
      });

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    },
    [code]
  );

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;
    const prevInputEl =
      target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }
  };

  const handleKeyDown = (e: any, index: any) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      e.target.previousSibling.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").trim();

    if (/^\d{6}$/.test(pasteData)) {
      setCode(pasteData.split(""));
      setVerificationPayload((prev) => ({
        ...prev,
        otp: pasteData,
      }));
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await dispatch(OtpVerificationLogin(verificationPayload));
    setLoading(false);
  };

  return (
    <div className="verification-container">
      <div className="verification-box">
        <h2 className="verification-title">Log In to Your Account</h2>
        <p className="verification-text">
          Enter the 6-digit verification code sent to your email address
        </p>
        <form onSubmit={handleSubmit} className="verification-form">
          <div className="verification-inputs">
            {code.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                className="verification-input"
                value={digit}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={inputOnFocus}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={index === 0 ? handlePaste : undefined}
              />
            ))}
          </div>
          <button
            type="submit"
            className="verification-button"
            disabled={
              verificationPayload.otp.length < 6 || isExpired || loading
            }
          >
            {loading ? "Verifying..." : "Log in"}
          </button>
        </form>
        {otpState?.otp_verificationError && !isExpired && (
          <p className="verification-error">
            {otpState?.otp_VerificationErrormsg}
          </p>
        )}
        {isExpired ? (
          <p className="verification-expired">
            <span style={{ color: "#CB1E1E" }}>
              Your One-Time-Password (OTP) has expired{" "}
            </span>
            <a href="/" className="login-link">
              {" "}
              click here
            </a>
            &nbsp; <span>to login again</span>
          </p>
        ) : (
          <p className="verification-timer">
            Token expires in: {formatTime(countdown)}
          </p>
        )}
      </div>
    </div>
  );
};

export default OTPInputPage;
