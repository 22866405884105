import { PropsWithChildren } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { PrivateRoutes } from "../types";

import AdminLogin from "../modules/onboarding/login";
import AdminForgotPw from "../modules/onboarding/reset-password";
import AdminUpdatePw from "../modules/onboarding/reset-password/update-password";

import UGradNewSessionCoursesWrap from "../modules/course-management/undergraduate";
import UGradLoadAllStudentsWrap from "../modules/manage-students";

import AdmissionModule from "../modules/admission-mgt";
import AdminDashboardWrap from "../modules/dashboard";

import AdminSettingsWrap from "../modules/admin-settings";
import AdminQualificationSettingsWrap from "../modules/admin-settings/qualification";
import AdminAwardsInViewSettingsWrap from "../modules/admin-settings/awards-in-view";
import AdminCountrySettingsWrap from "../modules/admin-settings/countries";
import AdminSpecialitiesSettingsWrap from "../modules/admin-settings/specialities";
import AdminCurrentSessionSettingsWrap from "../modules/admin-settings/current-session";
import AdminStudentPasswordSettingsWrap from "../modules/admin-settings/reset-student-password";
import AdminPasswordSettingsWrap from "../modules/admin-settings/reset-admin-password";
import PostUTMEManagement from "../modules/postutme";
import PostUTMEStudents from "../modules/postutme/putme-mgt";
import PostUTMEReports from "../modules/postutme/reports";
// import AdminStudentStatusUpdateWrap from "../modules/admin-settings/update-student-status";
import AdminUpdateStudentOriginWrap from "../modules/admin-settings/update-student-state-of-origin";
import CourseSubjectManagement from "../modules/course-subject";

import AdminManageRolesWrap from "../modules/admin-settings/all-roles";

import ManageCourseAdvisors from "../modules/manage-course-advisor";

import ManageCourseLecturers from "../modules/results/result-settings/manage-lecturers";

import AdminUpdateStudentInvoiceWrap from "../modules/admin-settings/update-student-invoice";
import AdminDeleteStudentInvoiceWrap from "../modules/admin-settings/delete-student-invoice";

import AdminUpdateStudentCourseOfStudyWrap from "../modules/admin-settings/update-student-course-of-study";
import AdminStudentCourseOfStudyChangeHistoryWrap from "../modules/admin-settings/course-of-study-change-history";

import AdminLoadAllUsersWrap from "../modules/admin-settings/all-users";
import AdminNewUserWrap from "../modules/admin-settings/new-user";
import AdminAssignDeptToCourseAdvisorWrap from "../modules/admin-settings/assign-dept-to-course-advisor";
import AdminUserPasswordSettingsWrap from "../modules/admin-settings/reset-other-users-password";
import EDocumentation from "../modules/edocumentation";
import DashboardData from "../modules/edocumentation/Dashboard";
import DocumentsReview from "../modules/edocumentation/documentsReview";

import AdminBursaryReportsWrap from "../modules/bursary-reports";
import AdminSchoolFeeReportWrap from "../modules/bursary-reports/school-fee";
import AdminAcceptanceFeeReportWrap from "../modules/bursary-reports/acceptance-fee";
import AdminAdditionalFeeReportWrap from "../modules/bursary-reports/additonal-fees";

import AdminFullReportsWrap from "../modules/reports";
import AdminMatriculationReportWrap from "../modules/reports/matriculation";
import AdminOldMatriculationReportWrap from "../modules/reports/old-matriculation";
import AdminNewStudentsPaymentsReportWrap from "../modules/reports/new-students-payments";
import AdminRegistrationReportWrap from "../modules/reports/registration-report";
import StudentManagementWrap from "../modules/admin-settings/student-management.";
import AdditionalFeePayment from "../modules/admin-settings/additional-payments";

import EOReportWrapper from "../modules/EO-reports/EO-report";
import NelfundReportWrap from "../modules/reports/nelfund";
import EOReportsWrapper from "../modules/EO-reports";
import HostelManagement from "../modules/hostel-management";
import HostelConfigurationWrapper from "../modules/hostel-management/block-management";
import Rooms from "../modules/hostel-management/Rooms";
import RoomDetails from "../modules/hostel-management/room-view";
import SessonSettings from "../modules/postutme/putme-settings/SessonSettings";
import PutmeReports from "../modules/postutme/postUtmeReports";
import PassFeed from "../modules/postutme/passfeed-report";
import HostelSettings from "../modules/hostel-management/hostel-settings";
import HostelAllocation from "../modules/hostel-management/allocation-management";
import PassFeedWithCard from "../modules/postutme/passfeedWithCard";
import PostUtmeEligibity from "../modules/postutme/eligibility-report";
import HostelReport from "../modules/hostel-management/hostel-report";
import HostelSearchByRoom from "../modules/hostel-management/hostel-report/search-room";
import HostelReportDownload from "../modules/hostel-management/hostel-report/search-reports";
import HostelSearchByMatric from "../modules/hostel-management/hostel-report/search-matric";
import CourseUnitExtensionPage from "../modules/admin-settings/course-unit-extension";
import DefferStudent from "../modules/admin-settings/deffer-student";
import DeactivateUser from "../modules/admin-settings/deactivate-user";
import BannedHostelStudent from "../modules/hostel-management/hostel-settings/blacklist-student";
import HostelStatus from "../modules/hostel-management/hostel-settings/hostel-status";
import TransferManagementWrapper from "../modules/admin-settings/transfer-management";
import StudentTransactionHistory from "../modules/bursary-reports/transaction-history";
import SearchPaymentInvoiceWrap from "../modules/bursary-reports/SearchPaymentInvoice";
import HostelReportWrap from "../modules/bursary-reports/hostel-report";
import AccommodationSwapSettings from "../modules/hostel-management/hostel-settings/accommodation-swap";
import OTPInputPage from "../modules/onboarding/login/OTPInputPage";
import InvoiceChecker from "../modules/bursary-reports/invoice-checker";
import CbsInvoiceQuery from "../modules/admin-settings/CBS-invoice-checker";
import ResultManagement from "../modules/results";
import ResultSettings from "../modules/results/result-settings";
import ResultGradeSettings from "../modules/results/result-settings/grade-settings";
import ResultScoreOptimizer from "../modules/results/result-settings/score-optimizer";
import ResultScoreSettings from "../modules/results/result-settings/score-settings";
import ManageSubjectLecturers from "../modules/results/result-settings/assign-courses-to-lecturer";

import ManageHods from "../modules/results/result-settings/manage-hods";
import ManageDeans from "../modules/results/result-settings/manage-deans";

import ResultReport from "../modules/results/result-reports";
import FacultyResultReport from "../modules/results/result-reports/faculty-reports";
import SenateResultReport from "../modules/results/result-reports/senate-reports";
import RePrintAdmissionLetter from "../modules/admin-settings/print-admission-letter";
import PorterStudentTransactionHistory from "../modules/admin-settings/transaction-history";
import EDocumentationSettings from "../modules/edocumentation/settings";
import UpdateStudentLevelWrap from "../modules/admin-settings/update-student-level";
import ResultManagementSettings from "../modules/results/result-manager";
import ScoresUploadWrap from "../modules/results/result-manager/upload-scores";
import EDocumentationDocumentTypesSettings from "../modules/edocumentation/settings/DocumentTypeSettings";
import EDocumentationAssignUserToFaculty from "../modules/edocumentation/settings/MapUserToFaculty";
import EOregistrationReport from "../modules/EO-reports/EO-registration-report";
import EDocumentationAssignUserToDepartment from "../modules/edocumentation/settings/mapUserToDepartment.tsx";
import SearchStudentReports from "../modules/edocumentation/reports/search-students";
import EDocumentationReports from "../modules/edocumentation/reports";
import AdditionalEDocDocuments from "../modules/edocumentation/settings/AdditionalDocuments.tsx";
import AdminStudentStatusUpdate from "../modules/admin-settings/deactivate-student";
import RegisterCourseWrap from "../modules/results/result-settings/register-student-course";
import BursaryDeleteStudentInvoiceWrap from "../modules/bursary-reports/delete-student-invoice";
import GenerateInvoiceWrap from "../modules/admin-settings/generate-tution-invoice";
import GenerateBursaryInvoiceWrap from "../modules/bursary-reports/generate-tution-invoice";
import GenerateOtherFeeInvoiceWrap from "../modules/bursary-reports/generate-other-invoice";
import GenerateOtherFeeInvoice from "../modules/admin-settings/generate-other-invoice";
const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/",
  children,
  route,
  accessRoles,
}: PrivateRoutes) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);

  if (
    loggedAccountInfo?.profile &&
    loggedAccountInfo?.profile?.hasChangedDefaultPassword === false &&
    route !== "/app/portal-settings/reset-admin-password"
  ) {
    return <Navigate to="/app/portal-settings/reset-admin-password" replace />;
  }

  let userRoles: any[] = loggedAccountInfo?.profile?.userRoles || [];

  userRoles = userRoles.filter((c: any, index: any) => {
    return userRoles.indexOf(c) === index;
  });

  let allowedRoles = accessRoles || [];
  let canUserAccess: boolean = false;
  if (loggedAccountInfo?.access_token) {
    for (let index = 0; index < userRoles.length; index++) {
      const element = userRoles[index];
      if (allowedRoles.includes(element)) {
        canUserAccess = true;
        break;
      }
    }
  }

  if (canUserAccess || allowedRoles.length === 0) {
    return children ? children : <Outlet />;
  } else {
    return <Navigate to={redirectPath} replace />;
  }
};
type OwnProps = ReturnType<typeof mapStateToProps>;
const AppRoutes = (pageProps: PropsWithChildren<OwnProps>) => {
  return (
    <Routes>
      <Route path="/" element={<AdminLogin />} />
      <Route path="/accounts/forgot-password" element={<AdminForgotPw />} />
      <Route
        path="/accounts/admin/reset-password/:resetCode"
        element={<AdminUpdatePw />}
      />
      <Route
        path="/accounts/admin/otp-verification"
        element={<OTPInputPage />}
      />

      <Route
        path="/app/course-management/undergraduate"
        element={
          <ProtectedRoute
            route="/app/course-management/undergraduate"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Course_Advisor", "Course_Presetter"]}
          >
            <UGradNewSessionCoursesWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app"
        element={
          <ProtectedRoute
            route="/app"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <AdminDashboardWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/students"
        element={
          <ProtectedRoute
            route="/app/students"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Student_Manager"]}
          >
            <UGradLoadAllStudentsWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/bursary-reports"
        element={
          <ProtectedRoute
            route="/app/bursary-reports"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary", "Invoice_Checker"]}
          >
            <AdminBursaryReportsWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/bursary-reports/school-fees"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/school-fees"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <AdminSchoolFeeReportWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/bursary-reports/acceptance-fees"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/acceptance-fees"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <AdminAcceptanceFeeReportWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/bursary-reports/additional-fees"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/additional-fees"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary", "Nanniss_Manager", "Sug_Manager"]}
          >
            <AdminAdditionalFeeReportWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/reports"
        element={
          <ProtectedRoute
            route="/app/reports"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Report_Manager"]}
          >
            <AdminFullReportsWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/reports/new-students-matriculation"
        element={
          <ProtectedRoute
            route="/app/reports/new-students-matriculation"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Report_Manager"]}
          >
            <AdminMatriculationReportWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/reports/matriculation"
        element={
          <ProtectedRoute
            route="/app/reports/matriculation"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Report_Manager"]}
          >
            <AdminOldMatriculationReportWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/reports/new-students-payments"
        element={
          <ProtectedRoute
            route="/app/reports/new-students-payments"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Report_Manager"]}
          >
            <AdminNewStudentsPaymentsReportWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/reports/registration"
        element={
          <ProtectedRoute
            route="/app/reports/registration"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Report_Manager"]}
          >
            <AdminRegistrationReportWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/reports/nelfund"
        element={
          <ProtectedRoute
            route="/app/reports/nelfund"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Report_Manager"]}
          >
            <NelfundReportWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/admissions-management"
        element={
          <ProtectedRoute
            route="/app/admissions-management"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Admission_Manager"]}
          >
            <AdmissionModule />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings"
        element={
          <ProtectedRoute
            route="/app/portal-settings"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={[
              "Admin",
              "Student_Manager",
              "Admission_Manager",
              "Student_Creator",
              "Mis_Manager",
              "Password_Resetter",
              "Invoice_Checker",
              "Bursary",
              "Invoice_Deleter",
            ]}
          >
            <AdminSettingsWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/update-student-course-of-study"
        element={
          <ProtectedRoute
            route="/app/portal-settings/update-student-course-of-study"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <AdminUpdateStudentCourseOfStudyWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/student-course-of-study-change-history"
        element={
          <ProtectedRoute
            route="/app/portal-settings/student-course-of-study-change-history"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <AdminStudentCourseOfStudyChangeHistoryWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/update-student-invoice"
        element={
          <ProtectedRoute
            route="/app/portal-settings/update-student-invoice"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <AdminUpdateStudentInvoiceWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/delete-student-invoice"
        element={
          <ProtectedRoute
            route="/app/portal-settings/delete-student-invoice"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Invoice_Deleter"]}
          >
            <AdminDeleteStudentInvoiceWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/course-advisor-management"
        element={
          <ProtectedRoute
            route="/app/portal-settings/course-advisor-management"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Course_Advisor_Manager"]}
          >
            <ManageCourseAdvisors />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/results/manage-lecturers"
        element={
          <ProtectedRoute
            route="/app/results/manage-lecturers"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Lecturer"]}
          >
            <ManageCourseLecturers />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/qualification"
        element={
          <ProtectedRoute
            route="/app/portal-settings/qualification"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <AdminQualificationSettingsWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/awards"
        element={
          <ProtectedRoute
            route="/app/portal-settings/awards"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <AdminAwardsInViewSettingsWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/countries"
        element={
          <ProtectedRoute
            route="/app/portal-settings/countries"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <AdminCountrySettingsWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/specialization"
        element={
          <ProtectedRoute
            route="/app/portal-settings/specialization"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminSpecialitiesSettingsWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/reset-student-password"
        element={
          <ProtectedRoute
            route="/app/portal-settings/reset-student-password"
            accessRoles={["Admin", "Password_Resetter"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminStudentPasswordSettingsWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/reset-admin-password"
        element={
          <ProtectedRoute
            route="/app/portal-settings/reset-admin-password"
            accessRoles={[]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminPasswordSettingsWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/reset-a-user-password"
        element={
          <ProtectedRoute
            route="/app/portal-settings/reset-a-user-password"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminUserPasswordSettingsWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/current-session"
        element={
          <ProtectedRoute
            route="/app/portal-settings/current-session"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminCurrentSessionSettingsWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/putme-settings/current-session"
        element={
          <ProtectedRoute
            route="/app/putme-settings/current-session"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <SessonSettings />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/student-management"
        element={
          <ProtectedRoute
            route="/app/portal-settings/student-management"
            accessRoles={["Admin", "Student_Creator"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <StudentManagementWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/postutme"
        element={
          <ProtectedRoute
            route="/app/postutme"
            accessRoles={["Admin", "PostUtme_Manager", "Bursary"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <PostUTMEManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/putme/passfeedback-reports"
        element={
          <ProtectedRoute
            route="/app/putme/passfeedback-reports"
            accessRoles={["Admin", "PostUtme_Manager"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <PassFeed />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/putme/passfeedback-scratchcard-reports"
        element={
          <ProtectedRoute
            route="/app/putme/passfeedback-scratchcard-reports"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <PassFeedWithCard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/postutme/students"
        element={
          <ProtectedRoute
            route="/app/postutme/students"
            accessRoles={["Admin", "PostUtme_Manager"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <PostUTMEStudents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/postutme/reports"
        element={
          <ProtectedRoute
            route="/app/postutme/reports"
            accessRoles={["Admin", "PostUtme_Manager"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <PostUTMEReports />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/update-student-status"
        element={
          <ProtectedRoute
            route="/app/portal-settings/update-student-status"
            accessRoles={["Admin", "Admission_Manager", "Student_Manager"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminStudentStatusUpdate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/update-student-origin"
        element={
          <ProtectedRoute
            route="/app/portal-settings/update-student-origin"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminUpdateStudentOriginWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/manage-roles"
        element={
          <ProtectedRoute
            route="/app/portal-settings/manage-roles"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminManageRolesWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/manage-users"
        element={
          <ProtectedRoute
            route="/app/portal-settings/manage-users"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminLoadAllUsersWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/assign-dept-to-course-advisor"
        element={
          <ProtectedRoute
            route="/app/assign-dept-to-course-advisor"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminAssignDeptToCourseAdvisorWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/manage-users/create-new"
        element={
          <ProtectedRoute
            route="/app/portal-settings/manage-users/create-new"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdminNewUserWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/course-subject"
        element={
          <ProtectedRoute
            route="/app/course-subject"
            accessRoles={["Admin", "Course_Manager"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <CourseSubjectManagement />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/e-documentation"
        element={
          <ProtectedRoute
            route="/app/e-documentation"
            accessRoles={[
              "Admin",
              "Faculty_Officer",
              "Academic_Officer",
              "Ict_Officer",
              "Bursary",
            ]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EDocumentation />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/e-documentation/settings"
        element={
          <ProtectedRoute
            route="/app/e-documentation/settings"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EDocumentationSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/e-documentation/settings/documentTypes"
        element={
          <ProtectedRoute
            route="/app/e-documentation/settings/documentTypes"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EDocumentationDocumentTypesSettings />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/e-documentation/settings/mapUserToFaculty"
        element={
          <ProtectedRoute
            route="/app/e-documentation/settings/mapUserToFaculty"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EDocumentationAssignUserToFaculty />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/e-documentation/settings/mapUserToDepartment"
        element={
          <ProtectedRoute
            route="/app/e-documentation/settings/mapUserToDepartment"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EDocumentationAssignUserToDepartment />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/e-documentation/settings/additionalDocuments"
        element={
          <ProtectedRoute
            route="/app/e-documentation/settings/additionalDocuments"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdditionalEDocDocuments />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/e-documentation/dashboard"
        element={
          <ProtectedRoute
            route="/app/e-documentation/dashboard"
            accessRoles={[
              "Admin",
              "Faculty_Officer",
              "Academic_Officer",
              "Ict_Officer",
            ]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <DashboardData />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/e-documentation/review"
        element={
          <ProtectedRoute
            route="/app/e-documentation/review"
            accessRoles={[
              "Admin",
              "Faculty_Officer",
              "Academic_Officer",
              "Ict_Officer",
            ]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <DocumentsReview />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/e-documentation/reports"
        element={
          <ProtectedRoute
            route="/app/e-documentation/reports"
            accessRoles={[
              "Admin",
              "Faculty_Officer",
              "Academic_Officer",
              "Ict_Officer",
            ]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EDocumentationReports />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/e-documentation/search-students"
        element={
          <ProtectedRoute
            route="/app/e-documentation/search-students"
            accessRoles={[
              "Admin",
              "Faculty_Officer",
              "Academic_Officer",
              "Ict_Officer",
            ]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <SearchStudentReports />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/additional-payment"
        element={
          <ProtectedRoute
            route="/app/portal-settings/additional-payment"
            accessRoles={["Admin", "Student_Manager"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <AdditionalFeePayment />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/generate-invoice"
        element={
          <ProtectedRoute
            route="/app/portal-settings/generate-invoice"
            accessRoles={["Admin", "Mis_Manager", "Busary"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <GenerateInvoiceWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/eo-reports"
        element={
          <ProtectedRoute
            route="/app/eo-reports"
            accessRoles={["Admin", "EO_Report"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EOReportsWrapper />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/eo-reports/exam/registration-report"
        element={
          <ProtectedRoute
            route="/app/eo-reports/exam/registration-report"
            accessRoles={["Admin", "EO_Report"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EOReportWrapper />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/eo-reports/registration-reports"
        element={
          <ProtectedRoute
            route="/app/eo-reports/registration-reports"
            accessRoles={["Admin", "EO_Report"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <EOregistrationReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/putme-report-cards"
        element={
          <ProtectedRoute
            route="/app/putme-report-cards"
            accessRoles={["Admin"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <PutmeReports />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/hostel/dashboard"
        element={
          <ProtectedRoute
            route="/app/hostel/dashboard"
            accessRoles={["Admin", "Hostel_Manager", "Hostel_Porter"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <HostelManagement />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/hostel/configuration"
        element={
          <ProtectedRoute
            route="/app/hostel/configuration"
            accessRoles={["Admin", "Hostel_Manager"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <HostelConfigurationWrapper />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/hostel/accommodations"
        element={
          <ProtectedRoute
            route="/app/hostel/accommodations"
            accessRoles={["Admin", "Hostel_Manager", "Hostel_Porter"]}
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
          >
            <Rooms />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/hostel/room"
        element={
          <ProtectedRoute
            route="/app/hostel/room"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager", "Hostel_Porter"]}
          >
            <RoomDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/hostel/settings"
        element={
          <ProtectedRoute
            route="/app/hostel/settings"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager"]}
          >
            <HostelSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/hostel/allocation-management"
        element={
          <ProtectedRoute
            route="/app/hostel/allocation-management"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager"]}
          >
            <HostelAllocation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/putme/eligibility-reports"
        element={
          <ProtectedRoute
            route="/app/putme/eligibility-reports"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <PostUtmeEligibity />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/hostel/reports"
        element={
          <ProtectedRoute
            route="/app/hostel/reports"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager", "Hostel_Porter"]}
          >
            <HostelReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/hostel/report/search-matric"
        element={
          <ProtectedRoute
            route="/app/hostel/report/search-matric"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager", "Hostel_Porter"]}
          >
            <HostelSearchByMatric />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/hostel/report/search-room"
        element={
          <ProtectedRoute
            route="/app/hostel/report/search-room"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager", "Hostel_Porter"]}
          >
            <HostelSearchByRoom />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/hostel/report-download"
        element={
          <ProtectedRoute
            route="/app/hostel/report-download"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager"]}
          >
            <HostelReportDownload />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/unit-extension"
        element={
          <ProtectedRoute
            route="/app/portal-settings/unit-extension"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Mis_Manager"]}
          >
            <CourseUnitExtensionPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/deferred-student"
        element={
          <ProtectedRoute
            route="/app/portal-settings/deferred-student"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Mis_Manager", "Student_Manager"]}
          >
            <DefferStudent />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/deactivate-user"
        element={
          <ProtectedRoute
            route="/app/portal-settings/deactivate-user"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <DeactivateUser />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/hostel/blacklisted-students"
        element={
          <ProtectedRoute
            route="/app/hostel/blacklisted-students"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager"]}
          >
            <BannedHostelStudent />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/hostel/accommodation-status"
        element={
          <ProtectedRoute
            route="/app/hostel/accommodation-status"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager"]}
          >
            <HostelStatus />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/student/transfer-management"
        element={
          <ProtectedRoute
            route="/app/portal-settings/student/transfer-management"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Mis_Manager", "Admission Manager"]}
          >
            <TransferManagementWrapper />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/bursary-reports/transaction-history"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/transaction-history"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <StudentTransactionHistory />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/bursary-reports/search/payment-invoice"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/search/payment-invoice"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary", "Invoice_Checker"]}
          >
            <SearchPaymentInvoiceWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/bursary-reports/accommodation-reports"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/accommodation-reports"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <HostelReportWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/hostel/admin/room-management"
        element={
          <ProtectedRoute
            route="/app/hostel/admin/room-management"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Hostel_Manager"]}
          >
            <AccommodationSwapSettings />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/bursary-reports/cbs/invoice-query"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/cbs/invoice-query"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "MIS_Manager", "Bursary", "Invoice_Checker"]}
          >
            <InvoiceChecker />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/cbs/invoice-query"
        element={
          <ProtectedRoute
            route="/app/portal-settings/cbs/invoice-query"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "MIS_Manager", "Bursary", "Invoice_Checker"]}
          >
            <CbsInvoiceQuery />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/results/dashboard"
        element={
          <ProtectedRoute
            route="/app/results/dashboard"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Lecturer"]}
          >
            <ResultManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/results/settings"
        element={
          <ProtectedRoute
            route="/app/results/settings"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <ResultSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/results/grade-settings"
        element={
          <ProtectedRoute
            route="/app/results/grade-settings"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <ResultGradeSettings />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/results/score-optimizer"
        element={
          <ProtectedRoute
            route="/app/results/score-optimizer"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <ResultScoreOptimizer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/results/score-settings"
        element={
          <ProtectedRoute
            route="/app/results/score-settings"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <ResultScoreSettings />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/results/assign-courses-to-lecturers"
        element={
          <ProtectedRoute
            route="/app/results/assign-courses-to-lecturers"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <ManageSubjectLecturers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/results/manage-hods"
        element={
          <ProtectedRoute
            route="/app/results/manage-hods"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <ManageHods />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/results/manage-deans"
        element={
          <ProtectedRoute
            route="/app/results/manage-deans"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <ManageDeans />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/results/reports"
        element={
          <ProtectedRoute
            route="/app/results/reports"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <ResultReport />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/results/senate-reports"
        element={
          <ProtectedRoute
            route="/app/results/senate-reports"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <SenateResultReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/results/faculty-reports"
        element={
          <ProtectedRoute
            route="/app/results/faculty-reports"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <FacultyResultReport />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/portal-settings/download/admission-letter"
        element={
          <ProtectedRoute
            route="/app/portal-settings/download/admission-letter"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Mis_Manager"]}
          >
            <RePrintAdmissionLetter />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/transaction-history"
        element={
          <ProtectedRoute
            route="/app/portal-settings/transaction-history"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Student_Manager", "Bursary"]}
          >
            <PorterStudentTransactionHistory />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/update-level"
        element={
          <ProtectedRoute
            route="/app/portal-settings/update-level"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Mis_Manager"]}
          >
            <UpdateStudentLevelWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/results/manager"
        element={
          <ProtectedRoute
            route="/app/results/manager"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Lecturer"]}
          >
            <ResultManagementSettings />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/results/manager/upload-results"
        element={
          <ProtectedRoute
            route="/app/results/manager/upload-results"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Lecturer"]}
          >
            <ScoresUploadWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/results/register-student-courses"
        element={
          <ProtectedRoute
            route="/app/results/register-student-courses"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin"]}
          >
            <RegisterCourseWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/bursary-reports/delete-student-invoice"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/delete-student-invoice"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <BursaryDeleteStudentInvoiceWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/bursary-reports/generate-school-fee-invoice"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/generate-school-fee-invoice"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <GenerateBursaryInvoiceWrap />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/bursary-reports/generate-other-fee-invoice"
        element={
          <ProtectedRoute
            route="/app/bursary-reports/generate-other-fee-invoice"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <GenerateOtherFeeInvoiceWrap />
          </ProtectedRoute>
        }
      />

      <Route
        path="/app/portal-settings/invoice/generate-other-fee"
        element={
          <ProtectedRoute
            route="/app/portal-settings/invoice/generate-other-fee"
            isAllowed={pageProps?.adminLoginRequest?.loggedIn}
            accessRoles={["Admin", "Bursary"]}
          >
            <GenerateOtherFeeInvoice />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
const mapStateToProps = (state: any) => ({
  adminLoginRequest: state.allOnboardingReducers.adminLogin,
});
export default connect(mapStateToProps)(AppRoutes);
