// ScoreSettingsTable.tsx
import React, { useState } from "react";
import { Button, OverlayTrigger, Popover, Spinner, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Pencil from "../../../../../assets/pencil.png";
import Delete from "../../../../../assets/delete.png";
import { toast } from "react-toastify";
import { deleteMaxExamScores } from "../../../../../redux/action/results";
import { IMaxScoreSettingsTable } from "../../../../../utils/types";


const ScoreSettingsTable: React.FC<IMaxScoreSettingsTable> = ({
  loading,
  setLoading,
  setShowModal,
  setSelectedScore,
  maxScores,
  fetchMaxScore,

}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogueId, setDialogueId] = useState<string>("");

  const handleDelete = async (uniqueId: string) => {
    setLoading(true);
    try {
      const res = await deleteMaxExamScores(uniqueId);
      if (res.status === 200) {
        toast.success("Max. score settings deleted successfully");
        await fetchMaxScore();
      }
    } catch (error) {
      toast.error("Error deleting score settings");
    } finally {
      setLoading(false);
      setDialogueId("");
      setShowDialog(false);
    }
  };

  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <p>Are you sure you want to delete this score Settings?</p>
      <div className="action-buttons">
        <Button variant="danger" onClick={() => handleDelete(dialogueId)} disabled={loading}>
          Delete {loading && <Spinner animation="border" size="sm" />}
        </Button>
        <Button variant="secondary" onClick={() => setShowDialog(false)} disabled={loading}>
          Cancel
        </Button>
      </div>
    </Popover>
  );

  return (
    <div className="result-table">
      <Table bordered hover>
        <thead>
          <tr>
            <th>S/N</th>
            <th>CA CAP</th>
            <th>Exam CAP</th>
            <th>Total CAP</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            [...Array(1)].map((_, index) => (
              <tr key={index}>
                {[...Array(5)].map((_, index) => (
                  <td key={index}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          ) : maxScores.length > 0 ? (
            maxScores.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.caMaxScore}</td>
                <td>{data.examMaxScore}</td>
                <td>{data.totalMaxScore}</td>
                <td>
                  <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <img
                      src={Pencil}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedScore(data);
                        setShowModal(true);
                      }}
                    />
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      show={data.examMaxScoreSettingUniqueId === dialogueId && showDialog}
                      overlay={popover}
                      rootClose
                    >
                      <img
                        src={Delete}
                        alt="delete"
                        onClick={() => {
                          setDialogueId(data.examMaxScoreSettingUniqueId);
                          setShowDialog(!showDialog);
                        }}
                        style={{ cursor: "pointer", width: "20px", height: "28px" }}
                      />
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                <h5>No Maximum score settings available</h5>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ScoreSettingsTable;