import React from 'react'
import { InAppTemplate } from '../../../shared/templates/portal'
import TransactionHistory from './TransactionHistory'

const StudentTransactionHistory = () => {
  return (
    <InAppTemplate
        pageTitle="STUDENT TRANSACTION HISTORY"
        childComponent={ <TransactionHistory  />}

    />
    )
}

export default StudentTransactionHistory
