import { RESULT_DASHBOARD_LIST } from '../../shared/_helpers/constants';
import Card from '../../shared/components/dashboard-cards/Card';



const ResultDashboard = () => {

  const loggedInUser: any = localStorage.getItem("adXAuth") || "{}";
  let user: any = JSON.parse(loggedInUser) || {};
  const userRoles = user?.profile?.userRoles;

  const allowedCards = RESULT_DASHBOARD_LIST.filter((item: any) => {
    return item.allowedRoles.some((role:any) => userRoles.includes(role));
  });
    
      return (
        
            <div className="menu-container">
              {allowedCards.map((item: any, index: number) => (
                <Card
                  key={index}
                  title={item.title}
                  buttonText={item.buttonText}
                  icon={item.icon}
                  backgroundColor={item.backgroundColor}
                  routeTo={item.routeTo}
                />
              ))}
            </div>
      );
    };
    

export default ResultDashboard
