import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { InAppTemplate } from "../../../../shared/templates/portal";
import { ToastContainer } from "react-toastify";
import DeansManagement from "./deans"
import {
  loadAllProgrammes,
} from "../../../../redux/action/admission";

const ManageHods = () => {
  const dispatch = useDispatch();
    useEffect(() => {
      // dispatch(loadAllProgrammes());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        pageTitle="MANAGE FACULTY DEANS"
        childComponent={<DeansManagement />}
      />

    </>
  )
}
export default ManageHods;