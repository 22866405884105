import { InAppTemplate } from "../../../../shared/templates/portal";
import FacultyReport from "./FacultyReport";
const FacultyResultReport = () => {
 
  return (
    <InAppTemplate
      childComponent={<FacultyReport />}
      pageTitle="PRINT FACULTY REPORT SUMMARIES"
    />
  );
};

export default FacultyResultReport;