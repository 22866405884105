import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import "../index.scss";
import { LecturerData } from "../../../../utils/types";

interface AssignedCourseProps {
  courses: LecturerData[];
  loading: boolean;
}
const DepartmentCourseTable = ({ courses, loading }: AssignedCourseProps) => {

  return (
    <div className="result-table">
      <Table bordered hover>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Programme</th>
            <th>Session</th>
            <th>Semester</th>
            <th>Departmental Course</th>
            <th>Course Code</th>
            <th>Course Unit</th>
            <th>Upload Status</th>
            <th style={{ textAlign: "center" }}>View</th>
            <th style={{ textAlign: "center" }}>Approval Status</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            [...Array(5)].map((_, index) => (
              <tr key={index}>
                {[...Array(10)].map((_, idx) => (
                  <td key={idx}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          ) : courses?.length > 0 ? (
            courses.map((data, index) => (
              <tr key={index} className="result-tr">
                <td>{index + 1}</td>
                <td>{data?.programme}</td>
                <td>{data?.session}</td>
                <td>{data?.courseSemester}</td>
                <td>{data?.courseTitle}</td>
                <td>{data?.courseCode}</td>
                <td>{data?.courseUnit}</td>
                <td>{data?.status}</td>
                <td>
                  <div className="upload-link">
                    <Link
                      className="btn upload-btn"
                      to="/app/results/manager/upload-results"
                      state={{
                        courseCode: data?.courseCode,
                        courseUnit: data?.courseUnit,
                        session: data?.session,
                        courseTitle: data?.courseTitle,
                      }}
                    >
                      Upload
                    </Link>
                  </div>
                </td>
                <td>{data?.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10} style={{ textAlign: "center" }}>
                No courses have been assigned yet.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DepartmentCourseTable;
