import React, {useState, useEffect} from 'react'
import "./index.scss"
import Dash1 from "../../../assets/dash-1.png";
import { POSTUTMETABS } from '../../../shared/_helpers/constants';
import { handleRequestErrors } from "../../../shared/utils";
import { getPostUtmeSession, getPutmeStat } from "../../../redux/action/putme";
import StatisticsCard from '../../../shared/components/dashboard-statistics-card';


const PUtmeDashboard = () => {
  const [data, setData] = useState<any>({});
  const [session, setSession] = useState<string>("")
  
 useEffect(() =>  {
  const getPutmeSession = async()=>{
    try {
    const response = await getPostUtmeSession()
    if (response.status === 200) {
    setSession(response?.data.session)
  } 
    } catch (error) {
      handleRequestErrors(error);
  
  }}
  getPutmeSession()
 }, [])
 

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await getPutmeStat();
        if (response.status === 200) {
          setData(response?.data);
        }
       
      } catch (error) {
        handleRequestErrors(error);
      } 
     
    };
    fetchData();
  }, []);

  
  return (
    <div>
         <div className="dashboard_stats">
       
          <div className="tab1_container">
            <div className="top_container">
              <div
                className="top_stat"
                style={{ backgroundColor: "green" }}
              >
                <div className="top_icon">
                  <img src={Dash1} alt="" />
                </div>
                <div className="top_values">
                  <div className="stat">CURRENT POST UTME SESSION</div>
                <div className="vaalue"> <h2>{session}</h2> </div>
                </div>
              </div>
            </div>


          <div className='tab-container'>

        {POSTUTMETABS.map((items:any, index: number)=>{
            return <StatisticsCard 
            key={index}
            itemName = {items.title} 
            color = {items.backgroundColor}
            count = {data[items?.data]}
            /> 
            
        })}   
          </div>      
      </div>
    </div>
    </div>
  );
};



export default PUtmeDashboard
