import { Button, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const TransactionTable = ({ loading, paymentList }: any) => {
  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Matric Number</th>
            <th>Full Name</th>
            <th>Level</th>
            <th>Invoice Number</th>
            <th>Payment Type</th>
            <th>Amount</th>
            <th>Session</th>
            <th>Generated On</th>
            <th>Payment Date</th>
            <th>Status</th>
            <th>Download </th>
          </tr>
        </thead>
        <tbody>
          {loading
            ? [...Array(20)].map((_, index) => (
                <tr key={index}>
                  {[...Array(12)].map((_, index) => (
                    <td key={index}>
                      <Skeleton height={20} />
                    </td>
                  ))}
                </tr>
              ))
            : paymentList.map((payment: any, index: number) => {
                const encodedSession = encodeURIComponent(payment.session);
                const encodedType = encodeURIComponent(payment.type);

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{payment?.matricNumber}</td>
                    <td>{payment?.fullName}</td>
                    <td>{payment?.level}</td>
                    <td>{payment?.invoiceNumber}</td>
                    <td>{payment?.paymentType}</td>
                    <td>{payment?.amountPaid}</td>
                    <td>{payment?.session}</td>
                    <td>{payment?.generatedOn}</td>
                    <td>{payment.paymentDate} </td>
                    <td
                      style={{
                        color:
                          payment?.paymentStatus === "PAID"
                            ? "green"
                            : payment?.paymentStatus === "PENDING"
                            ? "blue"
                            : "red",
                        fontWeight: "800",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      {payment?.paymentStatus}
                    </td>

                    <td>
                      {payment?.paymentStatus === "PAID" ? (
                        <Button
                          className="paid-btn"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://ibbuportals.ibbu.edu.ng/Verify?code=${payment.studentId}&session=${encodedSession}&type=${encodedType}`}
                        >
                          Download
                        </Button>
                      ) : (
                        <p>Awaiting Payment</p>
                      )}
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </>
  );
};

export default TransactionTable;
