import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import InputField from "../../shared/components/InputField";
import DropDown from "../../shared/components/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { addCourse } from "../../redux/action/course-subject";
import ModalFooterButtons from "../../shared/components/download-components/ModalFooterButtons";

const CourseModal = ({
  show,
  setShow,
  initialValues,
  courseInfo,
  setCourseInfo,
  selectedDepartment,
  setSelectedDepartment,
}: any) => {
  const dispatch = useDispatch();
  const { allProgrammes } = useSelector((state: any) => state.admissionReducer);

  const { allDepartments, addCourseLoading } = useSelector(
    (state: any) => state.courseSubjectReducer
  );
  const sortProgramme = (a: any, b: any) => {
    if (a?.label.toLowerCase() < b?.label.toLowerCase()) {
      return -1;
    }
    if (a?.label.toLowerCase() > b?.label.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const sortDept = (a: any, b: any) => {
    if (a.label?.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  };
  const programmesList =
    allProgrammes
      .filter(
        (programme: any) => programme.departmentUniqueId === selectedDepartment
      )
      .map((prog: any) => {
        return {
          value: prog.programmeUniqueId,
          label: prog.programmeName,
        };
      })
      ?.sort(sortProgramme) || [];

  const departmentsOptions =
    allDepartments
      .map((dept: any) => {
        return {
          value: dept?.departmentUniqueId,
          label: dept?.departmentName,
        };
      })
      ?.sort(sortDept) || [];

  let checkValidationSchema = Yup.object().shape({
    departmentUniqueId: Yup.string().required("Required"),
    programmeUniqueId: Yup.string().required("Required"),
    courseName: Yup.string().required("Required"),
    courseCode: Yup.string().required("Required"),
    courseUnit: Yup.string().required("Required"),
  });

  const unitOptions = Array.from({ length: 11 }, (_, index) => index).map(
    (value: number) => ({ label: value, value })
  );
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setCourseInfo(initialValues);
        setSelectedDepartment("");
      }}
      className="course_subject_wrap"
      size="lg"
      contentClassName="course-subject-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {courseInfo?.courseUniqueId
            ? `EDIT COURSE ${courseInfo?.courseCode}`
            : "ADD SINGLE COURSE"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form" >
        <Container>
          <Formik
            initialValues={courseInfo}
            validationSchema={checkValidationSchema}
            onSubmit={async (values) => {
              const valuesObj = {
                ...values,
                courseUniqueId: values?.courseUniqueId || "",
                semester: "",
                level: 0,
              };
              await dispatch(
                addCourse(valuesObj, setShow, setCourseInfo, initialValues)
              );
              setSelectedDepartment("");
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,
              } = props;
              const departmentName =
                departmentsOptions.find(
                  (dept: any) => dept.value === selectedDepartment
                )?.label || "";

              const programmeName =
                programmesList.find(
                  (programme: any) =>
                    programme.value === values.programmeUniqueId
                )?.label || "";

              return (
                <Form>
                  <DropDown
                    label="Department"
                    touched={touched}
                    errors={errors}
                    options={departmentsOptions}
                    value={{
                      value: departmentName,
                      label: departmentName,
                    }}
                    defaultValue={{
                      value: departmentName,
                      label: departmentName,
                    }}
                    field="departmentUniqueId"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    handleChange={setSelectedDepartment}
                    width="97%"
                  />
                  <DropDown
                    label="Programme"
                    touched={touched}
                    errors={errors}
                    options={programmesList}
                    value={{
                      value: programmeName,
                      label: programmeName,
                    }}
                    defaultValue={{
                      value: programmeName,
                      label: programmeName,
                    }}
                    field="programmeUniqueId"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    width="97%"
                  />
                  <InputField
                    field="courseName"
                    label="Course Title"
                    value={values.courseName}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    
                  />

                  <InputField
                    field="courseCode"
                    label="Course Code"
                    value={values.courseCode}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                  />
                  <DropDown
                    label="Course Unit"
                    touched={touched}
                    errors={errors}
                    options={unitOptions}
                    field="courseUnit"
                    value={{
                      value: values.courseUnit,
                      label: values.courseUnit,
                    }}
                    defaultValue={{
                      value: values.courseUnit,
                      label: values.courseUnit,
                    }}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    width="97%"
                  />

                  <ModalFooterButtons
                    isSubmitting={addCourseLoading}
                    onCancel={() => {
                      setShow(false);
                      setCourseInfo(initialValues);
                      setSelectedDepartment("");
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CourseModal;
