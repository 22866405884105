import "./index.scss";
import Navigation from "../../../shared/components/navigation/Navigation";
import { Link } from "react-router-dom";

const EDOCUMENTATION_SETTINGS_MENU = [
  {
    name: "Document Types Management",
    routeTo: "/app/e-documentation/settings/documentTypes",
  },
  {
    name: "Map User to Faculty",
    routeTo: "/app/e-documentation/settings/mapUserToFaculty",
  },
  {
    name: "Map User to Department",
    routeTo: "/app/e-documentation/settings/mapUserToDepartment",
  },
  {
    name: "Configure additional documents",
    routeTo: "/app/e-documentation/settings/additionalDocuments",
  },
];
const SettingsContent = () => {
  return (
    <div className="room-management">
      <div className="room-management-top-buttons">
        <Navigation
          to="/app/e-documentation"
          text="Back to E-Documentation Dashboard"
        />
      </div>
      <div className="settings-menu-list ">
        {EDOCUMENTATION_SETTINGS_MENU.map(({ name, routeTo }) => (
          <div className="each_setting">
            <div className="setting_name">{name}</div>
            <div className="setting_link">
              <Link className="btn" to={routeTo}>
                Manage
              </Link>{" "}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingsContent;
