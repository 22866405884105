import { connect } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../../shared/components/alert-msg";
import { LoadingItem } from "../../../shared/components/loading";
import Logo from "../../../assets/logo2.png";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { adminOnboarding } from "../../../redux/action/onboarding";
import { appConstantsOnboarding } from "../../../redux/action-constants/onboarding";

import "../index.scss";
import { Link } from "react-router-dom";

const adminGradVerifyForgotPwCodeRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.AdminVerifyForgotPwCodeAction(payload);
};

const adminUpdateNewPwActionRequest = async ({ pageProps, payload }: any) => {
  await pageProps.AdminUpdateNewPasswordAction(payload);
};
const AdminUpdatePw = (pageProps: any) => {
  const screenResolution: any = useWindowSize();
  const { resetCode } = useParams();
  const reloadVariable = localStorage.getItem("adRld");
  // let requestInfo = pageProps?.adminVerifyForgotPwCodeRequest;

  let yearTxt = new Date().getFullYear();

  useEffect(() => {
    let payload = `?resetCode=${resetCode}`;
    adminUpdateNewPwActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminGradVerifyForgotPwCodeRequest({
      pageProps,
      payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

   useEffect(() => {
      if (!reloadVariable) {
        localStorage.setItem("adRld", JSON.stringify(true));
        window.location.reload();
      }
   }, [reloadVariable]);

  let checkValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Password is required")
      .min(8, "Minimum of 8 Characters required"),
    confirmPassword: Yup.string()
      .required("Required")
      .when("newPassword", {
        is: (val: string | any[]) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Both password need to be the same"
        ),
      }),
  });
  let initialValues: any = {
    newPassword: "",
    confirmPassword: "",
  };
  return (
    <div className="onboarding_wrap reset">
      <div className="login-form-wrapper">
        <div className="onboarding_content splitted">
          {screenResolution?.width >= 767 && (
            <div className="each_side summary">
              <div>
                <div className="logo">
                  <img src={Logo} alt="" />
                </div>
                <div className="school_name">
                  IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                </div>
                <div className="student_type_txt">RESET PASSWORD</div>
              </div>
            </div>
          )}
          {screenResolution?.width <= 766 && (
            <div className="mobile_heading">
              <div className="logo">
                <img src={Logo} alt="" />
              </div>
              <div className="school_name">
                IBRAHIM BADAMASI BABANGIDA UNIVERSITY
              </div>
              {/* <div className="student_type_txt">FULL-TIME  STUDENT <br /> SIGN IN</div> */}
            </div>
          )}
          <div className="each_side form_info">
            <div className="form_heading">RESET PASSWORD</div>
            {pageProps?.adminVerifyForgotPwCodeRequest?.request_status ===
              appConstantsOnboarding?.VERFIY_PASSWORD_TOKEN_SUCCESS &&
              pageProps?.adminUpdateNewPasswordRequest?.request_status !==
                appConstantsOnboarding?.UPDATE_NEW_PASSWORD_SUCCESS && (
                <Formik
                  initialValues={initialValues}
                  validationSchema={checkValidationSchema}
                  onSubmit={async (values) => {
                    let { newPassword, confirmPassword } = values;
                    let payload = {
                      requestedBy:
                        pageProps?.adminVerifyForgotPwCodeRequest?.request_data
                          ?.response,
                      password: newPassword,
                      confirmPassword,
                      resetCode,
                    };

                    await adminUpdateNewPwActionRequest({ pageProps, payload });
                  }}
                >
                  {(props: FormikProps<any>) => {
                    const {
                      values,
                      touched,
                      errors,
                      // handleBlur,
                      handleChange,
                      // isSubmitting,
                    } = props;
                    return (
                      <Form>
                        <div className="form-group">
                          <label htmlFor="newPassword">New Password</label>
                          <div
                            className={
                              errors.newPassword && touched.newPassword
                                ? "invalid-input-wrap"
                                : ""
                            }
                          >
                            <Field
                              value={values.newPassword}
                              id="newPassword"
                              name="newPassword"
                              type="password"
                              onChange={handleChange("newPassword")}
                              className={` ${
                                errors.newPassword && touched.newPassword
                                  ? "is-invalid-input "
                                  : !errors.newPassword &&
                                    values.newPassword !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            />
                          </div>
                          <ErrorMessage
                            name="newPassword"
                            className="form-input-error"
                            component="div"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="confirmPassword">
                            Confirm Password
                          </label>
                          <div
                            className={
                              errors.confirmPassword && touched.confirmPassword
                                ? "invalid-input-wrap"
                                : ""
                            }
                          >
                            <Field
                              value={values.confirmPassword}
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              onChange={handleChange("confirmPassword")}
                              className={` ${
                                errors.confirmPassword &&
                                touched.confirmPassword
                                  ? "is-invalid-input "
                                  : !errors.confirmPassword &&
                                    values.confirmPassword !== ""
                                  ? "valid-input"
                                  : ""
                              }`}
                            />
                          </div>
                          <ErrorMessage
                            name="confirmPassword"
                            className="form-input-error"
                            component="div"
                          />
                        </div>

                        {pageProps?.adminUpdateNewPasswordRequest
                          ?.request_status ===
                          appConstantsOnboarding?.UPDATE_NEW_PASSWORD_FAILURE && (
                          <>
                            <AlertMsg
                              type="error"
                              message={
                                pageProps?.adminUpdateNewPasswordRequest
                                  ?.request_data.error
                              }
                            />
                            {pageProps?.adminUpdateNewPasswordRequest?.request_data.error.indexOf(
                              "expired"
                            ) && (
                              <div className="forgot_cta">
                                <Link to="/accounts/forgot-password">
                                  Go Back
                                </Link>
                              </div>
                            )}
                          </>
                        )}
                        <div className="submit_cta">
                          <button
                            disabled={
                              pageProps?.adminUpdateNewPasswordRequest
                                ?.is_request_processing
                            }
                            className="btn shaded"
                            type="submit"
                          >
                            {pageProps?.adminUpdateNewPasswordRequest
                              ?.is_request_processing
                              ? "Please wait..."
                              : "Update Password"}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            {pageProps?.adminVerifyForgotPwCodeRequest?.request_status ===
              appConstantsOnboarding?.VERFIY_PASSWORD_TOKEN_PENDING && (
              <LoadingItem />
            )}
            {pageProps?.adminVerifyForgotPwCodeRequest?.request_status ===
              appConstantsOnboarding?.VERFIY_PASSWORD_TOKEN_FAILURE && (
              <>
                <AlertMsg
                  type="error"
                  message={
                    pageProps?.adminVerifyForgotPwCodeRequest?.request_data
                      .error
                  }
                />
                <div className="forgot_cta">
                  <Link to="/accounts/forgot-password">Go Back</Link>
                </div>
              </>
            )}
            {pageProps?.adminUpdateNewPasswordRequest?.request_status ===
              appConstantsOnboarding?.UPDATE_NEW_PASSWORD_SUCCESS && (
              <>
                <AlertMsg
                  type="success"
                  message="Your Password has been successfully updated"
                />
                <div className="forgot_cta">
                  <Link to="/">Sign In</Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* {screenResolution?.width >= 767 && ( */}
      <div className="login-footer">
        <p>
          {" "}
          <span>&copy; {yearTxt}</span> IBB University. All rights reserved
        </p>
        <p>
          Developed by{" "}
          <Link
            to="https://gigbadi.com.ng/"
            target="_blank"
            className="footer-link"
          >
            Gigbadi
          </Link>
        </p>
      </div>
      {/* )} */}
    </div>
  );
};

const mapDispatchToProps = {
  AdminVerifyForgotPwCodeAction: adminOnboarding.AdminVerifyForgotPwCode,
  AdminUpdateNewPasswordAction: adminOnboarding.AdminUpdateNewPassword,
};
const mapStateToProps = (state: any) => ({
  adminVerifyForgotPwCodeRequest:
    state.allOnboardingReducers.adminVerifyForgotPwCode,
  adminUpdateNewPasswordRequest:
    state.allOnboardingReducers.adminUpdateNewPassword,
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminUpdatePw);
