import { Formik, FormikProps } from "formik";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import StudentStatusForm from "./StudentStatusForm";
import Switch from "react-switch";
import { useEffect, useState } from "react";
import { handleRequestErrors } from "../../../shared/utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  adminInAppActions,
  dectivatedStudent,
  getDeactivatedStudentProfile,
  re_ActivatedStudent,
} from "../../../redux/action/portal";

// ADD STUDENT MODAL
export const AddStudentModal: React.FC<any> = ({
  show,
  setShow,
  fetchDeactivatedStudent,
}) => {
  const adminCurrentSession = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetCurrentSessionReducer
  );
  const dispatch = useDispatch();
  const fetchCurrentSession = () => {
    dispatch(adminInAppActions.AdminGetCurrentSession(""));
  };

  const schoolSession =
    adminCurrentSession?.request_data?.response?.data?.items ?? [];

  const session = schoolSession.find(
    (item: any) => item?.type === "SCHOOL"
  )?.session;
  const initialValues = {
    matricNumber: "",
    studentUniqueId: "",
    fullName: "",
    level: "",
    session: session,
    reasons: "",
  };

  const checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
    reasons: Yup.string().required("Required"),
  });

  useEffect(() => {
    fetchCurrentSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="additional-payment-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          DE-ACTIVATE STUDENT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Formik
          initialValues={initialValues}
          validationSchema={checkValidationSchema}
          onSubmit={async () => {}}
        >
          {(formikProps: FormikProps<any>) => (
            <StudentStatusForm
              formikProps={formikProps}
              setShow={setShow}
              fetchDeactivatedStudent={fetchDeactivatedStudent}
              session={session}
            />
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

// EDIT STATUS MODAL

interface Student {
  matricNumber: string;
  studentUniqueId: string;
  fullName: string;
  reason: string;
  session: string;
  level: string;
  status: string;
}

interface EditStudentModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  selectedStudent: Student;
  fetchDeactivatedStudent: (page: number) => void;
}

const StudentStatus = {
  ACTIVATED: "RE-ACTIVATED",
  DEACTIVATED: "DE-ACTIVATED",
} as const;

export const EditStudentModal: React.FC<EditStudentModalProps> = ({
  show,
  setShow,
  selectedStudent,
  fetchDeactivatedStudent,
}) => {
  const initialStatus =
    selectedStudent?.status === StudentStatus.DEACTIVATED ? false : true;
  const [status, setStatus] = useState<boolean>(initialStatus);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
  const [showStatusMessage, setShowStatusMessage] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;
    const fetchProfile = async () => {
      try {
        const res = await getDeactivatedStudentProfile(
          selectedStudent.matricNumber
        );
        if (isMounted && res?.status === 200) {
          setStatus(res.data.status === StudentStatus.ACTIVATED);
        }
      } catch (err) {
        if (isMounted) handleRequestErrors(err);
      }
    };

    fetchProfile();

    return () => {
      isMounted = false;
    };
  }, [selectedStudent]);

  const handleStatus = async (newStatus: boolean) => {
    if (newStatus === status) return;

    setToggleLoading(true);
    try {
      const payload = {
        matricNumber: selectedStudent.matricNumber,
        studentUniqueId: selectedStudent.studentUniqueId,
        fullName: selectedStudent.fullName,
        reason: selectedStudent.reason,
        session: selectedStudent.session,
        level: selectedStudent.level,
      };
      const res = newStatus
        ? await re_ActivatedStudent(payload)
        : await dectivatedStudent(payload);

      if (res.status === 200) {
        setStatus(newStatus);
        setShowStatusMessage(true);
        setTimeout(() => setShowStatusMessage(false), 5000);
        fetchDeactivatedStudent(1);
      }
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setToggleLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="additional-payment-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          EDIT STUDENT STATUS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <div>
          <div style={{ marginBottom: "30px" }}>
            <p>
              <b>Name: {selectedStudent?.fullName}</b>
            </p>
            <p>
              <b>Matric Number: {selectedStudent?.matricNumber}</b>
            </p>
            <p>
              <b>Level: {selectedStudent?.level}</b>
            </p>
          </div>

          <div className="switch-wrapper">
            <div className="set-status">
              {!toggleLoading && !showStatusMessage && (
                <p
                  className="status-info"
                  style={{
                    color: status ? "red" : "green",
                    marginBottom: "4px",
                  }}
                >
                  {status
                    ? "Click to deactivate student"
                    : "Click to activate student"}
                </p>
              )}
              <Switch
                onChange={(newStatus: boolean) => handleStatus(newStatus)}
                checked={status}
                width={70}
                height={30}
                offColor="#FF0000"
                onColor="#00A85B"
                disabled={toggleLoading}
              />
            </div>

            {toggleLoading && <p className="loading">Updating status...</p>}
            {showStatusMessage && !toggleLoading && (
              <p
                className="loading"
                style={{ color: status ? "green" : "red" }}
              >
                Student {status ? "Activated" : "Deactivated"} Successfully !!
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
