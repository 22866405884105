import React from "react";
import { InAppTemplate } from "../../../shared/templates/portal";
import Reports from "./Reports";
import { ToastContainer } from "react-toastify";

const PostUTMEReports = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={2}
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<Reports />}
        pageTitle="SUBMISSION REPORT"
      />
    </>
  );
};

export default PostUTMEReports;
