import { useEffect, useState } from "react";
import Navigation from "../../../../shared/components/navigation/Navigation";
import GradeSettingsTable from "./components/GradeSettingsTable";
import "./index.scss";
import { getExamsGrade } from "../../../../redux/action/results";
import { AddOrUpdateGradeModal } from "./components/AddGradeModal";
import { IGradeSettings } from "../../../../utils/types";
import { toast } from "react-toastify";
import AddButton from "../../../../shared/components/download-components/AddButton";

const GradeSettings = () => {
  let initialValues: IGradeSettings = {
    examGradeSettingUniqueId: "",
    grade: "",
    lowerLimit: undefined as unknown as number,
    upperLimit: undefined as unknown as number,
    gradePoint: undefined as unknown as number,
    remark: "",
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedGrade, setSelectedGrade] =
    useState<IGradeSettings>(initialValues);
  const [examGrades, setExamGrades] = useState<IGradeSettings[]>([]);

  const fetchExamGrades = async () => {
    setLoading(true);
    try {
      const response = await getExamsGrade();
      if (response.status === 200) {
        setLoading(false);
        setExamGrades(response.data);
      }
      else{
        toast.error(response);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchExamGrades();
  }, []);

  return (
    <div className="settings-wrap">
      <div className="back">
        <Navigation to="/app/results/settings" text="Back to Result Settings" />
      </div>

      <AddOrUpdateGradeModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedGrade={selectedGrade}
        setSelectedGrade={setSelectedGrade}
        fetchExamGrades={fetchExamGrades}
        initialValues={initialValues}
      />

      <div className="add-grade">
        <AddButton
       onClick={() => {
        setShowModal(true);
      }}
       buttonText= "Add Grade"
        />
      
      </div>
      <GradeSettingsTable
        loading={loading}
        setLoading={setLoading}
        setShowModal={setShowModal}
        selectedGrade={selectedGrade}
        setSelectedGrade={setSelectedGrade}
        examGrades={examGrades}
        fetchExamGrades={fetchExamGrades}
      />
    </div>
  );
};

export default GradeSettings;
