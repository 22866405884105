import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import "./index.scss";

type SubmitButtonType = {
  onClick?: () => void;
  loading?: boolean;
  buttonText: string;
  className?: any;
  disabled?: any; 
};
const SubmitButton = ({
  onClick,
  loading,
  buttonText,
  className,
  disabled
}: SubmitButtonType) => {
  return (
    <div className="admsn-download-btn">
      <Button
        className={`adm-button ${className}`}
        onClick={onClick}
        type="submit"
        disabled={disabled}
      >

        {loading ? (
          <div className="loading-spiner">
            <DotLoader size={20} color="#ffff"  />
             <span style={{marginLeft:"10px"}}>Submitting...</span>
          </div>
        ) : (
          <span>
            {buttonText}
          </span>
        )}
      </Button>
    </div>
  );
};

export default SubmitButton;
