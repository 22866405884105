import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import InputField from "../../../shared/components/InputField";
import DropDown from "../../../shared/components/dropdown";
import { DotLoader } from "react-spinners";
import {
  addCarryOverCompulsoryFee,
  getStudentProfile,
} from "../../../redux/action/admission";
import { handleRequestErrors } from "../../../shared/utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { adminInAppActions } from "../../../redux/action/portal";

interface AdditionalPaymentModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchCarryOverPayments: any;
}

export interface Student {
  fullName: string;
  studentId: string;
  matricNumber: string;
  paymentType: string;
}



const AdditionalPaymentModal: React.FC<AdditionalPaymentModalProps> = ({
  show,
  setShow,
  fetchCarryOverPayments,
}) => {
  const initialValues = {
    matricNumber: "",
    paymentType: "",
  };

  const adminCurrentSession = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetCurrentSessionReducer
  );
  const dispatch = useDispatch();
  const fetchCurrentSession = () => {
    dispatch(adminInAppActions.AdminGetCurrentSession(""));
  };

  useEffect(() => {
    fetchCurrentSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schoolSession =
    adminCurrentSession?.request_data?.response?.data?.items ?? [];
  const session = schoolSession.find(
    (item: any) => item?.type === "SCHOOL"
  )?.session;

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="additional-payment-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add new student payment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Formik
          initialValues={initialValues}
          //   validationSchema={}
          onSubmit={async (values) => {}}
        >
          {(formikProps: FormikProps<any>) => (
            <AdditionalPaymentForm
              formikProps={formikProps}
              setShow={setShow}
              fetchCarryOverPayments={fetchCarryOverPayments}
              session={session}
            />
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const AdditionalPaymentForm: React.FC<{
  formikProps: FormikProps<any>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchCarryOverPayments: any;
  session: string;
}> = ({ formikProps, setShow, fetchCarryOverPayments, session }) => {
  const initialStudentValue = {
    fullName: "",
    studentId: "",
    matricNumber: "",
    paymentType: "",
  };
  const { values, touched, errors, handleChange, setFieldValue } = formikProps;
  const matricNumber = values.matricNumber;
  const [student, setStudent] = useState<Student>({
    ...initialStudentValue,
    paymentType: values?.paymentType,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleGetStudentProfile = async (matricNo: string) => {
    setStudent(initialStudentValue);
    setLoading(true);
    setError("");
    await getStudentProfile(matricNo)
      .then((data) => {
        setLoading(false);
        if (data?.data?.studentUniqueId) {
          const { studentUniqueId: studentId, fullName } = data?.data;
          setStudent((prevState) => ({
            ...prevState,
            studentId,
            fullName,
          }));
        }
      })
      .catch((err: any) => {
        setLoading(false);
        handleRequestErrors(err);
        if (err?.response?.status === 404) {
          setError(err?.response?.data);
          setStudent(initialStudentValue);
        }
      });
  };
  useEffect(() => {
    setStudent({
      ...student,
      paymentType: values.paymentType,
      matricNumber: values?.matricNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 500);
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matricNumber]);

  const handleSubmit = async () => {
    if (!student?.fullName || !student?.paymentType) {
      return setError("Enter valid matric number and payment type");
    }
    setSubmitting(true);
    await addCarryOverCompulsoryFee(student)
      .then(() => {
        setSubmitting(false);
        toast.success("Payment added successfully");
        setShow(false);
        fetchCarryOverPayments(1);
      })
      .catch((err) => {
        setSubmitting(false);
        handleRequestErrors(err);
        toast.error("An error occured, please try again.");
      });
  };

  return (
    <Form>
      {loading ? (
        <p className="student-name">Searching student...</p>
      ) : !loading && student?.fullName && matricNumber?.length ? (
        <p className="student-name">Name: {student?.fullName}</p>
      ) : null}
      {error?.length && !loading ? <p className="error">{error}</p> : null}
      <InputField
        field="matricNumber"
        label="Matric Number"
        name="matricNumber"
        value={values.matricNumber}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
      />
      <DropDown
        width="100%"
        name="session"
        field="session"
        label="Session"
        setFieldValue={setFieldValue}
        options={[
          {
            value: session,
            label: session,
          },
        ]}
        value={{
          value: session,
          label: session,
        }}
        touched={touched}
        errors={errors}
      />
      <DropDown
        width="100%"
        name="paymentType"
        field="paymentType"
        label="Payment type"
        setFieldValue={setFieldValue}
        options={[{ label: "GST FEE", value: "GST FEE" }]}
        value={{
          value: values.paymentType,
          label: values.paymentType,
        }}
        touched={touched}
        errors={errors}
      />

      <div className="button-container">
        <Button className="submit-btn" onClick={handleSubmit}>
          {submitting ? "Submitting..." : "Submit"}
          {submitting ? (
            <DotLoader
              color="white"
              loading={submitting}
              size={30}
              aria-label="Submitting"
            />
          ) : null}
        </Button>
      </div>
    </Form>
  );
};

export default AdditionalPaymentModal;
