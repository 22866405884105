import { useCallback, useEffect, useState } from "react";
import AdmissionTable from "./AdmissionTable";
import SearchBox from "../../shared/components/searchbox";
import { ReactComponent as Upload } from "../../assets/upload-icon.svg";
import { CiCirclePlus } from "react-icons/ci";
import "./index.scss";
import ReactPaginate from "react-paginate";
import StudentModal from "./StudentModal";
import fetchAndDownloadExcel from "../../shared/_helpers/downloadexcel";
import StudentsUpload from "./StudentsUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadAdmittedStudent,
  getAdmissionList,
  searchStudent,
} from "../../redux/action/admission";
import { admissionSampleData } from "../../shared/_helpers/constants";
import DownloadModal from "../../shared/components/download-components/DownloadModal";
import { toast } from "react-toastify";
import DownloadButton from "../../shared/components/download-components/DownloadButton";
import UploadButton from "../../shared/components/download-components/UploadButton";
import AddButton from "../../shared/components/download-components/AddButton";

const AdmissionContent = () => {
  const initialValues = {
    registrationNumber: "",
    state: "",
    fullName: "",
    lga: "",
    faculty: "",
    nationality: "",
    totalScore: "",
    department: "",
    isNotApproved: "",
    notApprovedReason: "",
    sex: "",
    programme: "",
    levelOfEntry: "",
    session: "",
    matricNumberFormation: "",
  };
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [studentInfo, setStudentInfo] = useState<any>(initialValues);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [selectedFaculty, setSelectedFaculty] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { items, totalCount } = useSelector(
    (state: any) => state.admissionReducer
  );
  const fileType = "EXCEL";

  const dispatch = useDispatch();
  let currentSession: any = localStorage.getItem("adXAuth");
  currentSession = JSON.parse(currentSession);
  const session = currentSession?.currentSession?.session;

  const pageCount = Math.ceil(totalCount / 20);
  const itemsPerPage = 20;
  const lastPageCount = (currentPage - 1) * itemsPerPage + items.length;

  const handleSearch = useCallback(
    (searchParam: any, pageSize: number) => {
      dispatch(searchStudent(searchParam, pageSize));
    },
    [dispatch]
  );
  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    setCurrentPage(newOffset);
    if (searchParameter.length) {
      dispatch(searchStudent(searchParameter, newOffset));
    } else {
      dispatch(getAdmissionList(newOffset, session));
    }
  };

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      setCurrentPage(1)
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      dispatch(getAdmissionList(1, session));
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch, dispatch, session]);

  const handleDownloadAdmittedStudents = async () => {
    setLoading(true);
    try {
      const response = await downloadAdmittedStudent();
      if (response?.data) {
        setShowDownloadModal(true);
        setDownloadUrl(response.data);
        setLoading(false);
      } else {
        toast.error("No record found");
      }
    } catch (error) {
      toast.error("Error downloading admitted students");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admission-management">
      {downloadUrl && (
        <DownloadModal
          showDownloadModal={showDownloadModal}
          setShowDownloadModal={setShowDownloadModal}
          downloadUrl={downloadUrl}
          fileType={fileType}
          text="Admission-List"
          session={session}
        />
      )}

      <p
        className="admission-upload-template"
        onClick={() => fetchAndDownloadExcel(admissionSampleData)}
      >
        NOTE : PLEASE CLICK HERE TO DOWNLOAD SAMPLE EXCEL TEMPLATE FOR ADMISSION
        BULK UPLOAD. ENSURE YOUR UPLOAD MATCHES THE EXCEL TEMPLATE COLUMNS.
      </p>
      <div className="admission-action-buttons">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />

        <AddButton
          onClick={() => setShow(true)}
          buttonText="Add Single Student"
        />

        <DownloadButton
          onClick={() => handleDownloadAdmittedStudents()}
          loading={loading}
          buttonText="Download All Admissions"
        />

        <UploadButton
            onClick={() => setShowUpload(true)}
            buttonText="Upload Bulk"
          />
 
      </div>
      <AdmissionTable
        admissionList={items}
        setStudentInfo={setStudentInfo}
        setShow={setShow}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedFaculty={selectedFaculty}
        setSelectedFaculty={setSelectedFaculty}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
      {items?.length ? (
        <div className="result-count">
          <p>
            Showing {lastPageCount} of {totalCount} results
          </p>
        </div>
      ) : null}
      <StudentsUpload show={showUpload} setShow={setShowUpload} />
      <StudentModal
        show={show}
        setShow={setShow}
        studentInfo={studentInfo}
        setStudentInfo={setStudentInfo}
        initialValues={initialValues}
        session={session}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedFaculty={selectedFaculty}
        setSelectedFaculty={setSelectedFaculty}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
        forcePage={currentPage - 1}
      />
    </div>
  );
};

export default AdmissionContent;
