import { Form, Formik, FormikProps } from "formik";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import InputField from "../../../../../shared/components/InputField";
import DropDown from "../../../../../shared/components/dropdown";
import * as Yup from "yup";
import { addOrUpdateExamGrade } from "../../../../../redux/action/results";
import {
  IAddOrUpdateGradeModal,
  IGradeSettings,
} from "../../../../../utils/types";
import ModalFooterButtons from "../../../../../shared/components/download-components/ModalFooterButtons";
import {
  examGradePoints,
  examGrades,
  examRemarks,
} from "../../../../../shared/_helpers/constants";

export const AddOrUpdateGradeModal: React.FC<IAddOrUpdateGradeModal> = ({
  showModal,
  setShowModal,
  selectedGrade,
  setSelectedGrade,
  fetchExamGrades,
  initialValues,
}: any) => {
  const handleSubmit = async (values: IGradeSettings) => {
    const payLoad = {
      examGradeSettingUniqueId: values.examGradeSettingUniqueId,
      grade: values.grade,
      lowerLimit: values.lowerLimit,
      upperLimit: values.upperLimit,
      gradePoint: values.gradePoint,
      remark: values.remark,
    };

    try {
      const response = await addOrUpdateExamGrade(payLoad);
      if (response.status === 200) {
        toast.success(
          selectedGrade
            ? "Settings updated successfully"
            : "Settings added successfully"
        );
        await fetchExamGrades();
        setShowModal(false);
        setSelectedGrade(initialValues);
      } else {
        toast.error("Failed to update grade");
      }
    } catch (error) {
      toast.error("Failed to update grade");
    }
  };

  // Validation schema
  let checkValidationSchema = Yup.object().shape({
    grade: Yup.string().required("Required"),
    lowerLimit: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Value cannot be negative")
      .max(100, "Value cannot be more than 100"),
    upperLimit: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Value cannot be negative")
      .max(100, "Value cannot be more than 100"),
    gradePoint: Yup.number().required("Required"),
    remark: Yup.string().required("Required"),
  });

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setSelectedGrade(initialValues);
      }}
      className="records_modal_wrap"
      size="lg"
      contentClassName="records_modal_content"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedGrade?.examGradeSettingUniqueId
            ? "UPDATE GRADE"
            : "ADD NEW GRADE"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="records-modal-form">
        <Formik
          initialValues={selectedGrade}
          validationSchema={checkValidationSchema}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            } = props;

            return (
              <Form>
                <Row>
                  <Col xs={12} md={6}>
                    <DropDown
                      label="Grade Name"
                      name="grade"
                      touched={touched}
                      errors={errors}
                      options={examGrades}
                      field="grade"
                      value={{
                        value: values.grade,
                        label: values.grade,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      classNamePrefix="custom-fields"
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <InputField
                      field="lowerLimit"
                      label="Min Score"
                      value={values.lowerLimit}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                    <DropDown
                      label="Grade Point"
                      name="gradePoint"
                      touched={touched}
                      errors={errors}
                      options={examGradePoints}
                      field="gradePoint"
                      value={{
                        value: values.gradePoint,
                        label: values.gradePoint,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      classNamePrefix="custom-fields"
                    />
                  </Col>

                  <Col xs={12} md={6}>
                    <InputField
                      field="upperLimit"
                      label="Max Scores"
                      value={values.upperLimit}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                    <DropDown
                      label="Remark"
                      name="remark"
                      touched={touched}
                      errors={errors}
                      value={{
                        value: values.remark,
                        label: values.remark,
                      }}
                      options={examRemarks}
                      field="remark"
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      classNamePrefix="custom-fields"
                    />
                  </Col>
                </Row>
                  <ModalFooterButtons
                    onCancel={() => {
                      setShowModal(false);
                      setSelectedGrade(initialValues);
                    }}
                    isSubmitting={isSubmitting}
                    submitButtonText={selectedGrade ? "Update" : "Add"}
                  />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
