import { InAppTemplate } from "../../../shared/templates/portal";
import SettingsContent from "./SettingsContent";

const EDocumentationSettings = () => {
  return (
    <InAppTemplate
      childComponent={<SettingsContent />}
      pageTitle="E-DOCUMENTATION SETTINGS"
    />
  );
};

export default EDocumentationSettings;
