import Navigation from "../../../shared/components/navigation/Navigation";
import { useEffect, useState } from "react";
import { adminInAppActions } from "../../../redux/action/portal";
import { toast} from "react-toastify";
import { LecturerData } from "../../../utils/types";
import AssignedCourseTable from "./result-tables/AssignedCourseTable";
import DepartmentCourseTable from "./result-tables/DepartmentCoursesTable";

const ResultManagerConfigurations  = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<LecturerData[]>([]);
  const [activeTab, setActiveTab] = useState<string>("Assigned_Course");


  const loggedInLecturer: any =
    localStorage.getItem("lecturersProfile") || "{}";
  const lecturerInfo: any = JSON.parse(loggedInLecturer) || {};
  const lecturerUniqueId = lecturerInfo?.lecturerUniqueId;

  useEffect(() => {
    const fetchAssignedCourses = async () => {
      setLoading(true);
      try {
        const response =
          await adminInAppActions.AdminGetALecturersSubjectAssignments(
            lecturerUniqueId
          );
        if (response?.status === 200) {
          setDataList(response?.data);
        } else {
          toast.error(response);
        }
      } catch (error: any) {
      } finally {
        setLoading(false);
      }
    };
    fetchAssignedCourses();
  }, [lecturerUniqueId]);

  return (
        <div className="">
              <div className="result_tabs_wrap">
            <div
              onClick={() => setActiveTab("Assigned_Course")}
              className={activeTab === "Assigned_Course" ? "each_tab is_Active" : "each_tab"}
            >
              My Assigned Courses
            </div>
            <div
              onClick={() => setActiveTab("Department_Course")}
              className={activeTab === "Department_Course" ? "each_tab is_Active" : "each_tab"}
            >
              Department Courses
            </div>
            <div
              onClick={() => setActiveTab("Faculty_Course")}
              className={activeTab === "Faculty_Course" ? "each_tab is_Active" : "each_tab"}
            >
              Faculty Courses
            </div>
        </div>
      

    <div className="settings-wrap">
      <div className="back">
        <Navigation
          to="/app/results/dashboard"
          text="Back to Result Dashboard"
        />
      </div>
      <div className="course-table">
       {activeTab === "Assigned_Course" && <AssignedCourseTable courses={dataList} loading={loading} />}
      {activeTab === "Department_Course" &&  <DepartmentCourseTable courses={dataList} loading={loading} />}
      </div>
    </div>

    </div>
  );
};

export default ResultManagerConfigurations;
