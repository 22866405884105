import { ToastContainer } from "react-toastify";
import { InAppTemplate } from "../../../shared/templates/portal";
import PrintAdmissionModal from "./PrintAdmissionModal";

const RePrintAdmissionLetter = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />

      <InAppTemplate
        pageTitle="PRINT ADMISSION LETTER"
        childComponent={<PrintAdmissionModal />}
      />
    </>
  );
};

export default RePrintAdmissionLetter;
