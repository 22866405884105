import { useState } from "react";
import Pencil from "../../../assets/pencil.png";
import Delete from "../../../assets/delete.png";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import Skeleton from "react-loading-skeleton";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import "react-tooltip/dist/react-tooltip.css";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteDocumentType,
  getDocumentTypes,
} from "../../../redux/action/edocumentation";

const DocumentsList = ({
  setSelectedDocument,
  setShow,
  Documentsloading,
  documentList,
  setDocumentList,
}: any) => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogueId, setDialogueId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async (uniqueId: string) => {
    setLoading(true);
    try {
      const res = await deleteDocumentType(uniqueId);
      if (res.status === 200) {
        toast.success("Document type deleted successfully.");
        const res = await dispatch(getDocumentTypes());
        setDocumentList(res);
      }
    } catch (error) {
      toast.error("Error deleting document type.");
    } finally {
      setLoading(false);
      setDialogueId("");
      setShowDialog(false);
    }
  };

  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <p>Are you sure you want to delete this document type?</p>
      <div className="action-buttons">
        <Button
          variant="danger"
          onClick={() => handleDelete(dialogueId)}
          disabled={loading}
        >
          Delete {loading && <Spinner animation="border" size="sm" />}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );
  return (
    <div>
      <Table bordered hover>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Level</th>
            <th>Document Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Documentsloading ? (
            [...Array(20)].map((_, index) => (
              <tr key={index}>
                {[...Array(4)].map((_, index) => (
                  <td key={index}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          ) : documentList.length > 0 ? (
            documentList?.map((document: any, index: any) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{document?.level}</td>
                  <td>{document.documentType}</td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <img
                        src={Pencil}
                        alt="edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedDocument(document);
                          setShow(true);
                        }}
                      />
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        show={
                          document?.eDocumentationDocsUniqueId === dialogueId &&
                          showDialog
                        }
                        overlay={popover}
                        rootClose
                      >
                        <img
                          src={Delete}
                          alt="delete"
                          onClick={() => {
                            setDialogueId(document?.eDocumentationDocsUniqueId);
                            setShowDialog(!showDialog);
                          }}
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            height: "28px",
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>
                <h5>No configured document found</h5>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DocumentsList;
