import React, { useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import { DotLoader } from "react-spinners";
import Navigation from "../../../../shared/components/navigation/Navigation";
import InputField from "../../../../shared/components/InputField";
import EDocReportTable from "./EDocReportTable";
import { searchStudentDocuments } from "../../../../redux/action/edocumentation";
import ReactPaginate from "react-paginate";

const EDocReportModal = () => {
  const initialValue = {
    matricNumber: "",
  };

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const itemsPerPage = 15;
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);
  const lastPageCount = currentPage * itemsPerPage + currentPageData.length;

  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
  });

  return (
    <div className="search-matric-container">
      <div className="report-container">
        <div className="back">
          <Navigation
            to="/app/e-documentation/reports"
            text="Back to e-Documentation Reports"
          />
        </div>

        <div className="report-page">
          <Formik
            initialValues={initialValue}
            validationSchema={checkValidationSchema}
            enableReinitialize
            onSubmit={async (values) => {
              const { matricNumber } = values;
              setLoading(true);
              try {
                const response = await searchStudentDocuments(matricNumber);
                if (response.status === 200) {
                  setData(response.data);
                  setShow(true);
                  setTotalCount(response.data.length);
                } else if (response.response?.status === 404) {
                  setShow(false);
                  toast.error("No record found");
                } else {
                  toast.info("No record found");
                }
              } catch (error) {
                toast.error("Failed to fetch records");
                setShow(false);
              } finally {
                setLoading(false);
              }
            }}
          >
            {(props: FormikProps<any>) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                setFieldTouched,
                setFieldValue,
              } = props;
              return (
                <Form>
                  <div className="form-container">
                    <div className="form-input">
                      <InputField
                        field="matricNumber"
                        label="Matric Number"
                        value={values?.matricNumber}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                    </div>

                    <div className="report-submit-btn">
                      <Button
                        className="submit-button"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <DotLoader
                            color="white"
                            loading={loading}
                            size={30}
                            aria-label="Searching..."
                          />
                        ) : (
                          "Search"
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      {show && (
        <div>
          <EDocReportTable
            documentDetails={currentPageData}
            loading={loading}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
          />
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="pagination_items"
            pageClassName="page_num"
            pageLinkClassName="page_link"
            activeClassName="active_page_link"
            previousClassName="previous_page_link"
            nextClassName="next_page_link"
          />
          <div className="result-count">
            <p>
              Showing {lastPageCount} of {totalCount} results
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EDocReportModal;
