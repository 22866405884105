import { useCallback, useEffect, useState } from "react";
import { InAppTemplate } from "../../../shared/templates/portal";
import { toast, ToastContainer } from "react-toastify";
import Navigation from "../../../shared/components/navigation/Navigation";
import SearchBox from "../../../shared/components/searchbox";
import AddButton from "../../../shared/components/download-components/AddButton";
import StudentStatusTable from "./StudentStatusTable";
import ReactPaginate from "react-paginate";
import { AddStudentModal, EditStudentModal } from "./StudentStatusModal";
import { handleRequestErrors } from "../../../shared/utils";
import {
  downloadAllDeactivateStudent,
  getAllDeactivatedStudent,
  searchDeactivatedStudents,
} from "../../../redux/action/portal";
import DownloadButton from "../../../shared/components/download-components/DownloadButton";
import "./index.scss";
import DownloadModal from "../../../shared/components/download-components/DownloadModal";
import { LoadingItem } from "../../../shared/components/loading";
const AdminStudentStatus = () => {
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<any>();
  const [deactivatedStudent, setDeactivatedStudent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);

  const fileType = "EXCEL";

  const pageCount = Math.ceil(totalCount / 20);

  const fetchDeactivatedStudent = useCallback(async (pageNumber: number) => {
    setLoading(true);
    try {
      const data = await getAllDeactivatedStudent(pageNumber);
      if (data?.status === 200) {
        setDeactivatedStudent(data?.data?.items);
        setTotalCount(data?.data.totalCount);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      handleRequestErrors(error);
    }
  }, []);

  useEffect(() => {
    fetchDeactivatedStudent(1);
  }, [fetchDeactivatedStudent]);

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    fetchDeactivatedStudent(newOffset);
  };

  const handleSearch = useCallback(
    async (pageNumber: number, searchParam: string) => {
      setLoading(true);
      await searchDeactivatedStudents(pageNumber, searchParam)
        .then(({ data }) => {
          setDeactivatedStudent(data?.items);
          setTotalCount(data?.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          handleRequestErrors(err);
        });
    },
    []
  );

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(1, searchParameter);
      }, 1000);
    } else {
      fetchDeactivatedStudent(1);
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch]);

  const downloadDeactivatedList = async () => {
    setDownloading(true);
    const downloadResponse = await downloadAllDeactivateStudent();
    if (downloadResponse?.data) {
      setShowDownloadModal(true);
      setDownloadUrl(downloadResponse.data);
      setDownloading(false);
    } else {
      toast.error("No record found");
    }
    setDownloading(false);
  };

  return (
    <div className="additional-payment-contaianer">
      <div className="back-to-portal">
        <Navigation
          to="/app/portal-settings"
          text="Back to Portal Settings"
        />
      </div>
      {loading && <LoadingItem />}

      {downloadUrl && (
        <DownloadModal
          showDownloadModal={showDownloadModal}
          setShowDownloadModal={setShowDownloadModal}
          downloadUrl={downloadUrl}
          fileType={fileType}
          text="Deactivated-student-list"
        />
      )}
      {!loading && (
        <div className="settings_header">
          <SearchBox
            placeholder="Search..."
            setSearchParameter={setSearchParameter}
            searchParameter={searchParameter}
          />
          <div className="action-btn">
            <DownloadButton
              onClick={() => downloadDeactivatedList()}
              loading={downloading}
              buttonText="Download "
            />
            <AddButton
              onClick={() => setShowAddModal(true)}
              buttonText="Add New Student"
            />
          </div>
        </div>
      )}
      <AddStudentModal
        show={showAddModal}
        setShow={setShowAddModal}
        fetchDeactivatedStudent={fetchDeactivatedStudent}
      />
      <EditStudentModal
        show={showEditModal}
        setShow={setShowEditModal}
        selectedStudent={selectedStudent}
        fetchDeactivatedStudent={fetchDeactivatedStudent}
      />
      {!loading && (
        <StudentStatusTable
          items={deactivatedStudent}
          loading={loading}
          setShow={setShowEditModal}
          setSelectedStudent={setSelectedStudent}
        />
      )}
      {deactivatedStudent?.length ? (
        <div className="result-count">
          <p>
            Showing {deactivatedStudent?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

const AdminStudentStatusUpdate = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        pageTitle={`ACTIVATE / DE-ACTIVATE STUDENT`}
        childComponent={<AdminStudentStatus />}
      />
    </>
  );
};

export default AdminStudentStatusUpdate;
