import { ToastContainer } from "react-toastify";
import { InAppTemplate } from "../../../../shared/templates/portal";
import GradeSettings from "./GradeSettings";

const ResultGradeSettings = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<GradeSettings />}
        pageTitle="GRADE SETTINGS"
      />
    </>
  );
};

export default ResultGradeSettings;
