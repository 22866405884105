import { ToastContainer } from "react-toastify";
import { InAppTemplate } from "../../../../shared/templates/portal";
import ScoreOptimizer from "./ScoreOptimizer";

const ResultScoreOptimizer = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<ScoreOptimizer />}
        pageTitle="SCORE OPTIMIZER"
      />
    </>
  );
};

export default ResultScoreOptimizer;
