import Switch from "react-switch";
import "./index.scss";
import { useState, useEffect } from "react";
import {
  getHostelStatus,
  setHostelStatus,
} from "../../../../redux/action/hostels";
import { handleRequestErrors } from "../../../../shared/utils";
import Navigation from "../../../../shared/components/navigation/Navigation";

const HostelStatusSettings = () => {
  const [status, setStatus] = useState<boolean>(
    JSON.parse(localStorage.getItem("hostelStatus") || "false") 
  );
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);
  const [showStatusMessage, setShowStatusMessage] = useState<boolean>(false);

  useEffect(() => {
    const fetchHostelStatus = async () => {
      try {
        const res = await getHostelStatus();
        if (res.status === 200) {
          setStatus(res.data);
          localStorage.setItem("hostelStatus", JSON.stringify(res.data));
        }
      } catch (err) {
        handleRequestErrors(err);
      } 
    };
    fetchHostelStatus();
  }, []);

  const handleStatus = async (newStatus: boolean) => {
    if (newStatus === status) return;

    setToggleLoading(true);
    try {
      const payload: any = { status: newStatus };
      const res = await setHostelStatus(payload);
      if (res.status === 200) {
        setStatus(newStatus);
        localStorage.setItem("hostelStatus", JSON.stringify(newStatus));
        setShowStatusMessage(true);
        setTimeout(() => setShowStatusMessage(false), 3000);
      }
    } catch (err) {
      handleRequestErrors(err);
    } finally {
      setToggleLoading(false);
    }
  };

  return (
    <div className="hostel-stat-wrapper">
      <div className="back-to-portal">
        <Navigation
          to="/app/hostel/settings"
          text="Back to Accommodation Settings"
        />
      </div>
      <div className="switch-wrapper">
        <div className="set-status">
          <p className="status-info">Status: </p>
          <span
            className={`status-span ${
              status === true ? "success" : "danger"
            }`}
          >
            {status === true ? "Open" : "Closed"}
          </span>
        </div>

        <div className="set-status">
          <p className="status-info">Set Status: </p>
          <Switch
            onChange={(status: boolean) => handleStatus(status)}
            checked={status}
            width={70}
            height={30}
            offColor="#FF0000"
            onColor="#00A85B"
          />
        </div>

   
          {toggleLoading && <p className="loading">Setting status...</p>}
          {showStatusMessage && !toggleLoading && (
            <p className="loading">Status updated successfully</p>
          )}
       
      </div>
    </div>
  );
};

export default HostelStatusSettings;
