import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { InAppTemplate } from "../../../../shared/templates/portal";
import { ToastContainer } from "react-toastify";
import CourseLecturers from "./course-lecturers"
import {
  loadAllProgrammes,
  loadFacultyAndDept
} from "../../../../redux/action/admission";

const ManageCourseLecturers = () => {
  const dispatch = useDispatch();
    useEffect(() => {
      dispatch(loadFacultyAndDept());
      dispatch(loadAllProgrammes());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        pageTitle="MANAGE LECTURERS"
        childComponent={<CourseLecturers />}
      />

    </>
  )
}
export default ManageCourseLecturers;