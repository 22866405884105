import { ToastContainer } from "react-toastify";
import { InAppTemplate } from "../../../../shared/templates/portal";
import ManageDocumentTypes from "./ManageDocumentTypes";

const EDocumentationDocumentTypesSettings = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<ManageDocumentTypes />}
        pageTitle="E-DOCUMENTATION DOCUMENT TYPES SETTINGS"
      />
    </>
  );
};

export default EDocumentationDocumentTypesSettings;
