/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Navigation from "../../../../shared/components/navigation/Navigation";
import DocumentsList from "../DocumentsList";
import { getDocumentTypes } from "../../../../redux/action/edocumentation";
import { useDispatch } from "react-redux";
import AddDocumentModal from "../AddDocumentModal";
import "../index.scss";

const ManageDocumentTypes = () => {
  const initialValues = {
    level: "",
    documentType: "",
    eDocumentationDocsUniqueId: "",
  };
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(initialValues);
  const [documentList, setDocumentList] = useState([]);
  const dispatch = useDispatch();

  const getDocumentsList = async () => {
    setLoading(true);
    const res = await dispatch(getDocumentTypes());
    setDocumentList(res);
    setLoading(false);
  };

  useEffect(() => {
    getDocumentsList();
  }, []);

  return (
    <div className="room-management">
      <AddDocumentModal
        setShow={setShow}
        show={show}
        initialValues={initialValues}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        setDocumentList={setDocumentList}
      />
      <div className="room-management-top-buttons">
        <Navigation
          to="/app/e-documentation/settings"
          text="Back to E-Documentation Settings Dashboard"
        />
      </div>
      <div className="add-button-container">
        <button
          className="add-btn"
          onClick={() => {
            setShow(true);
          }}
        >
          Add Document Type
        </button>
      </div>
      <DocumentsList
        setSelectedDocument={setSelectedDocument}
        setShow={setShow}
        Documentsloading={loading}
        documentList={documentList}
        setDocumentList={setDocumentList}
      />
    </div>
  );
};

export default ManageDocumentTypes;
