import React from "react";
import UpdateStudentLevel from "./UpdateStudentLevel";
import { InAppTemplate } from "../../../shared/templates/portal";
import { ToastContainer } from "react-toastify";

const UpdateStudentLevelWrap = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        pageTitle="UPDATE NEW STUDENT LEVEL"
        childComponent={<UpdateStudentLevel />}
      />
    </>
  );
};

export default UpdateStudentLevelWrap;
