
import { InAppTemplate } from '../../../../shared/templates/portal'
import HostelStatusSettings from './StatusSettings'

const HostelStatus = () => {
  return (
    <InAppTemplate
        pageTitle='HOSTEL ACCOMMODATION STATUS'
        childComponent={<HostelStatusSettings/>}

    />
  )
}

export default HostelStatus
