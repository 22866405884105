import { InAppTemplate } from "../../../../shared/templates/portal";
import SenateReport from "./SenateReport";
const SenateResultReport = () => {
 
  return (
    <InAppTemplate
      childComponent={<SenateReport />}
      pageTitle="PRINT SENATE REPORT SUMMARIES"
    />
  );
};

export default SenateResultReport;