import React, { ChangeEvent } from "react";
import { Container, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import UploadButton from "../../../../shared/components/download-components/UploadButton";
import Fileupload from "../../../../assets/Upload.png";
import { UploadModalProps } from "../../../../utils/types";

export const UploadModal: React.FC<UploadModalProps> = ({
  show,
  setShow,
  handleUpload,
  setSelectedFile,
  ongoing,
}) => {
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 1048576) {
        toast.error("File size should not exceed 1MB.");
        return;
      }
      setSelectedFile(file);
    }
  };

  return (
    <Modal
      show={show}
      contentClassName="students-upload-modal"
      onHide={() => setShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="admission-bulk-upload">
        <Container>
          <h4>UPLOAD EXCEL FILE</h4>
          <img src={Fileupload} alt="file upload" />
          <input
            style={{ cursor: "pointer" }}
            accept=".xls,.xlsx"
            type="file"
            onChange={handleFileChange}
          />
        </Container>
        <Modal.Footer>
          <ul>
            <li>
              Only <b>Excel</b> files are supported.
            </li>
            <li>Please use the downloaded excel template for accuracy.</li>
            <li>
              Please use the following format to save your file: <br /> Course
              Name Year (e.g., CSC 201 2024)
            </li>
          </ul>
          <UploadButton
            onClick={handleUpload}
            buttonText="Upload Scores"
            loading={ongoing}
          />
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};
