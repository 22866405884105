import { useDispatch } from "react-redux";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import ResultDashboard from "./ResultMenu";
import { useEffect } from "react";
import {
  loadAllProgrammes,
  loadFacultyAndDept,
} from "../../redux/action/admission";
import { adminInAppActions } from "../../redux/action/portal";
import { toast } from "react-toastify";

const ResultManagement = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFacultyAndDept());
    dispatch(loadAllProgrammes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loggedInUser: any = localStorage.getItem("adXAuth") || "{}";
  let user: any = JSON.parse(loggedInUser) || {};
  const userEmail = user?.profile?.email.trim();

  useEffect(() => {
    const fetchLecturerProfile = async () => {
      if (!userEmail) return;
      try {
        const response = await adminInAppActions.AdminGetLecturersProfile(
          userEmail
        );
        if (response.status === 200) {
          const lecturerData = response.data;
          localStorage.setItem(
            "lecturersProfile",
            JSON.stringify(lecturerData)
          );
        } else {
          toast.error(response);
        }
      } catch (error: any) {
      } finally {
      }
    };
  fetchLecturerProfile();
  }, [userEmail]);

  return (
    <InAppTemplate
      childComponent={<ResultDashboard />}
      pageTitle="RESULT DASHBOARD"
    />
  );
};

export default ResultManagement;
