import { Link } from 'react-router-dom'
import Navigation from '../../../shared/components/navigation/Navigation'

const ReportCards = () => {
    return (
        <div className="result-header">
        <div className="back">
        <Navigation to="/app/results/dashboard" text="Back to Result Dashboard" />
      </div>
    
        <div className="settings_list">

        <div className="each_setting">
          <div className="setting_name">Senate Report</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/senate-reports">
              Generate Report
            </Link>{" "}
          </div>
        </div>

        <div className="each_setting">
          <div className="setting_name">Faculty Report</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/faculty-reports">
            Generate Report
            </Link>{" "}
          </div>
        </div>
    
      </div>
      </div>
      )
}

export default ReportCards
