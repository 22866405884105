import { ToastContainer } from "react-toastify";
import { InAppTemplate } from "../../../../shared/templates/portal";
import EDocReportModal from "./EDocReportModal";

const SearchStudentDocument = () => {
  return (
    <div>
      <EDocReportModal />
    </div>
  );
};

const SearchStudentReports = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<SearchStudentDocument />}
        pageTitle="SEARCH STUDENT REPORT"
      />
    </>
  );
};

export default SearchStudentReports;
