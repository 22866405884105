import React from "react";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { DotLoader } from "react-spinners";

interface DownloadDropdownProps {
  loading: boolean;
  fileType: string;
  onSubmit?: () => void;
  setFileType: (type: string) => void;
  addCSV?: boolean; 
  disabled?: boolean;
}

const DownloadDropdown: React.FC<DownloadDropdownProps> = ({
  loading,
  fileType,
  onSubmit,
  setFileType,
  addCSV = true,
  disabled
}) => {
  return (
    <Dropdown as={ButtonGroup}>
      {/* Download Button */}
      <Button
        type="submit"
        disabled={disabled}
        onClick={onSubmit}
        variant="success"
        className="download_cta"
      >
        {loading ? (
          <div className="loading-spiner" style={{display: "flex"}}>
            <DotLoader size={20} color="#ffff"  />
             <span style={{marginLeft:"10px"}}>Downloading as {fileType}</span>
          </div>
        ) : (
          <span>
            Download as {fileType}
          </span>
        )}

      </Button>

      {/* Dropdown Toggle */}

      <Dropdown.Toggle
        disabled={loading}
        split
        variant="success"
        id="dropdown-split-basic"
      />

      {/* Dropdown Menu */}
      <Dropdown.Menu>
       {addCSV && <Dropdown.Item onClick={() => setFileType("CSV")}>CSV</Dropdown.Item>}
        <Dropdown.Item onClick={() => setFileType("EXCEL")}>
          Excel
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setFileType("PDF")}>PDF</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DownloadDropdown;
