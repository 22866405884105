import { Form, Formik, FormikProps } from "formik";
import "../../../admin-settings/index.scss";
import Table from "react-bootstrap/Table";
import Select from "react-select";
import { useState } from "react";
import { useSelector } from "react-redux";
import { appConstantsPortal } from "../../../../redux/action-constants/portal";
import { AlertMsg } from "../../../../shared/components/alert-msg";
import "../../../admin-settings/index.scss";
import { adminInAppActions } from "../../../../redux/action/portal";



const DepartmentAssignment = ({
  userId,
  setRequestSuccess,
  requestSuccess,
}: any) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showPending, setShowPending] = useState<boolean>(false);
  const [chosenFaculty, setChosenFaculty] = useState<any>();
  const [chosenDepartment, setChosenDepartment] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const requestInfo = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetAUserReducer
  );

  const userData = requestInfo?.request_data?.response?.data;
  let allData = requestInfo?.request_data?.response;
  let facultiesList = allData?.allDeptAndFaculty.map(
    (dept: any) => dept.faculty
  );
 
  const seen = new Set();
  const uniqueFaculties = facultiesList.filter((faculty: any) => {
    if (seen.has(faculty.facultyUniqueId)) {
      return false;
    }
    seen.add(faculty.facultyUniqueId);
    return true;
  });

  const facultyOptions = uniqueFaculties.map((faculty: any) => ({
    value: faculty.facultyName,
    label: faculty.facultyName,
    id: faculty.facultyUniqueId,
  }));

  let departmentList = allData?.allDeptAndFaculty.filter(
    (dept: any) => dept?.faculty?.facultyUniqueId === chosenFaculty?.id
  );

  const departmentOptions = departmentList.map((dept: any) => ({
    value: dept.departmentName,
    label: dept.departmentName,
    id: dept.departmentUniqueId,
  }));

  let initialValues: any = {};
  return (
    <div className="settingform_wrap">
      <span>{userData?.fullName}</span>
      <div className="student_form regular">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            setLoading(true);
            let payload: any = {
              searchTerm: userId,
              facultyName: chosenFaculty.value,
              departmentName: chosenDepartment.value,
            };
            const res = await adminInAppActions.AssignUserToDepartment(payload);
            if (res.status === 200) {
              setRequestSuccess(true);
            } else if (res.response.status < 500) {
              setErrorMsg(res?.response.data);
              setRequestSuccess(false);
            }
            setLoading(false);
          }}
        >
          {(props: FormikProps<any>) => {
            return (
              <Form>
                <Table hover className="info_table" bordered={true}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Present Academic Unit</th>
                      <th>New Academic Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Faculty</td>
                      <td style={{textAlign:"center"}}>{userData?.facultyName}</td>
                      <td>
                        <div>
                          <Select
                            openMenuOnClick={true}
                            value={chosenFaculty}
                            id="chosenFaculty"
                            onChange={(e) => {
                              setChosenFaculty(e);
                              setShowPending(false);
                            }}
                            options={facultyOptions}
                            placeholder="Select faculty"
                            className="select-wrap"
                            classNamePrefix="faculty-select"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr >
                      <td>Department</td>
                      <td style={{textAlign:"center"}}>{userData?.departmentName}</td>
                      <td>
                        <div>
                          <Select
                            openMenuOnClick={true}
                            value={chosenDepartment}
                            id="chosenDepartment"
                            onChange={(e) => {
                              setChosenDepartment(e);
                              setShowPending(false);
                            }}
                            options={departmentOptions}
                            placeholder="Select department"
                            className="select-wrap"
                            isDisabled={!chosenFaculty}
                            classNamePrefix="department-select"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                {requestInfo.request_status ===
                  appConstantsPortal?.GET_A_USER_FAILURE && (
                  <AlertMsg
                    type="error"
                    message={requestInfo?.request_data.error}
                  />
                )}

                {!requestSuccess && errorMsg.length ? (
                  <AlertMsg type="error" message={errorMsg} />
                ) : null}
                {showEdit && !errorMsg && !requestSuccess ? (
                  <div className="submit_cta">
                    <AlertMsg
                      type="error"
                      message={`Please confirm you want to assign  ${userData.fullName}  to ${chosenDepartment?.label} .`}
                    />
                  </div>
                ) : null}
                {showEdit && (
                  <div className="submit_cta">
                    <button
                      disabled={requestInfo?.is_request_processing}
                      className="btn shaded"
                      type="submit"
                    >
                      {loading ? "Please wait..." : "Assign User To Department"}
                    </button>
                  </div>
                )}

                {showPending && (
                  <AlertMsg type="error" message={ !chosenFaculty ? "SELECT FACULTY AND DEPARTMENT" : "SELECT DEPARTMENT"} />
                )}

                {!showEdit && (
                  <div className="submit_cta">
                    <button
                      onClick={() => {
                        if (!chosenDepartment?.label) {
                          setShowPending(true);
                        } else {
                          setShowPending(false);
                          setShowEdit(true);
                        }
                      }}
                      disabled={requestInfo?.is_request_processing}
                      className="btn shaded"
                      type="button"
                    >
                      Click to Assign User To Department
                    </button>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default DepartmentAssignment;
