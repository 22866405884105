import { InAppTemplate } from '../../../../shared/templates/portal'
import { ToastContainer } from 'react-toastify'
import AccommodationSwap from './AccommodationSwap'

const AccommodationSwapSettings = () => {
  return (
    <>
    <ToastContainer
         position="top-right"
         autoClose={5000}
         hideProgressBar={false}
         newestOnTop={false}
         closeOnClick
         rtl={false}
         pauseOnFocusLoss
         draggable
         pauseOnHover
         style={{ marginTop: "20px" }}
       />
   
   <InAppTemplate
     pageTitle=" SWAP STUDENT ROOM"
     childComponent={<AccommodationSwap />}
   />
   </>
  )
}

export default AccommodationSwapSettings
