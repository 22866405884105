import { InAppTemplate } from "../../../../shared/templates/portal";
import MapUserToDepartment from "./MapUserToDepartment";

const EDocumentationAssignUserToDepartment = () => {
  return (
    <InAppTemplate
      childComponent={<MapUserToDepartment />}
      pageTitle="ASSIGN USER TO DEPARTMENT"
    />
  );
};

export default EDocumentationAssignUserToDepartment;
