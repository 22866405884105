import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Table from "react-bootstrap/Table";
import "react-tooltip/dist/react-tooltip.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { InAppTemplate } from "../../shared/templates/portal";
import "react-datepicker/dist/react-datepicker.css";
import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";
import StatusIndicator from "../../shared/components/status-indicator";
import undo from "../../assets/Undo.png";

const adminUpdateAUserStatusActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.adminUpdateAUserStatusAction(payload, true);
};

const adminUpdateAUserRoleRequest = async ({
  pageProps,
  payload,
  actionType,
}: any) => {
  await pageProps.adminUserARoleAction(payload, actionType);
};

const adminGetAllUsersRequest = async ({
  pageProps,
  payload,
  isSearch,
}: any) => {
  await pageProps.adminGetAllUsersAction(payload, isSearch);
};

// const GetFeatureActions = (apiList: any[]) => {
//   let allControllers: any[] = [];
//   let controllerGroup: any[] = [];

//   apiList.forEach((eachApi: any) => {
//     if (!allControllers.includes(eachApi?.controller)) {
//       allControllers.push(eachApi?.controller);
//     }
//   });
//   if (allControllers.length >= 1) {
//     allControllers.forEach((eachItem: any) => {
//       let controllerName: any = eachItem.match(/[A-Z][a-z]+/g).join(" ");
//       let apiUrls = apiList.filter(
//         (eachApi: any) => eachApi?.controller === eachItem
//       );
//       let eachController = {
//         controllerName,
//         apiUrls,
//       };
//       controllerGroup.push(eachController);
//     });
//   }

//   return controllerGroup;
// };

// const DisplayPermissionSummary = ({ allApiPermissions, name }: any) => {
//   return (
//     <div className="all_allowed_access">
//       {name && <div className="head_txt">Review Acess Granted to {name}</div>}
//       <div className="all_grants">
//         {allApiPermissions.map((eachGrant: any, index: any) => {
//           let grant = eachGrant.split(" ")[1];
//           grant = grant.split("/")[1];
//           grant = grant?.match(/[A-Z][a-z]+/g).join(" ");
//           return (
//             <div className="each_grant" key={index}>
//               {grant}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

const UserViewInfo = ({ setViewUserData, selectedUser, pageProps }: any) => {
  const [newStatus, setStatus] = useState<any>("");

  const onlyUnique = (value: any, index: any, array: any) => {
    return array.indexOf(value) === index;
  };
  let userRolesList = selectedUser?.userRoles.filter(onlyUnique);

  let updateAUserStatusRequest = pageProps?.adminUpdateAUserStatusRequest;
  return (
    <div>
      <>
        <div className="user_details">
          <div className="user_data_wrap">
            <>
              <div className="">
                <div className="heading_text">USER DETAILS</div>
                <div className="user_item">
                  <div className="form-group">
                    <label className="label">Full Name </label>
                    <div className="data-value">{selectedUser?.fullName}</div>
                  </div>
                  <div className="form-group">
                    <label className="label">Email </label>
                    <div className="data-value">{selectedUser?.email}</div>
                  </div>
                  <div className="form-group">
                    <label className="label">Phone Number </label>
                    <div className="data-value">
                      {selectedUser?.phoneNumber}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label">User Status </label>

                    <div
                      className={`data-value  ${
                        selectedUser?.isActive
                          ? "active_status"
                          : "inactive_status"
                      }`}
                    >
                      {selectedUser?.isActive ? "Active" : "Inactive"}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="label">User role(s) </label>
                    <div className="all_roles_list">
                      {selectedUser?.userRoles &&
                        userRolesList.map((eachRole: any, index: any) => {
                          return (
                            <div className="data-value" key={index}>
                              {eachRole}{" "}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </>

            {newStatus !== "" &&
              updateAUserStatusRequest.request_status ===
                appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_RESET && (
                <AlertMsg
                  type="error"
                  message={`Please confirm you want to ${newStatus} ${selectedUser?.fullName}`}
                />
              )}

            {updateAUserStatusRequest.request_status ===
              appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_FAILURE && (
              <AlertMsg
                type="error"
                message={updateAUserStatusRequest?.request_data.error}
              />
            )}
            {updateAUserStatusRequest.request_status ===
              appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS && (
              <AlertMsg
                type="success"
                message={` ${selectedUser?.fullName} has been successfully ${newStatus}ed`}
              />
            )}

            <div className="form_cta with_two">
              {newStatus === "" &&
                updateAUserStatusRequest.request_status !==
                  appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS && (
                  <>
                    {selectedUser?.isActive && (
                      <span
                        onClick={() => setStatus("Deactivate")}
                        className="user_action"
                      >
                        Deactivate User
                      </span>
                    )}
                    {!selectedUser?.isActive && (
                      <span
                        onClick={() => setStatus("Activate")}
                        className="user_action"
                      >
                        Re-Activate User
                      </span>
                    )}
                  </>
                )}
              {newStatus !== "" &&
                updateAUserStatusRequest.request_status !==
                  appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS && (
                  <button
                    className="btn proceed_action"
                    onClick={() => {
                      let payload = {
                        name: selectedUser?.email,
                        status: newStatus,
                      };
                      adminUpdateAUserStatusActionRequest({
                        pageProps,
                        payload,
                      });
                    }}
                    disabled={updateAUserStatusRequest?.is_request_processing}
                  >
                    {updateAUserStatusRequest?.is_request_processing
                      ? "Please wait..."
                      : `Proceed to ${newStatus}`}
                  </button>
                )}
              <button
                className="btn back_cta"
                onClick={() => setViewUserData(false)}
                disabled={updateAUserStatusRequest?.is_request_processing}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

const DisplayUserData = ({
  pageProps,
  selectedUser,
  showEditBioData,
  setShowEditUser,
  viewBioData,
  setViewUserData,
}: any) => {
  return (
    <Modal
      className="settings_modal add_item_modal"
      show={showEditBioData || viewBioData}
      onHide={() => {
        if (showEditBioData) {
          setShowEditUser(false);
        }
        if (viewBioData) {
          setViewUserData(false);
        }
      }}
    >
      <Modal.Body>
        <>
          {/* {showEditBioData && (
              <StudentEditBiodataInfo
              selectedUser={selectedUser}
                setShowEditUser={setShowEditUser}
                pageProps={pageProps}
              />
            )} */}

          {viewBioData && (
            <UserViewInfo
              selectedUser={selectedUser}
              setViewUserData={setViewUserData}
              pageProps={pageProps}
            />
          )}
        </>
      </Modal.Body>
    </Modal>
  );
};

const onlyUnique = (value: any, index: any, array: any) => {
  return array.indexOf(value) === index;
};

const RenderEachUser = ({
  userData,
  pageProps,
  setSelectedUser,
  setShowEditUser,
  setViewUserData,
  setViewAddRoles,
  setAllRoleIDsList,
}: any) => {
  let userRolesList: any[] = userData?.userRoles || [];
  userRolesList = userRolesList.filter(onlyUnique);

  let rolesList =
    pageProps?.adminGetAllUsersRequest?.request_data?.response?.roles;

  let roleIDs = userRolesList.map(
    (eachItem) =>
      rolesList.filter((eachRole: any) => eachRole?.name === eachItem)[0]?.id
  );

  return (
    <tr>
      <td>{userData?.fullName}</td>
      <td>{userData?.username}</td>
      <td>
        <div className="all_user_roles">
          {userRolesList.map((eachRole: any, index: any) => {
            return <span key={index}>{eachRole} </span>;
          })}
        </div>
      </td>

      <td>
        <span
          onClick={() => {
            adminUpdateAUserRoleRequest({
              pageProps,
              payload: "CLEAR",
            });
            setSelectedUser(userData);
            setAllRoleIDsList(roleIDs);
            setViewAddRoles(true);
          }}
          className="add_user_role_cta"
        >
          + Add Role
        </span>
      </td>
      <td className="status_td">
        <StatusIndicator
          isActive={userData.isActive}
          className="toggle2"
          className1="icon-label"
          className2="icon-background2"
          className3="icon-check2"
        />
      </td>
    </tr>
  );
};

// const RenderEachFeature = ({ feature, collectApiPermissions }: any) => {
//   const [isAllowed, setIsAllowed] = useState<boolean>(false);

//   let apiName: any = feature?.action.match(/[A-Z][a-z]+/g).join(" ");

//   const handleStatus = (status: any) => {
//     setIsAllowed(status);
//     collectApiPermissions(feature, status);
//   };

//   return (
//     <div className="each_feature">
//       <div className="api_name">
//         {" "}
//         <span>Feature:</span> {apiName}
//       </div>
//       <div className="api_desc">
//         <span>Description:</span> {feature?.description || "N/A"}
//       </div>
//       <div className="api_permission">
//         <span>Allow Access:</span>
//         <Switch
//           onChange={handleStatus}
//           checked={isAllowed}
//           width={30}
//           height={15}
//         />
//       </div>
//     </div>
//   );
// };

const RenderEachRole = ({
  role,
  collectAllRoles,
  userRoles,
  pageProps,
  selectedUser,
}: any) => {
  const [actionText, setActionText] = useState<string>("");
  const [isAllowed, setIsAllowed] = useState<boolean>(
    userRoles && userRoles.length >= 1 && userRoles.indexOf(role?.name) > -1
  );

  let userRoleActionRequest = pageProps?.adminAddUserToARoleRequest;

  let apiName: any;
  if (role?.name.indexOf("_") > -1) {
    apiName = role?.name.split("_");
    apiName = apiName.join(" ");
  } else {
    apiName = role?.name.match(/[A-Z][a-z]+/g).join(" ");
  }

  const handleUpdateRoles = (actionType: string) => {
    let payload: any = {
      userId: selectedUser?.userId,
      roleName: role?.name,
    };
    if (actionType === "remove") {
      payload = {
        userId: selectedUser?.userId,
        roleId: role?.id,
      };
    }
    adminUpdateAUserRoleRequest({
      pageProps,
      payload,
      actionType,
    });
  };

  const handleStatus = (status: any) => {
    let actionType = "";
    setIsAllowed(status);
    collectAllRoles(role, status);
    if (status) {
      actionType = "add";
    } else {
      actionType = "remove";
    }
    setActionText(actionType);

    handleUpdateRoles(actionType);
  };

  return (
    <div className="each_feature">
      <div className="api_name">
        {" "}
        <span>Role:</span> {apiName}
      </div>
      <div className="api_permission">
        <span>Allow Access:</span>
        <Switch
          onChange={handleStatus}
          checked={isAllowed}
          width={30}
          height={15}
          offColor="#FF0000"
          onColor="#00A85B"
        />
      </div>
      {(userRoleActionRequest?.request_data?.actionPayload?.roleName ===
        role?.name ||
        userRoleActionRequest?.request_data?.actionPayload?.roleId ===
          role?.id) && (
        <>
          {actionText === "add" &&
            userRoleActionRequest?.is_request_processing && (
              <div className="action_status">Adding role...</div>
            )}
          {actionText === "add" &&
            userRoleActionRequest?.request_status ===
              appConstantsPortal?.UPDATE_A_USER_ROLE_SUCCESS && (
              <div className="action_status green">Role Added.</div>
            )}

          {actionText === "add" &&
            userRoleActionRequest?.request_status ===
              appConstantsPortal?.UPDATE_A_USER_ROLE_FAILURE && (
              <div className="action_status red">
                {userRoleActionRequest?.request_data.error}
              </div>
            )}
          {actionText === "remove" &&
            userRoleActionRequest?.is_request_processing && (
              <div className="action_status">Removing role...</div>
            )}
          {actionText === "remove" &&
            userRoleActionRequest?.request_status ===
              appConstantsPortal?.UPDATE_A_USER_ROLE_SUCCESS && (
              <div className="action_status green">Role removed.</div>
            )}
          {actionText === "remove" &&
            userRoleActionRequest?.request_status ===
              appConstantsPortal?.UPDATE_A_USER_ROLE_FAILURE && (
              <div className="action_status red">
                {userRoleActionRequest?.request_data.error}
              </div>
            )}
        </>
      )}
    </div>
  );
};

const AddAUserRoles = ({
  setViewAddRoles,
  selectedUser,
  pageProps,
  allRoleIDsList,
  pageNumber,
  pageSize,
}: any) => {
  const [allRoles, setAllRoles] = useState<any[]>([...allRoleIDsList]);
  // const [allRoles, setAllRoles] = useState<any[]>([...selectedUser?.userRoles]);
  // const [allRolesIds, setAllRoleIds] = useState<any[]>([...allRoleIDsList]);

  let rolesList =
    pageProps?.adminGetAllUsersRequest?.request_data?.response?.roles;

  const collectAllRoles = (role: any, status: boolean) => {
    let allRoleList: any[] = [...allRoles];
    let roleText = `${role?.id}`;

    if (status && !allRoleList.includes(roleText)) {
      allRoleList.push(roleText);
    }

    if (!status && allRoleList.includes(roleText)) {
      allRoleList = allRoleList.filter((eachItem) => eachItem !== roleText);
    }
    setAllRoles(allRoleList);
  };

  const sortedRoleList = rolesList.sort((a:any, b:any)=> a.name.localeCompare(b.name))

  return (
    <>
      <div className="all_features_section">
        <div className="heading">
          Select Roles that <span>{selectedUser?.fullName}</span> should be
          granted access to
        </div>
        {/* RenderEachRole */}
        <div className="all_api_list mb-30">
          {sortedRoleList.map((eachRole: any, idx: any) => {
            return (
              <div className="each_role_item" key={idx}>
                {/* <div className="controller_name">{eachRole?.controllerName}</div> */}
                <RenderEachRole
                  pageProps={pageProps}
                  selectedUser={selectedUser}
                  collectAllRoles={collectAllRoles}
                  role={eachRole}
                  key={idx}
                  userRoles={selectedUser?.userRoles}
                />
                {/* <div className="all_features">
                    {
                      eachRole?.map((eachRole: any, index: any) => {
                        return (
                          <RenderEachRole collectAllRoles={collectAllRoles} role={eachRole} key={index} />
                        )

                      })
                    }
                  </div> */}
              </div>
            );
          })}
        </div>

        {/* <div className="heading mt-50">Select Features <span>{selectedUser?.fullName}</span> should be  granted access to</div>
        <div className="all_api_list">
          {
            allApiList.map((eachController: any, idx: any) => {
              return (
                <div className="each_controller" key={idx}>
                  <div className="controller_name">{eachController?.controllerName}</div>
                  <div className="all_features">
                    {
                      eachController?.apiUrls.map((eachApi: any, index: any) => {
                        return (
                          <RenderEachFeature collectApiPermissions={collectApiPermissions} feature={eachApi} key={index} />
                        )

                      })
                    }
                  </div>
                </div>
              )
            })
          }

        </div> */}
        {/* <div className="form_cta justify-center">
          <button
            className="btn shaded"
            type="submit"
            disabled={addUserToRoleRequest?.is_request_processing}
            onClick={handleUpdateRoles}
          >
            {addUserToRoleRequest?.is_request_processing
              ? "Please wait..."
              : "Update Roles"}{" "}
          </button>
        </div> */}
      </div>
      {/* {allApiPermissions.length >= 1 && <DisplayPermissionSummary allApiPermissions={allApiPermissions} name={selectedUser?.fullName} />} */}
    </>
  );
};

// const SuccessfulRoleUpdate = ({ selectedUser, setViewAddRoles }: any) => {
//   return (
//     <div className="update_success">
//       <div className="success-message-wrap">
//         <div className="success-animation">
//           <svg
//             className="success_icon_tick"
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 52 52"
//           >
//             <circle
//               className="checkmark__circle"
//               cx="26"
//               cy="26"
//               r="25"
//               fill="none"
//             />
//             <path
//               className="checkmark__check"
//               fill="none"
//               d="M14.1 27.2l7.1 7.2 16.7-16.8"
//             />
//           </svg>
//         </div>
//         <div className="success-message-txt">
//           {" "}
//           The role(s) for {selectedUser?.fullName} has been successfully
//           updated. Please inform the user to log in{" "}
//         </div>
//         <div className="product-cta">
//           <button
//             className="btn back_cta"
//             onClick={() => setViewAddRoles(false)}
//           >
//             See All Users{" "}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

const DisplayPaginatedRecords = ({
  pageProps,
  setViewAddRoles,
  viewAddRoles,
  pageNumber,
  pageSize,
}: any) => {
  const [showEditBioData, setShowEditUser] = useState<boolean>(false);
  const [viewBioData, setViewUserData] = useState<boolean>(false);
  // const [viewAddRoles, setViewAddRoles] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>();

  // const [allApiList, setAllApiList] = useState<any[]>([]);
  const [allApiPermissions, setAllPermissions] = useState<any[]>([]);
  const [allRoleIDsList, setAllRoleIDsList] = useState<any[]>([]);
  // const [isPermissionPending, setIsPermissionPending] =
  //   useState<boolean>(false);

  let usersList =
    pageProps?.adminGetAllUsersRequest?.request_data?.response?.users.items;

  const collectApiPermissions = (permission: any, status: boolean) => {
    let allPermissions: any[] = [...allApiPermissions];
    let permissionApi = `${permission?.controller}/${permission?.action}`;
    let pullApiText = `${permission?.method} ${permissionApi}`;

    // if (status && !allPermissions.includes(pullApiText)) {
    //   allPermissions.push(pullApiText);
    //   setIsPermissionPending(false);
    // }

    if (!status && allPermissions.includes(pullApiText)) {
      allPermissions = allPermissions.filter(
        (eachItem) => eachItem !== pullApiText
      );
    }
    setAllPermissions(allPermissions);
  };

  return (
    <>
      {(showEditBioData || viewBioData) && (
        <DisplayUserData
          selectedUser={selectedUser}
          showEditBioData={showEditBioData}
          viewBioData={viewBioData}
          setShowEditUser={setShowEditUser}
          setViewUserData={setViewUserData}
          pageProps={pageProps}
        />
      )}
      {!viewAddRoles && usersList && (
        <Table hover>
          <thead>
            <tr>
              <th>FULL NAME</th>
              {/* <th>EMAIL</th> */}
              <th>USERNAME</th>
              {/* <th>PHONE NUMBER</th> */}
              <th>ROLE(S)</th>
              <th></th>
              <th>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {usersList.length >= 1 &&
              usersList.map((eachData: any, index: any) => {
                return (
                  <RenderEachUser
                    key={`${index}`}
                    setSelectedUser={setSelectedUser}
                    showEditBioData={showEditBioData}
                    setShowEditUser={setShowEditUser}
                    setViewUserData={setViewUserData}
                    viewBioData={viewBioData}
                    userData={eachData}
                    pageProps={pageProps}
                    setViewAddRoles={setViewAddRoles}
                    setAllRoleIDsList={setAllRoleIDsList}
                  />
                );
              })}
          </tbody>
        </Table>
      )}

      {/* {viewAddRoles && addUserToRoleRequest?.request_status !==
        appConstantsPortal?.UPDATE_A_USER_ROLE_SUCCESS && */}
      {viewAddRoles && (
        <AddAUserRoles
          setViewAddRoles={setViewAddRoles}
          selectedUser={selectedUser}
          pageProps={pageProps}
          collectApiPermissions={collectApiPermissions}
          allApiPermissions={allApiPermissions}
          allRoleIDsList={allRoleIDsList}
          pageNumber={pageNumber}
          pageSize={pageSize}
        />
      )}
      {/* {viewAddRoles && addUserToRoleRequest?.request_status ==
        appConstantsPortal?.UPDATE_A_USER_ROLE_SUCCESS &&
        <SuccessfulRoleUpdate selectedUser={selectedUser} setViewAddRoles={setViewAddRoles} />} */}

      {/* {viewAddRoles && addUserToRoleRequest?.request_status ==
        appConstantsPortal?.UPDATE_A_USER_ROLE_FAILURE &&
        <AlertMsg
          type="error"
          message={addUserToRoleRequest?.request_data.error}
        />
      } */}
    </>
  );
};

const PaginateAllRecordsList = ({
  pageProps,
  allData,
  itemsPerPage,
  setPageNumber,
  pageNumber,
  pageSize,
  viewAddRoles,
  setViewAddRoles,
}: any) => {
  // const [itemOffset, setItemOffset] = useState(0);

  // const endOffset = itemOffset + itemsPerPage;
  // const currentItems = allData.slice(itemOffset, endOffset);
  // const pageCount = Math.ceil(allData.length / itemsPerPage);

  // const handlePageClick = (event: any) => {
  //   const newOffset = (event.selected * itemsPerPage) % allData.length;

  //   setItemOffset(newOffset);
  // };

  const handlePageClick = (event: any) => {
    setPageNumber(event?.selected + 1);
  };

  return (
    <>
      <DisplayPaginatedRecords
        viewAddRoles={viewAddRoles}
        setViewAddRoles={setViewAddRoles}
        pageProps={pageProps}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />

      {allData?.totalCount > pageSize && !viewAddRoles && (
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={4}
          forcePage={pageNumber - 1}
          pageCount={allData.totalPages}
          previousLabel="<"
          renderOnZeroPageCount={null}
          className="pagination_items"
          pageClassName="page_num"
          pageLinkClassName="page_link"
          activeClassName="active_page_link"
          previousClassName="previous_page_link"
          nextClassName="next_page_link"
        />
      )}
    </>
  );
};

const LoadRecordsWrap = ({
  pageProps,
  setPageNumber,
  pageNumber,
  pageSize,
  searchQuery,
  setSearchQuery,
}: any) => {
  const [viewAddRoles, setViewAddRoles] = useState<boolean>(false);
  let loadAllUsersRequest = pageProps?.adminGetAllUsersRequest;

  return (
    <div className="students_list_wrap">
      {loadAllUsersRequest?.request_status ===
        appConstantsPortal?.GET_ALL_USERS_PENDING && (
        <div className="select_all_msm">
          <LoadingItem />
        </div>
      )}
      {loadAllUsersRequest?.request_status ===
        appConstantsPortal?.GET_ALL_USERS_FAILURE && (
        <div className="w-50-a">
          <AlertMsg
            type="error"
            message={loadAllUsersRequest?.request_data.error}
          />
        </div>
      )}
      {loadAllUsersRequest?.request_status ===
        appConstantsPortal?.GET_ALL_USERS_SUCCESS && (
        <div className="all_students_list">
          <div className="table_headings">
            {!viewAddRoles && (
              <div className="back-to-portal">
                <Navigation
                  to="/app/portal-settings"
                  text="Back to Portal Settings"
                />
              </div>
            )}
            {viewAddRoles && (
              <button
                type="button"
                className="back_to_grid btn back-to-user-btn"
                onClick={() => {
                  setViewAddRoles(false);
                  let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
                  adminGetAllUsersRequest({
                    pageProps,
                    payload,
                  });
                }}
              >
                <img className="icon" src={undo} alt="Icon" />
                Back to Users List
              </button>
            )}

            {!viewAddRoles && (
              <input
                type="text"
                className="input_wrap search_input_wrap"
                placeholder="Search"
                value={searchQuery}
                onChange={(e: any) => {
                  setSearchQuery(e.target.value);
                }}
              />
            )}

            <Link
              className="add_new_cta btn"
              to="/app/portal-settings/manage-users/create-new"
            >
              {" "}
              Create New User{" "}
            </Link>
          </div>
          <PaginateAllRecordsList
            pageProps={pageProps}
            allData={loadAllUsersRequest?.request_data?.response?.users}
            itemsPerPage={20}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            pageSize={pageSize}
            viewAddRoles={viewAddRoles}
            setViewAddRoles={setViewAddRoles}
          />
        </div>
      )}
    </div>
  );
};

const AllRecordsWrap = ({ pageProps }: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const pageSize = 10;

  useEffect(() => {
    let searchDebounce: any;
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    if (searchQuery?.length) {
      payload = `${payload}&searchQuery=${searchQuery}`;
      searchDebounce = setTimeout(() => {
        adminGetAllUsersRequest({
          pageProps,
          payload,
          isSearch: searchQuery.length >= 1,
        });
      }, 1000);
    } else {
      payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
      adminGetAllUsersRequest({
        pageProps,
        payload,
      });
    }

    return () => {
      clearTimeout(searchDebounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, pageNumber]);

  return (
    <div className="courses_wrap">
      <LoadRecordsWrap
        pageProps={pageProps}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        pageSize={pageSize}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
    </div>
  );
};

const AdminLoadAllUsersWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle="USER MANAGEMENT SETTINGS"
      childComponent={<AllRecordsWrap pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAllUsersAction: adminInAppActions.AdminGetAllUsers,
  // adminGetAUserAction: adminInAppActions.AdminGetAUser,

  adminUserARoleAction: adminInAppActions.AdminAddRemoveUserToARole,
  adminGetAllApisAndRolesAction: adminInAppActions.AdminGetAllApis,
  adminUpdateAUserStatusAction: adminInAppActions.AdminUpdateAUserStatus,
};
const mapStateToProps = (state: any) => ({
  adminGetAllUsersRequest: state.allUGradPortalReducers.adminGetAllUsersReducer,
  // adminGetAUserRequest:
  //   state.allUGradPortalReducers.adminGetAUserReducer,

  adminAddUserToARoleRequest:
    state.allUGradPortalReducers.adminAddUserToARoleReducer,
  adminGetAllApisAndRolesRequest:
    state.allUGradPortalReducers.adminGetAllApisReducer,

  adminUpdateAUserStatusRequest:
    state.allUGradPortalReducers.adminUpdateAUserStatusReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLoadAllUsersWrap);
