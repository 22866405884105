import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { Dispatch } from "redux";
import { history } from "../../../shared/_helpers/history";
import { appConstantsOnboarding } from "../../action-constants/onboarding";
import { handleRequestErrors } from "../../../shared/utils";

export const OtpVerificationLogin = (actionPayload: any) => {
  if (actionPayload !== "CLEAR") {
    return (dispatch: Dispatch) => {
      let userData: any;
      let apiConsume = ApiService.request({
        url: `${routes.OTP_VERIFY}`,
        http_method: "POST",
        data: actionPayload,
        headers: { "X-Signature": routes.apiKey },
      });
      dispatch(request(apiConsume));
      return apiConsume
        .then((response: any) => {
          let apiConsume2 = ApiService.request({
            url: `${routes.GET_CURRENT_SESSION}`,
            http_method: "GET",
            data: null,
            headers: { "X-Signature": routes.apiKey },
          });
          // dispatch(request(apiConsume));
          return apiConsume2
            .then((response2: any) => {
              userData = { ...response.data };
              userData.currentSession = response2.data;
              
              localStorage.setItem("adXAuth", JSON.stringify(userData));

              let apiConsume3 = ApiService.request({
                url: `${routes.GET_A_USER}`,
                http_method: "GET",
                data: null,
                headers: { "X-Signature": routes.apiKey },
              });
              return apiConsume3
                .then((response3: any) => {
                  userData.profile = response3?.data;
                  localStorage.setItem("adXAuth", JSON.stringify(userData));

                  dispatch(success(response.data, actionPayload?.userName));

                  if (response3?.data?.hasChangedDefaultPassword === true) {
                    let userRoles: any[] = userData?.profile?.userRoles || [];
                    userRoles = userRoles.filter((c: any, index: any) => {
                      return userRoles.indexOf(c) === index;
                    });

                   
                    if (
                      userRoles.includes("Admin") ||
                      userRoles.includes("Bursary")
                    ) {
                      history.replace("/app");
                    }

                    else if (userRoles.includes("Admin") || userRoles.includes("Bursary")) {
                      history.replace("/app");
                    }

                    else if (userRoles.length >= 1 && (userRoles.includes("Course_Advisor") || userRoles.includes("Course_Presetter"))) {
                      history.replace("/app/course-management/undergraduate");
                    }
                    else if (userRoles.length >= 1 && userRoles.includes("Course_Manager")) {
                      history.replace("/app/course-subject");
                    }
                    else if (userRoles.length >= 1 && (userRoles.some((role)=>['Faculty_Officer','Academic_Officer','Ict_Officer'].includes(role)))) {
                      history.replace("/app/e-documentation");
                    }
                   else if (userRoles.length >= 1 && userRoles.includes("EO_Report")) {
                      history.replace("/app/eo-reports/exam/registration-report");
                    }
                    else if (userRoles.includes("Admission_Manager")) {
                      history.replace("/app/admissions-management");
                    }

                   else if (userRoles.includes("PostUtme_Manager")) {
                      history.replace("/app/postutme");
                    }
                   else if (userRoles.includes("EDocumentation_Manager")) {
                      history.replace("/app/e-documentation");
                    }

                    else if (userRoles.length >= 1 && userRoles.includes("Student_Creator")) {
                      history.replace("/app/portal-settings");
                    }
                    else if (userRoles.length >= 1 && userRoles.some(role=>['Admin' , 'Student_Manager' , 'Bursary' , 'Invoice_Checker' , 'Mis_Manager', 'Student_Creator', 'Password_Resetter', 'Invoice_Deleter'].includes(role))){
                      history.replace("/app/portal-settings");
                    }

                   else if (userRoles.includes("Nanniss_Manager") || userRoles.includes("Sug_Manager")) {
                      history.replace("/app/bursary-reports/additional-fees");
                    }
                   else if (userRoles.includes("Hostel_Manager") || userRoles.includes("Hostel_Porter")) {
                      history.replace("/app/hostel/dashboard");
                    }
                    else if (userRoles.includes("Lecturer")) {
                      history.replace("/app/results/dashboard");
                    }
               
                  } else {
                    history.replace(
                      "/app/portal-settings/reset-admin-password"
                    );
                  }
                })
                .catch((error: any) => {
                  dispatch(failure(handleRequestErrors(error)));
                });
            })
            .catch((error: any) => {
              dispatch(failure(handleRequestErrors(error)));
            });
        })
        .catch((error: any) => {
          if (
            error?.response?.data ===
            "The provided token is invalid. Please check and try again."
          ) {
            dispatch(
              failure(
                "The provided token is invalid. Please check and try again."
              )
            );
          } else {
            dispatch(failure(handleRequestErrors(error)));
          }
        });
    };
  }

  return (dispatch: Dispatch) => {
    dispatch(clear());
  };

  function request(params: any) {
    return { type: appConstantsOnboarding.LOGIN_USER_PENDING, params };
  }
  function success(response: any, username: string) {
    return {
      type: appConstantsOnboarding.LOGIN_USER_SUCCESS,
      response,
      username,
    };
  }
  function failure(error: any) {
    return {
      type: appConstantsOnboarding.OTP_VERIFICATION_FAILURE,
      payload: error,
    };
  }
  function clear() {
    return { type: appConstantsOnboarding.LOGIN_USER_RESET, clear_data: "" };
  }
};
