import { useState } from "react";
import "./index.scss";
import { Block, Room } from "../AccommodationSwap";
import { toast } from "react-toastify";
import {
  getStudentMatricSearch,
  swapStudentRoom,
} from "../../../../../redux/action/hostels";
const RoomSwap = ({
  data,
  setData,
  site,
  selectedBlock,
  selectedBlockName,
  selectedRoom,
  selectedRoomName,
  selectedSite,
  handleBlockChange,
  onRoomSelect,
  inputError,
}: any) => {
  const [isBooking, setIsBooking] = useState(false);
  const bookingPayload = {
    studentId: data?.studentId,
    matricNumber: data?.matricNumber,
    newHostelSite: selectedSite,
    newBlockName: selectedBlockName,
    newRooomName: selectedRoomName,
    newRoomUniqueId: selectedRoom,
    newBlockUniqueId: selectedBlock,
  };
  const handleSubmitBooking = async () => {
    setIsBooking(true);

    try {
      const res = await swapStudentRoom(bookingPayload);
      if (res?.status === 200) {
        setIsBooking(false);
        toast.success("Room swap completed successfully");
        const response = await getStudentMatricSearch(data.matricNumber);
        setData(response.data);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data
          ? error?.response?.data
          : "An unexpected error occurred during room swap."
      );
    } finally {
      setIsBooking(false);
    }
  };

  return (
    <div className="site">
      <p>
        <b>Site:</b> {site?.siteName}{" "}
      </p>

      {/* BLOCK SELECTION */}
      <select
        className="rooms-dropdown"
        style={{ marginBottom: "15px" }}
        value={selectedBlock}
        onChange={(e) => handleBlockChange(site.siteName, e.target.value)}
      >
        <option value="">Select block</option>
        {site?.blocks.map((block: Block) => {
          return (
            <option value={block?.blockUniqueId} key={block?.blockUniqueId}>
              {block?.blockName}
            </option>
          );
        })}
      </select>

      {/* ROOM SELECTION */}
      <select
        className="rooms-dropdown"
        value={selectedRoom}
        onChange={(e) => onRoomSelect(site.siteName, e.target.value)}
        disabled={!selectedBlock}
      >
        <option value="">Select a room</option>
        {selectedBlock &&
          site.blocks
            .find((block: any) => block.blockUniqueId === selectedBlock)
            ?.rooms.map((room: Room) => (
              <option key={room.roomUniqueId} value={room.roomUniqueId}>
                {room.roomName}
              </option>
            ))}
      </select>
      {inputError && !selectedBlock && !selectedRoom && (
        <p className="input-error">Select block and room.</p>
      )}
      <button
        className="btn"
        onClick={() => handleSubmitBooking()}
        disabled={selectedSite !== site?.siteName}
      >
        {isBooking ? "Swapping in progress..." : "Swap"}
      </button>
    </div>
  );
};

export default RoomSwap;
