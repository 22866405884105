import React, { useEffect } from "react";
import { InAppTemplate } from "../../../shared/templates/portal";
import * as Yup from "yup";
import { Formik, FormikProps } from "formik";
import "./index.scss";
import { getSchoolSessionHistory } from "../../../redux/action/onboarding";
import { adminInAppActions } from "../../../redux/action/portal";
import { useSelector, useDispatch } from "react-redux";
import SchoolFeeInvoiceForm from "./SchoolFeeInvoiceForm";
import Navigation from "../../../shared/components/navigation/Navigation";
import { ToastContainer } from "react-toastify";

const CreateStudentInvoiceModal = ({}) => {
  const initialValues = {
    matricNumber: "",
  };

  useEffect(() => {
    fetchAllSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const state = useSelector((state: any) => state);
  const sessionHistory =
    state?.allUGradPortalReducers?.adminGetDashboardReducer?.sessionHistory ||
    [];

  const sessionOptions = sessionHistory.map((session: string) => ({
    value: session,
    label: session,
  }));

  const dispatch = useDispatch();
  const fetchAllSessions = () => {
    dispatch(adminInAppActions.AdminGetCurrentSession(""));
    dispatch(getSchoolSessionHistory());
  };

  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
    session: Yup.string().required("Required"),
    level: Yup.string().required("Required"),
  });

  return (
    <div className="invoice-wrapper">
      <div className="back-to-portal">
         <Navigation to="/app/bursary-reports" text="Back to Bursary Report" />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={checkValidationSchema}
        onSubmit={async (values) => {}}
      >
        {(formikProps: FormikProps<any>) => (
          <SchoolFeeInvoiceForm
            formikProps={formikProps}
            session={sessionOptions}
          />
        )}
      </Formik>
    </div>
  );
};

const GenerateBursaryInvoiceWrap = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        pageTitle={`GENERATE SCHOOL FEES INVOICE`}
        childComponent={<CreateStudentInvoiceModal />}
      />
    </>
  );
};

export default GenerateBursaryInvoiceWrap;
