import CryptoJS from "crypto-js";
// Load environment variables
const SECRET_KEY: any = "Xc7$9Kp2!Qz4@Lw6*Rn8&Ud1%Tv3^Ym5";

export const encryptData = (data: any) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

export const decryptData = (ciphertext: any) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
