import React, { useEffect } from "react";
import { POST_UTME_MENU_LIST } from "../../shared/_helpers/constants";
import {
  getAllProgrammesPutme,
  getPostUmeStudents,
} from "../../redux/action/putme";
import { useDispatch } from "react-redux";
import PUtmeDashboard from "./postutme-dashboard";
import Card from "../../shared/components/dashboard-cards/Card";

const PostUtme = () => {
  const dispatch = useDispatch();

  const loggedInUser: any = localStorage.getItem("adXAuth") || "{}";
  let user: any = JSON.parse(loggedInUser) || {};
  const userRoles = user?.profile?.userRoles;

  useEffect(() => {
    if (userRoles[0] !== "Bursary") {
      dispatch(getAllProgrammesPutme());
      dispatch(getPostUmeStudents(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {userRoles[0] !== "Bursary" && (
        <div className="menu-container">
          {POST_UTME_MENU_LIST.map((item: any, index: number) => (
            <Card
              key={index}
              title={item.title}
              buttonText={item.buttonText}
              icon={item.icon}
              backgroundColor={item.backgroundColor}
              routeTo={item.routeTo}
            />
          ))}
        </div>
      )}
      <div>
        <PUtmeDashboard />
      </div>
    </>
  );
};

export default PostUtme;
