import { ToastContainer } from "react-toastify";
import { InAppTemplate } from "../../../../shared/templates/portal";
import ScoreSettings from "./ScoreSettings";

const ResultScoreSettings = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<ScoreSettings />}
        pageTitle="MAXIMUM SCORE SETTINGS"
      />
    </>
  );
};

export default ResultScoreSettings;
