import { Form, Formik, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import InputField from "../../../../shared/components/InputField";
import * as Yup from "yup"

const SearchModal = ({ fetchPaymentHistory }: any) => {
  const initialValue = { matricNumber: "" };

  let ValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
    })

  return (
    <div className="search-container">
      <Formik
        initialValues={initialValue}
        validationSchema={ValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const { matricNumber } = values;
          await fetchPaymentHistory(matricNumber);
          setSubmitting(false);
        }}
      >
        {(props: FormikProps<any>) => {
          const { values, touched, errors, handleChange, isSubmitting } = props;
          return (
            <Form>
              <div className="search-form">
                <div className="settings search-input">
                  <InputField
                    field="matricNumber"
                    label="Matric Number"
                    placeholder="Enter matric number"
                    value={values.matricNumber}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                  />
                </div>

                <div className="modal-footer search-footer">
                  <Button
                    className="submit-btn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <DotLoader
                        color="white"
                        size={30}
                        aria-label="Searching..."
                      />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SearchModal;
