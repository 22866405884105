import { Form, Formik, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import InputField from "../../../../shared/components/InputField";
import * as Yup from "yup"

const InvoiceModal = ({ queryCsbInvoiceHistory, setIsExpanded }: any) => {
  const initialValue = { invoiceNumber: "" };

  let ValidationSchema = Yup.object().shape({
    invoiceNumber: Yup.string().required("Required"),
    })
  return (
    <div className="search-container">
      <Formik
        initialValues={initialValue}
        enableReinitialize
        validationSchema={ValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm}) => {
          setSubmitting(true);
          const { invoiceNumber } = values;
          await queryCsbInvoiceHistory({invoiceNumber});
          // resetForm()
          setIsExpanded(false);
          setSubmitting(false);
        }}
      >
        {(props: FormikProps<any>) => {
          const { values, touched, errors, handleChange, isSubmitting, } = props;
          return (
            <Form>
              <div className="search-form">
                <div className="settings search-input">
                  <InputField
                    field="invoiceNumber"
                    label="Invoice Number"
                    placeholder="Enter invoice number"
                    value={values.invoiceNumber}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                  />
                </div>

                <div className="modal-footer search-footer">
                  <Button
                    className="submit-btn"
                    type="submit"
                    disabled={isSubmitting || !values.invoiceNumber.length}
                  >
                    {isSubmitting ? (
                      <DotLoader
                        color="blue"
                        size={30}
                        aria-label="Searching..."
                      />
                    ) : (
                      "Search"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default InvoiceModal;
