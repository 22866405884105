import { Button } from "react-bootstrap";
import DropDown from "../../../../shared/components/dropdown";
import { Form, Formik, FormikProps } from "formik";
import Navigation from "../../../../shared/components/navigation/Navigation";
import "./index.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getSchoolSessionHistory } from "../../../../redux/action/onboarding";
import DownloadButton from "../../../../shared/components/download-components/DownloadButton";
import { levelsWithSpill } from "../../../../shared/_helpers/constants";
const SenateReport = () => {
  const initialValues = {};

  const dispatch = useDispatch();
  const { allProgrammes } = useSelector((state: any) => state.admissionReducer);

  const programmesList =
    allProgrammes.map((prog: any) => {
      return {
        value: prog.programmeName,
        label: prog.programmeName,
      };
    }) || [];

    const sortedProgrammes = programmesList.sort((a: any, b: any) => a.value.localeCompare(b.value));


  const state = useSelector((state: any) => state);
  const sessionHistory =
    state?.allUGradPortalReducers?.adminGetDashboardReducer?.sessionHistory ||
    [];
  const sessionOptions = sessionHistory.map((session: string) => ({
    value: session,
    label: session,
  }));

  useEffect(() => {
    if (!sessionHistory?.length) {
      dispatch(getSchoolSessionHistory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="report-container result-report">
      <div className="back">
        <Navigation to="/app/results/reports" text="Back to Reports" />
      </div>
      <div className="report-page">
        <Formik
          initialValues={initialValues}
          //   validationSchema={validationSchema}
          onSubmit={async () => {
            {
            }
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              // handleBlur,
              handleChange,
              setFieldTouched,
              setFieldValue,
              // isSubmitting,
            } = props;
            return (
              <Form>
                <div className="row">
                  <div className="form-group">
                    <DropDown
                      width="100%"
                      label="Programme"
                      touched={touched}
                      errors={errors}
                      value={{
                        value: values.programme,
                        label: values.programme,
                      }}
                      options={sortedProgrammes}
                      field="programme"
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <div className="form-group">
                    <div>
                      <DropDown
                        label="Session"
                        name="session"
                        touched={touched}
                        errors={errors}
                        options={sessionOptions}
                        field="session"
                        value={{
                          value: values.session,
                          label: values.session,
                        }}
                        defaultValue={{
                          value: values.session,
                          label: values.session,
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        width="97%"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group">
                    <DropDown
                      width="100%"
                      name="semester"
                      field="semester"
                      label="Semester"
                      setFieldValue={setFieldValue}
                      options={[
                        { label: "FIRST SEMESTER", value: "FIRST SEMESTER" },
                        { label: "SECOND SEMESTER", value: "SECOND SEMESTER" },
                      ]}
                      value={{
                        value: values.semester,
                        label: values.semester,
                      }}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                  <div className="form-group">
                    <div>
                      <DropDown
                        width="100%"
                        label="Level"
                        touched={touched}
                        errors={errors}
                        value={{
                          value: values.level,
                          label: values.level,
                        }}
                        options={levelsWithSpill}
                        field="level"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                  </div>
                </div>

                <div className="report-submit-btn">
                  <DownloadButton
                    onClick={() => ""}
                    loading={false}
                    buttonText="Print PDF "
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SenateReport;
