import { useCallback, useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import "../../../admin-settings/additional-payments/index.scss";
import SearchBox from "../../../../shared/components/searchbox";
import { ToastContainer } from "react-toastify";
import { handleRequestErrors } from "../../../../shared/utils";
import ReactPaginate from "react-paginate";
import Navigation from "../../../../shared/components/navigation/Navigation";
import AdditionalDocumentsTable from "./AdditionalDocumentTable";
import AdditionalDocumentModal from "./AdditionalDocumentModal";
import {
  getSpecificDocumentTypesForStudents,
  searchSpecificDocumentTypesForStudents,
} from "../../../../redux/action/edocumentation";

const AdditionalDocuments = () => {
  const initialDocumentnfo = {
    eDocumentationDocsUniqueId: "",
    matricNumber: "",
    documentName: "",
    session: "",
    fullName: "",
  };
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [documentList, setDocumentList] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] =
    useState(initialDocumentnfo);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const pageCount = Math.ceil(totalCount / 20);

  const fetchStudentsAdditionalDocuments = async (
    pageNumber: number,
    showLoading: boolean = false
  ) => {
    showLoading && setLoading(true);
    await getSpecificDocumentTypesForStudents(pageNumber)
      .then(({ data }: any) => {
        setDocumentList(data?.items);
        setTotalCount(data?.totalCount);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        handleRequestErrors(err);
      });
  };

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    fetchStudentsAdditionalDocuments(newOffset);
  };

  const handleSearch = useCallback(
    async (searchParam: any, pageNumber: number) => {
      await searchSpecificDocumentTypesForStudents(searchParam, pageNumber)
        .then(({ data }: any) => {
          setDocumentList(data?.items);
          setTotalCount(data?.totalCount);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          handleRequestErrors(err);
        });
    },
    []
  );

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      fetchStudentsAdditionalDocuments(1, true);
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch]);

  return (
    <div className="additional-payment-contaianer">
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <div className="back-to-portal">
        <Navigation
          to="/app/e-documentation/settings"
          text="Back to E-Documentation Settings Dashboard"
        />
      </div>
      <div className="settings_header">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />

        <div className="action-button" onClick={() => setShowModal(true)}>
          {" "}
          <CiCirclePlus style={{ width: "20px", height: "20px" }} />
          <span>Add</span>
        </div>
      </div>
      <AdditionalDocumentsTable
        items={documentList}
        loading={loading}
        fetchStudentsAdditionalDocuments={fetchStudentsAdditionalDocuments}
        setSelectedDocumentType={setSelectedDocumentType}
        setShow={setShowModal}
      />
      {documentList?.length ? (
        <div className="result-count">
          <p>
            Showing {documentList?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <AdditionalDocumentModal
        show={showModal}
        setShow={setShowModal}
        fetchStudentsAdditionalDocuments={fetchStudentsAdditionalDocuments}
        selectedDocumentType={selectedDocumentType}
        setSelectedDocumentType={setSelectedDocumentType}
        initialDocumentnfo={initialDocumentnfo}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

export default AdditionalDocuments;
