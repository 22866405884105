import React, {  useState, ChangeEvent } from "react";
import { useDispatch} from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Container } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import {
    adminInAppActions,
} from "../../../../redux/action/portal";
import InputField from "../../../../shared/components/InputField";
import DropDown from "../../../../shared/components/dropdown";


export const NewHODModal = ({
    showNew,
    setShowNew,
    allLecturersList,
    allDeptList,
    setCurrentPage
}: any) => {
    const [ongoing, setOngoing] = useState(false);
    const [selectedLecturer, setSelectedLecturer] = useState<any>();
    const [selectedDept, setSelectedDept] = useState<any>();





    const sortData = (a: any, b: any) => {
        if (a?.label.toLowerCase() < b?.label.toLowerCase()) {
            return -1;
        }
        if (a?.label.toLowerCase() > b?.label.toLowerCase()) {
            return 1;
        }
        return 0;
    };





    const lecturersList =
        allLecturersList
            .map((data: any) => {
                return {
                    
                    id: data.lecturerUniqueId,
                    value: data.fullName,
                    label: data.fullName,
                };
            })?.sort(sortData) || [];

    const alldataList =
        allDeptList
            .map((data: any) => {
                return {
                    id: data.departmentName,
                    value: data.departmentName,
                    label: data.departmentName
                };
            })?.sort(sortData) || [];



    let currentSession: any = localStorage.getItem("adXAuth");
    currentSession = JSON.parse(currentSession);
    const session = currentSession?.currentSession?.session;

    let initialValues = {
        hodUniqueId:"",
        fullName: "",
        lecturerUniqueId: "",
        department: "",
        session: session
    };
    let checkValidationSchema = Yup.object().shape({
        lecturerUniqueId: Yup.string().required("Required"),
        // level: Yup.string().required("Required"),
        department: Yup.string().required("Required"),

    });

    const handleCloseModal = () => {
        if (!ongoing) {
            setOngoing(false);
            setShowNew(false)
        }
    };

    return (
        <Modal
            show={showNew}
            onHide={handleCloseModal}
            className="records_modal_wrap"
            
            contentClassName="records_modal_content"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Add New HOD</Modal.Title>
            </Modal.Header>
            <Modal.Body className="records-modal-form">
                <Formik
                    initialValues={initialValues}
                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {

                        setCurrentPage(0)
                        setOngoing(true);

                       
                        let payload = {
                            hodUniqueId:"",
                            lecturerUniqueId: selectedLecturer?.id,
                            fullName: selectedLecturer?.label,
                            department: selectedDept,
                            session,
                        }

                        

                        const request = await adminInAppActions.AdminAddOrUpdateHod(payload)

                        if (request?.status === 200) {
                            toast.success(`${selectedLecturer?.label} has been successfully assigned as HOD of ${selectedDept} for ${session} session`);
                            setCurrentPage(1)
                            handleCloseModal()
                        } else {
                            toast.error(request);
                            setOngoing(false)
                        }
                        


                    }}
                >{(props: FormikProps<any>) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        setFieldValue,
                        setFieldTouched,
                    } = props;

                    return (
                        <Form>
                            <Row>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Select Lecturer"
                                        name="lecturerUniqueId"
                                        touched={touched}
                                        errors={errors}
                                        options={lecturersList}
                                        field="lecturerUniqueId"
                                        handleChange={(data: any) => {
                                            setSelectedLecturer(data)
                                            setFieldValue("lecturerUniqueId", data?.id)
                                        }}
                                        value={{
                                            value: values.lecturerUniqueId,
                                            label: values.lecturerUniqueId,
                                        }}
                                        defaultValue={{
                                            value: values.lecturerUniqueId,
                                            label: values.lecturerUniqueId,
                                        }}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="session"
                                        label="Session"
                                        value={values.session}
                                        touched={touched}
                                        disabled={true}
                                        errors={errors}
                                        handleChange={() => { }}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="session"
                                        label="Session"
                                        value={values.session}
                                        touched={touched}
                                        disabled={true}
                                        errors={errors}
                                        handleChange={() => { }}
                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <DropDown
                                        label="Department"
                                        name="department"
                                        touched={touched}
                                        errors={errors}
                                        options={alldataList}
                                        field="department"
                                        handleChange={(data: any) => {
                                            setSelectedDept(data)
                                            setFieldValue("department", data?.value)
                                            setFieldTouched("department", true)
                                        }}
                                        value={{
                                            value: values.department,
                                            label: values.department,
                                        }}
                                        defaultValue={{
                                            value: values.department,
                                            label: values.department,
                                        }}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                                
                            </Row>
                            


                            <div className="modal-footer">
                                <Button
                                    className="cancel-btn"
                                    onClick={() => setShowNew(false)}
                                    disabled={ongoing}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submit-btn"
                                    type="submit"
                                    disabled={ongoing}
                                >
                                    {ongoing ? (
                                        <DotLoader
                                            color="white"
                                            loading={ongoing}
                                            size={30}
                                            aria-label="Submitting"
                                        />
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </div>
                        </Form>

                    )

                }}
                </Formik>
            </Modal.Body>
        </Modal>
    )

}


export const EdiHODModal = ({
    showEdit,
    setShowEdit,
    allDeptList,

    setCurrentPage,
    selectedRecord,
    setSelectedRecord
}: any) => {
    const [ongoing, setOngoing] = useState(false);
    const [selectedDept, setSelectedDept] = useState<any>();
   



   


    const sortData = (a: any, b: any) => {
        if (a?.label.toLowerCase() < b?.label.toLowerCase()) {
            return -1;
        }
        if (a?.label.toLowerCase() > b?.label.toLowerCase()) {
            return 1;
        }
        return 0;
    };

    const alldataList =
    allDeptList
        .map((data: any) => {
            return {
                id: data.departmentName,
                value: data.departmentName,
                label: data.departmentName
            };
        })?.sort(sortData) || [];





    let currentSession: any = localStorage.getItem("adXAuth");
    currentSession = JSON.parse(currentSession);
    const session = currentSession?.currentSession?.session;


    let initialValues2 = {
        fullName: selectedRecord?.fullName,
        lecturerUniqueId: selectedRecord?.lecturerUniqueId,
        department: selectedRecord?.departmentName,
        session: session
    };

   
    let checkValidationSchema = Yup.object().shape({
        // newCourseUniqueId: Yup.string().required("Required"),
    });

    const handleCloseModal = () => {
        if (!ongoing) {
            setOngoing(false);
            setShowEdit(false)
            setSelectedRecord()
        }
    };

    return (
        <Modal
            show={showEdit}
            onHide={handleCloseModal}
            className="records_modal_wrap"
            size="lg"
            contentClassName="records_modal_content"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">EDIT A HOD</Modal.Title>
            </Modal.Header>
            <Modal.Body className="records-modal-form">
                <Formik
                    initialValues={initialValues2}
                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        setCurrentPage(0)
                        setOngoing(true);
                        let payload = {
                            hodUniqueId:selectedRecord.hodUniqueId,
                            lecturerUniqueId: selectedRecord?.lecturerUniqueId,
                            fullName: selectedRecord?.fullName,
                            department: values?.department,
                            session,
                        }
                       
                        

                        

                       

                        const request = await adminInAppActions.AdminAddOrUpdateHod(payload)

                        if (request?.status === 200) {
                            toast.success(`${selectedRecord?.fullName} has been successfully updated as HOD of ${values?.department} for ${session} session`);
                            setCurrentPage(1)
                            handleCloseModal()
                        } else {
                            toast.error(request);
                            setOngoing(false)
                        }
                       

                       


                    }}
                >{(props: FormikProps<any>) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        setFieldValue,
                        setFieldTouched,
                    } = props;

                    return (
                        <Form>
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="fullName"
                                        label="HOD Name"
                                        
                                        value={values.fullName}
                                        touched={touched}
                                        errors={errors}
                                        disabled={true}
                                        handleChange={()=>{}}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="session"
                                        label="Session"
                                        value={values.session}
                                        touched={touched}
                                        disabled={true}
                                        errors={errors}
                                        handleChange={() => { }}
                                    />
                                </Col>
                            </Row>


                            <Row>
                                <Col xs={12} md={12}>
                                    <DropDown
                                        label="Department"
                                        name="department"
                                        touched={touched}
                                        errors={errors}
                                        options={alldataList}
                                        field="department"
                                        handleChange={(data: any) => {
                                            setSelectedDept(data)
                                            setFieldValue("department", data?.value)
                                            setFieldTouched("department", true)
                                        }}
                                        value={{
                                            value: values.department,
                                            label: values.department,
                                        }}
                                        defaultValue={{
                                            value: values.department,
                                            label: values.department,
                                        }}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                                

                            </Row>
                            
                            <div className="modal-footer">
                                <Button
                                    className="cancel-btn"
                                    onClick={() => {
                                        if (!ongoing) {
                                            setShowEdit(false)
                                        }
                                    }}
                                    disabled={ongoing}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submit-btn"
                                    type="submit"
                                    disabled={ongoing}
                                >
                                    {ongoing ? (
                                        <DotLoader
                                            color="white"
                                            loading={ongoing}
                                            size={30}
                                            aria-label="Submitting"
                                        />
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </div>
                        </Form>

                    )

                }}
                </Formik>
            </Modal.Body>
        </Modal>
    )

}

export const DeleteHODModal = ({
    showDelete,
    setShowDelete,
    selectedRecord,
    setSelectedRecord,
    setCurrentPage
}: any) => {
    const [ongoing, setOngoing] = useState(false);







    const handleCloseModal = () => {
        if (!ongoing) {
            setOngoing(false);
            setShowDelete(false)
            setSelectedRecord()
        }
    };

    const handleDeleteRecord = async () => {
        setCurrentPage(0)
        setOngoing(true)
        let payload = `hodUniqueId=${selectedRecord?.hodUniqueId}&session=${selectedRecord?.session}`
        const request = await adminInAppActions.AdminDeleteAHod(payload)

        if (request?.status === 200) {
            toast.success(`${selectedRecord.fullName} has been successfully removed from as HOD of ${selectedRecord?.departmentName} `);
            setCurrentPage(1)
            handleCloseModal()
        } else {
            toast.error(request?.message || "An Error occured");
        }
        setOngoing(false)
    };

    return (
        <Modal
            show={showDelete}
            onHide={handleCloseModal}
            className="records_modal_wrap"
            size="lg"
            contentClassName="records_modal_content"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Remove {selectedRecord?.fullName} AS HOD </Modal.Title>
            </Modal.Header>
            <Modal.Body className="records-modal-form">

                <div className="confirm-deletio">
                    KINDLY CONFIRM YOU WANT TO REMOVE <strong> {selectedRecord?.fullName} </strong> as HOD of {selectedRecord?.departmentName} 
                </div>

                <div className="modal-footer">
                    <Button
                        className="cancel-btn"
                        onClick={() => {
                            if (!ongoing) {
                                setShowDelete(false)
                            }
                        }}
                        disabled={ongoing}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="submit-btn"
                        type="button"
                        onClick={handleDeleteRecord}
                        disabled={ongoing}
                    >
                        {ongoing ? (
                            <DotLoader
                                color="white"
                                loading={ongoing}
                                size={30}
                                aria-label="Please wait"
                            />
                        ) : (
                            "Proceed"
                        )}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )

}


export const BulkUploadModal = ({ show, setShow, setCurrentPage }: any) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [ongoing, setOngoing] = useState(false);

    const dispatch = useDispatch();
    let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
    loggedAccountInfo = JSON.parse(loggedAccountInfo);


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (selectedFile) {
            if (selectedFile.size > 1048576) {
                return;
            }
            setCurrentPage(0)
            setOngoing(true)
            const formData = new FormData();
            formData.append("File", selectedFile);

            const request = await adminInAppActions.AdminBulkUploadLecturerSubjectAssignments(formData);
            if (request?.status === 200) {
                toast.success(`Lecturers Course Assignment Bulk Data has been successfully uploaded`);
                setCurrentPage(1)
                setShow(false)
            } else {
                toast.error(request);
            }
            setOngoing(false)
        }
    };
    return (
        <Modal
            show={show}
            contentClassName="putme-bulk-upload-modal"
            onHide={() => {
                if (!ongoing) {
                    setShow(false)
                }
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h5>BULK UPLOAD</h5>
            </Modal.Header>
            <Modal.Body className="report-main-body">
                <Container>


                    <input
                        type="file"
                        className="file-input"
                        style={{ cursor: "pointer" }}
                        accept=".xls,.xlsx"
                        size={1048576}
                        onChange={handleFileChange}
                    />
                    {selectedFile && selectedFile.size > 1048576 ? (
                        <p className="input-error">Max file size is 1MB</p>
                    ) : null}
                </Container>
            </Modal.Body>
            <Modal.Footer>

                <Button
                    className="submit-btn"
                    type="button"
                    onClick={handleUpload}
                    disabled={ongoing}
                >
                    {ongoing ? (
                        <DotLoader
                            color="white"
                            loading={ongoing}
                            size={30}
                            aria-label="Please wait"
                        />
                    ) : (
                        "Upload"
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};