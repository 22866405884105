import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import "./index.scss";
import { MdUpload } from "react-icons/md";

type UploadButtonType = {
  loading?: boolean;
  onClick: () => void;
  buttonText: string;
  className?: any;
  styles?: any;
};
const UploadButton = ({
  loading = false,
  onClick,
  buttonText,
  className,
  styles,
}: UploadButtonType) => {
  return (
    <div className="admsn-download-btn">
      <Button
        className={`adm-button ${className}`}
        onClick={onClick}
        type="submit"
      >
        {loading ? (
          <div className="loading-spiner">
            <DotLoader size={20} color="#00a85b" /> Uploading...
          </div>
        ) : (
          <span style={{display: "flex", alignItems: "center"}}>
            {buttonText}
            <MdUpload className= "btn-logo" style= {styles}  />
          </span>
         )} 
      </Button>
     </div>
  );
};

export default UploadButton;
