import  { useEffect, useState } from "react";
import Filter from "../../../assets/filter.png";
import Navigation from "../../../shared/components/navigation/Navigation";
import { useSelector } from "react-redux";
import ReportModal from "./components/ReportModal";
import "./index.scss";
import { useDispatch } from "react-redux";
import { getSchoolSessionHistory } from "../../../redux/action/onboarding";

const HostelReport = () => {
  const [showFilter, setShowFilter] = useState(false);

  const dispatch = useDispatch()

  const state = useSelector((state: any) => state);
  const sessionHistory =
    state?.allUGradPortalReducers?.adminGetDashboardReducer?.sessionHistory ||
    [];
  const sessionOptions = sessionHistory.map((session: string) => ({
    value: session,
    label: session,
  }));

useEffect(() => {
    if (!sessionHistory?.length) {
      dispatch(getSchoolSessionHistory());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  

  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  const userData = JSON.parse(loggedAccountInfo);

  return (
    <div className="hostel-report-filter">
      <div className="back">
        <Navigation to="/app/bursary-reports" text="Back to Bursary Report" />
      </div>

      <div className="download-wrap">
        <div className="filter_summary hostel_filter">
          <button className="btn filter" onClick={() => setShowFilter(true)}>
            <img src={Filter} alt="" />
            Filter Options
          </button>
        </div>
      </div>

      <ReportModal
        userData={userData}
        sessionOptions={sessionOptions} // Options for session dropdown
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
    </div>
  );
};

export default HostelReport;
