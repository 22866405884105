import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import InputField from "../../../../shared/components/InputField";
import { DotLoader } from "react-spinners";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { adminInAppActions } from "../../../../redux/action/portal";
import { handleRequestErrors } from "../../../../shared/utils";
import { getStudentProfile } from "../../../../redux/action/admission";
import { addBlackListStudent } from "../../../../redux/action/hostels";

interface BlackListModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchBlacklistedStudent: any;
}

export interface BlacklistStudent {
  fullName: string;
  studentUniqueId: string;
  matricNumber: string;
 
}

export interface StudentProfile {
  fullName: string;
  level: string;
  gender: string;
}

const BlackListModal: React.FC<BlackListModalProps> = ({
  show,
  setShow,
  fetchBlacklistedStudent,
}) => {
  const initialValues = {
    matricNumber: "",
    session: "",
 
  };

  const adminCurrentSession = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetCurrentSessionReducer
  );
  const dispatch = useDispatch();
  const fetchCurrentSession = () => {
    dispatch(adminInAppActions.AdminGetCurrentSession(""));
  };

   let checkValidationSchema = Yup.object().shape({
      matricNumber: Yup.string().required("Required"),
    });

  useEffect(() => {
    fetchCurrentSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schoolSession =
    adminCurrentSession?.request_data?.response?.data?.items ?? [];


  const session = schoolSession.find(
    (item: any) => item?.type === "SCHOOL"
  )?.session;

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      contentClassName="additional-payment-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add blacklist student
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Formik
          initialValues={initialValues}
            validationSchema={checkValidationSchema}
          onSubmit={async () => {}}
        >
          {(formikProps: FormikProps<any>) => (
            <BlackListStudentForm
              formikProps={formikProps}
              setShow={setShow}
              fetchBlacklistedStudent={fetchBlacklistedStudent}
              session={session}
            />
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const BlackListStudentForm: React.FC<{
  formikProps: FormikProps<any>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchBlacklistedStudent: any;
  session: string;
}> = ({ formikProps, setShow, fetchBlacklistedStudent, session }) => {
  const initialStudentValue = {
    fullName: "",
    studentUniqueId: "",
    matricNumber: "",
  };

  const InitialStudentProfile = {
    fullName: "",
    level: "",
    gender: "",
  }
  const { values, touched, errors, handleChange, setFieldValue } = formikProps;
  const matricNumber = values.matricNumber;
  const [student, setStudent] = useState<BlacklistStudent>({
    ...initialStudentValue,
  });
  const [studentProfile, setStudentProfile] = useState<StudentProfile>({
    ...InitialStudentProfile,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");


  const handleGetStudentProfile = async (matricNo: string) => {
    setStudent(initialStudentValue);
    setLoading(true);
    setError("");
    await getStudentProfile(matricNo)
      .then((data) => {
        setLoading(false);
        if (data?.data?.studentUniqueId) {
          const { studentUniqueId, fullName, level, gender } = data?.data;
          setStudentProfile((prevState)=>({
            ...prevState,
            gender,
            fullName,
            level,
          }))
          
          setStudent((prevState) => ({
            ...prevState,
            studentUniqueId,
            fullName,
            matricNumber,


          }));
        }
      })
      .catch((err: any) => {
        setLoading(false);
        handleRequestErrors(err);
        if (err?.response?.status === 404) {
          setError(err?.response?.data);
          setStudentProfile(InitialStudentProfile);
        }
      });
  };
  useEffect(() => {
    setStudent((prevState) => ({
      ...prevState,
      matricNumber: values?.matricNumber,
    }));
  }, [values]);
  

  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 500);
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matricNumber]);

  const handleSubmit = async () => {
  
    if (!student?.fullName || !student?.studentUniqueId) {
      return setError("Enter valid matric number");
    }
  
    setSubmitting(true);
    await addBlackListStudent(student)
      .then(() => {
        setSubmitting(false);
        toast.success("Student blacklisted successfully");
        setShow(false);
        fetchBlacklistedStudent(1);
      })
      .catch((err) => {
        setSubmitting(false);
        handleRequestErrors(err);
        toast.error("An error occurred, please try again.");
      });
  };

 

  return (
    <Form>
      {loading ? (
        <p className="student-name">Searching student...</p>
      ) : !loading && studentProfile?.fullName && matricNumber?.length ? (
        <span>
            <p className="student-name">Name: {studentProfile?.fullName}</p>
           <p className="student-name">Level: {studentProfile?.level}</p>
            <p className="student-name">Gender: {studentProfile?.gender}</p> 
        </span>
      ) : null}
      {error?.length && !loading ? <p className="error">{error}</p> : null}
      <InputField
        field="matricNumber"
        label="Matric Number"
        name="matricNumber"
        value={values.matricNumber}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
      />

      <div className="button-container">
        <Button className="submit-btn" onClick={handleSubmit}>
          {submitting ? "Submitting..." : "Submit"}
          {submitting ? (
            <DotLoader
              color="white"
              loading={submitting}
              size={30}
              aria-label="Submitting"
            />
          ) : null}
        </Button>
      </div>
    </Form>
  );
};

export default BlackListModal;
