import { InAppTemplate } from "../../../shared/templates/portal";
import ReportCards from "./ReportCards";

const ResultReport = () => {
 
  return (
    <InAppTemplate
      childComponent={<ReportCards />}
      pageTitle="REPORTS"
    />
  );
};

export default ResultReport;