import { Form, Formik, FormikProps } from "formik";
import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import InputField from "../../../shared/components/InputField";
import { rePrintAdmission } from "../../../redux/action/admission";
import "./index.scss";
import Navigation from "../../../shared/components/navigation/Navigation";
import DownloadModal from "../../../shared/components/download-components/DownloadModal";
import { useState } from "react";
import { toast } from "react-toastify";

const PrintAdmissionModal = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  let fileType = "PDF";

  const fetchAdmissionLetters = async (payload: any) => {
    try {
      const response = await rePrintAdmission(payload);
      if (response?.status === 200) {
        setShowDownloadModal(true);
        setDownloadUrl(response.data);
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        toast.error("Student profile does not exist.");
      } else {
        toast.error("Error downloading Admission letter");
      }
    }
  };

  return (
    <div className="admission-wrapper">
      <div className="back-to-portal">
        <Navigation to="/app/portal-settings" text="Back to Portal Settings" />
      </div>

      <div>
        <DownloadModal
          showDownloadModal={showDownloadModal}
          setShowDownloadModal={setShowDownloadModal}
          downloadUrl={downloadUrl}
          fileType={fileType}
          text="Admission letter"
          session={""}
        />
      </div>

      <div className="search-container">
        <Formik
          initialValues={{ studentNumber: "" }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const payLoad = {
              searchValue: values.studentNumber,
            };
            await fetchAdmissionLetters(payLoad);
            setSubmitting(false);
          }}
        >
          {(props: FormikProps<any>) => {
            const { values, touched, errors, handleChange, isSubmitting } =
              props;
            return (
              <Form>
                <div className="search-form">
                  <div className="settings search-input">
                    <InputField
                      field="studentNumber"
                      label="Matric No. / Jamb Reg No."
                      value={values.studentNumber}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </div>

                  <div className="modal-footer admission-footer">
                    <Button
                      className="submit-btn"
                      type="submit"
                      disabled={isSubmitting || !values.studentNumber.length}
                    >
                      {isSubmitting ? (
                        <DotLoader
                          color="white"
                          size={30}
                          aria-label="Searching..."
                        />
                      ) : (
                        "Search"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default PrintAdmissionModal;
