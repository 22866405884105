import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Navigation from "../../../../shared/components/navigation/Navigation";
import { InAppTemplate } from "../../../../shared/templates/portal";
import UploadScoreTable from "./UploadScoreTable";
import DownloadButton from "../../../../shared/components/download-components/DownloadButton";
import UploadButton from "../../../../shared/components/download-components/UploadButton";
import SearchBox from "../../../../shared/components/searchbox";
import { UploadModal } from "./UploadModal";
import ReactPaginate from "react-paginate";
import fetchAndDownloadExcel from "../../../../shared/_helpers/downloadexcel";
import { ResultScoreSampleTemplate } from "../../../../shared/_helpers/constants";
import {
  AdminBulkUploadExamScores,
  getCourseRegisteredStudents,
} from "../../../../redux/action/results";
import { handleRequestErrors } from "../../../../shared/utils";
import { RegisteredList } from "../../../../utils/types";

const UploadScoresSettings = () => {
  const [loading, setLoading] = useState(false);
  const [ongoing, setOngoing] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [registeredList, setRegisteredList] = useState<RegisteredList[]>([]);
  const [filteredList, setFilteredList] = useState<RegisteredList[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadExamScores, setUploadExamScores] = useState(false);
    const [disableButton, setDisableButton] = useState(false);


  const location = useLocation();
  const { courseCode, courseUnit, session, courseTitle } = location.state || {};

  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentPageData = filteredList.slice(offset, offset + itemsPerPage);
  const lastPageCount = currentPage * itemsPerPage + currentPageData.length;

  const examYear =session.split("/")[0];

  const fetchRegisteredStudents = useCallback(async () => {
    setLoading(true);
    const payload = { courseCode, courseUnit, session };
    try {
      const response = await getCourseRegisteredStudents(payload);
      if (response?.status === 200) {
        if (response.data.isUploaded === true) {
          setRegisteredList(response.data.uploadExamScores);
          setFilteredList(response.data.uploadExamScores);
          setTotalCount(response?.data.uploadExamScores.length);
          setUploadExamScores(true);
        } else {
          setRegisteredList(response?.data.enrolledStudents);
          setFilteredList(response.data.enrolledStudents);
          setUploadExamScores(false);
          setTotalCount(response?.data.enrolledStudents.length);
        }
      } else {
        toast.error(response);
      }
    } catch (error) {
      handleRequestErrors(error);
    } finally {
      setLoading(false);
    }
  }, [courseCode, courseUnit, session]);

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }
    if (selectedFile.size > 1048576) {
      toast.error("File size should not exceed 1MB.");
      return;
    }

    if (!registeredList.length) {
      toast.error("No registered students found.");
      return;
    }

    setOngoing(true);
    const formData = new FormData();
    formData.append("File", selectedFile);
    formData.append("CourseUniqueId", registeredList[0].courseUniqueId);

    try {
      const request = await AdminBulkUploadExamScores(formData);
      if (request?.status === 200) {
        toast.success("Exam data has been successfully uploaded.");
        setRegisteredList(request?.data);
        setFilteredList(request.data);
        setShowUpload(false);
        setUploadExamScores(true);
        setDisableButton(true);
        
      } else {
        toast.error(request);
      }
    } catch (error) {
      handleRequestErrors(error);
    } finally {
      setOngoing(false);
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm) {
        const filtered = registeredList.filter(
          (student) =>
            student?.matricNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            student?.firstName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            student?.surName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredList(filtered);
        setCurrentPage(0);
      } else {
        setFilteredList(registeredList);
      }
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm, registeredList]);

  useEffect(() => {
    fetchRegisteredStudents();
  }, [fetchRegisteredStudents]);

  return (
    <div className="settings-wrap">
      <div className="back">
        <Navigation to="/app/results/manager" text="Back to Result Manager" />
      </div>

      <UploadModal
        show={showUpload}
        setShow={setShowUpload}
        setSelectedFile={setSelectedFile}
        handleUpload={handleUpload}
        ongoing={ongoing}
      />

      <div className="top-action">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchTerm}
          searchParameter={searchTerm}
        />
      
        <div className="top-btn-action">
          <DownloadButton
            onClick={() =>
              fetchAndDownloadExcel(
                ResultScoreSampleTemplate,
                `${courseCode} ${examYear}.xlsx`
              )
            }
            buttonText="Download Excel Template"
          />
          <UploadButton
            onClick={() => setShowUpload(true)}
            buttonText="Upload Excel Scores"
          />
        </div>
      </div>
<div className="course-details">
  
        {registeredList.length > 0 && (
            <span className="heading">
              <p> {courseTitle} </p>
              <p> [{courseCode}] </p>
            </span>
          )}
</div>

      <div className="course-table">
        <UploadScoreTable
          registeredList={registeredList}
          isUploaded={uploadExamScores}
          setIsUploaded={setUploadExamScores}
          loading={loading}
          fetchRegisteredStudents={fetchRegisteredStudents}
          paginationData={currentPageData}
          itemPerPage={itemsPerPage}
          currentPage={currentPage}
          disableButton={disableButton}
          setDisableButton={setDisableButton}
        />

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          className="pagination_items"
          pageClassName="page_num"
          pageLinkClassName="page_link"
          activeClassName="active_page_link"
          previousClassName="previous_page_link"
          nextClassName="next_page_link"
        />

        <div className="result-count">
          <p>
            Showing {lastPageCount} of {totalCount} results
          </p>
        </div>
      </div>
    </div>
  );
};

const ScoresUploadWrap = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<UploadScoresSettings />}
        pageTitle="SCORES UPLOAD"
      />
    </>
  );
};

export default ScoresUploadWrap;
