import { ToastContainer } from "react-toastify";
import { InAppTemplate } from "../../../shared/templates/portal";
import ResultManagerConfigurations from "./ResultManagementConfig";

const ResultManagementSettings = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        childComponent={<ResultManagerConfigurations  />}
        pageTitle="VIEW ASSIGNED COURSES AND UPLOAD SCORES"
      />
    </>
  );
};

export default ResultManagementSettings;
