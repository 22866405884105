import React from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Pencil from "../../../../assets/pencil.png";
// import ViewIco from "../../assets/view.png";
// import AddIcon from "../../assets/Add.png";
import DeleteIcon from "../../../../assets/delete.png";

const SubjectAssignmentsList = ({
  loading,
  currentPage,
  dataList,
  setSelectedLecturer,
  setSelectedAssignment,
  setShowEdit,
  setShowDelete
}: any) => {

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>SN</th>
          <th>Lecturer</th>
          <th>Course Subject</th>
          <th>Course Code </th>
          <th>Programme</th>
          <th>Level</th>
          <th>Semester</th>
          <th>Session</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {
          loading &&
          ([...Array(9)].map((_, index) => (
            <tr key={index}>
              {[...Array(9)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          )))
        }
        {
          !loading && dataList.length >= 1 &&
          (
            dataList.map((data: any, index: any) => {
              return (
                <tr key={index}

                >
                  {/* <td>{currentPage==0? 1 :(currentPage - 1) * 15 + index + 1} </td> */}
                  <td>{ index + 1} </td>
                  <td>{data?.fullName} </td>
                  <td>{data?.courseTitle} </td>
                  <td>{data?.courseCode} </td>
                  <td>{data?.programme} </td>
                  <td>{data?.level} </td>
                  <td>{data?.courseSemester} </td>
                  <td>{data?.session} </td>
                  <td onClick={async () => { }}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderLeft: "none",
                      }}
                    >
                      <img
                        src={Pencil}
                        alt="edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedAssignment(data)
                          setShowEdit(true)
                          
                        }}
                      />
                     
                        {/* <img
                          src={ViewIco}
                          alt="view"
                          style={{ cursor: "pointer", width: "25px" }}
                        /> */}
                      
                      <img
                        src={DeleteIcon}
                        alt="view"
                        style={{ cursor: "pointer", width: "30px" }}
                        onClick={() => {
                          setSelectedAssignment(data)
                          setShowDelete(true)
                          // setAddStudentModal(true);
                          // setRoomInfo(room);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              )
            })

          )
        }

      </tbody>
    </Table>
  )
}

export default SubjectAssignmentsList;