import { InAppTemplate } from "../../../../shared/templates/portal";
import MapUserToFaculty from "./MapUserToFaculty";

const EDocumentationAssignUserToFaculty = () => {
  return (
    <InAppTemplate
      childComponent={<MapUserToFaculty />}
      pageTitle="ASSIGN USER TO FACULTY"
    />
  );
};

export default EDocumentationAssignUserToFaculty;
