import Dash4 from "../../../assets/dash-4.png";
import "./index.scss";

 interface StatCard {
    itemName: string,
    color: string,
    count: number,
    description? : string,
 
 } 
const StatisticsCard = ({ itemName, color, count, description }: StatCard) => {

    return (
      <div className="putme_stat" style={{ background: color }}>
        <div className="stat_icon">
          <img src={Dash4} alt="" />
        </div>
        <div className="stat_values">
          <div className="stat">{description}</div>
          <div className="stat">{itemName}</div>
          <div className="vaalue">
            <h2> {count} </h2>
          </div>
        </div>
      </div>
    );
  };
  
  export default StatisticsCard;