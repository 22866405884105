import { Link } from 'react-router-dom'
import Navigation from '../../../shared/components/navigation/Navigation'

const ResultCards = () => {
  return (
    <div className="result-header">
      <div className="back">
        <Navigation to="/app/results/dashboard" text="Back to Result Dashboard" />
      </div>

      <div className="settings_list">

        <div className="each_setting">
          <div className="setting_name">Grade Settings</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/grade-settings">
              Updates
            </Link>{" "}
          </div>
        </div>

        <div className="each_setting">
          <div className="setting_name">Score Optimizer</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/score-optimizer">
              Updates
            </Link>{" "}
          </div>
        </div>

        <div className="each_setting">
          <div className="setting_name">Score Settings</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/score-settings">
              Updates
            </Link>{" "}
          </div>
        </div>

        <div className="each_setting">
          <div className="setting_name">Lecturer Management</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/manage-lecturers">
              Manage
            </Link>{" "}
          </div>
        </div>
        <div className="each_setting">
          <div className="setting_name">HOD Management</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/manage-hods">
              Manage
            </Link>{" "}
          </div>
        </div>
        <div className="each_setting">
          <div className="setting_name">Faculty Deans Management</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/manage-deans">
              Manage
            </Link>{" "}
          </div>
        </div>
        
        <div className="each_setting">
          <div className="setting_name">Assign Course to Lecturer</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/assign-courses-to-lecturers">
              Assign  course
            </Link>{" "}
          </div>
        </div>
        
        <div className="each_setting">
          <div className="setting_name">Register Courses for Student</div>
          <div className="setting_link">
            <Link className="btn" to="/app/results/register-student-courses">
              Register
            </Link>{" "}
          </div>
        </div>



      </div>
    </div>
  )
}

export default ResultCards
