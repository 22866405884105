import React from "react";
import Skeleton from "react-loading-skeleton";
import DownloadButton from "../../../../shared/components/download-components/DownloadButton";
import "./index.scss";
import { Table } from "react-bootstrap";
import { formatDate } from "../../../postutme/reports/Reports";
import { DocDetails } from "../../../../utils/types";
import { toast } from "react-toastify";

const EDocReportTable: React.FC<DocDetails> = ({
  documentDetails,
  loading,
  currentPage,
  itemsPerPage,
}: any) => {
  const handleDownload = async (fileUrl: string, filename: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      // Set a default filename
      a.download = `${filename}.jpg`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      toast.error("Download error");
    }
  };
  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Matric Number</th>
            <th>Full Name</th>
            <th>Level</th>
            <th>Document Ref. Id</th>
            <th>Session</th>
            <th>Date Uploaded</th>
            <th>Review Date</th>
            <th>Status</th>
            <th style={{ textAlign: "center" }}>Download </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            [...Array(20)].map((_, index) => (
              <tr key={index}>
                {[...Array(11)].map((_, index) => (
                  <td key={index}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          ) : !loading && documentDetails.length > 0 ? (
            documentDetails?.map((detail: any, index: number) => {
              const reviewDate = formatDate(detail?.reviewedDate);
              const dateUploaded = formatDate(detail?.dateUploaded);

              return (
                <tr key={index}>
                  <td>{currentPage * itemsPerPage + index + 1}</td>
                  <td>{detail?.matricNumber}</td>
                  <td>{detail?.fullName}</td>
                  <td>{detail?.level}</td>
                  <td>{detail?.referenceId}</td>
                  <td>{detail?.session}</td>
                  <td>{dateUploaded}</td>
                  <td>{reviewDate}</td>
                  <td
                    style={{
                      color: detail?.status === "APPROVED" ? "green" : "red",
                      fontWeight: "800",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    {detail?.status}
                  </td>

                  <td style={{ display: "flex", justifyContent: "flex-start" }}>
                    <DownloadButton
                      className="doc-btn"
                      buttonText="Front"
                      onClick={() =>
                        handleDownload(
                          detail?.downloadUrlFront,
                          `${detail?.documentType}-front`
                        )
                      }
                    />

                    {detail?.downloadUrlBack ? (
                      <DownloadButton
                        className="doc-btn"
                        buttonText="Back"
                        onClick={() =>
                          handleDownload(
                            detail?.downloadUrlBack,
                            `${detail?.documentType}-back`
                          )
                        }
                      />
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={11} style={{ textAlign: "center" }}>
                <h5>No documents have been submitted yet.</h5>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};
export default EDocReportTable;
