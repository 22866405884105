import Navigation from '../../../shared/components/navigation/Navigation'


interface Success{
    fullName: string,
    oldMatricNumber: string,
    newMatricNumber: string,
}
const SuccessMessage = ({fullName, oldMatricNumber, newMatricNumber} : Success) => {
  return (
    <div>
 <div className="back-to-portal" style={{ marginLeft: "15px" }}>
        <Navigation to="/app/portal-settings" text="Back to Update Student Level" />
      </div>

       <div className="update_success">
        <div className="success-message-wrap">
          <div className="success-animation">
            <svg
              className="success_icon_tick"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
          <div className="success-message-txt">
            <p>
              The Student level for <b>{fullName}</b> with Matriculation Number <b>{oldMatricNumber}</b> has
              been successfully updated.
            </p>
            <p> Please see the new Matriculation Number here: <b>{newMatricNumber}</b></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessMessage
