import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Pencil from "../../assets/pencil.png";
import ViewIco from "../../assets/view.png";
import AddIcon from "../../assets/Add.png";
import { Room } from "./RoomsContent";
import { Link } from "react-router-dom";

const RoomsTable = ({
  allRooms,
  loading,
  setRoomInfo,
  currentPage,
  setShow,
  setAddStudentModal,
  setSelectedRowId,
  selectedRowId,
}: any) => {
  const handleRowClick = (roomId: string) => {
    setSelectedRowId(roomId);
    localStorage.setItem("selectedRowId", roomId);
  };

  const formatRoomInfo = (room: Room) => {
    const { allocation, ...otherDetails } = room;

    const formattedAllocations = allocation.reduce((acc, alloc) => {
      acc[`${alloc.level}L`] = alloc.allocationPercentage;
      return acc;
    }, {} as { [key: string]: number });

    return { ...otherDetails, ...formattedAllocations };
  };
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Hostel Site</th>
          <th>Blocks</th>
          <th>Room Name</th>
          <th>Gender</th>
          <th>Bed Space</th>
          <th>Allocated</th>
          <th>Non-Allocated</th>
          <th>Status</th>
          <th>Amount</th>
          <th>Reserved</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          [...Array(20)].map((_, index) => (
            <tr key={index}>
              {[...Array(12)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : allRooms?.length > 0 ? (
          allRooms.map((room: any, index: number) => {
            return (
              <tr
                key={index}
                className={
                  room.roomUniqueId === selectedRowId ? "selected-row" : ""
                }
                onClick={() => handleRowClick(room.roomUniqueId)}
              >
                <td>{(currentPage - 1) * 15 + index + 1}</td>
                <td>{room?.hostelSite}</td>
                <td>{room?.blockName}</td>
                <td>{room?.roomName}</td>
                <td>{room?.gender}</td>
                <td>{room?.totalBedSpace}</td>
                <td>{room?.allocatedBedSpace}</td>
                <td>{room?.nonAllocatedBedSpace}</td>
                <td
                  style={{
                    color:
                      room?.totalBedSpace === room?.allocatedBedSpace
                        ? "green"
                        : room?.allocatedBedSpace === 0
                        ? "red"
                        : "blue",
                    fontWeight: "800",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {room?.totalBedSpace === room?.allocatedBedSpace
                    ? "FULL"
                    : room?.allocatedBedSpace === 0
                    ? "EMPTY"
                    : "NOT FULL"}
                </td>
                <td>{room?.amount}</td>
                <td>{room?.isReserved ? "Yes" : "No"}</td>

                <td onClick={async () => {}}>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderLeft: "none",
                    }}
                  >
                    <img
                      src={Pencil}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (room?.allocation?.length) {
                          const roomInfo = formatRoomInfo(room);
                          setRoomInfo(roomInfo);
                        } else {
                          setRoomInfo(room);
                        }
                        setShow(true);
                      }}
                    />
                    <Link
                      to="/app/hostel/room"
                      state={{
                        roomId: room?.roomUniqueId,
                        site: room?.hostelSite,
                        block: room?.blockName,
                        blockUniqueId: room?.blockUniqueId,
                        roomName: room?.roomName,
                        gender: room?.gender,
                        amount: room?.amount,
                      }}
                    >
                      <img
                        src={ViewIco}
                        alt="view"
                        style={{ cursor: "pointer", width: "25px" }}
                      />
                    </Link>
                    <img
                      src={AddIcon}
                      alt="view"
                      style={{ cursor: "pointer", width: "30px" }}
                      onClick={() => {
                        setAddStudentModal(true);
                        setRoomInfo(room);
                      }}
                    />
                  </span>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={12}>
              <h5>No rooms found</h5>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default RoomsTable;
