import React, { useState } from "react";
import {
  Button,
  OverlayTrigger,
  Popover,
  Spinner,
  Table,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Pencil from "../../../../../assets/pencil.png";
import Delete from "../../../../../assets/delete.png";
import { toast } from "react-toastify";
import { deleteExamScoresOptimizer } from "../../../../../redux/action/results";
import { IScoreOptimizerTable } from "../../../../../utils/types";

const ScoreOptimizerTable: React.FC<IScoreOptimizerTable>= ({
  loading,
  setLoading,
  setShowModal,
  setSelectedScore,
  optimizedScores,
  fetchOptimizedScores,
}: any) => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogueId, setDialogueId] = useState("");


  const handleDelete = async (uniqueId: string) => {
    setLoading(true);
    try {
      const res = await deleteExamScoresOptimizer(uniqueId);
      if (res.status === 200) {
        await fetchOptimizedScores();
        toast.success("Optimization deleted successfully");
      }
    } catch (error) {
      toast.error("Error deleting optimization settings");
    } finally {
      setLoading(false);
      setDialogueId("");
      setShowDialog(false);
    }
  };
  
  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <p>Are you sure you want to delete this settings?</p>
      <div className="action-buttons">
        <Button variant="danger" onClick={() => handleDelete(dialogueId)} disabled={loading}>
          Delete {loading ? <Spinner animation="border" size="sm" /> : null}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );

  const sortedExamGrades = optimizedScores?.sort((a: any, b: any) =>
    a.originalGrade.localeCompare(b.originalGrade)
  );

  return (
    <div className="result-table">
    <Table bordered hover>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Original Score</th>
          <th>Original Grade</th>
          <th>Optimized Score</th>
          <th>Optimize Grade</th>
          <th style={{ textAlign: "center" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          [...Array(5)].map((_, index) => (
            <tr key={index}>
              {[...Array(6)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : sortedExamGrades?.length > 0 ? (
          sortedExamGrades.map((data: any, index: number) => {
            return (
              <tr key={index} className="result-tr">
                <td>{index + 1}</td>
                <td>{data?.originalScore}</td>
                <td>{data?.originalGrade}</td>
                <td>{data?.optimizedScore}</td>
                <td>{data?.optimizedGrade}</td>

                <td>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <img
                      src={Pencil}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedScore(data);
                        setShowModal(true);
                      }}
                    />
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      show={data?.examScoreOptimizerUniqueId === dialogueId && showDialog}
                      overlay={popover}
                      rootClose
                    >
                      <img
                        src={Delete}
                        alt="delete"
                        onClick={() => {
                          setDialogueId(data?.examScoreOptimizerUniqueId);
                          setShowDialog(!showDialog);
                        }}
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "28px",
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                </td>
              </tr>
            );
          })
        ) : !optimizedScores.length ? (
          <tr>
            <td colSpan={6}>
              <h5>No score optimization available.</h5>
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
    </div>
  );
};

export default ScoreOptimizerTable;
