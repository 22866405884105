import React, { useMemo, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Container } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import { adminInAppActions } from "../../../../redux/action/portal";
import InputField from "../../../../shared/components/InputField";
import DropDown from "../../../../shared/components/dropdown";
import ModalFooterButtons from "../../../../shared/components/download-components/ModalFooterButtons";

export const NewLecturerModal = ({
  showNew,
  setShowNew,
  setSelectedDepartment,
  setSelectedFaculty,
  setSelectedProgramme,
  setCurrentPage,
}: any) => {
  const [ongoing, setOngoing] = useState(false);
  const { allProgrammes } = useSelector((state: any) => state.admissionReducer);

  const sortProgramme = (a: any, b: any) => {
    if (a?.label.toLowerCase() < b?.label.toLowerCase()) {
      return -1;
    }
    if (a?.label.toLowerCase() > b?.label.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const programmesList =
    allProgrammes
      .map((prog: any) => {
        return {
          value: prog.programmeName,
          label: prog.programmeName,
        };
      })
      ?.sort(sortProgramme) || [];

  let initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    programme: "",
    status: "ACTIVE",
  };
  let checkValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("Required"),
    email: Yup.string().email().required("Valid email is required"),
    phoneNumber: Yup.string().required("Required"),
    programme: Yup.string().required("Required"),
  });

  const handleCloseModal = () => {
    if (!ongoing) {
      setOngoing(false);
      setShowNew(false);
      setSelectedProgramme("");
      setSelectedFaculty("");
      setSelectedDepartment("");
    }
  };

  return (
    <Modal
      show={showNew}
      onHide={handleCloseModal}
      className="records_modal_wrap"
      size="lg"
      contentClassName="records_modal_content"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          CREATE A LECTURER
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="records-modal-form">
        <Formik
          initialValues={initialValues}
          validationSchema={checkValidationSchema}
          onSubmit={async (values) => {
            setCurrentPage(0);
            setOngoing(true);
            let payload = {
              lecturerUniqueId: "",
              fullName: values.fullName,
              email: values.email,
              phoneNumber: values.phoneNumber,
              programme: values.programme,
              status: "ACTIVE",
            };

            const request = await adminInAppActions.AdminAddOrUpdateLecturer(
              payload
            );

            if (request?.status === 200) {
              toast.success(
                `${values.fullName} has been successfully created as Lecturer`
              );
              setCurrentPage(1);
              handleCloseModal();
            } else {
              toast.error(request);
            }
            setOngoing(false);
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              setFieldTouched,
            } = props;

            return (
              <Form>
                <Row>
                  <Col xs={12} md={12}>
                    <InputField
                      field="fullName"
                      label="Full Name"
                      value={values.fullName}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <InputField
                      field="email"
                      label="Email"
                      value={values.email}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InputField
                      field="phoneNumber"
                      label="Phone Number"
                      value={values.phoneNumber}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                    <DropDown
                      label="Course"
                      name="programme"
                      touched={touched}
                      errors={errors}
                      options={programmesList}
                      field="programme"
                      handleChange={setSelectedProgramme}
                      value={{
                        value: values.programme,
                        label: values.programme,
                      }}
                      defaultValue={{
                        value: values.programme,
                        label: values.programme,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      classNamePrefix="custom-fields"
                    />
                  </Col>
                </Row>
                <div className="modal-footer">
                  <Button
                    className="cancel-btn"
                    onClick={() => setShowNew(false)}
                    disabled={ongoing}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="submit-btn"
                    type="submit"
                    disabled={ongoing}
                  >
                    {ongoing ? (
                      <DotLoader
                        color="white"
                        loading={ongoing}
                        size={30}
                        aria-label="Submitting"
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export const EditLecturerModal = ({
  showEdit,
  setShowEdit,
  selectedDepartment,
  setSelectedDepartment,
  selectedFaculty,
  setSelectedFaculty,
  selectedProgramme,
  setSelectedProgramme,
  setCurrentPage,
  selectedLecturer,
  setSelectedLecturer,
}: any) => {
  const [ongoing, setOngoing] = useState(false);
  const { faculties, allProgrammes } = useSelector(
    (state: any) => state.admissionReducer
  );

  const programmesList =
    allProgrammes.map((prog: any) => {
      return {
        value: prog.programmeName,
        label: prog.programmeName,
      };
    }) || [];


  let initialValues = {
    fullName: selectedLecturer?.fullName,
    email: selectedLecturer?.email,
    phoneNumber: selectedLecturer?.phoneNumber,
    programme: selectedLecturer?.programme,
    status: selectedLecturer?.status,
  };
  let checkValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("Required"),
    email: Yup.string().email().required("Valid email is required"),
    phoneNumber: Yup.string().required("Required"),
    programme: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
  });

  const handleCloseModal = () => {
    if (!ongoing) {
      setOngoing(false);
      setShowEdit(false);
      setSelectedProgramme();
      setSelectedFaculty();
      setSelectedDepartment();
      setSelectedLecturer();
    }
  };

  return (
    <Modal
      show={showEdit}
      onHide={handleCloseModal}
      className="records_modal_wrap"
      size="lg"
      contentClassName="records_modal_content"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          EDIT A LECTURER
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="records-modal-form">
        <Formik
          initialValues={initialValues}
          validationSchema={checkValidationSchema}
          onSubmit={async (values) => {
            setCurrentPage(0);
            setOngoing(true);
            let payload = {
              lecturerUniqueId: selectedLecturer?.lecturerUniqueId,
              fullName: values.fullName,
              email: values.email,
              phoneNumber: values.phoneNumber,
              programme: values.programme,
              status: values.status,
            };

            const request = await adminInAppActions.AdminAddOrUpdateLecturer(
              payload
            );

            if (request?.status === 200) {
              toast.success(`Details has been successfully updated`);
              setCurrentPage(1);
              handleCloseModal();
            } else {
              setCurrentPage(1);
              toast.error(request);
            }
            setOngoing(false);
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              setFieldTouched,
            } = props;

            return (
              <Form>
                <Row>
                  <Col xs={12} md={6}>
                    <InputField
                      field="fullName"
                      label="Full Name"
                      value={values.fullName}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <DropDown
                      label="Status"
                      touched={touched}
                      errors={errors}
                      value={{
                        value: values.status,
                        label: values.status,
                      }}
                      defaultValue={{
                        value: values.level,
                        label: values.level,
                      }}
                      options={[
                        { value: "ACTIVE", label: "ACTIVE" },
                        { value: "REVOKED", label: "REVOKED" },
                        { value: "DE-ACTIVATED", label: "DE-ACTIVATED" },
                      ]}
                      field="status"
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      classNamePrefix="custom-fields"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <InputField
                      field="email"
                      label="Email"
                      value={values.email}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InputField
                      field="phoneNumber"
                      label="Phone Number"
                      value={values.phoneNumber}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                    <DropDown
                      label="Course"
                      name="programme"
                      touched={touched}
                      errors={errors}
                      // disabled={!values.departmentName}
                      options={programmesList}
                      field="programme"
                      handleChange={setSelectedProgramme}
                      value={{
                        value: values.programme,
                        label: values.programme,
                      }}
                      defaultValue={{
                        value: values.programme,
                        label: values.programme,
                      }}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      classNamePrefix="custom-fields"
                    />
                  </Col>
                </Row>
                <ModalFooterButtons
                  onCancel={() => {
                    setShowEdit(false);
                  }}
                  isSubmitting={ongoing}
                  submitButtonText={"Submit"}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export const DeleteLecturerModal = ({
  showDelete,
  setShowDelete,
  selectedLecturer,
  setSelectedLecturer,
  setCurrentPage,
}: any) => {
  const [ongoing, setOngoing] = useState(false);

  const handleCloseModal = () => {
    if (!ongoing) {
      setOngoing(false);
      setShowDelete(false);
      setSelectedLecturer();
    }
  };

  const handleDeleteRecord = async () => {
    setCurrentPage(0);
    setOngoing(true);
    const request = await adminInAppActions.AdminDeleteALecturer(
      selectedLecturer?.lecturerUniqueId
    );

    if (request?.status === 200) {
      toast.success(
        `${selectedLecturer.fullName} has been successfully deleted as Lecturer`
      );
      setCurrentPage(1);
      handleCloseModal();
    } else {
      toast.error(request?.message || "An Error occured");
    }
    setOngoing(false);
  };

  return (
    <Modal
      show={showDelete}
      onHide={handleCloseModal}
      className="records_modal_wrap"
      size="lg"
      contentClassName="records_modal_content"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          DELETE A LECTURER
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="records-modal-form">
        <div className="confirm-deletio">
          KINDLY CONFIRM YOU WANT TO DELETE{" "}
          <strong> {selectedLecturer?.fullName} </strong> as LECTURER OF{" "}
          <strong> {selectedLecturer?.programme}</strong>
        </div>

        <ModalFooterButtons
          onCancel={() => {
            setShowDelete(false);
          }}
          onClick={handleDeleteRecord}
          isSubmitting={ongoing}
          submitButtonText={"Proceed"}
        />
      </Modal.Body>
    </Modal>
  );
};

export const BulkUploadModal = ({ show, setShow, setCurrentPage }: any) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [ongoing, setOngoing] = useState(false);

  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      if (selectedFile.size > 1048576) {
        return;
      }
      setCurrentPage(0);
      setOngoing(true);
      const formData = new FormData();
      formData.append("File", selectedFile);

      const request = await adminInAppActions.AdminBulkUploadLecturers(
        formData
      );
      if (request?.status === 200) {
        toast.success(`Lecturers Bulk Data has been successfully uploaded`);
        setCurrentPage(1);
        setShow(false);
      } else {
        toast.error(request);
      }
      setOngoing(false);
    }
  };
  return (
    <Modal
      show={show}
      contentClassName="putme-bulk-upload-modal"
      onHide={() => {
        if (!ongoing) {
          setShow(false);
        }
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h5>BULK UPLOAD</h5>
      </Modal.Header>
      <Modal.Body className="report-main-body">
        <Container>
          <input
            type="file"
            className="file-input"
            style={{ cursor: "pointer" }}
            accept=".xls,.xlsx"
            size={1048576}
            onChange={handleFileChange}
          />
          {selectedFile && selectedFile.size > 1048576 ? (
            <p className="input-error">Max file size is 1MB</p>
          ) : null}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <ModalFooterButtons
          onClick={handleUpload}
          isSubmitting={ongoing}
          submitButtonText={"Upload"}
        />
      </Modal.Footer>
    </Modal>
  );
};
