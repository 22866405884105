import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { InAppTemplate } from "../../shared/templates/portal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import CourseSubject from "./CourseSubject";
import {
  addCourseLoading,
  courseSubjectLoading,
  loadAllDepartments,
} from "../../redux/action/course-subject";
import { loadAllProgrammes } from "../../redux/action/admission";

const CourseSubjectManagement = () => {
  const dispatch = useDispatch();
  const getAll = async () => {
    await dispatch(loadAllDepartments());
  };
  useEffect(() => {
    getAll();
    dispatch(loadAllProgrammes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(courseSubjectLoading(false));
      dispatch(addCourseLoading(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        pageTitle="COURSE MANAGEMENT MODULE"
        childComponent={<CourseSubject />}
      />
    </>
  );
};

export default CourseSubjectManagement;
