import React from 'react'
import { InAppTemplate } from '../../../../shared/templates/portal'
import BannedStudent from './BlackListedStudent'

const BannedHostelStudent = () => {
  return (
    <InAppTemplate
        pageTitle='BLACKLISTED HOSTEL ACCOMMODATION STUDENTS'
        childComponent={<BannedStudent/>}

    />
  )
}

export default BannedHostelStudent
