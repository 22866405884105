import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "./index.scss";

const StudentRoomTable = ({ item, show }: any) => {
  return (
    <div className="report-wrapper">
      {show && (
        <Table bordered hover>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Matric Number</th>
              <th>Site Name</th>
              <th>Block Name</th>
              <th>Room No</th>
              <th>Level</th>
              <th>Paid</th>
            </tr> 
          </thead>
          <tbody>
            {item.length === 0 ? (
              [...Array(1)].map((_, index) => (
                <tr key={index}>
                  {[...Array(6)].map((_, index) => (
                    <td key={index}>
                      <Skeleton height={20} />
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td>{item.fullName}</td>
                <td>{item.matricNumber}</td>
                <td>{item.hostelSite}</td>
                <td>{item.blockName}</td>
                <td>{item.roomName}</td>
                <td>{item.level}</td>
                <td>{item.hasPaid}</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default StudentRoomTable;
