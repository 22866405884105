import React from "react";
import Pencil from "../../assets/pencil.png";
import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";

const AdmissionTable = ({
  admissionList,
  setShow,
  setStudentInfo,
  setSelectedDepartment,
  setSelectedFaculty,
  currentPage,
  itemsPerPage
}: any) => {
  const { admissionListLoading } = useSelector(
    (state: any) => state.admissionReducer
  );

  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Jamb No.</th>
          <th>Full Name</th>
          <th>Course</th>
          <th>State</th>
          <th>Sex</th>
          <th>Session</th>
          <th>Level</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
        {admissionListLoading ? (
          [...Array(20)].map((_, index) => (
            <tr key={index}>
              {[...Array(9)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : admissionList.length ? (
          admissionList?.map((student: any, index: any) => {
            return (
              <tr key={index}>
                <td>{(currentPage - 1) * itemsPerPage + (index + 1)}</td>
                <td>{student.registrationNumber}</td>
                <td>{student.fullName}</td>
                <td>{student.programme}</td>
                <td>{student.state}</td>
                <td>{student.sex}</td>
                <td>{student.session}</td>
                <td>{student.levelOfEntry}</td>

                {/* PENCIL */}
                <td
                  onClick={async () => {
                    await setStudentInfo(student);
                    await setSelectedDepartment(student.department);
                    await setSelectedFaculty(student.faculty);
                    setShow(true);
                  }}
                >
                  <img src={Pencil} alt="edit" style={{ cursor: "pointer" }} />
                </td>
              </tr>
            );
          })
        ) : (
          <p className="no-results">No results...</p>
        )}
      </tbody>
    </Table>
  );
};

export default AdmissionTable;
