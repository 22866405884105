import { useState } from "react";
import { Formik, FormikProps } from "formik";
import "./index.scss";
import Navigation from "../../../shared/components/navigation/Navigation";
import SuccessMessage from "./SuccessMessage";
import UpdateLevelForm from "./UpdateLevelForm";

interface Students {
  fullName: string;
  level: string;
  matricNumber: string;
}
interface UpdatePayload {
  matricNumber: string;
  newLevel: string;
}

const initialStudentValues = {
  fullName: "",
  level: "",
  matricNumber: "",
};

const UpdateStudentLevel = () => {
  const [studentDetails, setStudentDetails] = useState<Students>(initialStudentValues);
  const [newLevelSuccess, setNewLevelSuccess] = useState<boolean>(false);
  const [newMatricNumber, setNewMatricNumber] = useState<string>("");

  const initialValues: UpdatePayload = {
    matricNumber: "",
    newLevel: "",
  };

  return (
    <>
      {!newLevelSuccess && (
        <div>
          <div className="back-to-portal" style={{ marginLeft: "15px" }}>
            <Navigation
              to="/app/portal-settings"
              text="Back to Portal Settings"
            />
          </div>

          <div className="update-page">
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {}}
            >
              {(formikProps: FormikProps<any>) => (
                <UpdateLevelForm
                  formikProps={formikProps}
                  setNewLevelSuccess={setNewLevelSuccess}
                  setStudentDetails={setStudentDetails}
                  studentDetails={studentDetails}
                  setNewMatricNumber={setNewMatricNumber}
                />
              )}
            </Formik>
          </div>
        </div>
      )}

      {newLevelSuccess && (
        <SuccessMessage
          fullName={studentDetails.fullName}
          oldMatricNumber={studentDetails.matricNumber}
          newMatricNumber={newMatricNumber}
        />
      )}
    </>
  );
};

export default UpdateStudentLevel;