import { Dispatch } from "redux";
import { ApiService } from "../../../services/apiService";
import { routes } from "../../../services/paths";
import { handleRequestErrors } from "../../../shared/utils";
import * as types from "../../action-constants/edocumentation";
import { toast } from "react-toastify";

export const dashBoardDataAction = (payload: any) => {
  return {
    type: types.GET_DASHBOARD_STATS,
    payload,
  };
};

export const dashBoardDataLoading = (payload: boolean) => {
  return {
    type: types.DASHBOARD_STARTS_LOADING,
    payload,
  };
};

export const getEdocumentationRequestsAction = (payload: any) => {
  return {
    type: types.GET_ALL_REQUESTS,
    payload,
  };
};
export const edocumentationRequestsLoading = (payload: boolean) => {
  return {
    type: types.EDOCUMENTATION_REQUEST_LOADING,
    payload,
  };
};

export const documentTypesAction = (payload: any) => {
  return {
    type: types.DOCUMENT_TYPES,
    payload,
  };
};

export const allPendingEDocumentationRequests = (payload: any) => {
  return {
    type: types.ALL_PENDING_CLEARANCE_REQUESTS,
    payload,
  };
};

export const loadingPendingRequests = (payload: any) => {
  return {
    type: types.PENDING_CLEARANCE_REQUESTS_LOADING,
    payload,
  };
};

export const getDashboardData = () => async (dispatch: Dispatch) => {
  dispatch(dashBoardDataLoading(true));
  let apiConsume = ApiService.request({
    url: `${routes.GET_EDOCUMENTATION_DASHBOARD_DATA}`,
    http_method: "GET",
    data: null,
    headers: { "X-Signature": routes.apiKey },
  });
  apiConsume
    .then((data: any) => {
      if (data.status === 200) {
        dispatch(dashBoardDataLoading(false));
        dispatch(dashBoardDataAction(data?.data));
      }
    })
    .catch((err: any) => {
      dispatch(dashBoardDataLoading(false));
      handleRequestErrors(err);
      return err;
    });
};

export const getAllRequests =
  (pageNumber: number) => async (dispatch: Dispatch) => {
    dispatch(edocumentationRequestsLoading(true));
    let apiConsume = ApiService.request({
      url: `${routes.GET_EDOCUMENTATION_REQUESTS}?pageSize=20&pageNumber=${pageNumber}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then((data: any) => {
        dispatch(edocumentationRequestsLoading(false));
        if (data.status === 200) {
          dispatch(getEdocumentationRequestsAction(data?.data));
        }
      })
      .catch((err: any) => {
        dispatch(edocumentationRequestsLoading(false));
        handleRequestErrors(err);
        return err;
      });
  };

export const searchRequests =
  (pageNumber: number, searchQuery: string) => async (dispatch: Dispatch) => {
    dispatch(edocumentationRequestsLoading(true));
    let apiConsume = ApiService.request({
      url: `${routes.EDOCUMENTATION_SEARCH}?pageSize=20&pageNumber=${pageNumber}&searchQuery=${searchQuery}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then((data: any) => {
        dispatch(edocumentationRequestsLoading(false));
        if (data.status === 200) {
          dispatch(getEdocumentationRequestsAction(data?.data));
        }
      })
      .catch((err: any) => {
        dispatch(edocumentationRequestsLoading(false));
        handleRequestErrors(err);
        return err;
      });
  };

export const searchRequestByParameters =
  (
    pageNumber: number,
    programme: string = "",
    documentType: string = "",
    level: string = ""
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(edocumentationRequestsLoading(true));
    let apiConsume = ApiService.request({
      url: `${routes.EDOCUMENTATION_SEARCH_BY_PARAMETERS}?pageSize=20&pageNumber=${pageNumber}&programme=${programme}&documentType=${documentType}&level=${level}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then((data: any) => {
        dispatch(edocumentationRequestsLoading(false));
        if (data.status === 200) {
          dispatch(getEdocumentationRequestsAction(data?.data));
        }
      })
      .catch((err: any) => {
        dispatch(edocumentationRequestsLoading(false));
        handleRequestErrors(err);
        return err;
      });
  };

export const getPendingClearanceRequests =
  (pageNumber: number, updateLoadingState = true) =>
  async (dispatch: Dispatch) => {
    if (updateLoadingState) {
      dispatch(loadingPendingRequests(true));
    }
    let apiConsume = ApiService.request({
      url: `${routes.PENDING_CLEARANCE_REQUESTS}?pageSize=20&pageNumber=${pageNumber}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then((data: any) => {
        if (updateLoadingState) {
          dispatch(loadingPendingRequests(false));
        }
        if (data.status === 200) {
          dispatch(allPendingEDocumentationRequests(data?.data));
        }
      })
      .catch((err: any) => {
        if (updateLoadingState) {
          dispatch(loadingPendingRequests(false));
        }
        handleRequestErrors(err);
        return err;
      });
  };

export const getDocumentTypes = () => async (dispatch: Dispatch) => {
  let apiConsume = ApiService.request({
    url: `${routes.EDOCUMENTATION_DOCUMENT_TYPES}`,
    http_method: "GET",
    data: null,
    headers: { "X-Signature": routes.apiKey },
  });
  return apiConsume
    .then((data: any) => {
      if (data.status === 200) {
        dispatch(documentTypesAction(data?.data));
        return data?.data
      }
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const approveOrRejectDocument =
  (requestBody: any) => async (dispatch: Dispatch) => {
    let apiConsume = ApiService.request({
      url: requestBody?.rejectReasons
        ? `${routes.REJECT_EDOCUMENTATION_DOCUMENT}`
        : `${routes.APPROVE_EDOCUMENTATION_DOCUMENT}`,
      http_method: "POST",
      data: requestBody,
      headers: { "X-Signature": routes.apiKey },
    });
    return apiConsume
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        toast.error("An error occured, please try again");
        handleRequestErrors(err);
        return err;
      });
  };

  export const addOrUpdateDocumentType =
  (requestBody: any) => {
    let apiConsume = ApiService.request({
      url: routes.ADD_OR_UPDATE_EDOCUMENTATION_DOCUMENT_TYPE,
      http_method: "POST",
      data: requestBody,
      headers: { "X-Signature": routes.apiKey },
    });
    return apiConsume
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        toast.error("An error occured, please try again");
        handleRequestErrors(err);
        return err;
      });
  };

  export const deleteDocumentType = async (eDocumentationDocsUniqueId: string) => {
    return await ApiService.request({
      url:  `${routes.DELETE_EDOCUMENTATION_DOCUMENT_TYPE}?eDocumentationDocsUniqueId=${eDocumentationDocsUniqueId}`,
      http_method: "DELETE",
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        handleRequestErrors(err);
        if (err?.response?.status < 500) {
          toast.error(err?.response?.data);
          return err;
        }
      });
  };
  
  

export const submitEDocumentationReview =
  (requestBody: any) => async (dispatch: Dispatch) => {
    let apiConsume = ApiService.request({
      url: `${routes.SUBMIT_EDOCUMENTATION_REVIEW}`,
      http_method: "POST",
      data: requestBody,
      headers: { "X-Signature": routes.apiKey },
    });
    return apiConsume
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        toast.error("An error occured, please try again");
        handleRequestErrors(err);
        return err;
      });
  };

export const searchClearanceRequests =
  (pageNumber: number, searchQuery: string) => async (dispatch: Dispatch) => {
    let apiConsume = ApiService.request({
      url: `${routes.SEARCH_CLEARANCE_REQUEST}?pageSize=20&pageNumber=${pageNumber}&searchQuery=${searchQuery}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(allPendingEDocumentationRequests(data?.data));
        }
      })
      .catch((err: any) => {
        handleRequestErrors(err);
        return err;
      });
  };


  export const searchStudentDocuments = async (payload: string) => {
    return await ApiService.request({
      url: `${routes.SEARCH_STUDENT_DOCUMENTS}?matricNumber=${payload}`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        handleRequestErrors(err);
        return err;
      });
  };


  export const addOrUpdateSpecificDocumentTypesForStudent =
  (requestBody: any) => { 
    let apiConsume = ApiService.request({
      url: routes.ADD_OR_UPDATE_SPECIFIC_DOCUMENT_TYPE_FOR_STUDENT,
      http_method: "POST",
      data: requestBody,
      headers: { "X-Signature": routes.apiKey },
    });
    return apiConsume
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        toast.error("An error occured, please try again");
        handleRequestErrors(err);
        return err;
      });
  };

  export const getSpecificDocumentTypesForStudents =
  (pageNumber: any) => { 
    let apiConsume = ApiService.request({
      url: `${routes.GET_SPECIFIC_DOCUMENT_TYPES_FOR_STUDENTS}?pageNumber=${pageNumber}&pageSize=20`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    });
    return apiConsume
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        toast.error("An error occured, please try again");
        handleRequestErrors(err);
        return err;
      });
  };

  export const searchSpecificDocumentTypesForStudents =
  (searchQuery:string,pageNumber: number) => { 
    let apiConsume = ApiService.request({
      url: `${routes.SEARCH_SPECIFIC_DOCUMENT_TYPES_FOR_STUDENTS}?pageNumber=${pageNumber}&pageSize=20&searchQuery=${searchQuery}`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    });
    return apiConsume
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        toast.error("An error occured, please try again");
        handleRequestErrors(err);
        return err;
      });
  };

  export const getEdocumentationStats =  async () => { 
    return await ApiService.request({
      url: `${routes.GET_EDOCUMENTATION_STATISTICS}`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    })
    .then((data:any)=> {
      return data;
    })
  };

  export const deleteSpecificDocumentTypeForStudent =
  (edocumentationDocsUniqueId:string) => { 
    let apiConsume = ApiService.request({
      url: `${routes.DELETE_SPECIFIC_DOCUMENT_TYPES_FOR_STUDENT}?edocumentationDocsUniqueId=${edocumentationDocsUniqueId}`,
      http_method: "DELETE",
      headers: { "X-Signature": routes.apiKey },
    });
    return apiConsume
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        toast.error("An error occured, please try again");
        handleRequestErrors(err);
        return err;
      });
  };
  
  

  

  