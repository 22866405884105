import Navigation from "../../../shared/components/navigation/Navigation";
import { Link } from "react-router-dom";

const ReportContent = () => {
  return (
    <div className="room-management">
      <div className="room-management-top-buttons">
        <Navigation
          to="/app/e-documentation"
          text="Back to E-Documentation Dashboard"
        />
      </div>
      <div className="settings-menu-list ">
        <div className="each_setting">
          <div className="setting_name">Search Student</div>
          <div className="setting_link">
            <Link
              className="btn"
              to="/app/e-documentation/search-students"
            >
              Search
            </Link>{" "}
          </div>
        </div>

      </div>
    </div>
  );
};

export default ReportContent;
