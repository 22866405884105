import { toast } from "react-toastify";
import { ApiService } from "../../../services/apiService";
import { examRoutes } from "../../../services/paths";
import { handleRequestErrors } from "../../../shared/utils";

export const getExamsGrade = async () => {
  return await ApiService.request({
    url: `${examRoutes.GET_EXAMS_GRADE_SETTINGS}`,
    http_method: "GET",
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      return handleRequestErrors(err);
    });
};

export const addOrUpdateExamGrade = async (payload: any) => {
  return await ApiService.request({
    url: `${examRoutes.ADD_UPDATE_GRADE_SETTINGS}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const deleteGradeSetting = async (examGradeSettingUniqueId: string) => {
  return await ApiService.request({
    url: `${examRoutes.DELETE_GRADE_SETTINGS}?examGradeSettingUniqueId=${examGradeSettingUniqueId}`,
    http_method: "DELETE",
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      if (err?.response?.status < 500) {
        toast.error(err?.response?.data);
        return err;
      }
    });
};

export const getNewGradeSetting = async (score: number) => {
  return await ApiService.request({
    url: `${examRoutes.GET_A_GRADE_SETTINGS}?score=${score}`,
    http_method: "GET",
    headers: { "X-Signature": examRoutes.apiKey },
  })
};



export const getMaxExamScores = async () => {
  return await ApiService.request({
    url: `${examRoutes.GET_EXAMS_MAX_SCORE_SETTINGS}`,
    http_method: "GET",
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      return handleRequestErrors(err);
    });
};

export const addOrUpdateMaxExamScores = async (payload: any) => {
  return await ApiService.request({
    url: `${examRoutes.ADD_UPDATE_MAX_SCORE_SETTINGS}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const deleteMaxExamScores = async (examMaxScoreUniqueId: string) => {
  return await ApiService.request({
    url: `${examRoutes.DELETE_MAX_SCORE_SETTINGS}?examMaxScoreUniqueId=${examMaxScoreUniqueId}`,
    http_method: "DELETE",
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      if (err?.response?.status < 500) {
        toast.error(err?.response?.data);
        return err;
      }
    });
};


export const getExamScoresOptimizer = async () => {
  return await ApiService.request({
    url: `${examRoutes.GET_EXAMS_SCORE_OPTIMIZER}`,
    http_method: "GET",
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      return handleRequestErrors(err);
    });
};

export const addOrUpdateExamScoresOptimizer = async (payload: any) => {
  return await ApiService.request({
    url: `${examRoutes.ADD_UPDATE_SCORE_OPTIMIZER}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const deleteExamScoresOptimizer = async (examScoreOptimizerUniqueId: string) => {
  return await ApiService.request({
    url: `${examRoutes.DELETE_SCORE_OPTIMIZER}?examScoreOptimizerUniqueId=${examScoreOptimizerUniqueId}`,
    http_method: "DELETE",
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      if (err?.response?.status < 500) {
        toast.error(err?.response?.data);
        return err;
      }
    });
};



export const getCourseRegisteredStudents = async (
{ 
  courseCode,
  courseUnit,
  session
}: any
) => {
  return await ApiService.request({
    url: `${examRoutes.STUDENT_REGISTERED_COURSES}?courseCode=${courseCode}&courseUnit=${courseUnit}&session=${session}`,
    http_method: "GET",
    headers: { "X-Signature": examRoutes.apiKey },
  })
};

export const AdminBulkUploadExamScores = async (payload: any) => {
  return await ApiService.request({
    url: `${examRoutes.UPLOAD_EXAM_SCORES}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then(async (response: any) => {
      return response;
    })
    .catch((err: any) => {
      return handleRequestErrors(err);
    });
};

export const addOrUpdateExamScores = async (payload: any) => {
  return await ApiService.request({
    url: `${examRoutes.ADD_UPDATE_EXAMSCORE}`,
    http_method: "POST",
    data: payload,
    headers: { "X-Signature": examRoutes.apiKey },
  })
    .then((data: any) => {
      return data;
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};




