import { Link } from "react-router-dom";

interface PortalSetttingsOption {
  name: string;
  linkTo: string;
  buttonText: string;
  allowedRoles: string[];
}

const PORTAL_SETTINGS_MODULE_LIST = [
  {
    name: "Configure Highest Qualifications",
    linkTo: "/app/portal-settings/qualification",
    buttonText: "Configure",
    allowedRoles: ["Admin"],
  },
  {
    name: "Configure Awards-in-view",
    linkTo: "/app/portal-settings/awards",
    buttonText: "Configure",
    allowedRoles: ["Admin"],
  },
  {
    name: "Configure Country Settings",
    linkTo: "/app/portal-settings/countries",
    buttonText: "Configure",
    allowedRoles: ["Admin"],
  },
  {
    name: "School Session Settings",
    linkTo: "/app/portal-settings/current-session",
    buttonText: "Configure",
    allowedRoles: ["Admin"],
  },
  {
    name: "Configure Student Specialization",
    linkTo: "/app/portal-settings/specialization",
    buttonText: "Configure",
    allowedRoles: ["Admin"],
  },
  {
    name: "Update Student Invoice",
    linkTo: "/app/portal-settings/update-student-invoice",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Delete Student Invoice",
    linkTo: "/app/portal-settings/delete-student-invoice",
    buttonText: "Delete",
    allowedRoles: ["Admin", "Invoice_Deleter", ],
  },
  {
    name: "Change New Admitted Student Course",
    linkTo: "/app/portal-settings/update-student-course-of-study",
    buttonText: "Change Course",
    allowedRoles: ["Admin"],
  },
  {
    name: "Check Matric No. History",
    linkTo: "/app/portal-settings/student-course-of-study-change-history",
    buttonText: "Check",
    allowedRoles: ["Admin"],
  },
  {
    name: "Update Student State of Origin",
    linkTo: "/app/portal-settings/update-student-origin",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Assign Dept. To Course Advisor",
    linkTo: "/app/portal-settings/assign-dept-to-course-advisor",
    buttonText: "Assign",
    allowedRoles: ["Admin"],
  },
  {
    name: "Configure Role Management",
    linkTo: "/app/portal-settings/manage-roles",
    buttonText: "Configure",
    allowedRoles: ["Admin"],
  },
  {
    name: "User Management",
    linkTo: "/app/portal-settings/manage-users",
    buttonText: "Manage",
    allowedRoles: ["Admin"],
  },
  {
    name: "Reset Student Password",
    linkTo: "/app/portal-settings/reset-student-password",
    buttonText: "Reset Password",
    allowedRoles: ["Admin", "Password_Resetter", "Invoice_Checker"],
  },
  {
    name: "Manage Student Activation",
    linkTo: "/app/portal-settings/update-student-status",
    buttonText: "Manage",
    allowedRoles: ["Admin", "Admission_Manager", "Student_Manager"],
  },
  {
    name: "Update your Account Password",
    linkTo: "/app/portal-settings/reset-admin-password",
    buttonText: "Update",
    allowedRoles: ["all"],
  },
  {
    name: "Reset Other Users Password",
    linkTo: "/app/portal-settings/reset-a-user-password",
    buttonText: "Reset Password",
    allowedRoles: ["Admin"],
  },
  {
    name: "Create Student",
    linkTo: "/app/portal-settings/student-management",
    buttonText: "Create",
    allowedRoles: ["Admin", "Student_Creator"],
  },
  {
    name: "Course Advisor Management",
    linkTo: "/app/portal-settings/course-advisor-management",
    buttonText: "Manage Advisor",
    allowedRoles: ["Admin", "Course_Advisor_Manager"],
  },
  
  {
    name: "Manage Additional Payment",
    linkTo: "/app/portal-settings/additional-payment",
    buttonText: "Manage",
    allowedRoles: ["Admin", "Student_Manager"],
  },
  {
    name: "Generate School Fee Invoice",
    linkTo: "/app/portal-settings/generate-invoice",
    buttonText: "Generate Invoice",
    allowedRoles: ["Admin", "Mis_Manager", "Busary"],
  },
  {
    name: "Manage student course unit extension",
    linkTo: "/app/portal-settings/unit-extension",
    buttonText: "Manage",
    allowedRoles: ["Admin", "Mis_Manager"],
  },
  {
    name: "Manage Student Deferment",
    linkTo: "/app/portal-settings/deferred-student",
    buttonText: "Manage",
    allowedRoles: ["Admin", "Mis_Manager", "Student_Manager"],
  },
  {
    name: "Activate/Deactivate User ",
    linkTo: "/app/portal-settings/deactivate-user",
    buttonText: "Update",
    allowedRoles: ["Admin", ],
  },
  {
    name: "Student Transfer Management",
    linkTo: "/app/portal-settings/student/transfer-management",
    buttonText: "Add Student",
    allowedRoles: ["Admin", "Mis_Manager", "Admission_Manager" ],
  },
  {
    name: "CBS Invoice Search",
    linkTo: "/app/portal-settings/cbs/invoice-query",
    buttonText: "Search",
    allowedRoles: ["Admin", "Mis_Manager", ],
  },
  {
    name: "Re-Print Admission Letter",
    linkTo: "/app/portal-settings/download/admission-letter",
    buttonText: "Re-Print",
    allowedRoles: ["Admin", "Mis_Manager", ],
  },
  {
    name: "Student Transaction History",
    linkTo: "/app/portal-settings/transaction-history",
    buttonText: "View",
    allowedRoles: ["Admin", "Bursary", "Student_Manager" ],
  },
  {
    name: "Update New Student Level",
    linkTo: "/app/portal-settings/update-level",
    buttonText: "Update",
    allowedRoles: ["Admin", "Mis_Manager"],
  },
  {
    name: "Generate Other Fee Invoice",
    linkTo: "/app/portal-settings/invoice/generate-other-fee",
    buttonText: "Generate Invoice",
    allowedRoles: ["Admin", "Mis_Manager",],
  },
];

const sortedPortalSettings = PORTAL_SETTINGS_MODULE_LIST.sort((a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
});
const PortalSettingsCard = () => {
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  const userRoles = loggedAccountInfo?.profile?.userRoles;
  const username = loggedAccountInfo?.profile?.username || "";

return (
  <>
    {sortedPortalSettings.map((settings: PortalSetttingsOption, index: any) => {
    
      const isSpecialCard =
        settings.name === "Update Student Invoice" 
        // 
        // ||settings.name === "Delete Student Invoice";

   
      const cardToDisplay =
        settings?.allowedRoles?.length &&
        userRoles.some((role: string) =>
          settings?.allowedRoles?.includes(role)
        ) &&
        (!isSpecialCard || (userRoles.includes("Admin") && (username === "ibbu_dev_core_admin" || username === "koko_master")));

      return cardToDisplay ? (
        <div className="each_setting" key={index}>
          <div className="setting_name">{settings.name}</div>
          <div className="setting_link">
            <Link className="btn" to={settings.linkTo}>
              {settings.buttonText}
            </Link>
          </div>
        </div>
      ) : null;
    })}
  </>
);
};

export default PortalSettingsCard;
