import { ErrorMessage, Form, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import InputField from "../../../shared/components/InputField";
import SubmitButton from "../../../shared/components/download-components/SubmitButton";
import { handleRequestErrors } from "../../../shared/utils";
import { getStudentProfile } from "../../../redux/action/admission";
import { FaCalendarAlt, FaSortDown } from "react-icons/fa";
import Select, { components } from "react-select";
import { deactivationReasons } from "../../../shared/_helpers/constants";
import { useDispatch } from "react-redux";
import "./index.scss";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { addDeactivateStudent } from "../../../redux/action/portal";
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSortDown style={{ color: "#000000" }} />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    display: "flex",
    alignItems: "center",
    background: "#F1FFF9",
    borderColor: state.isFocused ? "#00A85B" : "#00A85B",
    boxShadow: state.isFocused ? "0 0 0 1px #00A85B" : "#00A85B",
    ":hover": {
      borderColor: "#00A85B",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: "2px",
  }),
  focus: () => ({
    borderColor: "#00A85B",
    boxShadow: "0 0 0 1px #00A85B",
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "90%",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    color: "black",
    ":hover": {
      backgroundColor: "#00A85B",
      color: "#F1FFF9",
    },
  }),
};

const StudentStatusForm: React.FC<{
  formikProps: FormikProps<any>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchDeactivatedStudent: any;
  session: string;
}> = ({ formikProps, session, setShow, fetchDeactivatedStudent }) => {
  const initialStudentValue = {
    studentUniqueId: "",
    fullName: "",
    level: "",
    deactivatedBy: "",
    deactivatedAt: "",
    session,
    status: "",
    reasons: "",
  };

  const InitialStudentProfile = {
    fullName: "",
    level: "",
    gender: "",
  };
  const { values, touched, errors, handleChange, setFieldValue } = formikProps;
  const matricNumber = values.matricNumber;
  const [student, setStudent] = useState<any>({
    ...initialStudentValue,
  });
  const [studentProfile, setStudentProfile] = useState<any>({
    ...InitialStudentProfile,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };
  const containerRef = useRef<HTMLDivElement>(null);

  const sortedReasons = deactivationReasons.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOptions(selectedOption);
    setMenuIsOpen(false);
    setFieldValue("reasons", selectedOptions);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleGetStudentProfile = async (matricNo: string) => {
    setStudent(initialStudentValue);
    setLoading(true);
    setError("");
    await getStudentProfile(matricNo)
      .then((data) => {
        // console.log(data)
        setLoading(false);
        if (data?.data?.studentUniqueId) {
          const { studentUniqueId, fullName, level, gender } = data?.data;
          setStudentProfile((prevState: any) => ({
            ...prevState,
            gender,
            fullName,
            level,
          }));

          setStudent((prevState: any) => ({
            ...prevState,
            studentUniqueId,
            fullName,
            matricNumber,
            level,
          }));
        }
      })
      .catch((err: any) => {
        setLoading(false);
        handleRequestErrors(err);
        if (err?.response?.status === 404) {
          setError(err?.response?.data);
          setStudentProfile(InitialStudentProfile);
        }
      });
  };
  useEffect(() => {
    setStudent((prevState: any) => ({
      ...prevState,
      matricNumber: values?.matricNumber,
    }));
  }, [values]);

  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 500);
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matricNumber]);

  

  const handleSubmit = async () => {
    if (!student?.fullName || !student?.studentUniqueId) {
      setError("Enter valid matric number");
      return;
    }

    const reasons = selectedOptions
      .map((option: { label: string; value: string }) => option?.value)
      .join(",");

    const payload = {
      matricNumber: student.matricNumber,
      fullName: student.fullName,
      studentUniqueId: student.studentUniqueId,
      level: student.level,
      session,
      reasons: reasons || undefined,
    };

    console.log(payload);

    setSubmitting(true);
    try {
      const response = await addDeactivateStudent(payload);
      if (response.status === 200) {
        setSubmitting(false);
        setSelectedOptions([])
        toast.success("Student deactivated successfully");
        setShow(false);
        fetchDeactivatedStudent(1);
      }
      else{
        setSubmitting(false);
        setSelectedOptions([])
        toast.error("Failed to deactivate student");
      }
    } catch {}
  };

  return (
    <Form>
      {loading ? (
        <p className="student-name">Searching student...</p>
      ) : !loading && studentProfile?.fullName && matricNumber?.length ? (
        <span>
          <p className="student-name">Name: {studentProfile?.fullName}</p>
          <p className="student-name">Level: {studentProfile?.level}</p>
          <p className="student-name">
            Gender:{" "}
            {studentProfile?.gender === "F"
              ? "Female"
              : studentProfile?.gender === "M"
              ? "Male"
              : ""}
          </p>
        </span>
      ) : null}
      {error?.length && !loading ? <p className="error">{error}</p> : null}
      <div style={{ marginBottom: "10px" }}>
        <InputField
          field="matricNumber"
          label="Matric Number"
          name="matricNumber"
          value={values.matricNumber}
          touched={touched}
          errors={errors}
          handleChange={handleChange}
        />
      </div>

      <div ref={containerRef} className="status-select">
        <label htmlFor="rejectionReason">De-Activation Reason</label>
        <Select
          onChange={handleSelectChange}
          id="rejectionReason"
          placeholder="REJECT REASONS"
          name="rejectionReason"
          options={sortedReasons}
          value={selectedOptions}
          styles={customStyles}
          components={{ DropdownIndicator }}
          isMulti
          onMenuOpen={handleMenuOpen}
          menuIsOpen={menuIsOpen}
          isDisabled={!studentProfile?.fullName && !matricNumber?.length}
        />
      </div>

      <div className="button-container">
        <SubmitButton
          onClick={handleSubmit}
          loading={submitting}
          disabled={submitting}
          buttonText="Submit"
          className="submit-btn"
        />
      </div>
    </Form>
  );
};

export default StudentStatusForm;
