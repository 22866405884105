import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import SearchBox from "../../../shared/components/searchbox";
import "./index.scss";
import ListOfUploads from "./ListOfUploads";
import { FaSortDown } from "react-icons/fa";
import Select, { components } from "react-select";
import { reasonsOptions } from "../../../shared/_helpers/constants";
import { LoadingItem } from "../../../shared/components/loading";
import {
  approveOrRejectDocument,
  getPendingClearanceRequests,
  submitEDocumentationReview,
  searchClearanceRequests,
} from "../../../redux/action/edocumentation";
import { toast } from "react-toastify";
import Done from "../../../assets/Done.png";
import Cancel from "../../../assets/Cancel.png";
import Pending from "../../../assets/Pending.png";
import Navigation from "../../../shared/components/navigation/Navigation";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSortDown style={{ color: "#000000" }} />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "90%",
    display: "flex",
    alignItems: "center",
    background: "#F1FFF9",
    borderColor: state.isFocused ? "#00A85B" : "#00A85B",
    boxShadow: state.isFocused ? "0 0 0 1px #00A85B" : "#00A85B",
    ":hover": {
      borderColor: "#00A85B",
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    marginRight: "2px",
  }),
  focus: () => ({
    borderColor: "#00A85B",
    boxShadow: "0 0 0 1px #00A85B",
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "90%",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    color: "black",
    ":hover": {
      backgroundColor: "#00A85B",
      color: "#F1FFF9",
    },
  }),
};

const DocumentsReviewContent = () => {
  const { pendingClearanceRequestsLoading, allPendingClearanceRequests } =
    useSelector((state: any) => state.edocumentationReducer);

  const [searchParameter, setSearchParameter] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentRequest, setCurrentRequest] = useState<any>({});
  const [submitReviewLoading, setSubmitReviewLoading] =
    useState<boolean>(false);

  const [loadingApprovalRequests, setLoadingApprovalRequests] =
    useState<boolean>(false);
  const [activeDocument, setActiveDocument] = useState<any>({});
  const [openRereview, setOpenRereview] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  let currentSession: any = localStorage.getItem("adXAuth");
  currentSession = JSON.parse(currentSession);
  const session = currentSession?.currentSession?.session;

  const handleApproveOrRejectDocument = async () => {
    setLoadingApprovalRequests(true);
    const rejectReasons = selectedOptions
      .map((option: { label: string; value: string }) => option?.value)
      .join(",");
    const vals = {
      documentType: activeDocument?.documentType,
      ...(rejectReasons?.length && { rejectReasons }),
      matricNumber: currentRequest?.matricNumber,
      session,
      studentId: currentRequest?.studentUniqueId,
    };
    const res = await dispatch(approveOrRejectDocument(vals));
    setLoadingApprovalRequests(false);
    if (res?.status === 200) {
      toast.success("Document review submitted");
      setSelectedOptions([]);
      dispatch(getPendingClearanceRequests(pageNumber, false));
      setShowDialog(false);
    }
  };

  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm action</h6>
      <p>
        Are you sure you want to {selectedOptions.length ? "reject" : "approve"}{" "}
        this document?
      </p>
      <div className="action-buttons">
        <Button
          className={!selectedOptions.length ? "success" : ""}
          variant={selectedOptions.length ? "danger" : "success"}
          onClick={handleApproveOrRejectDocument}
          disabled={loadingApprovalRequests}
        >
          {selectedOptions.length ? "Reject" : "Approve"}
          {loadingApprovalRequests ? (
            <Spinner animation="border" size="sm" />
          ) : null}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={loadingApprovalRequests}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );

  const handleNext = async () => {
    if (currentIndex < allPendingClearanceRequests?.items.length - 1) {
      setCurrentRequest(allPendingClearanceRequests?.items[currentIndex + 1]);
      setActiveDocument(
        allPendingClearanceRequests?.items[currentIndex + 1]
          ?.documentWithLink[0]
      );
      setCurrentIndex(currentIndex + 1);
    } else if (allPendingClearanceRequests?.hasNextPage) {
      setCurrentIndex(0);
      await dispatch(getPendingClearanceRequests(pageNumber + 1, false));
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePrevious = async () => {
    if (currentIndex > 0) {
      setCurrentRequest(allPendingClearanceRequests?.items[currentIndex - 1]);
      setActiveDocument(
        allPendingClearanceRequests?.items[currentIndex - 1]
          ?.documentWithLink[0]
      );
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(0);
      await dispatch(getPendingClearanceRequests(pageNumber - 1, false));
      setPageNumber(pageNumber - 1);
    }
  };
  const handleChange = (selectedOption: any) => {
    setSelectedOptions(selectedOption);
    setMenuIsOpen(true);
  };

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleSearch = useCallback(
    (searchParam: string, pageNumber: number) => {
      setCurrentIndex(0);
      dispatch(searchClearanceRequests(pageNumber, searchParam));
    },
    [dispatch]
  );

  const handleOuterClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (containerRef.current && !containerRef.current.contains(target)) {
      setMenuIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleOuterClick);
    return () => {
      document.removeEventListener("mousedown", handleOuterClick);
    };
  }, []);

  useEffect(() => {
    if (allPendingClearanceRequests?.items?.length) {
      setCurrentRequest(allPendingClearanceRequests?.items[currentIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPendingClearanceRequests]);

  useEffect(() => {
    if (allPendingClearanceRequests?.items?.length) {
      setActiveDocument(
        allPendingClearanceRequests?.items[currentIndex]?.documentWithLink[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPendingClearanceRequests]);

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      dispatch(getPendingClearanceRequests(1));
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch, dispatch]);

  useEffect(() => {
    dispatch(getPendingClearanceRequests(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openImage = (imageUrl: string) => {
    window.open(imageUrl, "_blank");
  };

  const allDocsApproved = useMemo(
    () =>
      currentRequest?.documentWithLink?.every(
        (doc: any) => doc.status === "APPROVED" || doc.status === "REJECTED"
      ),
    [currentRequest]
  );

  const submitReview = async () => {
    setSubmitReviewLoading(true);
    const values = {
      matricNumber: currentRequest?.matricNumber,
      session,
      studentId: currentRequest?.studentUniqueId,
    };
    const res = await dispatch(submitEDocumentationReview(values));
    if (res?.status === 200) {
      toast.success("Request review submitted");
      dispatch(getPendingClearanceRequests(pageNumber, false));
    }
    setSubmitReviewLoading(false);
  };

  const handleStudentClick = (student: any, index: number) => {
    setCurrentRequest(student);
    setActiveDocument(student?.documentWithLink[0]);
    setCurrentIndex(index);
  };
  return (
    <div className="e-documentation-review">
      <div className="back" style={{ marginTop: "20px" }}>
        <Navigation
          to="/app/e-documentation"
          text="Back to e-Documentation Dasbhoard"
        />
      </div>

      {pendingClearanceRequestsLoading ? (
        <LoadingItem />
      ) : !allPendingClearanceRequests?.items?.length ? (
        <h3 className="no-pending-requests">No pending clearance requests.</h3>
      ) : (
        <div>
          <div className="document-review-student-search">
            <SearchBox
              placeholder="Search matric number"
              setSearchParameter={setSearchParameter}
              searchParameter={searchParameter}
            />
          </div>
          <div className="students-navigation">
            <button
              onClick={handlePrevious}
              disabled={
                currentIndex === 0 &&
                !allPendingClearanceRequests?.hasPreviousPage
              }
            >
              PREVIOUS
            </button>
            <h4>
              {currentRequest?.fullName} &nbsp; {currentRequest?.matricNumber}
            </h4>
            <button
              onClick={handleNext}
              disabled={
                currentIndex ===
                  allPendingClearanceRequests?.items?.length - 1 &&
                !allPendingClearanceRequests?.hasNextPage
              }
            >
              NEXT
            </button>
          </div>
          {currentRequest?.documentWithLink?.length ? (
            <div className="status-definition">
              {" "}
              <div className="status-definition">
                <div>
                  <img src={Pending} alt="done" /> = Pending Approval
                </div>
                <div>
                  <img src={Done} alt="approved" /> = Approved
                </div>
                <div>
                  <img src={Cancel} alt="approved" /> = Rejected
                </div>
              </div>
            </div>
          ) : null}
          <ListOfUploads
            documents={currentRequest?.documentWithLink}
            setActiveDocument={setActiveDocument}
            activeDocument={activeDocument}
          />

          <div className="students-document">
            <div className="students-list">
              {allPendingClearanceRequests?.items?.map(
                (student: any, index: number) => (
                  <div
                    key={index}
                    className={
                      currentIndex === index
                        ? `active single-student`
                        : `single-student`
                    }
                    onClick={() => handleStudentClick(student, index)}
                  >
                    <p>{student?.fullName}</p>
                    <p className="department">
                      {student?.programme} ({student?.level} LEVEL)
                    </p>
                  </div>
                )
              )}
            </div>

            <div className="documents-review">
              <div className="documents">
                <h4>Click on the images to view enlarged image</h4>
                <div className="images">
                  {activeDocument?.frontLink ? (
                    <img
                      onClick={() => openImage(activeDocument?.frontLink)}
                      src={activeDocument?.frontLink}
                      alt="Document 1"
                    />
                  ) : null}
                  {activeDocument?.backLink ? (
                    <img
                      onClick={() => openImage(activeDocument?.backLink)}
                      src={activeDocument?.backLink}
                      alt="Document 2"
                    />
                  ) : null}
                </div>
              </div>
              {activeDocument?.status === "APPROVED" ||
              activeDocument?.status === "REJECTED" ? (
                <div className="re-review-check">
                  <label htmlFor="openRereview" className="checkbox-label">
                    <input
                      type="checkbox"
                      name="openRereview"
                      id="openRereview"
                      className="custom-checkbox"
                      onChange={(e) => {
                        setSelectedOptions([]);
                        setOpenRereview(e.target.checked);
                      }}
                    />
                    {!openRereview
                      ? "Click to re-review document."
                      : "Click to cancel docuemnt re-review."}
                  </label>
                </div>
              ) : null}
              <div className="review-section">
                {(activeDocument?.status === "APPROVED" ||
                  activeDocument?.status === "REJECTED") &&
                !openRereview ? null : (
                  <>
                    <div
                      ref={containerRef}
                      className="form-group form-dropdown"
                    >
                      <Select
                        onChange={handleChange}
                        id="rejectionReason"
                        placeholder="REJECT REASONS"
                        name="rejectionReason"
                        options={reasonsOptions}
                        value={selectedOptions}
                        styles={customStyles}
                        components={{ DropdownIndicator }}
                        isMulti
                        onMenuOpen={handleMenuOpen}
                        menuIsOpen={menuIsOpen}
                        isDisabled={
                          (activeDocument?.status === "APPROVED" ||
                            activeDocument?.status === "REJECTED") &&
                          !openRereview
                        }
                      />
                    </div>
                    <OverlayTrigger
                      trigger="click"
                      placement="left"
                      show={showDialog}
                      overlay={popover}
                      rootClose
                    >
                      <button
                        onClick={() => setShowDialog(true)}
                        disabled={
                          (activeDocument?.status === "APPROVED" ||
                            activeDocument?.status === "REJECTED") &&
                          !openRereview
                        }
                      >
                        {selectedOptions?.length
                          ? "REJECT DOCUMENT"
                          : "APPROVE DOCUMENT"}
                      </button>
                    </OverlayTrigger>{" "}
                  </>
                )}
              </div>
              {allDocsApproved ? (
                <>
                  <div className="submit-review-button">
                    <button onClick={submitReview}>
                      SUBMIT REVIEW{" "}
                      {submitReviewLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : null}
                    </button>
                  </div>
                  <p className="submit-note">
                    <strong>NOTE:</strong> Don't forget to click "SUBMIT REVIEW"
                    to finish the e-document review.
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsReviewContent;
