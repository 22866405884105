import React, { useState } from "react";
import {
  Button,
  OverlayTrigger,
  Popover,
  Spinner,
  Table,
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Pencil from "../../../../../assets/pencil.png";
import Delete from "../../../../../assets/delete.png";
import { deleteGradeSetting } from "../../../../../redux/action/results";
import { toast } from "react-toastify";
import "../index.scss";
import { IGradeSettingsTable } from "../../../../../utils/types";

const GradeSettingsTable: React.FC<IGradeSettingsTable> = ({
  loading,
  setShowModal,
  selectedGrade,
  setSelectedGrade,
  examGrades,
  setLoading,
  fetchExamGrades,
}: any) => {
  const [showDialog, setShowDialog] = useState(false);
  const [dialogueId, setDialogueId] = useState("");

  const handleDelete = async (uniqueId: string) => {
    setLoading(true);
    try {
      const res = await deleteGradeSetting(uniqueId);
      if (res.status === 200) {
        await fetchExamGrades();
        toast.success("Grade setting deleted successfully");
      }
    } catch (error) {
      toast.error("Error deleting grade setting");
    } finally {
      setLoading(false);
      setDialogueId("");
      setShowDialog(false);
    }
  };
  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <div>
        <p>
          Are you sure you want to delete the{" "}
          <p>
            <b>Grade {selectedGrade?.grade}</b> Settings?
          </p>
        </p>
      </div>

      <div className="action-buttons">
        <Button
          variant="danger"
          onClick={() => handleDelete(dialogueId)}
          disabled={loading}
        >
          Delete {loading ? <Spinner animation="border" size="sm" /> : null}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={loading}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );

  const sortedExamGrades = examGrades?.sort((a: any, b: any) =>
    a.grade.localeCompare(b.grade)
  );

  return (
    <div className="result-table">
      <Table bordered hover>
        <thead>
          <tr>
            <th>S/N</th>
            <th>Name</th>
            <th>Min Scores</th>
            <th>Max Scores</th>
            <th>Points</th>
            <th>Remark</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            [...Array(5)].map((_, index) => (
              <tr key={index}>
                {[...Array(7)].map((_, index) => (
                  <td key={index}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          ) : sortedExamGrades?.length > 0 ? (
            sortedExamGrades.map((data: any, index: number) => {
              return (
                <tr key={index} className="result-tr">
                  <td>{index + 1}</td>
                  <td>{data?.grade}</td>
                  <td>{data?.lowerLimit}</td>
                  <td>{data?.upperLimit}</td>
                  <td>{data?.gradePoint}</td>
                  <td
                    style={{
                      color: data?.remark === "PASS" ? "green" : "red",
                      fontWeight: "800",
                      fontSize: "12px",
                    }}
                  >
                    {data?.remark}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <img
                        src={Pencil}
                        alt="edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedGrade(data);
                          setShowModal(true);
                        }}
                      />
                      <OverlayTrigger
                        trigger="click"
                        placement="left"
                        show={
                          data?.examGradeSettingUniqueId === dialogueId &&
                          showDialog
                        }
                        overlay={popover}
                        rootClose
                      >
                        <img
                          src={Delete}
                          alt="delete"
                          onClick={() => {
                            setDialogueId(data?.examGradeSettingUniqueId);
                            setShowDialog(!showDialog);
                            setSelectedGrade(data);
                          }}
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            height: "28px",
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7} style={{ textAlign: 'center' }}>
                <h5>No grade setting available yet</h5>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default GradeSettingsTable;
