import { useState } from "react";
import { queryCbsInvoice } from "../../../redux/action/admission";
import { handleRequestErrors } from "../../../shared/utils";
import Navigation from "../../../shared/components/navigation/Navigation";
import InvoiceModal from "../../bursary-reports/invoice-checker/components/InvoiceModal";
import CbsInvoiceTable from "../../bursary-reports/invoice-checker/components/TransactionTable";


export interface CbsPayment {
  invoiceNumber: string;
  totalAmountPaid: number;
  amountPaid: any;
  transactionDate: any;
  generatedDate: string;
  recipient: string;
  status: string;
  expiryDate: string;
}

const InvoiceCheckerWrap = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState<CbsPayment | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
    const [isExpanded, setIsExpanded] = useState<any>(false);

  const initialValue = {
    invoiceNumber: "",
  };

  const queryCsbInvoiceHistory = async (invoiceNumber: string) => {
    setLoading(true);
    try {
      const response = await queryCbsInvoice(invoiceNumber);
      if (response.status === 200) {
        setPaymentData(response.data);
        setErrorMsg("");
   
      }
    } catch (error: any) {
      if (error.response?.status >= 400) {
        setErrorMsg(error.response.data);
      } else {
        handleRequestErrors(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-history">
         <div className="back-to-portal">
        <Navigation to="/app/portal-settings" text="Back to Portal Settings" />
      </div>
      <InvoiceModal
        queryCsbInvoiceHistory={queryCsbInvoiceHistory}
        initialValue={initialValue}
        setIsExpanded={setIsExpanded}
      />
      {errorMsg && (
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            color: "red",
            width: "70%",
          }}
        >
          {errorMsg}
        </div>
      )}
      {!errorMsg && paymentData?.recipient ? (
        <div className="payment-table">
          <CbsInvoiceTable
           loading={loading} 
           paymentData={paymentData}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          />
        </div>
      ) : null}
    </div>
  );
};

export default InvoiceCheckerWrap;
