import React, { useEffect, useState } from "react";
import Navigation from "../../../../shared/components/navigation/Navigation";
import ScoreSettingsTable from "./components/ScoreSettingsTable";
import { getMaxExamScores } from "../../../../redux/action/results";
import { IMaxScore } from "../../../../utils/types";
import { toast } from "react-toastify";
import MaxScoreSettingsModal from "./components/ScoreSettingsModal";
import AddButton from "../../../../shared/components/download-components/AddButton";

const initialValues: IMaxScore = {
  examMaxScoreSettingUniqueId: "",
  caMaxScore: undefined as unknown as number,
  examMaxScore: undefined as unknown as number,
  totalMaxScore: undefined as unknown as number,
};

const ScoreSettings: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedScore, setSelectedScore] = useState<IMaxScore >(initialValues);
  const [maxScores, setMaxScores] = useState<IMaxScore[]>([]);

  const fetchMaxScore = async () => {
    setLoading(true);
    try {
      const response = await getMaxExamScores();
      if (response.status === 200) {
        setMaxScores(response.data);
      }
    } catch (error) {
      toast.error("Failed to fetch score settings");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMaxScore();
  }, []);

  return (
    <div className="settings-wrap">
      <div className="back">
        <Navigation to="/app/results/settings" text="Back to Result Settings" />
      </div>

      <div className="add-grade">
      <AddButton
       onClick={() => {
        setShowModal(true);
      }}
       buttonText= "Add Score"
       variant= "light"
        />
        {/* <button className="add-btn" onClick={() => setShowModal(true)}>
          Add Score
        </button> */}
      </div>
      <ScoreSettingsTable
        loading={loading}
        setLoading={setLoading}
        setShowModal={setShowModal}
        setSelectedScore={setSelectedScore}
        maxScores={maxScores}
        fetchMaxScore={fetchMaxScore}
 
      />
      <MaxScoreSettingsModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedScore={selectedScore}
        setSelectedScore={setSelectedScore}
        initialValues={initialValues}
        fetchMaxScore={fetchMaxScore}
   
      />
    </div>
  );
};

export default ScoreSettings;