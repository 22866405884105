import { Button } from "react-bootstrap";
import "./index.scss";
import { MdAddCircle} from "react-icons/md";

type AddButtonType = {
  onClick: () => void;
  buttonText: string;
  className?: any;
  iconStyles?: any;
  variant?: "light" | "dark";
  disabled?: boolean
};

// const variantClasses = {
//   light: {
//     backgroundColor: '#ffff',
//     color: '#00a85b',
//   },
//   dark :  {
//     backgroundColor: '#00a85b',
//     color: 'ffff',
//   },

// }

const AddButton = ({
  onClick,
  buttonText,
  className,
  iconStyles,
  disabled,
}: AddButtonType) => {

  // const variantStyle:any = variant === "light" ?
  // variantClasses.light : variant === "dark" ? variantClasses.dark : "";


  return (
    <div className="admsn-download-btn">
      <Button
        className={`adm-button ${className}`}
        onClick={onClick}
        type="submit"
        disabled={disabled}
      >
    
            {buttonText}
            <MdAddCircle className= "btn-logo" style= {iconStyles}  />
      
      </Button>
     </div>
  );
};

export default AddButton;
