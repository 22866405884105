import Done from "../../../assets/Done.png";
import Rejected from "../../../assets/Cancel.png";
import Pending from "../../../assets/Pending.png";
const ListOfUploads = ({
  documents,
  activeDocument,
  setActiveDocument,
}: any) => {
  return (
    <div className="docs-list">
      {documents?.map((document: any) => (
        <div
          className={
            activeDocument?.documentType === document?.documentType
              ? "active-document document-type-update"
              : "document-type-update"
          }
          key={document?.documentType}
          onClick={() => setActiveDocument(document)}
        >
          <p>{document?.documentType}</p>
          <img
            src={
              document.status === "APPROVED"
                ? Done
                : document.status === "REJECTED"
                ? Rejected
                : Pending
            }
            alt="done"
          />
        </div>
      ))}
    </div>
  );
};

export default ListOfUploads;
