import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import "./index.scss";
import { MdDownload } from "react-icons/md";

type DownloadButtonType = {
  onClick?: () => void;
  loading?: boolean;
  buttonText: string;
  className?: any;
  styles?: any; 
  href?: string; 
  disabled?: boolean; 
  target?: "_blank" | "_self" | "_parent" | "_top" | string;
  rel?: string; 
};
const DownloadButton = ({
  onClick,
  loading,
  buttonText,
  className,
  styles,
  href,
  disabled,
  target= "_blank",
  rel="noreferrer"
}: DownloadButtonType) => {
  return (
    <div className="admsn-download-btn">
      <Button
        className={`adm-button ${className}`}
        onClick={onClick}
        type="submit"
        disabled={disabled}
        href={href} 
         rel={rel}
         target={target}
      >

        {loading ? (
          <div className="loading-spiner">
            <DotLoader size={20} color="rgb(14, 47, 119)"  />
             <span style={{marginLeft:"10px"}}>Downloading...</span>
          </div>
        ) : (
          <span>
            {buttonText}
            <MdDownload className= "btn-logo" style= {styles} />
          </span>
        )}
      </Button>
    </div>
  );
};

export default DownloadButton;
