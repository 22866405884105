import  {  useState } from 'react'
import TransactionTable from './components/TransactionTable'
import { allPaymentHistory } from '../../../redux/action/admission';
import SearchModal from './components/SearchModal';
import { handleRequestErrors } from '../../../shared/utils';
import "./index.scss"
import Navigation from '../../../shared/components/navigation/Navigation';

interface Payment {
  matricNumber: string;
  fullName: string;
  invoiceNumber: string;
  paymentType: string;
  amountPaid: string;
  session: string;
  generatedOn: string;
  paymentDate: string;
  paymentStatus: string;
}

const TransactionHistory = () => {
 const [loading, setLoading] = useState(false);
 const [paymentList, setPaymentList] = useState<Payment[]>([]);
 const [hasSearched, setHasSearched] = useState(false);

 const initialValue = {
  MatricNumber : ""
}

const fetchPaymentHistory = async (matricNumber: string) => {
  setLoading(true);
  try {
    const response = await allPaymentHistory(matricNumber);
    if (response.status === 200) {
      setPaymentList(response.data);
    }
  } catch (err) {
    handleRequestErrors(err);
  } finally {
    setLoading(false);
    setHasSearched(true)
  }
};

  
  return (
    <div className='payment-history'>
      <div className="back-to-portal">
      <Navigation to="/app/bursary-reports"  text = "Back to Bursary Report"/>
      </div>
      <SearchModal
      fetchPaymentHistory={fetchPaymentHistory}
      initialValue={initialValue}
      />
 {( paymentList.length > 0 ) ?(
  <div className="payment-table">
    <TransactionTable
      loading={loading}
      setLoading={setLoading}
      paymentList= {paymentList}
      />
  </div>)  : (
        !loading && hasSearched && <h5>No payment has been made yet...</h5>
      )}
  
    </div>
  )
}

export default TransactionHistory
