import React from "react";
import { InAppTemplate } from "../../../shared/templates/portal";
import HostelReports from "./HostelReports";
import { ToastContainer } from "react-toastify";

const HostelReportWrap = () => {
  return (
    <>
     <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ marginTop: "20px" }}
        />
    
    <InAppTemplate
      pageTitle="HOSTEL FEES PAYMENT"
      childComponent={<HostelReports />}
    />
    </>
  );
};

export default HostelReportWrap;
