import React from "react";
import { Form, Formik, FormikProps } from "formik";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import InputField from "../../../../../shared/components/InputField";
import * as Yup from "yup";
import { addOrUpdateMaxExamScores } from "../../../../../redux/action/results";
import { IMaxScoreSettingsModal, IMaxScore } from "../../../../../utils/types";
import ModalFooterButtons from "../../../../../shared/components/download-components/ModalFooterButtons";

const MaxScoreSettingsModal: React.FC<IMaxScoreSettingsModal> = ({
  showModal,
  setShowModal,
  selectedScore,
  setSelectedScore,
  initialValues,
  fetchMaxScore,
}) => {
  const validationSchema = Yup.object().shape({
    caMaxScore: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Value cannot be negative")
      .max(100, "Value cannot be more than 100"),
    examMaxScore: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Value cannot be negative")
      .max(100, "Value cannot be more than 100"),
    totalMaxScore: Yup.number()
      .typeError("Must be a number")
      .required("Required")
      .min(0, "Value cannot be negative")
      .max(100, "Value cannot be more than 100")
      .test(
        "match-sum",
        "The sum of CA and Exam maximum score must equal 100.",
        function (value) {
          const { caMaxScore, examMaxScore } = this.parent;
          return value === caMaxScore + examMaxScore;
        }
      ),
  });

  const handleSubmit = async (values: IMaxScore) => {
    try {
      const response = await addOrUpdateMaxExamScores(values);
      if (response.status === 200) {
        toast.success(
          selectedScore
            ? "Settings updated successfully"
            : "Settings added successfully"
        );
        await fetchMaxScore();
        setShowModal(false);
        setSelectedScore(initialValues);
      } else {
        toast.error("Failed to update score settings");
      }
    } catch (error) {
      toast.error("Failed to update score settings");
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setSelectedScore(initialValues);
      }}
      className="records_modal_wrap"
      size="lg"
      contentClassName="records_modal_content"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedScore
            ? "UPDATE MAX SCORE SETTINGS"
            : "ADD MAX SCORE SETTINGS"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="records-modal-form">
        <Formik
          initialValues={selectedScore}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
          }}
        >
          {(props: FormikProps<IMaxScore>) => {
            const { values, touched, errors, handleChange, isSubmitting } =
              props;
            return (
              <Form>
                <Row>
                  <Col xs={12} md={6}>
                    <InputField
                      field="caMaxScore"
                      label="CA CAP"
                      value={values.caMaxScore}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <InputField
                      field="examMaxScore"
                      label="EXAM CAP"
                      value={values.examMaxScore}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <InputField
                      field="totalMaxScore"
                      label="TOTAL CAP"
                      value={values.totalMaxScore}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
                
                  <ModalFooterButtons
                    onCancel={() => {
                      setShowModal(false);
                      setSelectedScore(initialValues);
                    }}
                    isSubmitting={isSubmitting}
                    submitButtonText={selectedScore ? "Update" : "Submit"}
                  />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default MaxScoreSettingsModal;
