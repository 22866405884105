import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import InputField from "../../../../shared/components/InputField";
import DropDown from "../../../../shared/components/dropdown";
import { DotLoader } from "react-spinners";
import { handleRequestErrors } from "../../../../shared/utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getStudentProfile } from "../../../../redux/action/admission";
import { addOrUpdateSpecificDocumentTypesForStudent } from "../../../../redux/action/edocumentation";
import { adminInAppActions } from "../../../../redux/action/portal";

interface AdditionalDocumentModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchStudentsAdditionalDocuments: any;
  selectedDocumentType: any;
  setSelectedDocumentType: (values: Student) => void;
  initialDocumentnfo: Student;
}

export interface Student {
  eDocumentationDocsUniqueId: string;
  matricNumber: string;
  documentName: string;
  session: string;
  fullName: string;
}

const AdditionalDocumentModal: React.FC<AdditionalDocumentModalProps> = ({
  show,
  setShow,
  fetchStudentsAdditionalDocuments,
  selectedDocumentType,
  setSelectedDocumentType,
  initialDocumentnfo,
}) => {
  const adminCurrentSession = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetCurrentSessionReducer
  );
  const dispatch = useDispatch();
  const fetchCurrentSession = () => {
    dispatch(adminInAppActions.AdminGetCurrentSession(""));
  };

  useEffect(() => {
    fetchCurrentSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schoolSession =
    adminCurrentSession?.request_data?.response?.data?.items ?? [];
  const session = schoolSession.find(
    (item: any) => item?.type === "SCHOOL"
  )?.session;

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
        setSelectedDocumentType(initialDocumentnfo);
      }}
      contentClassName="additional-payment-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectedDocumentType?.eDocumentationDocsUniqueId
            ? "Update Document type"
            : "Add Document type"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="student-modal-form">
        <Formik
          initialValues={selectedDocumentType}
          //   validationSchema={}
          onSubmit={async (values) => {}}
        >
          {(formikProps: FormikProps<any>) => (
            <AdditionalDocumentForm
              formikProps={formikProps}
              setShow={setShow}
              fetchStudentsAdditionalDocuments={
                fetchStudentsAdditionalDocuments
              }
              selectedDocumentType={selectedDocumentType}
              session={session}
            />
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const AdditionalDocumentForm: React.FC<{
  formikProps: FormikProps<any>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchStudentsAdditionalDocuments: any;
  session: string;
  selectedDocumentType: any;
}> = ({
  formikProps,
  setShow,
  fetchStudentsAdditionalDocuments,
  session,
  selectedDocumentType,
}) => {
  const initialStudentValue = {
    eDocumentationDocsUniqueId: "",
    matricNumber: "",
    documentName: "",
    fullName: "",
    session,
  };
  const { values, touched, errors, handleChange, setFieldValue } = formikProps;

  const matricNumber = values.matricNumber;
  const [student, setStudent] = useState<Student>({
    ...values,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handleGetStudentProfile = async (matricNo: string) => {
    setStudent(values);
    setLoading(true);
    setError("");
    await getStudentProfile(matricNo)
      .then((data) => {
        setLoading(false);
        if (data?.data?.studentUniqueId) {
          const { matricNumber, fullName } = data?.data;
          setStudent((prevState) => ({
            ...prevState,
            matricNumber,
            fullName,
          }));
        }
      })
      .catch((err: any) => {
        setLoading(false);
        handleRequestErrors(err);
        if (err?.response?.status === 404) {
          setError(err?.response?.data);
          setStudent(initialStudentValue);
        }
      });
  };
  useEffect(() => {
    setStudent({
      ...student,
      documentName: values.documentName,
      matricNumber: values?.matricNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 500);
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matricNumber]);

  const handleSubmit = async () => {
    if (!student?.matricNumber || !student?.documentName) {
      return setError("Enter valid matric number and document name.");
    }
    const { fullName, ...rest } = student;

    setSubmitting(true);
    await addOrUpdateSpecificDocumentTypesForStudent(rest)
      .then(() => {
        setSubmitting(false);
        toast.success(
          student?.eDocumentationDocsUniqueId
            ? "Document type updated successfully"
            : "Document type added successfully"
        );
        setShow(false);
        fetchStudentsAdditionalDocuments(1);
      })
      .catch((err: any) => {
        setSubmitting(false);
        handleRequestErrors(err);
        toast.error("An error occured, please try again.");
      });
  };

  return (
    <Form>
      {loading ? (
        <p className="student-name">Searching student...</p>
      ) : !loading && student?.fullName && matricNumber?.length ? (
        <p className="student-name">Name: {student?.fullName}</p>
      ) : null}
      {error?.length && !loading ? <p className="error">{error}</p> : null}
      <InputField
        field="matricNumber"
        label="Matric Number"
        name="matricNumber"
        value={values.matricNumber}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
      />
      <DropDown
        width="100%"
        name="session"
        field="session"
        label="Session"
        setFieldValue={setFieldValue}
        options={[
          {
            value: session,
            label: session,
          },
        ]}
        value={{
          value: session,
          label: session,
        }}
        touched={touched}
        errors={errors}
      />

      <InputField
        field="documentName"
        label="Document Type"
        name="documentName"
        value={values.documentName}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
      />

      <div className="button-container">
        <Button className="submit-btn" onClick={handleSubmit}>
          {submitting ? "Submitting..." : "Submit"}
          {submitting ? (
            <DotLoader
              color="white"
              loading={submitting}
              size={30}
              aria-label="Submitting"
            />
          ) : null}
        </Button>
      </div>
    </Form>
  );
};

export default AdditionalDocumentModal;
