import { useEffect, useState } from "react";
import {
  E_DOCUMENTATION_MENU_LIST,
  E_DOCUMENTATION_STATISTICS,
} from "../../shared/_helpers/constants";
import Card from "../../shared/components/dashboard-cards/Card";
import StatisticsCard from "../../shared/components/dashboard-statistics-card";
import { getEdocumentationStats } from "../../redux/action/edocumentation";
import { handleRequestErrors } from "../../shared/utils";
import Dash1 from "../../assets/dash-1.png";
import { getPostUtmeSession } from "../../redux/action/putme";


const EDocumentationDashboard = () => {
const [docStats, setDocStats] = useState([])
const [session, setSession] = useState<string>("")


  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  const userRoles: string[] = loggedAccountInfo?.profile?.userRoles || [];

 useEffect(() =>  {
  const getPutmeSession = async()=>{
    try {
    const response = await getPostUtmeSession()
    if (response.status === 200) {
    setSession(response?.data.session)
  } 
    } catch (error) {
      handleRequestErrors(error);
  
  }}
  getPutmeSession()
 }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getEdocumentationStats();
        if (response.status === 200) {
          setDocStats(response?.data);
        }
      } catch (error) {
        handleRequestErrors(error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="menu-container">
        {E_DOCUMENTATION_MENU_LIST.map((item: any, index: number) =>
         ( !userRoles.includes("Admin") &&
          item.title.toLowerCase() === "settings") ||  userRoles.includes("Bursary") ? null : (
            <Card
              key={index}
              title={item.title}
              buttonText={item.buttonText}
              icon={item.icon}
              backgroundColor={item.backgroundColor}
              routeTo={item.routeTo}
            />
          )
        )}
      </div>


      <div className="dashboard_stats">
      <div className="tab1_container">
      <div className="top_container">
              <div
                className="top_stat"
                style={{ backgroundColor: "green" }}
              >
                <div className="top_icon">
                  <img src={Dash1} alt="" />
                </div>
                <div className="top_values">
                  <div className="stat">CURRENT E-DOCUMENTATION SESSION</div>
                <div className="vaalue"> <h2>{session}</h2> </div>
                </div>
              </div>
            </div>

        <div className="tab-container">
          {E_DOCUMENTATION_STATISTICS.map((items: any, index: number) => 
           !userRoles.some(role => ["Admin", "Bursary"].includes(role)) ? null : (
              <StatisticsCard
                key={index}
                itemName={items.title}
                color={items.backgroundColor}
                count= {docStats[items.data]}
              />
             )  )
          }
        </div>
        </div>
      </div>
    </>
  );
};

export default EDocumentationDashboard;
