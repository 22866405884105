import TransferManagementModal from './TransferManagement';
import { InAppTemplate } from '../../../shared/templates/portal';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { loadAllProgrammes, loadFacultyAndDept, } from '../../../redux/action/admission';
import { ToastContainer } from 'react-toastify';

const TransferManagementWrapper = () => {

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(loadFacultyAndDept());
      dispatch(loadAllProgrammes());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />

    <InAppTemplate
      pageTitle="STUDENT TRANSFER MANAGEMENT"
      childComponent={<TransferManagementModal  />}
    />
</>
  );
};

export default TransferManagementWrapper;
