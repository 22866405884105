import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Form, FormikProps } from "formik";
import InputField from "../../../shared/components/InputField";
import DropDown from "../../../shared/components/dropdown";
import "./index.scss";
import {
  getStudentProfile,
  generateOtherFeeInvoice,
} from "../../../redux/action/admission";
import { handleRequestErrors } from "../../../shared/utils";
import { toast } from "react-toastify";
import { DotLoader } from "react-spinners";
import { additionalFees } from "../../../shared/_helpers/constants";
import { optionTypes } from "../../../types";

export interface OtherFeeInvoice {
  fullName?: string;
  matricNumber: string;
  level: string;
  session: string;
  paymentType: number;
}
const OtherFeeInvoiceForm: React.FC<{
  formikProps: FormikProps<any>;
  session: string;
}> = ({ formikProps, session }) => {
  const initialStudentValue = {
    fullName: "",
    studentId: "",
    matricNumber: "",
    level: "",
    session: "",
    paymentType: 0,
  };
  const {
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
  } = formikProps;
  const matricNumber = values.matricNumber;
  const [student, setStudent] = useState<OtherFeeInvoice>({
    ...initialStudentValue,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [downloadUrl, setDownloadUrl] = useState<string>("");

  const handleGetStudentProfile = async (matricNo: string) => {
    setStudent(initialStudentValue);
    setLoading(true);
    setError("");
    await getStudentProfile(matricNo)
      .then((data) => {
        setLoading(false);
        if (data?.data?.studentUniqueId) {
          const { studentUniqueId: studentId, fullName } = data?.data;
          setStudent((prevState) => ({
            ...prevState,
            studentId,
            fullName,
          }));
        }
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        if (err?.response?.status === 404) {
          setError(err?.response?.data);
        } else if (err?.response?.status === 400) {
          setError(err?.response?.data);
          setStudent(initialStudentValue);
        }
        handleRequestErrors(err);
      });
  };

  useEffect(() => {
    setStudent({
      ...student,
      matricNumber: values?.matricNumber,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 500);
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matricNumber]);

  const DownloadLink = ({ url }: any) => (
    <a href={url} className="download_invoice" target="_blank" rel="noreferrer">
      Download Invoice
    </a>
  );
  const handleSubmit = async () => {
    if (!student?.fullName || !values?.level || !values?.session) {
      return setError("All fields are required");
    }
    let payload = {
      matricNumber: values?.matricNumber,
      level: values?.level,
      session: values?.session,
      paymentType: parseInt(values?.paymentType),
    };

    setSubmitting(true);

    setError("");
    await generateOtherFeeInvoice(payload)
      .then((res: any) => {
        if (res?.data?.downloadUrl) {
          setSubmitting(false);
          setDownloadUrl(res?.data?.downloadUrl);
        } else {
          setError("No invoice available.");
          setSubmitting(false);
        }
      })
      .catch((err: any) => {
        setSubmitting(false);
        handleRequestErrors(err);
        toast.error("An error occured, please try again.");
      });
  };

  return (
    <Form>
      <div className="tuition-form-wrap">
        <div className="form-inputs">
          {loading ? (
            <p className="student-name">Searching student...</p>
          ) : !loading && student?.fullName && matricNumber?.length ? (
            <p className="student-name">Name: {student?.fullName}</p>
          ) : null}
          {error?.length && !loading ? <p className="error">{error}</p> : null}
          <InputField
            field="matricNumber"
            label="Matric Number"
            name="matricNumber"
            value={values.matricNumber}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
          />
          <DropDown
            width="100%"
            name="session"
            field="session"
            label="Session"
            setFieldValue={setFieldValue}
            options={session}
            value={{
              value: values?.session,
              label: values?.session,
            }}
            touched={touched}
            errors={errors}
          />
          <DropDown
            width="100%"
            label="Level"
            touched={touched}
            errors={errors}
            value={{
              value: values.level,
              label: values.level,
            }}
            options={[
              { value: "100", label: "100" },
              { value: "200", label: "200" },
              { value: "300", label: "300" },
              { value: "400", label: "400" },
              { value: "500", label: "500" },
              { value: "600", label: "600" },
            ]}
            field="level"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />

          <DropDown
            width="100%"
            name="paymentType"
            label="Payment Type"
            touched={touched}
            errors={errors}
            value={
              additionalFees.find(
                (fee) => fee.value === values.paymentType
              ) || {
                value: "",
                label: "",
              }
            }
            options={additionalFees}
            field="paymentType"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onChange={(option: optionTypes) => {
              setFieldValue("paymentType", option?.value);
            }}
          />

          <div className="button-container">
            <Button className="submit-btn" onClick={handleSubmit}>
              {submitting ? "Submitting..." : "Submit"}
              {submitting ? (
                <DotLoader
                  color="white"
                  loading={submitting}
                  size={30}
                  aria-label="Submitting"
                />
              ) : null}
            </Button>
          </div>
          {downloadUrl && !submitting && <DownloadLink url={downloadUrl} />}
        </div>
      </div>
    </Form>
  );
};

export default OtherFeeInvoiceForm;
