import InvoiceCheckerWrap from "./InvoiceCheckerWrap";
import { InAppTemplate } from "../../../shared/templates/portal";

const InvoiceChecker = () => {
  return (
    <InAppTemplate
      pageTitle="CBS INVOICE STATUS"
      childComponent={<InvoiceCheckerWrap />}
    />
  );
};

export default InvoiceChecker;
