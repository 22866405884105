import { InAppTemplate } from "../../../shared/templates/portal";
import InvoiceCheckerWrap from "./InvoiceCheckerWrap";

const CbsInvoiceQuery = () => {
  return (
    <InAppTemplate
      pageTitle="CBS INVOICE STATUS"
      childComponent={<InvoiceCheckerWrap />}
    />
  );
};

export default CbsInvoiceQuery;
