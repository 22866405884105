import React, { useEffect, useState } from "react";
import { handleRequestErrors } from "../../../shared/utils";
import Dash1 from "../../../assets/dash-1.png";
import { TotalHostelStatisticsTabs } from "../../../shared/_helpers/constants";
import HostelCard from "./HostelCard";
import { getHostelStats } from "../../../redux/action/hostels";
import StatisticsCard from "../../../shared/components/dashboard-statistics-card";

const HostelDashboard = () => {
  const [totalStat, setTotalStat] = useState<any>({});
  const [hostelStat, setHostelStat] = useState<any[]>([]);

  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  const session = loggedAccountInfo?.currentSession?.session;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHostelStats();

        if (response.status === 200) {
          setTotalStat(response?.data);
          setHostelStat(response?.data.sitesPayment);
        }
      } catch (error) {
        handleRequestErrors(error);
      }
    };
    fetchData();
  }, []);

  const hostelStatisticsTabs = hostelStat.flatMap((site: any) => [
    {
      title: `${site.siteName}`,
      description: "TOTAL INVOICES GENERATED",
      data: site.totalInvoice,
    },
    {
      title: `${site.siteName}`,
      description: "TOTAL INVOICES PAID ",
      data: site.totalPaidInvoices,
    },
    {
      title: `${site.siteName}`,
      description: "TOTAL INVOICES PENDING ",
      data: site.totalPendingInvoices,
    },
  ]);


  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div>
      <div className="dashboard_stats">
        <div className="tab1_container">
          <div className="top_container">
            <div className="top_stat" style={{ backgroundColor: "green" }}>
              <div className="top_icon">
                <img src={Dash1} alt="" />
              </div>
              <div className="top_values">
                <div className="stat">CURRENT HOSTEL SESSION</div>
                <div className="vaalue">
                  <h2>{session}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-wrapper">
            <HostelCard />
          </div>

          <div className="tab-container">
            {TotalHostelStatisticsTabs.map((items: any, index: number) => (
              <StatisticsCard
                key={index}
                itemName={items.title}
                color={items.backgroundColor}
                count={totalStat[items?.data]}
              />
            ))}
          </div>

          <div className="tab-container">
            {hostelStatisticsTabs.map((items: any, index: number) => {
               const randomColor = getRandomColor();
          return   (  <StatisticsCard
                key={index}
                itemName={items.title}
                description={items.description}
                color={randomColor}
                count={items.data}
              />)
              })}
          </div>

         
        </div>
      </div>
    </div>
  );
};

export default HostelDashboard;
