import { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import "./index.scss";
import InputField from "../../../shared/components/InputField";
import DropDown from "../../../shared/components/dropdown";
import { addOrEditRoom } from "../../../redux/action/hostels";
import { DotLoader } from "react-spinners";
import { getPaginatedHostelSiteBlock } from "../../../redux/action/hostels";

type Payload = {
  blockUniqueId: string;
  hostelSite: string;
  blockName: string;
  gender: string;
  amount: number;
};

const SettingsModal = ({
  show,
  setShow,
  fetchedData,
  initialValues,
  addInfo,
  setAddInfo,
}: any) => {
  const [loading, setLoading] = useState(false);

  let checkValidationSchema = Yup.object().shape({
    siteName: Yup.string().required("Required"),
    blockName: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });
  return (
    <div>
      <Modal
        show={show}
        contentClassName="students-upload-modal"
        onHide={() => {
          setShow(false);
          setAddInfo(initialValues);
        }}
        aria-labelledby="
        contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <span> SETTINGS </span>
          <Modal.Header closeButton></Modal.Header>
        </div>

        <Modal.Body className="student-modal-form-comp">
          <Container>
            <Formik
              initialValues={addInfo}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                setLoading(true);
                const { siteName, amount, blockName, blockUniqueId, gender } =
                  values;

                let payload: Payload = {
                  blockUniqueId,
                  hostelSite: siteName,
                  blockName,
                  gender,
                  amount,
                };
                const res = await addOrEditRoom(payload);
                setLoading(false);
                if (res.status === 200) {
                  setAddInfo(initialValues);
                  setShow(false);
                  fetchedData(1)
                }
              }}
            >
              {(props: FormikProps<any>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                } = props;
                return (
                  <>
                    <Form>
                      <div className="settings">
                        <InputField
                          field="siteName"
                          label="Site Name"
                          value={values.siteName}
                          touched={touched}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>

                      <div className="settings">
                        <InputField
                          field="blockName"
                          label="Block Name"
                          value={values?.blockName}
                          touched={touched}
                          git
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>

                      <div
                        className="settings"
                        style={{ marginBottom: "10px" }}
                      >
                        <DropDown
                          label="Gender"
                          touched={touched}
                          errors={errors}
                          options={[
                            { label: "Male", value: "M" },
                            { label: "Female", value: "F" },
                          ]}
                          field="gender"
                          value={{
                            value: values?.gender,
                            label:
                              values?.gender === "F"
                                ? "Female"
                                : values?.gender === "M"
                                ? "Male"
                                : "",
                          }}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          width={"100%"}
                        />
                      </div>

                      <div className="settings">
                        <InputField
                          field="amount"
                          label="Amount"
                          value={values?.amount}
                          touched={touched}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>

                      <div className="modal-footer">
                        <Button
                          className="submit-btn"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <DotLoader
                              color="white"
                              loading={loading}
                              size={30}
                              aria-label="Submitting"
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SettingsModal;
