import { useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import ReactPaginate from "react-paginate";
import Navigation from "../../../../shared/components/navigation/Navigation";
import { handleRequestErrors } from "../../../../shared/utils";
import SearchBox from "../../../../shared/components/searchbox";
import BlackListModal from "./BlackListModal";
import HostelBlackListTable from "./BlackListTable";
import { getAllBlackListStudent, searchBlackListStudent } from "../../../../redux/action/hostels";
import AddButton from "../../../../shared/components/download-components/AddButton";

const BlackListedStudent = () => {
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [blackList, setBlackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const pageCount = Math.ceil(totalCount / 20);
  const fetchBlacklistedStudent = async (
    pageNumber: number,
    showLoading: boolean = false
  ) => {
    showLoading && setLoading(true);
    await getAllBlackListStudent(pageNumber)
      .then(({ data }) => {
        setBlackList(data?.items);
        setTotalCount(data?.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        handleRequestErrors(err);
      });
  };

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    fetchBlacklistedStudent(newOffset);
  };

  const handleSearch = useCallback(
    async (searchParam: any, pageNumber: number) => {
      await searchBlackListStudent(searchParam, pageNumber)
        .then(({ data }) => {
          setBlackList(data?.items);
          setTotalCount(data?.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          handleRequestErrors(err);
        });
    },
    []
  );

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      fetchBlacklistedStudent(1, true);
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch]);

  return (
    <div className="additional-payment-contaianer">
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <div className="back-to-portal">
        <Navigation
          to="/app/hostel/settings"
          text="Back to Accommodation Settings"
        />
      </div>
      <div className="settings_header">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />

        <AddButton
          onClick={() => setShowModal(true)}
          buttonText="Add"
        />
      </div>
      <HostelBlackListTable
        items={blackList}
        loading={loading}
        fetchBlacklistedStudent={fetchBlacklistedStudent}
      />
      {blackList?.length ? (
        <div className="result-count">
          <p>
            Showing {blackList?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <BlackListModal
        show={showModal}
        setShow={setShowModal}
        fetchBlacklistedStudent={fetchBlacklistedStudent}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

export default BlackListedStudent;
