import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "../index.scss";
import {
  addOrUpdateExamScores,
  getExamsGrade,
} from "../../../../redux/action/results";
import { toast } from "react-toastify";
import {
  GradeSetting,
  RegisteredList,
  UploadScoreTableProps,
} from "../../../../utils/types";
import SubmitButton from "../../../../shared/components/download-components/SubmitButton";

const UploadScoreTable: React.FC<UploadScoreTableProps> = ({
  registeredList,
  loading,
  fetchRegisteredStudents,
  paginationData,
  itemPerPage,
  currentPage,
  disableButton,
  setDisableButton
}) => {
  const [scores, setScores] = useState<RegisteredList[]>(registeredList);
  const [tableScore, setTableScore] =  useState<RegisteredList[]>(registeredList);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [gradeSettings, setGradeSettings] = useState<GradeSetting[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setScores(registeredList);
    setTableScore(paginationData);
  }, [registeredList, paginationData]);

  useEffect(() => {
    const fetchGradeSettings = async () => {
      try {
        const response = await getExamsGrade();
        if (response.status === 200) {
          setGradeSettings(response.data);
        }
      } catch (error) {}
    };
    fetchGradeSettings();
  }, []);

  const calculateGrade = (totalScore: number): string => {
    const setting = gradeSettings.find(
      (grade) =>
        totalScore >= grade.lowerLimit && totalScore <= grade.upperLimit
    );
    return setting ? setting.grade : "";
  };

  const validateScore = (name: string, value: string): boolean => {
    const numValue = Number(value);
    
    if (value === "") return true; 
    
    if (isNaN(numValue)) {
      return false;
    }
    
    if (name === "caScore" && numValue > 30) {
      return false;
    }
    
    if (name === "examScore" && numValue > 70) {
      return false;
    }
    
    return true;
  };

  const handleScoreChange = async (
    index: number,
    name: keyof RegisteredList,
    value: string
  ) => {
    if (!validateScore(name, value) && value !== "") {
      setErrors({
        ...errors,
        [`${name}-${index}`]: name === "caScore" 
          ? "CA Score must be less than or equal 30" 
          : "Exam Score must be less than or equal 70"
      });
      return;
    } else {
      const newErrors = {...errors};
      delete newErrors[`${name}-${index}`];
      setErrors(newErrors);
    }

    const newScores = [...tableScore];
    const updatedScore = value === "" ? "" : Number(value);

    const { caScore = "", examScore = "" } = newScores[index];
    const newCaScore = name === "caScore" ? updatedScore : caScore;
    const newExamScore = name === "examScore" ? updatedScore : examScore;

    const totalScore = Number(newCaScore) + Number(newExamScore);

    const grade = calculateGrade(totalScore);
    newScores[index] = {
      ...newScores[index],
      [name]: updatedScore,
      totalScore,
      grade,
    };

    setTableScore(newScores);
    setScores(newScores);
    setDisableButton(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (Object.keys(errors).length > 0) {
      toast.error("Please fix all validation errors before submitting");
      return;
    }

    setIsSubmitting(true);
    try {
      const payload = scores.map((score) => ({
        matricNumber: score.matricNumber,
        firstName: score.firstName,
        surName: score.surName,
        caScore: score.caScore,
        examScore: score.examScore,
        courseUniqueId: score.courseUniqueId,
        courseCode: score.courseCode,
        courseTitle: score.courseTitle,
        courseUnit: score.courseUnit,
        session: score.session,
        semester: score.semester,
        courseId: score.courseId,
        grade: score.grade,
        studentUniqueId: score.studentUniqueId,
      }));
      const response = await addOrUpdateExamScores(payload);
      if (response.status === 200) {
        toast.success("Scores uploaded successfully");
        fetchRegisteredStudents();
        setIsSubmitting(false);
      }
    } catch (error) {}
  };

  return (
    <div className="result-table">
      <form onSubmit={handleSubmit}>
        <Table bordered hover>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Matric Number</th>
              <th>Name</th>
              <th>CA Score</th>
              <th>Exam Score</th>
              <th>Total</th>
              <th>Grade</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              [...Array(20)].map((_, index) => (
                <tr key={index}>
                  {[...Array(7)].map((_, idx) => (
                    <td key={idx}>
                      <Skeleton height={20} />
                    </td>
                  ))}
                </tr>
              ))
            ) : !loading && tableScore.length > 0 ? (
              tableScore.map((data, index) => {
                const fullName = `${data.firstName} ${data.surName}`;
                const caError = errors[`caScore-${index}`];
                const examError = errors[`examScore-${index}`];

                return (
                  <tr key={index} className="result-tr">
                    <td>{currentPage * itemPerPage + index + 1}</td>
                    <td>{data.matricNumber}</td>
                    <td>{fullName}</td>
                    <td className="table-input">
                      <input
                        type="number"
                        name="caScore"
                        value={data.caScore ?? ""}
                        onChange={(e) =>
                          handleScoreChange(index, "caScore", e.target.value)
                        }
                        max={30}
                        className={caError ? "input-error" : ""}
                      />
                      {caError && (
                        <div className="error-message">{caError}</div>
                      )}
                    </td>
                    <td className="table-input">
                      <input
                        type="number"
                        name="examScore"
                        value={data.examScore ?? ""}
                        onChange={(e) =>
                          handleScoreChange(index, "examScore", e.target.value)
                        }
                       max={70}
                        className={examError ? "input-error" : ""}
                      />
                      {examError && (
                        <div className="error-message">{examError}</div>
                      )}
                    </td>

                    <td style={{ paddingLeft: "20px" }}> {data.totalScore}</td>
                    <td
                      style={{
                        paddingLeft: "20px",
                        color:
                          data?.totalScore && data.totalScore >= 40
                            ? "green"
                            : "red",
                        fontWeight: "800",
                        fontSize: "12px",
                      }}
                    >
                      {data.grade}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  <h5>No courses have been uploaded yet.</h5>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="bottom-btn-action">
          <SubmitButton
            loading={isSubmitting}
            buttonText="Click To Submit Score"
            disabled={isSubmitting || !disableButton || Object.keys(errors).length > 0}
            className="submit-btn"
          />
        </div>
      </form>
    </div>
  );
};

export default UploadScoreTable;
