/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { handleRequestErrors } from "../../../shared/utils";
import { Form } from "formik";
import InputField from "../../../shared/components/InputField";
import DropDown from "../../../shared/components/dropdown";
import { DotLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import {
  addOrUpdateStudentLevel,
  getStudentProfile,
} from "../../../redux/action/admission";
import "./index.scss";
interface Students {
  fullName: string;
  level: string;
  matricNumber: string;
}

const initialStudentValues = {
  fullName: "",
  level: "",
  matricNumber: "",
};

const updateLevelOptions = [
  { label: "100", value: "100" },
  { label: "200", value: "200" },
];

interface UpdateLevelFormProps {
  formikProps: any;
  setNewMatricNumber: (matricNumber: string) => void;
  setNewLevelSuccess: (success: boolean) => void;
  setStudentDetails: (details: Students) => void;
  studentDetails: Students;
}

const UpdateLevelForm = ({
  formikProps,
  setNewMatricNumber,
  setNewLevelSuccess,
  setStudentDetails,
  studentDetails,
}: UpdateLevelFormProps) => {
  const { values, touched, errors, handleChange, setFieldValue } = formikProps;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [newLevelFailure, setNewLevelFailure] = useState<string>("");
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const matricNumber = values.matricNumber;

  const handleGetStudentProfile = async (matricNo: string) => {
    setLoading(true);
    setError("");

    try {
      const data = await getStudentProfile(matricNo);
      setLoading(false);
      if (data?.data?.studentUniqueId) {
        const { level, fullName } = data.data;
        setStudentDetails({
          ...studentDetails,
          level,
          fullName,
          matricNumber: values.matricNumber,
        });
      }
    } catch (err: any) {
      setLoading(false);
      handleRequestErrors(err);
      if (err?.response?.status === 404) {
        setError(err?.response?.data);
        setStudentDetails(initialStudentValues);
      }
    }
  };

  useEffect(() => {
    setNewLevelFailure("");
    setShowDetails(true);
  }, [values.matricNumber, values.newLevel]);

  useEffect(() => {
    let searchDebounce: any;
    if (matricNumber.length >= 14) {
      searchDebounce = setTimeout(() => {
        handleGetStudentProfile(matricNumber);
      }, 1000);
    }
    return () => clearTimeout(searchDebounce);
  }, [matricNumber]);

  const handleSubmit = async (values: any) => {
    const { matricNumber, newLevel } = values;
    setIsSubmitting(true);
    try {
      const payload = {
        matricNumber,
        newLevel,
      };
      const response = await addOrUpdateStudentLevel(payload);
      if (response?.status === 200) {
        setNewLevelSuccess(true);
        setNewMatricNumber(response.data);
      } else if (response?.response?.status === 400) {
        setNewLevelFailure(response?.response?.data);
      }
    } catch (err: any) {
      handleRequestErrors(err);
    } finally {
      setShowDetails(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Form>
      <div className="update-form">
        {showDetails && (
          <div>
            {loading ? (
              <p className="student-name">Searching student...</p>
            ) : !loading && studentDetails?.fullName ? (
              <>
                <p className="student-name">
                  Name: <b>{studentDetails.fullName}</b>
                </p>
                <p className="student-name">
                  Current Level: <b>{studentDetails.level}</b>
                </p>
              </>
            ) : null}
            {error?.length && !loading ? (
              <p className="error-message">{error}</p>
            ) : null}
          </div>
        )}
        <div className="submit-info">
          <InputField
            field="matricNumber"
            label="Matric Number"
            name="matricNumber"
            value={values.matricNumber}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
          />
          <DropDown
            width="100%"
            name="newLevel"
            field="newLevel"
            label="Level"
            setFieldValue={setFieldValue}
            options={updateLevelOptions}
            value={
              values.newLevel
                ? { value: values.newLevel, label: values.newLevel }
                : null
            }
            touched={touched.newLevel}
            errors={errors.newLevel}
          />
        </div>

        <div className="button-container">
          <Button
            className="update-btn"
            onClick={() => handleSubmit(values)}
            disabled={
              isSubmitting ||
              loading ||
              !values.matricNumber ||
              !values.newLevel
            }
          >
            {isSubmitting ? (
              <>
                <DotLoader
                  color="white"
                  loading={isSubmitting}
                  size={30}
                  aria-label="Submitting"
                />
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
        {newLevelFailure && <p className="failure">{newLevelFailure}</p>}
      </div>
    </Form>
  );
};

export default UpdateLevelForm;
