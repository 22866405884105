import { useState } from "react";
import FacultyAssignment from "./FacultyAssignment";
import AssignUserToFacultySearch from "./AssignUserToFacultySearch";
import { useSelector } from "react-redux";
import { appConstantsPortal } from "../../../../redux/action-constants/portal";
import "../../../admin-settings/index.scss";
import Navigation from "../../../../shared/components/navigation/Navigation";
import SuccessMessage from "../../../../shared/components/confirmation-alert/SuccessMessage";

const MapUserToFaculty = () => {
  const [userId, setUserId] = useState("");
  const [requestSuccess, setRequestSuccess] = useState(false);
  const requestInfo = useSelector(
    (state: any) => state.allUGradPortalReducers.adminGetAUserReducer
  );
  return (
    <div className="settings_wrap_content">
      <div className="back-to-portal">
        <Navigation
          to="/app/e-documentation/settings"
          text="Back to e-documentation settings dashboard"
        />
      </div>
      {!requestSuccess ? (
        <>
          <AssignUserToFacultySearch setUserId={setUserId} />
          {requestInfo.request_status ===
            appConstantsPortal?.GET_A_USER_SUCCESS &&
            userId && (
              <FacultyAssignment
                userId={userId}
                requestSuccess={requestSuccess}
                setRequestSuccess={setRequestSuccess}
              />
            )}
        </>
      ) : (
        <SuccessMessage
        message="User successfully assigned to faculty."
        />
      )}
    </div>
  );
};

export default MapUserToFaculty;
