import React, { useMemo, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Container } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import {
    adminInAppActions,
} from "../../redux/action/portal";
import InputField from "../../shared/components/InputField";
import DropDown from "../../shared/components/dropdown";


export const NewAdvisorModal = ({
    showNew,
    setShowNew,
    selectedDepartment,
    setSelectedDepartment,
    selectedFaculty,
    setSelectedFaculty,
    selectedProgramme,
    setSelectedProgramme,
    setCurrentPage
}: any) => {
    const [ongoing, setOngoing] = useState(false);
    const { faculties, allProgrammes } = useSelector(
        (state: any) => state.admissionReducer
    );

    const formatFaculties = useMemo(
        () =>
            faculties.map((faculty: any) => {
                return {
                    value: faculty.undergraduateFacultyName,
                    label: faculty.undergraduateFacultyName,
                };
            }),
        [faculties]
    );

    const programmesList =
        allProgrammes
            .filter(
                (programme: any) => programme.departmentUniqueId === selectedDepartment
            )
            .map((prog: any) => {
                return {
                    value: prog.programmeName,
                    label: prog.programmeName,
                };
            }) || [];

    const departmentsOptions =
        faculties
            ?.find(
                (faculty: any) => faculty?.undergraduateFacultyName === selectedFaculty
            )
            ?.undergraduateDepartments?.map((dept: any) => {
                return {
                    value: dept?.undergraduateDepartmentName,
                    label: dept?.undergraduateDepartmentName,
                    id: dept?.undergraduateDepartmentUniqueId,
                };
            }) || [];



    let initialValues = {
        fullName: "",
        email: "",
        phoneNumber: "",
        facultyName: "",
        departmentName: "",
        programme: "",
        level: "",
        status: "ACTIVE",
    };
    let checkValidationSchema = Yup.object().shape({
        fullName: Yup.string().required("Required"),
        email: Yup.string().email().required("Valid email is required"),
        phoneNumber: Yup.string().required("Required"),
        facultyName: Yup.string().required("Required"),
        departmentName: Yup.string().required("Required"),
        programme: Yup.string().required("Required"),
        level: Yup.string().required("Required"),
    });

    const handleCloseModal = () => {
        if (!ongoing) {
            setOngoing(false);
            setShowNew(false)
            setSelectedProgramme("")
            setSelectedFaculty("")
            setSelectedDepartment("")
        }
    };

    return (
        <Modal
            show={showNew}
            onHide={handleCloseModal}
            className="records_modal_wrap"
            size="lg"
            contentClassName="records_modal_content"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">CREATE A COURSE ADVISOR</Modal.Title>
            </Modal.Header>
            <Modal.Body className="records-modal-form">
                <Formik
                    initialValues={initialValues}
                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        setCurrentPage(0)
                        setOngoing(true);
                        let payload = {
                            courseAdvisorUniqueId: "",
                            fullName: values.fullName,
                            email: values.email,
                            phoneNumber: values.phoneNumber,
                            facultyName: values.facultyName,
                            departmentName: values.departmentName,
                            programme: values.programme,
                            level: values.level,
                            status: "ACTIVE"
                        }

                        const request = await adminInAppActions.AdminAddOrUpdateCourseAdvisor(payload)

                        if (request?.status === 200) {
                            toast.success(`${values.fullName} has been successfully created as Course Advisor`);
                            setCurrentPage(1)
                            handleCloseModal()
                        } else {
                            toast.error(request);
                        }
                        setOngoing(false)


                    }}
                >{(props: FormikProps<any>) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        setFieldValue,
                        setFieldTouched,
                    } = props;

                    return (
                        <Form>
                            <Row>
                                <Col xs={12} md={12}>
                                    <InputField
                                        field="fullName"
                                        label="Full Name"
                                        value={values.fullName}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="email"
                                        label="Email"
                                        value={values.email}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="phoneNumber"
                                        label="Phone Number"
                                        value={values.phoneNumber}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Faculty"
                                        touched={touched}
                                        errors={errors}
                                        options={formatFaculties}
                                        field="facultyName"
                                        setFieldValue={setFieldValue}
                                        handleChange={setSelectedFaculty}
                                        setFieldTouched={setFieldTouched}
                                        value={{
                                            value: values.facultyName,
                                            label: values.facultyName,
                                        }}
                                        defaultValue={{
                                            value: values.facultyName,
                                            label: values.facultyName,
                                        }}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Department"
                                        touched={touched}
                                        errors={errors}
                                        options={departmentsOptions}
                                        field="departmentName"
                                        disabled={!values.facultyName}
                                        value={{
                                            value: values.departmentName,
                                            label: values.departmentName,
                                        }}
                                        defaultValue={{
                                            value: values.departmentName,
                                            label: values.departmentName,
                                        }}
                                        setFieldValue={setFieldValue}
                                        handleChange={setSelectedDepartment}
                                        setFieldTouched={setFieldTouched}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Course"
                                        name="programme"
                                        touched={touched}
                                        errors={errors}
                                        disabled={!values.departmentName}
                                        options={programmesList}
                                        field="programme"
                                        handleChange={setSelectedProgramme}
                                        value={{
                                            value: values.programme,
                                            label: values.programme,
                                        }}
                                        defaultValue={{
                                            value: values.programme,
                                            label: values.programme,
                                        }}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Level"
                                        touched={touched}
                                        errors={errors}
                                        disabled={!values.programme}
                                        value={{
                                            value: values.level,
                                            label: values.level,
                                        }}
                                        defaultValue={{
                                            value: values.level,
                                            label: values.level,
                                        }}
                                        options={[
                                            { value: "100", label: "100" },
                                            { value: "200", label: "200" },
                                            { value: "300", label: "300" },
                                            { value: "400", label: "400" },
                                            { value: "500", label: "500" },
                                            { value: "600", label: "600" },
                                        ]}
                                        field="level"
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                            </Row>
                            <div className="modal-footer">
                                <Button
                                    className="cancel-btn"
                                    onClick={() => setShowNew(false)}
                                    disabled={ongoing}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submit-btn"
                                    type="submit"
                                    disabled={ongoing}
                                >
                                    {ongoing ? (
                                        <DotLoader
                                            color="white"
                                            loading={ongoing}
                                            size={30}
                                            aria-label="Submitting"
                                        />
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </div>
                        </Form>

                    )

                }}
                </Formik>
            </Modal.Body>
        </Modal>
    )

}


export const EditAdvisorModal = ({
    showEdit,
    setShowEdit,
    selectedDepartment,
    setSelectedDepartment,
    selectedFaculty,
    setSelectedFaculty,
    selectedProgramme,
    setSelectedProgramme,
    setCurrentPage,
    selectedAdvisor,
    setSelectedAdvisor
}: any) => {
    const [ongoing, setOngoing] = useState(false);
    const { faculties, allProgrammes } = useSelector(
        (state: any) => state.admissionReducer
    );



    const formatFaculties = useMemo(
        () =>
            faculties.map((faculty: any) => {
                return {
                    value: faculty.undergraduateFacultyName,
                    label: faculty.undergraduateFacultyName,
                };
            }),
        [faculties]
    );

    const programmesList =
        allProgrammes
            .filter(
                (programme: any) => programme.departmentUniqueId === selectedDepartment
            )
            .map((prog: any) => {
                return {
                    value: prog.programmeName,
                    label: prog.programmeName,
                };
            }) || [];

    const departmentsOptions =
        faculties
            ?.find(
                (faculty: any) => faculty?.undergraduateFacultyName === selectedFaculty
            )
            ?.undergraduateDepartments?.map((dept: any) => {
                return {
                    value: dept?.undergraduateDepartmentName,
                    label: dept?.undergraduateDepartmentName,
                    id: dept?.undergraduateDepartmentUniqueId,
                };
            }) || [];



    let initialValues = {
        fullName: selectedAdvisor?.fullName,
        email: selectedAdvisor?.email,
        phoneNumber: selectedAdvisor?.phoneNumber,
        facultyName: selectedAdvisor?.facultyName,
        departmentName: selectedAdvisor?.departmentName,
        programme: selectedAdvisor?.programme,
        level: selectedAdvisor?.level,
        status: selectedAdvisor?.status,
    };
    let checkValidationSchema = Yup.object().shape({
        fullName: Yup.string().required("Required"),
        email: Yup.string().email().required("Valid email is required"),
        phoneNumber: Yup.string().required("Required"),
        facultyName: Yup.string().required("Required"),
        departmentName: Yup.string().required("Required"),
        programme: Yup.string().required("Required"),
        level: Yup.string().required("Required"),
        status: Yup.string().required("Required"),
    });

    const handleCloseModal = () => {
        if (!ongoing) {
            setOngoing(false);
            setShowEdit(false)
            setSelectedProgramme()
            setSelectedFaculty()
            setSelectedDepartment()
            setSelectedAdvisor()
        }
    };

    return (
        <Modal
            show={showEdit}
            onHide={handleCloseModal}
            className="records_modal_wrap"
            size="lg"
            contentClassName="records_modal_content"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">EDIT A COURSE ADVISOR</Modal.Title>
            </Modal.Header>
            <Modal.Body className="records-modal-form">
                <Formik
                    initialValues={initialValues}
                    validationSchema={checkValidationSchema}
                    onSubmit={async (values) => {
                        setCurrentPage(0)
                        setOngoing(true);
                        let payload = {
                            courseAdvisorUniqueId: selectedAdvisor?.courseAdvisorUniqueId,
                            fullName: values.fullName,
                            email: values.email,
                            phoneNumber: values.phoneNumber,
                            facultyName: values.facultyName,
                            departmentName: values.departmentName,
                            programme: values.programme,
                            level: values.level,
                            status: values.status
                        }

                        const request = await adminInAppActions.AdminAddOrUpdateCourseAdvisor(payload)

                        if (request?.status === 200) {
                            toast.success(`Details has been successfully updated`);
                            setCurrentPage(1)
                            handleCloseModal()
                        } else {
                            setCurrentPage(1)
                            toast.error(request);
                        }
                        setOngoing(false)


                    }}
                >{(props: FormikProps<any>) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        setFieldValue,
                        setFieldTouched,
                    } = props;

                    return (
                        <Form>
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="fullName"
                                        label="Full Name"
                                        value={values.fullName}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Status"
                                        touched={touched}
                                        errors={errors}
                                        value={{
                                            value: values.status,
                                            label: values.status,
                                        }}
                                        defaultValue={{
                                            value: values.level,
                                            label: values.level,
                                        }}
                                        options={[
                                            { value: "ACTIVE", label: "ACTIVE" },
                                            { value: "REVOKED", label: "REVOKED" },
                                            { value: "DE-ACTIVATED", label: "DE-ACTIVATED" },
                                        ]}
                                        field="status"
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="email"
                                        label="Email"
                                        value={values.email}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <InputField
                                        field="phoneNumber"
                                        label="Phone Number"
                                        value={values.phoneNumber}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Faculty"
                                        touched={touched}
                                        errors={errors}
                                        options={formatFaculties}
                                        field="facultyName"
                                        setFieldValue={setFieldValue}
                                        handleChange={setSelectedFaculty}
                                        setFieldTouched={setFieldTouched}
                                        value={{
                                            value: values.facultyName,
                                            label: values.facultyName,
                                        }}
                                        defaultValue={{
                                            value: values.facultyName,
                                            label: values.facultyName,
                                        }}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Department"
                                        touched={touched}
                                        errors={errors}
                                        options={departmentsOptions}
                                        field="departmentName"
                                        disabled={!values.facultyName}
                                        value={{
                                            value: values.departmentName,
                                            label: values.departmentName,
                                        }}
                                        defaultValue={{
                                            value: values.departmentName,
                                            label: values.departmentName,
                                        }}
                                        setFieldValue={setFieldValue}
                                        handleChange={setSelectedDepartment}
                                        setFieldTouched={setFieldTouched}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Course"
                                        name="programme"
                                        touched={touched}
                                        errors={errors}
                                        disabled={!values.departmentName}
                                        options={programmesList}
                                        field="programme"
                                        handleChange={setSelectedProgramme}
                                        value={{
                                            value: values.programme,
                                            label: values.programme,
                                        }}
                                        defaultValue={{
                                            value: values.programme,
                                            label: values.programme,
                                        }}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <DropDown
                                        label="Level"
                                        touched={touched}
                                        errors={errors}
                                        disabled={!values.programme}
                                        value={{
                                            value: values.level,
                                            label: values.level,
                                        }}
                                        defaultValue={{
                                            value: values.level,
                                            label: values.level,
                                        }}
                                        options={[
                                            { value: "100", label: "100" },
                                            { value: "200", label: "200" },
                                            { value: "300", label: "300" },
                                            { value: "400", label: "400" },
                                            { value: "500", label: "500" },
                                            { value: "600", label: "600" },
                                        ]}
                                        field="level"
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        classNamePrefix="custom-fields"
                                    />
                                </Col>
                            </Row>
                            <div className="modal-footer">
                                <Button
                                    className="cancel-btn"
                                    onClick={() => {
                                        if (!ongoing) {
                                            setShowEdit(false)
                                        }
                                    }}
                                    disabled={ongoing}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="submit-btn"
                                    type="submit"
                                    disabled={ongoing}
                                >
                                    {ongoing ? (
                                        <DotLoader
                                            color="white"
                                            loading={ongoing}
                                            size={30}
                                            aria-label="Submitting"
                                        />
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </div>
                        </Form>

                    )

                }}
                </Formik>
            </Modal.Body>
        </Modal>
    )

}

export const DeleteAdvisorModal = ({
    showDelete,
    setShowDelete,
    selectedAdvisor,
    setSelectedAdvisor,
    setCurrentPage
}: any) => {
    const [ongoing, setOngoing] = useState(false);







    const handleCloseModal = () => {
        if (!ongoing) {
            setOngoing(false);
            setShowDelete(false)
            setSelectedAdvisor()
        }
    };

    const handleDeleteRecord = async () => {
        setCurrentPage(0)
        setOngoing(true)
        const request = await adminInAppActions.AdminDeleteACourseAdvisor(selectedAdvisor?.courseAdvisorUniqueId)

        if (request?.status === 200) {
            toast.success(`${selectedAdvisor.fullName} has been successfully deleted as Course Advisor`);
            setCurrentPage(1)
            handleCloseModal()
        } else {
            toast.error(request);
        }
        setOngoing(false)
    };

    return (
        <Modal
            show={showDelete}
            onHide={handleCloseModal}
            className="records_modal_wrap"
            size="lg"
            contentClassName="records_modal_content"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">DELETE A COURSE ADVISOR</Modal.Title>
            </Modal.Header>
            <Modal.Body className="records-modal-form">

                <div className="confirm-deletio">
                    KINDLY CONFIRM YOU WANT TO DELETE <strong> {selectedAdvisor?.fullName} </strong> as COURSE ADVISOR OF <strong> {selectedAdvisor?.level} {selectedAdvisor?.departmentName}</strong>
                </div>

                <div className="modal-footer">
                    <Button
                        className="cancel-btn"
                        onClick={() => {
                            if (!ongoing) {
                                setShowDelete(false)
                            }
                        }}
                        disabled={ongoing}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="submit-btn"
                        type="button"
                        onClick={handleDeleteRecord}
                        disabled={ongoing}
                    >
                        {ongoing ? (
                            <DotLoader
                                color="white"
                                loading={ongoing}
                                size={30}
                                aria-label="Please wait"
                            />
                        ) : (
                            "Proceed"
                        )}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )

}


export const BulkUploadModal = ({ show, setShow, setCurrentPage }: any) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [ongoing, setOngoing] = useState(false);

    const dispatch = useDispatch();
    let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
    loggedAccountInfo = JSON.parse(loggedAccountInfo);


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (selectedFile) {
            if (selectedFile.size > 1048576) {
                return;
            }
            setCurrentPage(0)
            setOngoing(true)
            const formData = new FormData();
            formData.append("File", selectedFile);

            const request = await adminInAppActions.AdminBulkUploadCourseAdvisor(formData);
            if (request?.status === 200) {
                toast.success(`Course Advisors Bulk Data has been successfully uploaded`);
                setCurrentPage(1)
                setShow(false)
            } else {
                toast.error(request);
            }
            setOngoing(false)
        }
    };
    return (
        <Modal
            show={show}
            contentClassName="putme-bulk-upload-modal"
            onHide={() => {
                if (!ongoing) {
                    setShow(false)
                }
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h5>BULK UPLOAD</h5>
            </Modal.Header>
            <Modal.Body className="report-main-body">
                <Container>


                    <input
                        type="file"
                        className="file-input"
                        style={{ cursor: "pointer" }}
                        accept=".xls,.xlsx"
                        size={1048576}
                        onChange={handleFileChange}
                    />
                    {selectedFile && selectedFile.size > 1048576 ? (
                        <p className="input-error">Max file size is 1MB</p>
                    ) : null}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                
                <Button
                        className="submit-btn"
                        type="button"
                        onClick={handleUpload}
                        disabled={ongoing}
                    >
                        {ongoing ? (
                            <DotLoader
                                color="white"
                                loading={ongoing}
                                size={30}
                                aria-label="Please wait"
                            />
                        ) : (
                            "Upload"
                        )}
                    </Button>
            </Modal.Footer>
        </Modal>
    );
};